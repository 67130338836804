import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import CircleIcon from '@mui/icons-material/Circle';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, Divider } from '@mui/material';
import { desktop_h3, desktop_p3 } from '../styles/textStyles';
import { regularBtnStyles } from '../styles/buttons';
import { neutralColors, primaryColors, yellowColors } from '../styles/colors';
import { useCustomerState } from '../context/customer/CustomerContext';

interface HomeCardProps {
  title?: string | undefined,
  subtitle: string,
  active: boolean,
  url: string,
  internalUrl: boolean,
  logo?: string | undefined
}

const HomeCard = (props: HomeCardProps) => {

  const { title, subtitle, active, url, internalUrl, logo } = props
  const history = useHistory()
  const customerState = useCustomerState()
  const { selected } = customerState

  const isSubscriptionAdmin = selected?.name.indexOf('Kiva') !== -1 && selected?.roles.indexOf("subscriptionAdmin") !== -1

  const handleClick = () => {

    if(active){
      if (internalUrl) {
        history.push(url)
      } else {
        window.open(url)
      }
    }else{
      if(isSubscriptionAdmin){
        history.push('/admin')
      }else{
        history.push('/contact-us')
      }
    }

  }

  return (
    <Card sx={cardStyles}
      onClick={handleClick}>

      <CardContent sx={{padding: 0, margin: '0 12px'}}>
        {title !== undefined && <Typography variant="h3" sx={desktop_h3}>
          {title}
        </Typography>}
        <Divider sx={{mt: '12px', mb: '12px', background: '#000', height:'1px'}} />
        {logo !== undefined && <Typography>
          <img width={logo.includes('css') ? 100 : 120} src={logo.includes('css') ? '/kiva-css.png' : '/kiva-analytics.png'} />
        </Typography>}
        <Typography variant="subtitle1" sx={desktop_p3}>
          {subtitle}
        </Typography>
      </CardContent>

      <CardActions sx={{mt: '20px'}}>
        <Grid container style={{ border: 'none', height: '32px'}}>
          <Grid container style={{ justifyContent: "center", alignItems: 'center', width:'100%' }}>
            <Grid item><Button sx={{...regularBtnStyles, color: active ? primaryColors[950] : neutralColors[950], background: active ? primaryColors[100]: yellowColors[50], '&:hover': { background: active ?  primaryColors[300] : yellowColors[200]}}} style={{}}>{active ? 'Launch' : (isSubscriptionAdmin ? 'Activate' : 'Contact Us')}</Button></Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>

  );
}

const cardStyles = {
  boxSizing: 'border-box',
  border: 'none',
  width: '100%',
  minHeight: '180px',
  height: '100%',
  padding: '24px 24px 12px',
  borderRadius: '15px',
  backgroundColor: `${neutralColors[50]}`,
  boxShadow: 'none',
  display:'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'left',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: `${neutralColors[50]}`,

  }
}

export default HomeCard;
