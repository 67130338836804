import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { regularBtnStyles } from "../../styles/buttons"
import styled from "styled-components"
import { apiFetchCustomDatasetPages, apiFetchCustomDatasetPagesFilters } from "../../api/Assistant"
import { CustomDatasetPagesResponse, PageFilters } from "../../api/Types"
import { debouncePromise } from "../../utils/Generators"
import { CheckBox, Close } from "@mui/icons-material"
import { questionTemplates } from "./templateQuestions"


const Fields = styled.div`
  display:grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 1em;
`

const Pages = styled.div`
`

const PageDescription = styled.div`
  font-size:0.8em;
`

const PageLink = styled.a`
  font-size:0.7em;
`

const Prefix = styled.span`
  font-style:italic;
`

const CloseContainer = styled.div`
  position:absolute;
  top:1em;
  right:1em;
`

export type FlexibleLoaderDialogProps = {
  customerId: number
  setOpen: (open: boolean) => void
  setLoading: (loading: boolean) => void
  onImport: (rows: { question: string, answer: string }[]) => void
  open: boolean
}

const apiFetchCustomDatasetPagesFiltersDebounced = debouncePromise<PageFilters | null>(apiFetchCustomDatasetPagesFilters, 1000)


export const FlexibleLoaderDialog = (props: FlexibleLoaderDialogProps) => {
  const { customerId, open, setOpen, setLoading, onImport } = props
  const [url, setUrl] = useState("")
  const [filters, setFilters] = useState<PageFilters>({ urls: [], title: "title", description: '[class="description"]', titlePostfix: "" })
  const [pages, setPages] = useState<CustomDatasetPagesResponse | undefined>()
  const [titlePrefix, setTitlePrefix] = useState("Kuvaile")
  const [checked, setChecked] = useState(false)
  const[prefixDisabled, setPrefixDisabled] = useState(false)

  const fetchProducts = () => {
    setLoading(true)
    apiFetchCustomDatasetPages(customerId, url, filters, 4).then((response) => {
      setPages(response)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const importPages = () => {
    setLoading(true)
    if (pages) {
      apiFetchCustomDatasetPages(customerId, url, filters, pages.total).then((response) => {
        setOpen(false)
        setLoading(false)
        onImport(response.previewPages.map(p => ({ question: `${checked ? questionTemplates[(Math.random() * questionTemplates.length) | 0 ] : titlePrefix}  ${p.title!}`, answer: p.description! })))
      }).catch(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    apiFetchCustomDatasetPagesFiltersDebounced(customerId, url).then((filters: any) => {
      if (filters) {
        setFilters(filters)
      }
    })
  }, [url])

  return (<Dialog fullWidth
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>
      Import Examples
    </DialogTitle>
    <DialogContent>
      <CloseContainer>
        <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
      </CloseContainer>
      <Fields>
        <FormControl fullWidth>
          <TextField style={{ marginTop: '0.5em' }} label="Sitemap URL" value={url} size="small" onChange={(ev) => { setUrl(ev.target.value) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="URL filter" value={filters.urls.join(",")} size="small"
            onChange={(ev) => { setFilters({ ...filters, urls: ev.target.value.split(",") }) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Title selector" value={filters.title} size="small"
            onChange={(ev) => { setFilters({ ...filters, title: ev.target.value }) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Description selector" value={filters.description} size="small"
            onChange={(ev) => { setFilters({ ...filters, description: ev.target.value }) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Title postfix" value={filters.titlePostfix} size="small"
            onChange={(ev) => { setFilters({ ...filters, titlePostfix: ev.target.value }) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Title prefix" value={titlePrefix} size="small" onChange={(ev) => { setTitlePrefix(ev.target.value) }} disabled={prefixDisabled} />
        </FormControl>
        <FormControl fullWidth>
        <FormControlLabel
          value="start"
          control={
          <Checkbox
            checked={checked}
            onChange={(event) => {setChecked(event.target.checked); 
              if(checked){
                setPrefixDisabled(false)
              } else {
                setPrefixDisabled(true)
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}/>}
          label="Generate Title Prefixes"
          labelPlacement="start"
        />
        </FormControl>
        <Button sx={{ ...regularBtnStyles, maxWidth: '200px' }} disabled={!url.length} onClick={() => { fetchProducts() }}>Fetch</Button>
      </Fields>
      {pages ? <h4>Found {pages.total} pages</h4> : <h4>No pages loaded yet.</h4>}
      {pages && <Pages>
        {pages.previewPages.map(p => <>
          <h5><Prefix>{checked ? questionTemplates[(Math.random() * questionTemplates.length) | 0 ] : titlePrefix}</Prefix> {p.title}</h5>
          <PageDescription>{p.description}</PageDescription>
          <PageLink target="_blank" href={p.url}>{p.url}</PageLink>
        </>)}
      </Pages>}
      <hr />
      {pages && pages.total > 0 && <Button sx={{ ...regularBtnStyles, maxWidth: '200px' }} onClick={() => importPages()}>
        Import {pages.total} pages
      </Button>}
    </DialogContent>
    <DialogActions>
    </DialogActions>
  </Dialog>)
}