import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdvancedPostBoosting } from '../../../context/meta/MetaActions';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { DevicePlatforms } from '../../../context/meta/MetaTypes';
import PublisherPlatforms from './PublisherPlatforms';
import { useLocation } from 'react-router-dom';


const TargetingPlacements = () => {
  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')
  
  const [{ adAutomationSettings, advancedPostBoosting }, dispatch] = useStateValue()

  const { targeting } = adAutomationSettings.adSet
  const { device_platforms } = adAutomationSettings.adSet.targeting

  const [placementsSelction, setPlacementsSelection] = useState<string>(metaAdAutoLocation ? targeting.placementsSelection : advancedPostBoosting.adSet.targeting.placementsSelection)
  const [devicePlatforms, setDevicePlatforms] = useState<DevicePlatforms[]>(metaAdAutoLocation ? device_platforms || [] : advancedPostBoosting.adSet.targeting.device_platforms || [])

  const handlePlacementsSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    setPlacementsSelection(e.target.value)
    // Reset placements fields when the user chooses to select them automatically.
    if (value === 'automatically') {
      if(metaAdAutoLocation){
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting,
              device_platforms: [],
              publisher_platforms: [],
              facebook_positions: [],
              audience_network_positions: [],
              instagram_positions: [],
              messenger_positions: []
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting,
              device_platforms: [],
              publisher_platforms: [],
              facebook_positions: [],
              audience_network_positions: [],
              instagram_positions: [],
              messenger_positions: []
            }
          }
        }))
      }
    }
    if(metaAdAutoLocation){
      dispatch(setAdAutomationSettings({
        ...adAutomationSettings, adSet: {
          ...adAutomationSettings.adSet, targeting: {
            ...adAutomationSettings.adSet.targeting, placementsSelection: e.target.value
          }
        }
      }))
    } else {
      dispatch(setAdvancedPostBoosting({
        ...advancedPostBoosting, adSet: {
          ...advancedPostBoosting.adSet, targeting: {
            ...advancedPostBoosting.adSet.targeting, placementsSelection: e.target.value
          }
        }
      }))
    }
  }

  const handleDevicesSelection = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as DevicePlatforms
    setDevicePlatforms([value])
    if (value === 'all') {
      if(metaAdAutoLocation){
        // To target all devices send an empty device_platforms array OR don't send it at all.
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, device_platforms: []
            }
          }
        }))
      }else{
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, device_platforms: []
            }
          }
        }))
      }
    } else {
      if(metaAdAutoLocation){
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, device_platforms: [value]
            }
          }
        }))
      }else{
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, device_platforms: [value]
            }
          }
        }))
      }
    }
  }

  return (
    <>
      <Grid item xs={12} sx={{mt: 3, mb: 2}}><Divider /></Grid>

      <Grid item xs={12}>
        <Typography variant='h6'>Placements</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Select placements</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={placementsSelction}
            onChange={handlePlacementsSelection}
          >
            <FormControlLabel value="automatically" control={<Radio />} label="Automatically" />
            <FormControlLabel value="manually" control={<Radio />} label="Manually" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {placementsSelction === "manually" && <>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontSize: '18px' }}>Devices</Typography>
        </Grid>

        <Grid item xs={6}>

          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="gender-select-label">Devices</InputLabel>
            <Select
              labelId="devices-select-label"
              id="devices-selection"
              value={devicePlatforms.length > 0 ? devicePlatforms[0] : 'all'}
              onChange={handleDevicesSelection}
              label="Devices selection"
            >
              <MenuItem sx={{ fontSize: '14px' }} value={"mobile"}>Mobile</MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value={"desktop"}>Desktop</MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value={"all"}>All</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <PublisherPlatforms/>

      </>}

    </>
  )
}

export default TargetingPlacements;