import { useEffect, useState } from 'react'
import { InputBase, Box, Divider, IconButton, CircularProgress, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export type FilterFieldProps = {
  filter: string,
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const FilterField = ({filter, handleFilterChange}: FilterFieldProps) => {

  return (
    <form>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <InputBase
          placeholder="Filter..."
          value={filter}
          onChange={handleFilterChange}
          sx={{width: '100%'}}
        />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 4 }} />
    </form>
  )
}

export default FilterField