import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdvancedPostBoosting, setMatchedPosts } from '../../../context/meta/MetaActions';
import { PostBoostingCondition } from '../../../context/meta/MetaTypes';
import IndividualCondition from './IndividualCondition';
import * as uuid from 'uuid'


interface AndOrConditionsProps {
  handleApplyConditions?: any
  handleFiltersChange: any
}
const AndOrConditions = ({ handleFiltersChange }: AndOrConditionsProps) => {

  const [{ allPosts, matchedPosts, advancedPostBoosting }, dispatch] = useStateValue()

  React.useEffect(() => {
    handleFiltersChange()
  }, [advancedPostBoosting.filters])

  const handleLogicalOperatorChange = (_event: React.MouseEvent<HTMLElement>, operator: string) => {
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, filters: { ...advancedPostBoosting.filters, logicalOperator: operator, conditions: [] } }));
  };

  const handleAddNewCondition = () => {
    const newCondition: PostBoostingCondition = {
      id: uuid.v4(),
      conditionText: 'comments',
      operator: '>',
      textOperator: 'contains',
      value: 0
    }
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, filters: { ...advancedPostBoosting.filters, conditions: [...advancedPostBoosting.filters.conditions, newCondition] } }))

  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h5'>Conditions</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1' sx={{ mb: 1, color: 'rgba(0, 0, 0, 0.6)' }}>Logical operator</Typography>
        <ToggleButtonGroup
          color="primary"
          value={advancedPostBoosting.filters.logicalOperator}
          exclusive
          onChange={handleLogicalOperatorChange}
          aria-label="Platform"
        >
          <ToggleButton sx={{ width: '60px' }} value="and">And</ToggleButton>
          <ToggleButton sx={{ width: '60px' }} value="or">Or</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {advancedPostBoosting.filters.conditions.map((c: PostBoostingCondition, index: number) => (
        <React.Fragment key={c.id}>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <IndividualCondition condition={c} />
          </Grid>
          <Grid item xs={12}>
            {advancedPostBoosting.filters.conditions.length > 1 &&
              advancedPostBoosting.filters.conditions.length - 1 !== index &&
              <Typography variant='body1' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{advancedPostBoosting.filters.logicalOperator.toUpperCase()}</Typography>
            }
          </Grid>
        </React.Fragment>
      )
      )}
      <Grid item xs={3} sx={{ marginTop: '24px' }}>
        <Button variant="contained" onClick={handleAddNewCondition} sx={{ textTransform: 'none' }}>
          Add condition
        </Button>
      </Grid>

      <Grid item xs={12} sx={{ mt: 6, mb: 3 }}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default AndOrConditions;