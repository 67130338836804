
export const montserratBoldFontFamily = `'Montserrat-Bold', Arial, Helvetica, sans-serif`;
export const montserratSemiBoldFontFamily = `Montserrat-SemiBold, Arial, Helvetica, sans-serif`;

export const desktop_h1 = {
  fontSize: "28px",
  fontStyle: "normal",
  lineHeight: "114.286%",
  letterSpacing: "-0.56px",
};

export const desktop_h2 = {
  fontSize: "22px",
  fontStyle: "normal",
  lineHeight: "109.091%",
  letterSpacing: "-0.44px",
};

export const desktop_h3 = {
  fontSize: "18px",
  fontStyle: "normal",
  lineHeight: "111.111%",
  letterSpacing: "-0.36px",
};

export const desktop_h4 = {
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "110%",
  letterSpacing: "-0.32px",
};


export const desktop_p1 = {
  fontSize: "18px",
  fontStyle: "normal",
  lineHeight: "155.556%",
};

export const desktop_p2 = {
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "150%",
};

export const desktop_p3 = {
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "142.857%",
};

export const desktop_labels_l1 = {
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "100%",
};
