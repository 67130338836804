import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { apiGetManagedAdDataMappings, apiSetManagedAdDataMappings } from "../../api/Customer"
import { ManageAdDataMapping, ManagedAdDataSource, ManagedAdDataType } from "../../api/Types"
import { debounce } from "../../utils/Generators"


const SubTitle = styled.div`
  font-size:1.1em;
`


const initialDataMapping: ManageAdDataMapping = {
  templateParameter: "",
  type: "Static",
  value: "",
  path: ""
}

export type TemplateParametersProps = {
  customerId: number,
  creativeId: string,
  datasources: ManagedAdDataSource[]
  onUpdated: () => void
}

const debouncedSetManagedAdDataMappings = debounce(apiSetManagedAdDataMappings)

export const TemplateParametersView = (props: TemplateParametersProps) => {
  const { customerId, creativeId, datasources, onUpdated } = props
  const [dataMappingRequiresUpdate, setDataMappingRequiresUpdate] = useState(false)
  const [dataMappings, setDataMappings] = useState<ManageAdDataMapping[]>([])

  useEffect(() => {
    if (dataMappingRequiresUpdate) {
      setDataMappingRequiresUpdate(false)
      debouncedSetManagedAdDataMappings(customerId, creativeId, dataMappings, () => {
        onUpdated()
      })
    }
  }, [dataMappingRequiresUpdate])

  useEffect(() => {
    apiGetManagedAdDataMappings(customerId, creativeId).then((dataMappings) => setDataMappings(dataMappings))
  }, [creativeId])

  const updateDataMapping = (newDm: ManageAdDataMapping) => {
    const newMappings = dataMappings.map(dm => dm.templateParameter === newDm.templateParameter ? newDm : dm)
    setDataMappings(newMappings)
    setDataMappingRequiresUpdate(true)
  }

  return (<Box>
    <Grid container rowGap={3}>
      {dataMappings.map(dm => <Grid item xs={4}>
        <Stack spacing={2} maxWidth={300}>
          <div>Parameter "{dm.templateParameter}"</div>
          <FormControl>
            <InputLabel size="small">Source type</InputLabel>
            <Select size="small" label="Source Type" value={dm.type}
              onChange={(ev) => updateDataMapping({ ...dm, type: ev.target.value as ManagedAdDataType })}>
              <MenuItem value="Static">Static</MenuItem>
              <MenuItem value="Dynamic">Dynamic</MenuItem>
            </Select>
          </FormControl>

          {dm.type === "Static" && <FormControl>
            <TextField label="Value" size="small" value={dm.value}
              onChange={(ev) => updateDataMapping({ ...dm, value: ev.target.value })} />
          </FormControl>}
          {dm.type === "Dynamic" && <FormControl>
            <InputLabel size="small">Data source</InputLabel>
            <Select size="small" label="Data source" value={dm.path}
              onChange={(ev) => updateDataMapping({ ...dm, path: ev.target.value })}>
              {datasources.map((p, index) => <MenuItem key={index} value={p.key}>{p.key}({p.value}{p.unit})</MenuItem>)}
            </Select>
          </FormControl>}
        </Stack>
      </Grid>)}
    </Grid>
  </Box>)
}