import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Divider, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useCustomerState } from "../context/customer/CustomerContext";
import { ShopBody, apiGetShop, apiSendShop, apiValidateFeed } from "../api/Css";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { countryCodes, currencies, languages } from "../components/Utils";
import styled from "styled-components";
import LanguageList from "../components/Css/Languages";
import { desktop_h1 } from "../styles/textStyles";
import { regularBtnStyles } from "../styles/buttons";
import { useAlertStateValue } from "../context/alert/AlertContext";
import { LoadingModal } from "../components/Modal";
import { AlertSeverity } from "../context/alert/AlertTypes";
import { setAlert } from "../context/alert/AlertAction";


const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}

const textFieldTextStyles = {
  mb: 0.3
}


const Value = styled.div`
    font-display: swap;
    font-size: 1.0em;
    padding-left:0.5em;
    margin-left:0.2em;
    margin-right:0.3em;
`
const CountryList = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`
const DropDownValue = styled.div`
    font-display: swap;
    font-size: 0.6em;
    display:grid;
    grid-template-columns: 2em 1fr;
    align-items: middle;
    min-height:2.0em;
    max-height:2.0em;
`
const CountryCheckBox = styled.input`
    font-display: swap;
    font-size: 11px;
`
const CountryLabel = styled.label`
    font-display: swap;
    font-size: 11px;
`
const Label = styled.div`
    font-display: swap;
    font-size: 1em;
    line-height: 100%;
    margin-bottom: 8px;
`
const Content = styled.div`
  display:grid;
  grid-template-columns:1fr;
  column-gap:25px;
  position:relative;
`
export const CssView = () => {
  const { selected } = useCustomerState()
  const [name, setName] = useState<string>('')
  const [websiteUrl, setWebsiteUrl] = useState<string>('')
  const [currency, setCurrency] = useState<string>('EUR')
  const [language, setLanguage] = useState<string>('fi')
  const [productsUrl, setProductsUrl] = useState<string>('')
  const [merchantCountries, setMerchantCountries] = useState<string[]>(['FI'])
  const [merchantId, setMerchantId] = useState<string>('')
  const [waitingSave, setWaitingSave] = useState(false)
  const [_, alertDispatch] = useAlertStateValue()
  const [loading, setLoading] = useState(false)
  const [validFeed, setValidFeed] = useState(true)

  const postShop = () => {
    const body: ShopBody = {
      name,
      websiteUrl,
      currency,
      language,
      config: {
        productsUrl,
        productUrlTemplate: ''
      },
      merchantId,
      merchantCountries
    }
    if (selected) {
      setWaitingSave(true)
      setValidFeed(false)
      apiValidateFeed(selected.id, {feed: productsUrl}).then((e) => {
        if(e.valid !== undefined && e.valid === true) {
            setWaitingSave(false)
            setValidFeed(true)
        } else {
            setWaitingSave(false)
            setValidFeed(false)
        }
      }).catch(() => {
        setWaitingSave(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in saving. Product Catalog url is invalid.", duration: 5000 }))
      })
      if(validFeed === true){
        apiSendShop(selected.id, body).then(() => {
            setValidFeed(true)
            setWaitingSave(false)
          }).catch(() => {
            setWaitingSave(false)
            setValidFeed(true)
            alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in saving. Please check inputs.", duration: 5000 }))
        })
      }
      
    }
  }
  useEffect(() => {
    if (selected) {
      setLoading(true)
      apiGetShop(selected.id).then(response => {
        setName(response.name);
        setWebsiteUrl(response.websiteUrl);
        setCurrency(response.currency || 'EUR');
        setLanguage(response.language || 'fi');
        setProductsUrl(response.config?.productsUrl);
        setMerchantCountries(response.merchantCountries || ['FI']);
        setMerchantId(response.merchantId)
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    }
  }, [selected])
  return (
    <Grid container sx={{ margin: '50px' }}>
      <Grid item xs={12} sx={{ marginBottom: 3 }}>
        <Typography variant="h1" sx={desktop_h1}>Kiva CSS</Typography>
      </Grid>
      <Grid container sx={{ maxWidth: 800 }} rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={gridItemStyles}>
          <Label>Shop name</Label>
          <TextField fullWidth value={name} onChange={(e) => { setName(e.target.value) }} size="small" sx={{ border: 'none' }} />
        </Grid>

        <Grid item xs={4} sx={gridItemStyles}>
          <Label>Merchant ID</Label>
          <TextField fullWidth value={merchantId} onChange={(e) => { setMerchantId(e.target.value) }} size="small" sx={{ border: 'none' }} />
        </Grid>

        <Grid item xs={6} sx={gridItemStyles}>
          <Label>Shop URL</Label>
          <TextField fullWidth value={websiteUrl} onChange={(e) => { setWebsiteUrl(e.target.value) }} size="small" sx={{ border: 'none' }} />
        </Grid>

        <Grid item xs={6} sx={gridItemStyles}>
          <Label>Product catalog URL (Google XML feed)</Label>
          <TextField error={!validFeed} fullWidth value={productsUrl} onChange={(e) => { setProductsUrl(e.target.value) }} size="small" sx={{ border: 'none' }} />
        </Grid>

        <Grid item xs={6} sx={gridItemStyles}>
          <Label>Country</Label>
          <Select size="small" fullWidth={true} value={merchantCountries[0]}
            onChange={(ev) => setMerchantCountries([ev.target.value])}>
            {
              countryCodes.map(cc => <MenuItem key={cc.code} value={cc.code}>{cc.name}</MenuItem>)
            }
          </Select>
        </Grid>

        <Grid item xs={6} sx={gridItemStyles}>
          <Label>Language</Label>
          <Select size="small" fullWidth={true} value={language}
            onChange={(ev) => setLanguage(ev.target.value)}>
            {
              languages.map(l => <MenuItem key={l.code} value={l.code}>{l.name}</MenuItem>)
            }
          </Select>
        </Grid>

        <Grid item xs={6} sx={gridItemStyles}>
          <Label>Currency</Label>
          <Select size="small" fullWidth={true} value={currency}
            onChange={(ev) => setCurrency(ev.target.value)}>
            {
              Object.keys(currencies).map(cc => <MenuItem key={cc} value={cc}>{currencies[cc]}</MenuItem>)
            }
          </Select>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Button onClick={() => { postShop() }} sx={regularBtnStyles} variant="contained">Save</Button>
        </Grid>
      </Grid>
      {(loading || waitingSave) && <LoadingModal message={waitingSave ? "Saving..." : "Loading..."} />}
    </Grid>)
}

const countryListAccordionStyles = {
  boxShadow: 'none',
  border: '1px solid #ccc',
  borderRadius: '4px',
  '.css1elwnq4MuiPaperRootMuiAccordionRoot::before': {
    backgroundColor: 'none !important'
  },
  '.cssQ0jschMuiPaperRootMuiAccordionRoot::before': {
    backgroundColor: 'none !important'
  }
}
