import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { AdComponent, AdTemplate, AdTemplateInstance, AdTemplateType } from "./Types"
import { useCustomerState } from "../../context/customer/CustomerContext"
import { apiCreateAdTemplate, apiDeleteAdTemplate, apiGetAdTemplates, apiUpdateAdTemplate } from "../../api/Customer"
import { setLoading } from "../../context/shared/SharedActions"
import EditIcon from '@mui/icons-material/Edit';
import { debounce } from "../../utils/Generators"
import { AdSize, ManagedAdType } from "../../api/Types"
import { AdTemplateInstanceEditor } from "./AdTemplateInstanceEditor"
import { regularBtnStyles } from "../../styles/buttons"
import { StyledMenu } from "../Ads/StyledMenu"
import React from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RestoreIcon from '@mui/icons-material/Restore';
import { v4 as uuidv4 } from "uuid"


export const AdSizes: AdSize[] = [
  { width: 160, height: 600 },
  { width: 300, height: 250 },
  { width: 300, height: 300 },
  { width: 300, height: 600 },
  { width: 360, height: 450 },
  { width: 360, height: 640 },
  { width: 620, height: 891 },
  { width: 628, height: 1200 },
  { width: 640, height: 360 },
  { width: 640, height: 480 },
  { width: 728, height: 90 },
  { width: 980, height: 400 },
  { width: 1080, height: 1920 },
  { width: 1080, height: 1080 },
  { width: 1200, height: 628 },
  { width: 1200, height: 1200 },
  { width: 1280, height: 720 },
  { width: 1440, height: 1080 },
  { width: 1920, height: 1080 },
  { width: 1920, height: 1536 },
  { width: 1920, height: 1920 },
]

const CreateContainer = styled.div`
  grid-column-start:1;
  grid-column-end:3;
  grid-template-columns:2fr 1fr 2fr 1fr;
  display:grid;
  column-gap:0px;
  margin-bottom:20px;
`

const Container = styled.div`
  display:grid;
  grid-template-columns:1fr 10em;
  align-items: center;
`

const TabContainer = styled.div`
  grid-column-start:0;
  grid-column-end:2;
`

const Heading = styled.div`
  font-size:24px;
`

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3, paddingLeft: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </TabContainer>
  );
}

const debounceApiUpdateAdTemplate = debounce(apiUpdateAdTemplate)

export const AdTemplateManager = () => {
  const customer = useCustomerState()
  const customerId = customer.selected?.id

  const [adTemplates, setAdTemplates] = useState<AdTemplate[]>([])
  const [adTemplateName, setAdTemplateName] = useState("New template")
  const [createFromAdTemplate, setCreateFromAdTemplate] = useState<AdTemplate | undefined>()
  const [adTemplate, setAdTemplate] = useState<AdTemplate | undefined>()
  const [createTemplateOpen, setCreateTemplateOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [instanceTabValue, setInstanceTabValue] = useState(0)
  const [editName, setEditName] = useState(false)
  const [newInstanceSize, setNewInstanceSize] = useState<string | undefined>()
  const [createInstanceOpen, setCreateInstanceOpen] = useState(false)
  const [deleteInstance, setDeleteInstance] = useState<AdTemplateInstance | undefined>()
  const [showDeleteTemplate, setShowDeleteTemplate] = useState(false)
  const [recentlyDeletedInstances, setRecentlyDeletedInstances] = useState<AdTemplateInstance[]>([])
  const [copyInstance, setCopyInstance] = useState<string>("blank")

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openRecentlyDeletedInstances = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeRecentlyDeletedInstances = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (customer.selected) {
      apiGetAdTemplates(customer.selected?.id).then((response) => setAdTemplates(response)).catch(() => setAdTemplates([]))
    }
  }, [customer])


  useEffect(() => {
    const first = AdSizes.filter(s => !hasSize(s.width, s.height))[0]
    setNewInstanceSize(`${first?.width}x${first?.height}`)
  }, [createInstanceOpen])


  function updateAdTemplateName(value: string) {
    if (adTemplate && customer.selected) {
      const updated = { ...adTemplate, name: value }
      setAdTemplate(updated)
      setAdTemplates(adTemplates.map(t => t.templateId === updated.templateId ? updated : t))
      debounceApiUpdateAdTemplate(customer.selected.id, updated)
    }
  }

  function updateAdTemplate(value: AdTemplate) {
    if (value && customer.selected) {
      setAdTemplate(value)
      setAdTemplates(adTemplates.map(t => t.templateId === value.templateId ? value : t))
      debounceApiUpdateAdTemplate(customer.selected.id, value)
    }
  }

  function createTypeInstance(type: ManagedAdType) {
    if (adTemplate && customer.selected) {
      const updated = { ...adTemplate, types: adTemplate.types.concat({ version: 1, type, instances: [], entryPoint: "index.html" }) }
      setAdTemplate(updated)
      setAdTemplates(adTemplates.map(t => t.templateId === updated.templateId ? updated : t))
      debounceApiUpdateAdTemplate(customer.selected.id, updated)
    }
  }

  const htmlType = adTemplate?.types.find(i => i.type === "HTML")

  function updateInstance(instance: AdTemplateInstance) {
    if (htmlType && adTemplate && customer.selected) {
      const newTemplate = {
        ...adTemplate, types: [{ ...htmlType, instances: htmlType.instances.map(i => i.width === instance.width && i.height === instance.height ? instance : i) }]
      }
      setAdTemplate(newTemplate)
      debounceApiUpdateAdTemplate(customer.selected.id, newTemplate)
    }
  }

  function calculateNewPosition(component: AdComponent, newWidth: number, newHeight: number, originalWidth: number, originalHeight: number) {
    const factorWitdh = component.area.x / originalWidth
    const factorHeight = component.area.y / originalHeight
    component.area.x = factorWitdh * newWidth
    component.area.y = factorHeight * newHeight
    component.id = uuidv4()
    return component
  }

  function updateResizedPositions(instance: AdTemplateInstance) {
    const [newWidth, newHeight] = newInstanceSize!.split("x").map(p => parseInt(p))
    const components = instance.components.map(i => calculateNewPosition(i, newWidth, newHeight, instance.width, instance.height))
    return components
  }

  function addInstanceForType() {
    if (htmlType && adTemplate && newInstanceSize) {
      const size = newInstanceSize.split("x").map(p => parseInt(p))
      const type: AdTemplateType = {
        ...htmlType, instances: htmlType.instances.concat([{
          version: 1,
          width: size[0],
          height: size[1],
          components: copyInstance !== "blank" ? updateResizedPositions(JSON.parse(copyInstance) as AdTemplateInstance) : []
        }])
      }
      const newAdTemplate = { ...adTemplate, types: [type] }
      apiUpdateAdTemplate(customerId!, newAdTemplate).then(() => setAdTemplate(newAdTemplate))
    }
  }

  function removeInstanceOfType(instanceType: AdTemplateInstance) {
    if (htmlType && adTemplate) {
      const instance: AdTemplateType = {
        ...htmlType, instances: htmlType.instances.filter(i => i.width !== instanceType.width || i.height !== instanceType.height)
      }
      const newAdTemplate = { ...adTemplate, types: [instance] }
      apiUpdateAdTemplate(customerId!, newAdTemplate).then(() => setAdTemplate(newAdTemplate))
    }
  }

  function hasSize(width: number, height: number): boolean {
    const size = htmlType?.instances.find(i => i.width === width && i.height === height)
    return size !== undefined
  }

  return (<Container>
    <CreateContainer>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel size="small">Templates</InputLabel>
        <Select label="Templates" size="small" onChange={(e) => setAdTemplate(adTemplates.find(t => t.templateId === e.target.value))}>
          {adTemplates.map(t => <MenuItem value={t.templateId}>{t.name}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 150 }}>
        <Button sx={regularBtnStyles} onClick={() => setCreateTemplateOpen(true)}>Create new</Button>
      </FormControl>
    </CreateContainer>
    {adTemplate && <>
      {editName && <TextField value={adTemplate.name} size='small' sx={{ width: '360px' }}
        onChange={(ev) => updateAdTemplateName(ev.target.value)}
        onKeyDown={(event) => { if (event.key === 'Enter') setEditName(false) }} />}
      {!editName && <Heading>{adTemplate.name} <IconButton onClick={() => setEditName(true)}><EditIcon /></IconButton></Heading>}
      <Button color="error" style={{ maxHeight: '3.0em' }} variant="contained" onClick={() => setShowDeleteTemplate(true)}>Delete</Button>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={(_, tabValue) => setTabValue(tabValue)}>
          <Tab label="HTML" />
          <Tab label="Image" />
          <Tab label="Video" />
          <Tab label="Text" />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        {!htmlType && <Button variant="contained" onClick={() => createTypeInstance("HTML")}>Create</Button>}
        {htmlType && customer && <>
          <Tabs value={instanceTabValue} onChange={(_, tabValue) => setInstanceTabValue(tabValue)} >
            {htmlType.instances.map(i => <Tab label={`${i.width}x${i.height}`} />)}
            <Box sx={{ display: 'flex', alignItems: 'center', ml: '24px', gap: '16px' }}>
              <Button color="primary" variant="outlined" size="small" onClick={() => setCreateInstanceOpen(true)}>New...</Button>
              {/* Recently deleted */}
              <Box>
                <Button
                  id="demo-customized-button"
                  aria-controls={openRecentlyDeletedInstances ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openRecentlyDeletedInstances ? 'true' : undefined}
                  variant="outlined"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  size="small"
                >
                  Recently deleted
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={openRecentlyDeletedInstances}
                  onClose={closeRecentlyDeletedInstances}
                >
                  {recentlyDeletedInstances.length > 0 && recentlyDeletedInstances.map((instance, index) => <MenuItem key={index} disableRipple sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>{instance.width}x{instance.height}</Typography>
                    <Tooltip placement="top" title="Restore">
                      <RestoreIcon onClick={() => {
                        if (htmlType && adTemplate) {
                          const type: AdTemplateType = {
                            ...htmlType, instances: htmlType.instances.concat([instance])
                          }
                          const newAdTemplate = { ...adTemplate, types: [type] }
                          apiUpdateAdTemplate(customerId!, newAdTemplate).then(() => setAdTemplate(newAdTemplate))
                          recentlyDeletedInstances.splice(index, 1)
                          closeRecentlyDeletedInstances();
                        }
                      }} />
                    </Tooltip>
                  </MenuItem>)}
                </StyledMenu>
              </Box>
            </Box>
          </Tabs>
          {htmlType.instances.map((i, index) => <CustomTabPanel value={instanceTabValue} index={index}>
            <AdTemplateInstanceEditor adTemplate={adTemplate} customerId={customerId!} templateId={adTemplate?.templateId} instance={i}
              onDelete={(instance: AdTemplateInstance) => setDeleteInstance(instance)}
              onUpdate={(instance: AdTemplateInstance) => updateInstance(instance)}
              onUpdateTemplate={(instance: AdTemplate) => updateAdTemplate(instance)} />
          </CustomTabPanel>)}
        </>}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        Not available
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        Not available
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        Not available
      </CustomTabPanel>
    </>}
    <Dialog
      open={createTemplateOpen}
      onClose={() => setCreateTemplateOpen(false)}
    >
      <DialogTitle>
        Create Ad Template
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <TextField label="Name" value={adTemplateName} size="small" fullWidth onChange={(event) => setAdTemplateName(event.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small">Copy from</InputLabel>
                <Select label="Templates" size="small" onChange={(e) => setCreateFromAdTemplate(adTemplates.find(t => t.templateId === e.target.value))}>
                  {adTemplates.map(t => <MenuItem value={t.templateId}>{t.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => { setCreateTemplateOpen(false) }}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => {
          setLoading(true)
          apiCreateAdTemplate(customer.selected!.id, adTemplateName, createFromAdTemplate?.templateId).then((response) => {
            setCreateTemplateOpen(false)
            setLoading(false)
            setAdTemplates([response].concat(adTemplates))
            setAdTemplate(response)
          })
        }}>Create</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={createInstanceOpen}
      onClose={() => setCreateInstanceOpen(false)}
    >
      <DialogTitle>
        Create new Size
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Select size</DialogContentText>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <Select size="small" value={newInstanceSize} onChange={(ev) => setNewInstanceSize(ev.target.value)}>
            {AdSizes.map(size => <MenuItem value={`${size.width}x${size.height}`} disabled={hasSize(size.width, size.height)}>{size.width}x{size.height}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <DialogContentText>Copy from size</DialogContentText>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <Select size="small" value={copyInstance} onChange={(ev) => setCopyInstance(ev.target.value)}>
            <MenuItem value={"blank"}>Use blank</MenuItem>
            {htmlType?.instances?.map(t => <MenuItem value={JSON.stringify(t)}>{t.width}x{t.height}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => { setCreateInstanceOpen(false) }}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => {
          setLoading(true)
          addInstanceForType()
          setCreateInstanceOpen(false)
        }}>Create</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={deleteInstance !== undefined}
      onClose={() => setDeleteInstance(undefined)}
    >
      <DialogTitle>
        Delete Instance Size
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete the size {deleteInstance?.width}x{deleteInstance?.height}?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => { setDeleteInstance(undefined) }}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => {
          if (deleteInstance) { removeInstanceOfType(deleteInstance); recentlyDeletedInstances.push(deleteInstance) }
          setDeleteInstance(undefined)
        }}>Delete</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={showDeleteTemplate}
      onClose={() => setShowDeleteTemplate(false)}
    >
      <DialogTitle>
        Delete template
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete template {adTemplate?.name}?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => { setShowDeleteTemplate(false) }}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => {
          if (customerId && adTemplate) {
            setShowDeleteTemplate(false)
            apiDeleteAdTemplate(customerId, adTemplate.templateId).then(() => {
              setAdTemplates(adTemplates.filter(a => a.templateId !== adTemplate.templateId))
              setAdTemplate(undefined)
            })
          }
        }}>Delete</Button>
      </DialogActions>
    </Dialog>
  </Container>)
}