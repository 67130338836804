import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { createNewAds, deleteMarkeitngObject, getAllCampaigns, getCampaignAdSets } from '../../../../api/Marketing';
import { useCustomerState } from '../../../../context/customer/CustomerContext';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { AlertSeverity } from '../../../../context/alert/AlertTypes';
import { useAlertStateValue } from '../../../../context/alert/AlertContext';
import { setAlert } from '../../../../context/alert/AlertAction';
import CampaignDetails from './CampaignDetails';
import AdSetDetails from './AdSetDetails';
import { blackBtn, whiteBtnBlackBorder } from '../../../../styles/buttons';
import { Post } from '..';
import CircleIcon from '@mui/icons-material/Circle';
import { convertToFinnishDate } from '../../../../utils/helpers';
import { Colors } from '../../../../constants/colors';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface AddPostsDialogProps {
  selectedPosts: Post[]
}

const AddPostsDialog = ({ selectedPosts }: AddPostsDialogProps) => {

  const customerState = useCustomerState()
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('')
  const [selectedCampaign, setSelectedCampaign] = useState<any>({})

  const [selectedAdSetId, setSelectedAdSetId] = useState<string>('')
  const [selectedAdSet, setSelectedAdSet] = useState<any>({})
  const [allCampaigns, setAllCampaigns] = useState<any[]>([])
  const [campaigns, setCampaigns] = useState<any[]>([])
  const [campaignsByKiva, setCampaignsByKiva] = useState<any[]>([])
  const [rbValue, setRbValue] = useState('all')
  const [createBtnProgress, setCreateBtnProgress] = useState(false)

  useEffect(() => {
    customerState?.selected?.id && getAllCampaigns(customerState?.selected?.id).then((result) => {
      setAllCampaigns(result.allCampaigns)
      setCampaigns(result.allCampaigns)
      setCampaignsByKiva(result.allCampaigns.filter((c: any) => c.createdByPostBooster))
    }).catch(error => {
      setAllCampaigns([])
      setCampaigns([])
      setCampaignsByKiva([])
      console.error(error)
    });
  }, [customerState])

  const [open, setOpen] = React.useState(false);
  const [{ alert }, alertDispatch] = useAlertStateValue()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCampaignSelection = async (e: SelectChangeEvent<any>) => {
    const id: string = e.target.value
    setSelectedCampaignId(id)
    const campaign = allCampaigns.find((c: any) => c.id.toString() === id.toString())
    setSelectedCampaign(campaign)
    const customerId = customerState?.selected?.id
    if (customerId) {
      const result = await getCampaignAdSets(customerId, id)
      if (result.success) {
        setSelectedCampaign({ ...campaign, adSets: result.adSets })
      } else {
        setSelectedCampaign({ ...campaign, adSets: [] })
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
      }
    }
    setSelectedAdSet({})
  }

  const handleAdSetSelection = (e: SelectChangeEvent<any>) => {
    const id: string = e.target.value
    setSelectedAdSetId(id)
    const adSet = selectedCampaign.adSets.find((adSet: any) => adSet.id.toString() === id.toString())
    setSelectedAdSet(adSet)
  }

  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v: string = e.target.value
    setRbValue(v);
    setSelectedCampaignId('')
    setSelectedCampaign({})
    setSelectedAdSetId('')
    setSelectedAdSet({})

    if (v === 'byadmanager') {
      const adManagerCampaigns = allCampaigns.filter((cam: any) => !cam.createdByPostBooster).filter(c => !c.isAdAutomationCampaign)
      setCampaigns(adManagerCampaigns)

    } else if (v === 'bykiva') {
      const kivaCampaigns = allCampaigns.filter((cam: any) => cam.createdByPostBooster)
      setCampaigns(kivaCampaigns)
    } else {
      setCampaigns(allCampaigns)
    }
  }

  const isThereAddPostsErrors: boolean = !selectedCampaignId || !selectedCampaign || !selectedAdSetId || !selectedAdSet

  const handleAdd = async () => {
    setCreateBtnProgress(true)
    const customerId = customerState?.selected?.id;
    if (customerId) {
      const mPosts = selectedPosts.map((p: Post) => { return { id: p.id, type: p.type, promotable_id: p.promotable_id, message: p.message } })
      const result = await createNewAds(customerId, mPosts, selectedAdSetId)
      if (result.success) {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Ads created for selected posts successfully!", duration: 7000 }))
        setOpen(false)
      } else {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
        setCreateBtnProgress(false)
      }

    }
  }

  return (
    <>
      <div>
        <Button variant="contained" sx={blackBtn} onClick={handleClickOpen}>
          Create
        </Button>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>

            <Typography sx={{ fontSize: '24px' }}>Create ads for the selected posts</Typography>

          </BootstrapDialogTitle>
          <DialogContent dividers>

            <Grid container sx={{ maxWidth: 600 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant="h6">Selected posts: <span style={{ fontWeight: 700, fontSize: '18px', color: '#1876d1' }}>{selectedPosts.length}</span></Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Campaigns</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={rbValue}
                    onChange={handleRadioButtonChange}
                  >
                    <FormControlLabel value="all" control={<Radio />} label="All" />
                    <FormControlLabel value="byadmanager" control={<Radio />} label="by Ads Manager" />
                    <FormControlLabel value="bykiva" control={<Radio />} label="by Kiva Dashboard" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel id="campaign-objective-select-label">Campaign</InputLabel>
                  <Select
                    labelId="campaign-objective-select-label"
                    id="campaign-objective"
                    value={selectedCampaignId}
                    onChange={handleCampaignSelection}
                    label="Budget type"
                  >
                    {campaigns.length && campaigns.map((c: any) => {
                      return <MenuItem disabled={c.isAdAutomationCampaign}
                        sx={{ fontSize: '14px' }}
                        key={c.id}
                        value={c.id}>
                        <CircleIcon sx={{ fontSize: '12px', marginRight: '7px', color: c.status === "PAUSED" ? Colors.yellow : Colors.green }} />
                        {`${c.isAdAutomationCampaign ? 'Ad automation | ' : ''} ${c.name}`}
                        <span style={{ marginLeft: '4px', color: "#808080" }}> | {c.stop_time ? ` Until ${convertToFinnishDate(c.stop_time)}` : ' Ongoing'}</span>
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {selectedCampaign.name ? <>

              <CampaignDetails campaign={selectedCampaign} selectedAdSetId={selectedAdSetId} handleAdSetSelection={handleAdSetSelection} />

              {selectedAdSet.name ? <>

                <AdSetDetails adSet={selectedAdSet} />

              </> : null
              }

            </> : null}

          </DialogContent>
          <DialogActions sx={{ mt: 0.5, mb: 0.5 }}>

            <Button variant='contained' sx={blackBtn} autoFocus
              onClick={handleAdd} disabled={isThereAddPostsErrors ? true : false}>
              {createBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: "#fff" }} /> : 'Create'}
            </Button>
            <Button variant='outlined' sx={whiteBtnBlackBorder} autoFocus
              onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}

export default AddPostsDialog;