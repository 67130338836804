import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomationSettingsErrors, setAdvancedPostBoosting, setAdvancedPostBoostingErrors } from '../../../context/meta/MetaActions';
import { MarketingObjStatus, CampaignObjectives, MetaErrors } from '../../../context/meta/MetaTypes';
import { capitalizeFirstLetter, beautifyString } from '../../../utils/helpers'
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import AdvancedPostBoosting from '../PostBoosterPage/AdvancedPostBoosting';
import { desktop_h2, desktop_h3 } from '../../../styles/textStyles';
import { useCustomMuiStyles } from '../../../styles/muiStyles';


const AdAutomationInfo = () => {

  const classes = useCustomMuiStyles()
  // const [metaState, dispatch] = useStateValue()
  // You can destructure only the adSettings property from metaState
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting, advancedPostBoostingErrors }, dispatch] = useStateValue()

  const [adAutomationName, setAdAutomationName] = useState(adAutomationSettings.name)
  const [adAutomationNameError, setAdAutomationNameError] = useState(false)
  const [adAutomationActive, setAdAutomationActive] = useState<boolean>(adAutomationSettings.active)
  const [maxNumberOfActiveAds, setMaxNumberOfActiveAds] = useState(adAutomationSettings.maxNumberOfActiveAds)


  useEffect(() => {
    setAdAutomationActive(adAutomationSettings.active)
  }, [adAutomationSettings])

  const handleAdAutomationNameChange = (e: any) => {
    const n = e.target.value
    setAdAutomationName(n)
    dispatch(setAdAutomationSettings({ ...adAutomationSettings, name: n}))
    if (n && n.length >= 3) {
      setAdAutomationNameError(false)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.adAutomationNameError)))
    } else {
      setAdAutomationNameError(true)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.adAutomationNameError).concat([MetaErrors.adAutomationNameError])))
    }
  }

  const handleAdAutomationActiveChange = (e: SelectChangeEvent<boolean>) => {
    const s = !adAutomationActive
    setAdAutomationActive(s)
    dispatch(setAdAutomationSettings({
      ...adAutomationSettings,
      active: s,
      campaign: { ...adAutomationSettings.campaign, status: s ? MarketingObjStatus.ACTIVE : MarketingObjStatus.PAUSED },
      adSet: { ...adAutomationSettings.adSet, status: s ? MarketingObjStatus.ACTIVE : MarketingObjStatus.PAUSED }
    }))
  }

  const handleMaxNumberOfActiveAdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxNumberOfActiveAds = Number(e.target.value)
    setMaxNumberOfActiveAds(maxNumberOfActiveAds)
    dispatch(setAdAutomationSettings({
      ...adAutomationSettings,
      maxNumberOfActiveAds: maxNumberOfActiveAds
    }))
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        {/*<Typography variant="h6">Create a new campaign</Typography>*/}
      </Grid>

      <Grid item xs={12} sx={{}}>
        <Typography variant='h2' sx={desktop_h2}>Ad automation</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" sx={desktop_h3}>Name and status</Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="standard-basic"
          label="Ad automation name"
          name='Ad automation name'
          variant="standard"
          fullWidth
          value={adAutomationName}
          onChange={handleAdAutomationNameChange}
          error={adAutomationNameError}
          helperText={adAutomationNameError ? "Enter at least 3 characters." : ""}
          InputLabelProps={{
            shrink: true
          }}
          className={classes.inputFieldRoot}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="ad-automation-status-select-label">Ad automation status</InputLabel>
          <Select
            labelId="ad-automation-status-select-label"
            id="ad-automation-status"
            value={adAutomationActive}
            onChange={handleAdAutomationActiveChange}
            label="Budget type"
            className={classes.inputFieldRoot}
          >
            <MenuItem sx={{ fontSize: '14px' }} key={'true'} value={'true'}>ON</MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} key={'false'} value={'false'}>OFF</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sx={{mt: 1}}>
        <Typography variant="h6">Max number of active ads</Typography>
        {/* INFO: Campaign can have up to 200 ad sets. Ad set can have up to 50 ads. */}
        <Typography variant="body1" sx={{ mt: 2, mb: 2, color: "#7B7B7B", fontSize: '14px' }}>The automation can have up to 200 active ads.</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="standard-basic"
          label="Max number of active ads"
          name='Max number of active ads'
          variant="standard"
          type='number'
          fullWidth
          value={maxNumberOfActiveAds}
          onChange={handleMaxNumberOfActiveAdsChange}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{ min: 1, max: 200 }}
          sx={{ fontSize: '14px' }}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>

    </Grid>
  )
}

export default AdAutomationInfo;