import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Box } from '@mui/material';
import { radioClasses } from "@mui/material/Radio";
import { desktop_p2 } from '../../../styles/textStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useStateValue } from '../../../context/ads/AdsContext';
import { setAdUnits } from '../../../context/ads/AdsActions';
import { useState } from 'react';
import { GetCreativesParams } from '../../../api/Creative';
import { pageSize } from '../../AdUnits';

export type ActivitiesProps = {
  handleAdsSearchBtnClick: () => void
  getCreativesUsingParams: (params: GetCreativesParams) => void
  

}

const Activities = ({ handleAdsSearchBtnClick , getCreativesUsingParams }: ActivitiesProps) => {
  const [{ adUnits }, dispatch] = useStateValue()

  const { floodlightActivity, allFloodlightActivities, startDate, endDate, sort, direction, statuses, searchKeyword,
    campaigns, adGroups, dimensions, categories, showZeros } = adUnits

  const [activities, setActivities] = useState<string | undefined>(!floodlightActivity ? "all" : floodlightActivity)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent ) => {
    setActivities(event.target.value)

    getCreativesUsingParams({
      searchKeyword,
      startDate, endDate, 
      sort,
      direction, statuses,
      campaigns,
      adGroups,
      dimensions,
      floodlightActivity: event.target.value === "all" ? undefined : event.target.value, 
      showZeros, 
      start: 0,
      categories,
      count: pageSize
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl sx={{ width: 180 }} size="small">
      <InputLabel id="demo-select-small-label">Activities</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={activities}
        label="Activities"
        onChange={handleChange}
      >
        {allFloodlightActivities.map((a: {id?: string, title: string} )=>  <MenuItem key={a.id} value={a.id}>{a.title}</MenuItem>)}
      </Select>
    </FormControl>
    </Box>
  );
}

export default Activities