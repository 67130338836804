import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { apiCreateCustomModel } from "../../api/Assistant"
import { useState } from "react"
import { CustomModel, CustomModelType } from "../../api/Types"


export type CreateCustomModelDialogProps = {
  customerId: number
  open: boolean
  setOpen: (open: boolean) => void
  setLoading: (loading: boolean) => void
  onCreate: (customModels: CustomModel[]) => void
}

export const CreateCustomModelDialog = (props: CreateCustomModelDialogProps) => {
  const { customerId, open, setOpen, setLoading, onCreate } = props
  const [newName, setNewName] = useState("")
  const [newType, setNewType] = useState<CustomModelType>("PaLM2")
  return (<Dialog
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>
      Create Model
    </DialogTitle>
    <DialogContent>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel size="small">Type</InputLabel>
        <Select label="Models" value={newType} size="small" onChange={(e) => {
          setNewType(e.target.value as CustomModelType)
        }}>
          <MenuItem value={"PaLM2"}>PaLM 2</MenuItem>
          <MenuItem value={"Pena"}>Pena</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <TextField label="Name" value={newName} size="small" onChange={(ev) => { setNewName(ev.target.value) }} />
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="error" onClick={() => { setOpen(false) }}>Cancel</Button>
      <Button variant="contained" color="primary" onClick={() => {
        setLoading(true)
        apiCreateCustomModel(customerId, newName, newType).then((customModels) => {
          setLoading(false)
          setOpen(false)
          onCreate(customModels)
        }).catch(() => {
          setLoading(false)
          setOpen(false)
        })
      }}>Create</Button>
    </DialogActions>
  </Dialog>)
}