import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiGetCustomerServices } from '../api/Customer';
import { CustomerService } from '../api/Types';
import { useAuthState } from '../context/auth/AuthContext';
import { useCustomerState } from '../context/customer/CustomerContext';
import { useSharedStateValue } from '../context/shared/SharedContext';
import HomeCard from './HomeCard';
import { LoadingModal } from './Modal';
import { desktop_h1, desktop_h3 } from '../styles/textStyles';

const Header = styled.div`
    margin-top:1em;
    margin-bottom:0.5em;
    font-size:2.0em;
`

export const PersonView = () => {
  const auth = useAuthState()
  const { selected } = useCustomerState()
  const [services, setServices] = useState<CustomerService[]>([])
  const [loading, setLoading] = useState(false)

  const minWidth1000 = useMediaQuery('(min-width:1000px)');
  const minWidth1200 = useMediaQuery('(min-width:1200px)');
  const minWidth1400 = useMediaQuery('(min-width:1400px)');
  const maxWidth800 = useMediaQuery('(max-width:800px)');

  const [{ drawerIsOpen }, sharedDispatch] = useSharedStateValue()
  const [itemXS, setItemXS] = useState(3)

  useEffect(() => {
    setLoading(true)
    if (selected) {
      apiGetCustomerServices(selected.id).then((services) => {
        setServices(services);
        setLoading(false)
      }).catch(() => setLoading(false))
    }
  }, [selected])
  useEffect(() => {

    if (drawerIsOpen) {

      if (minWidth1000) {
        setItemXS(6)
      }
      if (minWidth1200) {
        setItemXS(4)
      }
      if (minWidth1400) {
        setItemXS(3)
      }
    } else {
      if (minWidth1000) {
        setItemXS(4)
      }
      if (minWidth1200) {
        setItemXS(3)
      }
      if (maxWidth800) {
        setItemXS(6)
      }
    }

  }, [minWidth1000, minWidth1200, minWidth1400, maxWidth800, drawerIsOpen])

  return (
    !loading ? <Box id="rootBox" sx={rootBoxStyles}>
      <Box sx={{}}>
        <Typography variant='h1' sx={desktop_h1}>Hi {auth.user.name.split(' ')[0]}!</Typography>
      </Box>

      <Box sx={{ mt: '46px', mb: '40px' }}>
        <Typography variant='h3' sx={desktop_h3}>Your active services</Typography>
      </Box>
      <Grid container rowSpacing={'24px'} columnSpacing={'24px'} style={{}}>
        {services.filter(s => s.active).map(s => <Grid key={s.title} item xs={itemXS}>
          <HomeCard title={s.title} subtitle={s.description} active={s.active === true} url={s.link}
            internalUrl={!s.link.startsWith("http")} />
        </Grid>)}
      </Grid>

      <Box sx={{ mt: '46px', mb: '40px' }}>
        <Typography variant='h3' sx={desktop_h3}>Inactive services</Typography>
      </Box>
      <Grid container rowSpacing={'24px'} columnSpacing={'24px'} style={{}}>
        {services.filter(s => !s.active).map(s => <Grid key={s.title} item xs={itemXS}>
          <HomeCard title={s.title.toLowerCase().includes('digital marketing assistant') ? 'MAIA' : s.title} subtitle={s.description} active={s.active === true} url={s.link}
            internalUrl={!s.link.startsWith("http")} />
        </Grid>)}
      </Grid>
    </Box> : <LoadingModal />
  )

}

const rootBoxStyles = {
  margin: '24px 36px',
  border: 'none',
  boxSizing: 'border-box',
  maxWidth: '1672px',
  padding: '32px',
  borderRadius: '15px',
  opacity: '0.9',
  background: '#fff'
}