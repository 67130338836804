export const questionTemplates = [
    " Kirjoita kuvaus tuotteesta ",
    " Kerro tuotteesta ",
    " Tee kuvaus tuotteesta ",
    " Kuvaile tuotteen ominaisuuksia ",
    " Tee tuotekuvaus tuotteesta   ",
    " Kuvaile tuotetta ",
    " Anna tuotekuvaus tuotteesta   ",
    " Kirjoita arvostelu tuotteesta   ",
    " Arvostele tuote ",
    " Kerro yksityiskohtaisesti tuotteesta ",
    " Referoi tuotetta ",
    " Kuvaile tuotteen tärkeimmät ominaisuudet: ",
    " Kirjoita template tuotteelle ",
    " Kirjoita pohja tuotteelle ",
    " Generoi kuvaus tuotteelle ",
    " Generoi tuotekuvaus tuotteesta ",
    " Generoi teksti tuotteelle ",
    " Generoi kategoriakuvaus tuotteesta ",
    " Kerro jotain tuotteesta ",
    " Tuotekuvaus ",
    " Puhu tuoteesta ",
    " Kerro faktoja tuotteesta ",
    " Mitä osaat sanoa tuotteesta ",
]
