import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ConversionDetails } from '../../api/Types';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Divider } from '@mui/material';
import { desktop_h3, desktop_h4, desktop_p3 } from '../../styles/textStyles';
import { beautifyAndCapitalizeFirstLetter } from '../../utils/helpers';

type ConversionDetailsDialogProps = {
  totalConversionDetails: ConversionDetails | undefined,
  desktopConversionDetails: ConversionDetails | undefined,
  mobileConversionDetails: ConversionDetails | undefined
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '600px'
   },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ConversionDetailsDialog = ({totalConversionDetails, desktopConversionDetails, mobileConversionDetails }: ConversionDetailsDialogProps ) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <InfoIcon/>
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{minWidth: 800}}
      >
        <DialogTitle sx={{ m: 0, p: 2, ...desktop_h3 }} id="customized-dialog-title">
          Conversion Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{}}>
            <Typography variant='h4' sx={{...desktop_h4, mb: '16px', mt: '8px'}}>
              Total Conversion Details:
            </Typography>
            {totalConversionDetails !== undefined && Object.entries(totalConversionDetails).map(([key, value]) => (
              <Typography sx={{...desktop_p3}} key={key}>{beautifyAndCapitalizeFirstLetter(key)}: {value}</Typography>
            ))}
          </Box>
          <Divider sx={{m: '32px auto'}}/>
          <Box sx={{}}>
            <Typography variant='h4' sx={{...desktop_h4, mb: '16px'}}>
              Mobile Conversion Details
            </Typography>
            {mobileConversionDetails !== undefined && Object.entries(mobileConversionDetails).map(([key, value]) => (
              <Typography sx={{...desktop_p3}} key={key}>{beautifyAndCapitalizeFirstLetter(key)}: {value}</Typography>
            ))}
          </Box>
          <Divider sx={{m: '32px auto'}}/>
          <Box sx={{}}>
            <Typography variant='h4' sx={{...desktop_h4, mb: '16px'}}>
              Desktop Conversion Details
            </Typography>
            {desktopConversionDetails !== undefined && Object.entries(desktopConversionDetails).map(([key, value]) => (
              <Typography sx={{...desktop_p3}} key={key}>{beautifyAndCapitalizeFirstLetter(key)}: {value}</Typography>
            ))}
          </Box>
        </DialogContent>

      </BootstrapDialog>
    </React.Fragment>
  );
}

export default ConversionDetailsDialog;