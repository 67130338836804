import { Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material"
import styled from "styled-components"
import { AggregationPeriod, Attribution, AttributionType, ComparisonPeriod, VisitorFilters } from "../../api/Types"
import DateRangePickerMui from "../DateRangePickerMui"
import { useAlertStateValue } from "../../context/alert/AlertContext"
import { useEffect, useState } from "react"
import moment from "moment"
import { apiGetAttributionFilters, apiGetDailyAttributions } from "../../api/Attributions"
import dayjs from "dayjs"
import { DateRange } from "@mui/x-date-pickers-pro/internals/models"
import { BarChart } from "@mui/x-charts"
import { aggregationToLabel, cumulativeArray, getPreviousPeriodDates } from "../../api/Utils"
import { stringToColour } from "../Utils"

const ECommerceHeading = styled.div`
  display:grid;
  width:100%;
  align-items:center;
  grid-template-columns: 22em 22em 16em 24em;
  font-size:0.8em;
  row-gap:1em;
  column-gap:1em;
`

export type ConversionAttributionProps = {
  customerId: number
  loading: boolean
  setLoading: (loading: boolean) => void
}


export const ConversionAttribution = (props: ConversionAttributionProps) => {
  const { customerId, loading, setLoading } = props
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"))
  const [endDate, setEndDate] = useState(moment().subtract(1, "days"))
  const [attributionType, setAttributionType] = useState<AttributionType>("LastClick")
  const [dailyChannelAttributions, setDailyChannelAttributions] = useState<Attribution[]>([])
  const [comparisonChannelAttributions, setComparisonChannelAttributions] = useState<Attribution[]>([])
  const [channel, setChannel] = useState<string>("All")
  const [aggregation, setAggregation] = useState<AggregationPeriod>("date")
  const [campaign, setCampaign] = useState("All")
  const [filters, setFilters] = useState<VisitorFilters | undefined>()
  const [landingPage, setLandingPage] = useState("All")
  const [comparison, setComparison] = useState<ComparisonPeriod>("Custom")
  const [conversionType, setConversionType] = useState<string>("All")
  const [variable, setVariable] = useState<string>("count")
  const [diagram, setDiagram] = useState<string>("separate")
  const [stackChannels, setStackChannels] = useState(false)

  useEffect(() => {
    setLoading(true)
    const comp = getPreviousPeriodDates(startDate, endDate, comparison)
    Promise.all([
      apiGetAttributionFilters(customerId,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")).then((filters) => setFilters(filters)),
      apiGetDailyAttributions(customerId, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), attributionType,
        aggregation, channel !== 'All' ? channel : undefined, campaign !== 'All' ? campaign : undefined, landingPage !== 'All' ? landingPage : undefined,
        conversionType !== 'All' ? conversionType : undefined, stackChannels).then((attributions) => {
          setDailyChannelAttributions(attributions)
        }),
      apiGetDailyAttributions(customerId, comp.startDate.format("YYYY-MM-DD"), comp.endDate.format("YYYY-MM-DD"), attributionType,
        aggregation, channel !== 'All' ? channel : undefined, campaign !== 'All' ? campaign : undefined, landingPage !== 'All' ? landingPage : undefined,
        conversionType !== 'All' ? conversionType : undefined, stackChannels).then((attributions) => {
          setComparisonChannelAttributions(attributions)
        })
    ]).then(() => setLoading(false)).catch(() => setLoading(false))
  }, [customerId, attributionType, startDate, endDate, aggregation, channel, campaign, landingPage, comparison, conversionType, stackChannels])

  const handleDateRangeChange = (dateRange: DateRange<dayjs.Dayjs>) => {
    if (dateRange[0] && dateRange[1]) {
      setStartDate(moment(dateRange[0].toDate()))
      setEndDate(moment(dateRange[1].toDate()))
    }
  }

  const variableName: any = {
    count: {
      cumulative: "cumulativeCount",
      separate: "count"
    },
    revenue: {
      cumulative: "cumulativeRevenue",
      separate: "revenue"
    }
  }

  const series: any[] = []
  if (stackChannels) {
    const channels = new Set<string>(dailyChannelAttributions.map(d => d.channelName!))
    for (const channelName of channels) {
      series.push({ data: dailyChannelAttributions.filter(v => !v.estimated && v.channelName === channelName).map(v => (v as any)[variableName[variable][diagram]]), label: channelName, color: stringToColour(channelName + "1239"), stack: 'stack1' })
    }
  } else {
    series.push({ data: dailyChannelAttributions.filter(v => !v.estimated).map(v => (v as any)[variableName[variable][diagram]]), label: variable === "count" ? 'Conversions' : 'Revenue', color: "#83a53dff", stack: 'stack1' })
  }
  if (!stackChannels) {
    series.push({ data: dailyChannelAttributions.map(v => v.estimated ? ((v as any)[variableName[variable][diagram]]) : 0), label: 'Estimated', color: "#7fafff", stack: 'stack1' })
  }
  series.push({ data: comparisonChannelAttributions.map(v => (v as any)[variableName[variable][diagram]]), label: 'Comparison', color: "#83a53d4f", stack: 'stack2' })
  const containsRevenue = dailyChannelAttributions.find(d => d.revenue > 0) !== undefined
  const dates = Array.from(new Set(dailyChannelAttributions.map(c => c.date)))
  const comp = getPreviousPeriodDates(startDate, endDate, comparison)

  return (<><ECommerceHeading>
    <FormControl>
      <InputLabel id={"conversion-id"}>Conversion</InputLabel>
      <Select value={conversionType} label="Conversion" labelId="conversion-id" size="small"
        onChange={(ev) => setConversionType(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.conversionTypes.map(c => <MenuItem value={c}>{c}</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"attribution-id"}>Attribution</InputLabel>
      <Select value={attributionType} label="Attribution" labelId="attribution-id" size="small"
        onChange={(ev) => setAttributionType(ev.target.value as AttributionType)}>
        <MenuItem value="FirstClick">First click</MenuItem>
        <MenuItem value="LastClick">Last click</MenuItem>
        <MenuItem value="TimeDecay">Time decay</MenuItem>
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"channel-id"}>Channel</InputLabel>
      <Select value={channel} label="Channel" labelId="channel-id" size="small"
        onChange={(ev) => setChannel(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.channels.map(c => <MenuItem value={c.channel}>{c.channelName} ({c.count})</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"campaign-id"}>Campaign</InputLabel>
      <Select value={campaign} label="Campaign" labelId="campaign-id" size="small"
        onChange={(ev) => setCampaign(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.campaignNames.map(c => <MenuItem value={c.campaignName}>{c.campaignName} ({c.count})</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"landingpage-id"}>Landing page</InputLabel>
      <Select value={landingPage} label="Landing page" labelId="landingpage-id" size="small"
        onChange={(ev) => setLandingPage(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.landingPageTitles.map(c => <MenuItem value={c.landingPageTitle}>{c.landingPageTitle} ({c.count})</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"comparison-id"}>Comparison</InputLabel>
      <Select value={comparison} label="Comparison" labelId="comparison-id" size="small"
        onChange={(ev) => setComparison(ev.target.value as ComparisonPeriod)}>
        <MenuItem value="Custom">{comp.startDate.format("DD.MM.YYYY")} - {comp.endDate.format("DD.MM.YYYY")}</MenuItem>
        <MenuItem value="Week">Week</MenuItem>
        <MenuItem value="Month">Month</MenuItem>
        <MenuItem value="Year">Year</MenuItem>
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"period-id"}>Aggregation</InputLabel>
      <Select value={aggregation} label="Aggregation" labelId="period-id" size="small"
        onChange={(ev) => setAggregation(ev.target.value as AggregationPeriod)}>
        <MenuItem value="date">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
        <MenuItem value="month">Month</MenuItem>
      </Select>
    </FormControl>
    <FormControl>
      <DateRangePickerMui startDate={startDate.toDate()} endDate={endDate.toDate()} onChange={handleDateRangeChange} onClose={() => ''} />
    </FormControl>
    <FormControl>
      <RadioGroup
        row
        defaultValue="count"
        name="radio-buttons-group"
        value={variable}
        onChange={(ev) => setVariable(ev.target.value)}
      >
        <FormControlLabel value="count" control={<Radio />} label="Count" />
        <FormControlLabel value="revenue" disabled={!containsRevenue} control={<Radio />} label="Revenue" />
      </RadioGroup>
    </FormControl>
    <FormControl>
      <RadioGroup
        row
        defaultValue="separate"
        name="radio-buttons-group"
        value={diagram}
        onChange={(ev) => setDiagram(ev.target.value)}
      >
        <FormControlLabel value="separate" control={<Radio />} label={`Per ${aggregationToLabel(aggregation)}`} />
        <FormControlLabel value="cumulative" control={<Radio />} label="Cumulative" />
      </RadioGroup>
    </FormControl>
    <FormControl>
      <FormControlLabel control={<Checkbox checked={stackChannels} onChange={(ev) => setStackChannels(ev.target.checked)} />} label={`Stack channels`} />
    </FormControl>
  </ECommerceHeading>
    {
      !loading && <Grid container spacing={1} marginTop={'1em'}>
        <Grid item xs={12}>
          <Card style={{ height: "100%" }}>
            <CardHeader title={variable === "count" ? `Conversions` : 'Revenue'} subheader={conversionType !== 'All' ? conversionType : ""} />
            <CardContent>
              <BarChart
                margin={{ left: 80 }}
                xAxis={[
                  { scaleType: 'band', label: variable === "count" ? 'Conversions (pcs)' : 'Revenue (€)', data: dates }
                ]}
                yAxis={[
                  {
                    min: 0,
                    valueFormatter: (value) => { return variable === "count" ? `${value} pcs` : `${value} €` }
                  }
                ]}
                series={series}
                height={300}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    }</>)
}