import { Button } from "@mui/material"
import { regularBtnStyles } from "../../styles/buttons"
import { primaryColors } from "../../styles/colors"
import CircularProgressLoading from "../CircularProgressLoading"

export type LoadingButtonProps = {
  loading: boolean
  title: string
  disabled?: boolean
  onClick: () => void
}

export const LoadingButton = (props: LoadingButtonProps) => {
  return (<Button sx={regularBtnStyles} disabled={props.disabled} onClick={props.onClick}>
    {props.loading ? <CircularProgressLoading boxStyles={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0, width: "50px" }} circularProgressStyles={{ width: "18px", height: "18px", color: primaryColors[900] }} /> : props.title}
  </Button>)
}