import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdvancedPostBoosting } from '../../../context/meta/MetaActions';
import { PostBoostingCondition } from '../../../context/meta/MetaTypes';

interface IndividualConditionProps {
  condition: PostBoostingCondition
}

const IndividualCondition = ({ condition }: IndividualConditionProps) => {


  const [conditionText, setConditionText] = useState(condition.conditionText)
  const [operator, setOperator] = useState(condition.operator)
  const [textOperator, setTextOperator] = useState(condition.textOperator)
  const [{ advancedPostBoosting }, dispatch] = useStateValue()
  const [value, setValue] = useState(condition.value)

  const handleConditionTextChange = (event: SelectChangeEvent<any>) => {
    setConditionText(event.target.value);
    const updated = { ...condition, conditionText: event.target.value }
    const newFiltes = {...advancedPostBoosting.filters, conditions: advancedPostBoosting.filters.conditions.map(c => c.id === condition.id ? updated : c)}
    dispatch(setAdvancedPostBoosting({...advancedPostBoosting, filters: newFiltes}))
  };

  const handleOperatorChange = (event: SelectChangeEvent<any>) => {
    setOperator(event.target.value);
    const updated = { ...condition, operator: event.target.value }
    const newFilters = {...advancedPostBoosting.filters, conditions: advancedPostBoosting.filters.conditions.map(c => c.id === condition.id ? updated : c)}
    dispatch(setAdvancedPostBoosting({...advancedPostBoosting, filters: newFilters}))
  };
  const handleTextOperatorChange = (event: SelectChangeEvent<any>) => {
    setTextOperator(event.target.value);
    const updated = { ...condition, textOperator: event.target.value }
    const newFilters = {...advancedPostBoosting.filters, conditions: advancedPostBoosting.filters.conditions.map(c => c.id === condition.id ? updated : c)}
    dispatch(setAdvancedPostBoosting({...advancedPostBoosting, filters: newFilters}))
  };

  const deleteCondition = () => {
    const newConditionsArray = advancedPostBoosting.filters.conditions.filter((c: PostBoostingCondition) => c.id !== condition.id)
    const newFilters = {...advancedPostBoosting.filters, conditions: newConditionsArray}
    dispatch(setAdvancedPostBoosting({...advancedPostBoosting, filters: newFilters}))
  }

  const handleConditionValueChange = (event: any) => {
    setValue(event.target.value)
    const updated = { ...condition, value: event.target.value }
    const newFilters = {...advancedPostBoosting.filters, conditions: advancedPostBoosting.filters.conditions.map(c => c.id === condition.id ? updated : c)}
    dispatch(setAdvancedPostBoosting({...advancedPostBoosting, filters: newFilters}))
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Condition</InputLabel>
          <Select
            size='small'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={conditionText}
            label="Condition"
            onChange={handleConditionTextChange}
          >
            <MenuItem value={'comments'}>Comments</MenuItem>
            <MenuItem value={'reactions'}>Reactions</MenuItem>
            <MenuItem value={'post_text'}>Post text</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Operator</InputLabel>
          {conditionText !== 'post_text' && <Select
            size='small'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={operator}
            label="Operator"
            onChange={handleOperatorChange}
          >
            <MenuItem value={'>'}>{'>'}</MenuItem>
            <MenuItem value={'<'}>{'<'}</MenuItem>
            <MenuItem value={'='}>{'='}</MenuItem>
            <MenuItem value={'>='}>{'>='}</MenuItem>
            <MenuItem value={'<='}>{'<='}</MenuItem>
          </Select>}

          {conditionText === 'post_text' && <Select
            size='small'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={textOperator}
            label="Operator"
            onChange={handleTextOperatorChange}
          >
            <MenuItem value={'contains'}>{'contains'}</MenuItem>
            <MenuItem value={"does_not_contain"}>{"doesn't contain"}</MenuItem>
          </Select>}
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl fullWidth>
          {conditionText === 'post_text' && <TextField id="outlined-basic" size='small' onChange={handleConditionValueChange} label="Value" variant="outlined" />}
          {conditionText !== 'post_text' && <TextField id="outlined-basic" size='small' onChange={handleConditionValueChange} type='number' label="Value" variant="outlined" />}
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <IconButton aria-label="delete" size="medium" onClick={deleteCondition}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default IndividualCondition;
