import { Cancel, Check } from "@mui/icons-material"
import { Icon, IconButton, TextField, Tooltip } from "@mui/material"
import { useState } from "react"
import styled from "styled-components"
import { cutStringToMaxLength } from "../Utils"


const EditContainer = styled.div`

`

export type EditableTextProps = {
  label: string
  text: string
  onChange: (text: string) => void
}

const SelectableText = styled.div`
  cursor:pointer;
  overflow:hidden;
`

export const EditableText = (props: EditableTextProps) => {
  const { label, text, onChange } = props
  const [selected, setSelected] = useState(false)
  const [currentText, setCurrentText] = useState(text)
  return (<>
    {selected ? <EditContainer>
      <TextField size="small" label={label} multiline rows={4} value={currentText} fullWidth onChange={(ev) => setCurrentText(ev.target.value)} />
      <IconButton style={{ color: "red" }} onClick={() => { setCurrentText(text); setSelected(false) }}><Cancel /></IconButton>
      <IconButton style={{ color: "green" }} onClick={() => { setSelected(false); onChange(currentText) }}><Check /></IconButton>
    </EditContainer> : <SelectableText onClick={() => { if (!selected) { setCurrentText(text); setSelected(true) } }}>
      <Tooltip title={<>{text}</>} arrow>
        <div>{cutStringToMaxLength(text, 128)}</div>
      </Tooltip>
    </SelectableText>}</>)
}

