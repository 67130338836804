import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import styled from "styled-components"
import { ComparisonPeriod, Visitor, VisitorChannel, VisitorFilters } from "../../api/Types"
import DateRangePickerMui from "../DateRangePickerMui"
import { useAlertStateValue } from "../../context/alert/AlertContext"
import { useEffect, useState } from "react"
import moment from "moment"
import dayjs from "dayjs"
import { DateRange } from "@mui/x-date-pickers-pro/internals/models"
import { apiGetDailyVisitors, apiGetKivaAnalyticsSessions, apiGetVisitorChannels } from "../../api/Visitors"
import { BarChart } from '@mui/x-charts';
import { getPreviousPeriodDates } from "../../api/Utils"

const VisitorHeading = styled.div`
  display:grid;
  width:99%;
  align-items:center;
  grid-template-columns: 10em 8em 8em 10em 8em 12em 24em;
  font-size:0.8em;
  column-gap:1em;
`

export type VisitorAnalyticsProps = {
  customerId: number
  loading: boolean
  setLoading: (loading: boolean) => void
}


export const VisitorAnalytics = (props: VisitorAnalyticsProps) => {
  const { customerId, loading, setLoading } = props
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"))
  const [endDate, setEndDate] = useState(moment().subtract(1, "days"))
  const [channel, setChannel] = useState<string>("All")
  const [filters, setFilters] = useState<VisitorFilters | undefined>()
  const [aggregation, setAggregation] = useState("date")
  const [campaign, setCampaign] = useState("All")
  const [landingPage, setLandingPage] = useState("All")
  const [visitors, setVisitors] = useState<Visitor[]>([])
  const [visitorsComparison, setVisitorsComparison] = useState<Visitor[]>([])
  const [variable, setVariable] = useState<string>("sessions")
  const [comparison, setComparison] = useState<ComparisonPeriod>("Custom")
  const [kivaAnalyticsVisitors, setKivaAnalyticsVisitors] = useState<Visitor[]>([])

  const variableToTitle = (variable: string): string => {
    if (variable === "sessions") return "sessions"
    if (variable === "users") return "users"
    return "new users"
  }

  const comp = getPreviousPeriodDates(startDate, endDate, comparison)
  useEffect(() => {
    setLoading(true)
    const comp = getPreviousPeriodDates(startDate, endDate, comparison)
    Promise.all([apiGetVisitorChannels(customerId,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")).then((filters) => setFilters(filters)),
    apiGetDailyVisitors(customerId, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), variable, aggregation, channel,
      campaign, landingPage).then(
        (visitors) => setVisitors(visitors)),
    apiGetDailyVisitors(customerId, comp.startDate.format("YYYY-MM-DD"), comp.endDate.format("YYYY-MM-DD"), variable, aggregation, channel,
      campaign, landingPage).then(
        (visitors) => setVisitorsComparison(visitors)),
    apiGetKivaAnalyticsSessions(customerId, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), aggregation, variable).then(
      (visitors) => setKivaAnalyticsVisitors(visitors)
    )
    ]).then(() => setLoading(false))
  }, [customerId, startDate, endDate, aggregation, channel, campaign, landingPage, variable, comparison])

  const handleDateRangeChange = (dateRange: DateRange<dayjs.Dayjs>) => {
    if (dateRange[0] && dateRange[1]) {
      setStartDate(moment(dateRange[0].toDate()))
      setEndDate(moment(dateRange[1].toDate()))
    }
  }

  const channelName = channel === 'All' ? 'All channels' : filters?.channels.find(c => c.channel === channel)?.channelName
  const series = [
    { data: visitorsComparison.map(v => v.count), label: "Comparison", color: '#83a53d7f' },
    { data: visitors.map(v => v.count), label: variableToTitle(variable), color: '#83a53d' }
  ]
  if ((aggregation === "date" && variable === "users") || variable === "sessions") {
    series.push({ data: kivaAnalyticsVisitors.map(v => v.count), label: "Kiva Analytics", color: '#cfa53d' })
  }

  return (<><VisitorHeading>
    <FormControl>
      <InputLabel id={"variable-id"}>Variable</InputLabel>
      <Select value={variable} label="Variable" labelId="variable-id" size="small"
        onChange={(ev) => setVariable(ev.target.value)}>
        <MenuItem value="sessions">Sessions</MenuItem>
        <MenuItem value="users">Users</MenuItem>
        <MenuItem value="newUsers">New users</MenuItem>
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"channel-id"}>Channel</InputLabel>
      <Select value={channel} label="Channel" labelId="channel-id" size="small"
        onChange={(ev) => setChannel(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.channels.map(c => <MenuItem value={c.channel}>{c.channelName} ({c.count})</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"campaign-id"}>Campaign</InputLabel>
      <Select value={campaign} label="Campaign" labelId="campaign-id" size="small"
        onChange={(ev) => setCampaign(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.campaignNames.map(c => <MenuItem value={c.campaignName}>{c.campaignName} ({c.count})</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"landingpage-id"}>Landing page</InputLabel>
      <Select value={landingPage} label="Landing page" labelId="landingpage-id" size="small"
        onChange={(ev) => setLandingPage(ev.target.value)}>
        <MenuItem value="All">All</MenuItem>
        {filters?.landingPageTitles.map(c => <MenuItem value={c.landingPageTitle}>{c.landingPageTitle} ({c.count})</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"period-id"}>Aggregation</InputLabel>
      <Select value={aggregation} label="Aggregation" labelId="period-id" size="small"
        onChange={(ev) => setAggregation(ev.target.value)}>
        <MenuItem value="date">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
        <MenuItem value="month">Month</MenuItem>
      </Select>
    </FormControl>
    <FormControl>
      <InputLabel id={"comparison-id"}>Comparison</InputLabel>
      <Select value={comparison} label="Comparison" labelId="comparison-id" size="small"
        onChange={(ev) => setComparison(ev.target.value as ComparisonPeriod)}>
        <MenuItem value="Custom">{comp.startDate.format("DD.MM.YYYY")} - {comp.endDate.format("DD.MM.YYYY")}</MenuItem>
        <MenuItem value="Week">Week</MenuItem>
        <MenuItem value="Month">Month</MenuItem>
        <MenuItem value="Year">Year</MenuItem>
      </Select>
    </FormControl>
    <FormControl>
      <DateRangePickerMui startDate={startDate.toDate()} endDate={endDate.toDate()} onChange={handleDateRangeChange} onClose={() => ''} />
    </FormControl>
  </VisitorHeading>
    {
      !loading && <Grid container spacing={1} marginTop={'1em'}>
        <Grid item xs={12}>
          <Card style={{ height: "100%" }}>
            <CardHeader title={`Daily ${variableToTitle(variable)}`} subheader={channelName} />
            <CardContent>

              <BarChart
                xAxis={[{ scaleType: 'band', label: 'Date', data: visitors.map(v => v.date!).flat() }]}
                series={series}

                height={300}
              />
            </CardContent>
            <CardContent>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    }</>)
}