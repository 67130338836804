import { useEffect } from "react";
import { createPortalSession } from "../../api/Stripe";
import { Box, CircularProgress, Typography } from "@mui/material";

/**
 * Success Component
 * @props sessionId
 */
const SuccessDisplay = ({ sessionId }: any) => {

  useEffect(() => {
    setTimeout(async () => {
      const redirectRes = await createPortalSession(sessionId)
      window.location.href = redirectRes?.data?.sessionUrl      
    }, 7000);
  }, [sessionId])
  
  return (
    <Box sx={{ display: 'grid', alignContent: 'center', alignItems: 'center', flexGrow: 1 }}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='h6' sx={{margin: '16px auto', color: 'green'}}  align='center' >
          Subscribed successfully!
        </Typography>
        <Typography variant='h6' sx={{margin: '16px auto'}}  align='center' >
          <CircularProgress  style={{width: '28px', height: '28px', color: "#5c5c5c"}} /> 
        </Typography>
        <Typography variant='body1' sx={{margin: '16px auto'}}  align='center' >Please wait. You are being redirected to manage your billing info...</Typography>
      </Box>
    </Box>
  );
};
export default SuccessDisplay