export type CompetitorCreative = {
  id: string
  creationTime: string
  startTime: string
  endTime: string
  competitorName: string
  competitorId: string
  channel: string
  category: string
  status: any
  url: string
  titles: string[];
  descriptions: string[]
  externalUrls: string[]
  landingPageUrls: string[]
  width: string
  height: string
  platforms: string[]
}

export type CompetitorCreativeResponse = {
  creatives: CompetitorCreative[]
}

export type CompetitorAdStatus = "ALL" | "ACTIVE" | "INACTIVE"

export enum MetaCompetitorAdType {
  ALL = "ALL",
  EMPLOYMENT_ADS = "EMPLOYMENT_ADS",
  FINANCIAL_PRODUCTS_AND_SERVICES_ADS = "FINANCIAL_PRODUCTS_AND_SERVICES_ADS",
  HOUSING_ADS = "HOUSING_ADS",
  POLITICAL_AND_ISSUE_ADS = "POLITICAL_AND_ISSUE_ADS"
}

export type CompetitorAdUnits = {
  startDate: Date
  endDate: Date
  searchKeyword: string
  status: "ALL" | "ACTIVE" | "INACTIVE"
  creatives: CompetitorCreativeResponse
  categories: string[]
  facebookPageIds: string[]
  adType: MetaCompetitorAdType
  loading: boolean
  gridViewItemsCount: number
}

export type CompetitorFacebookPages = {
  id: string,
  name: string
}

export const SET_COMPETITOR_AD_UNITS = 'SET_COMPETITOR_AD_UNITS'
export const SET_COMPETITOR_FACEBOOK_PAGES = 'SET_COMPETITOR_FACEBOOK_PAGES'

type SetCompetitorAdUnitsAction = {
  type: typeof SET_COMPETITOR_AD_UNITS;
  data: CompetitorAdUnits
};

type SetCompetitorFacebookPagesAction = {
  type: typeof SET_COMPETITOR_FACEBOOK_PAGES;
  data: CompetitorFacebookPages[]
};

export type CompetitorAction = SetCompetitorAdUnitsAction | SetCompetitorFacebookPagesAction

export type CompetitorState = {
  competitorAdUnits: CompetitorAdUnits
  competitorFacebookPages:  CompetitorFacebookPages[]
};

