import { Accordion, AccordionDetails, Grid, Typography } from '@mui/material'
import React from 'react'
import { desktop_p2, montserratBoldFontFamily } from '../../../styles/textStyles'

const MetaAccordionHeader = () => {
  return (
    <Accordion sx={{ alignItems: 'center', background:'#f2f2f2', borderRadius:'none', ":before": {backgroundColor: 'rgba(0,0,0,0) !important'} }} >
    <AccordionDetails sx={{padding:'12px 16px'}}>
      <Grid container sx={{ alignItems:'center' }}>
        <Grid item xs={6}>
          <Typography sx={{ ...desktop_p2, fontFamily: montserratBoldFontFamily }}>
            Name
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{marginLeft:'-8px'}}>
          <Typography sx={{ ...desktop_p2, fontFamily: montserratBoldFontFamily }}>Created time</Typography>
        </Grid>
        <Grid item xs={3} sx={{marginLeft:'-8px'}}>
          <Typography sx={{ ...desktop_p2, fontFamily: montserratBoldFontFamily }}>Delete</Typography>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
  )
}

export default MetaAccordionHeader