import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiCreateGoogleSheetAdTemplate, apiCreateManageAd, apiGetAdTemplates, apiGetGoogleSheetAds, apiGetManagedAds } from '../../api/Customer';
import { ManagedAd, ManagedAdType } from '../../api/Types';
import { useCustomerState } from '../../context/customer/CustomerContext';
import ManagedAdView from './ManagedAdView';
import { AdCreateType, AdTemplate } from '../AdTemplateView/Types';
import { regularBtnStyles, whiteBlackBorderBtnStyles } from '../../styles/buttons';
import { getURLParameter } from '../../utils/Generators';
import { response } from 'express';

const Header = styled.div`
  margin-top:1em;
  margin-bottom:0.5em;
  font-size:2.0em;
`

const CreateContainer = styled.div`
  grid-column-start:1;
  grid-column-end:3;
  grid-template-columns:1fr 1fr 5em;
  display:grid;
  column-gap:0px;
  max-width:700px;
  margin-bottom:20px;
  row-gap:5px;
`

export const ManagedAdsView = () => {
  const customer = useCustomerState()
  const [ads, setAds] = useState([] as ManagedAd[])
  const [adCreateType, setAdCreateType] = useState<AdCreateType>("template")
  const [adType, setAdType] = useState("HTML" as ManagedAdType)
  const [adName, setAdName] = useState("New ad")
  const [adEntryPoint, setAdEntryPoint] = useState("index.html")
  const [loading, setLoading] = useState(false)
  const [adTemplates, setAdTemplates] = useState<AdTemplate[]>([])
  const [adTemplateId, setAdTemplateId] = useState<string | undefined>()
  const [exportUrl, setExportUrl] = useState<string | undefined>()
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  

  const fetchGoogleSheetAdTemplate = async (customerId: number) => {
    setLoading(true)
    await apiGetGoogleSheetAds(customerId).then((response: any) => {
      if(response.redirectUrl) {
        window.location.href = response.redirectUrl
      } else 
      {
        // TODO: Update values from incoming data
        window.location.reload()
        // TODO: apiGetGoogleSheetAds return also params. If needed they can be accessed here.
      }
      
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const createGoogleSheetAdTemplate = async (customerId: number) => {
    setLoading(true)
    await apiCreateGoogleSheetAdTemplate(customerId, {ads: ads}).then((response) => {
      if (response.redirectUrl) {
        window.location.href = response.redirectUrl
      } else {
        setExportUrl(response.url)
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (getURLParameter("export") === "true") {
      setExportDialogOpen(true)
    }
  }, [window.location.href])

  useEffect(() => {
    if (customer.selected) {
      setLoading(true)
      apiGetManagedAds(customer.selected.id).then((response) => {
        setAds(response)
        setLoading(false)
      }).catch(error => { setLoading(false); })
      apiGetAdTemplates(customer.selected.id).then((response) => {
        setAdTemplates(response)
      })
    }
  }, [customer])
  return (customer.selected ? <Box sx={{ margin: '50px 24px' }}>
    <Header>Dynamic Ads</Header>
    {loading && <CircularProgress style={{ width: '32px', height: '32px' }} />}
    <CreateContainer>
      <Stack spacing={2} direction={"row"}>
        <Button size="medium" variant="contained" sx={regularBtnStyles} onClick={(e) => createGoogleSheetAdTemplate(customer.selected!.id)}>Upload sheet</Button>
        <Button size="medium" variant="contained" sx={regularBtnStyles} onClick={(e) => fetchGoogleSheetAdTemplate(customer.selected!.id)}>Import sheet</Button>
      </Stack>
      <RadioGroup row value={adCreateType} onChange={(_, value) => setAdCreateType(value as AdCreateType)} style={{ marginLeft: 10, gridColumnStart: 1, gridColumnEnd: 6 }}>
        <FormControlLabel label="Template" control={<Radio value="template" />} />
      </RadioGroup>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <TextField label="Name" value={adName} size="small" onChange={(event) => setAdName(event.target.value)} />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel size="small">Templates</InputLabel>
        <Select label="Templates" size="small" value={adTemplateId} onChange={(e) => setAdTemplateId(adTemplates.find(t => t.templateId === e.target.value)?.templateId)}>
          {adTemplates.map(t => <MenuItem value={t.templateId}>{t.name}</MenuItem>)}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 100 }}>
        <Button size="medium" variant="contained" onClick={() => {
          setLoading(true)
          apiCreateManageAd(customer.selected!.id, { createType: adCreateType, type: adType, name: adName, entryPoint: adEntryPoint, templateId: adTemplateId }).then((response) => {
            setAds([response].concat(ads))
          }).finally(() => setLoading(false))
        }} sx={regularBtnStyles}>Create</Button>
      </FormControl>
      <Dialog
        open={exportDialogOpen}
        onClose={() => setExportDialogOpen(false)}>
        <DialogTitle>
          Export existing Ads to Sheet
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {exportUrl ? <>Export was done. Sheet can be found on: <a target='_blank' href={exportUrl}>here</a></> :
              (getURLParameter("export") ? "Authentication was needed first. Press export to continue." :
                "This will create new sheet to your Google Workspace. Do you wish to continue?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!exportUrl && <Button sx={{...whiteBlackBorderBtnStyles}} onClick={() => {
            window.location.href = window.location.href.replace("?export=true", "")
          }}>Cancel</Button>}
          <Button sx={{...regularBtnStyles}} onClick={() => {
            if (exportUrl) {
              setExportDialogOpen(false)
              window.location.href = window.location.href.replace("?export=true", "")
            } else {
              createGoogleSheetAdTemplate(customer.selected!.id)
            }
          }}>{exportUrl ? "Close" : "Export"}</Button>
      </DialogActions>
    </Dialog>
    </CreateContainer>
    {!loading && ads.map((ad) =>
      <ManagedAdView key={ad.creativeId} customerId={customer.selected!.id} ad={ad} onDelete={() => setAds(ads.filter(a => a.creativeId !== ad.creativeId))} />
    )}
  </Box> : null)
}