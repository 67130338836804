import React, { useState, useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { setAlert } from '../../context/alert/AlertAction';
import CustomAlert from '../CustomAlert';
import { Link } from 'react-router-dom';
import { Check } from '@mui/icons-material';
import styled from 'styled-components';
import { createCheckoutSession } from '../../api/Stripe';
import { CheckoutRequestBody } from '../LandingPage/utils';
import { LoadingButton } from '../Common/LoadingButton';
import { KivaCssLogo } from '../Logo';


const List = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top:1em;
`

const ListLong = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  margin-top:1em;
`


const ListItem = styled.div`
  display:grid;
  grid-template-columns: 2em 1fr;
`

const ListItemIcon = styled.div``

const ListItemText = styled.div``

/**
 *
 * Landing page
 */
const CssLandingPage = () => {

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>('');
  const [selectedProducts, setSelectedProducts] = useState<any[]>(["kiva-css"])
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [userEmail, setUserEmail] = useState('')
  const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [companyName, setCompanyName] = useState('')
  const checkoutErrors: boolean = !userEmail || !emailReg.test(userEmail) || !companyName || selectedProducts.length < 1
  const [loading, setLoading] = useState(false)


  const handleSelectProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (selectedProducts.includes(value)) {
      setSelectedProducts(selectedProducts.filter(s => s !== value));
    } else {
      setSelectedProducts(selectedProducts.concat(value));
    }
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    const msg = query.get('msg')

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }
    if (query.get('canceled')) {
      setSuccess(false);
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Order canceled", duration: null }))

    }
    if (msg && msg === 'validationerror') {
      setSuccess(false);
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all required fields correctly!", duration: null }))
    }
    if (msg && msg === 'emailerror') {
      setSuccess(false);
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "You have already an account in Kiva Suite. Please login to manage your subscription.", duration: null }))
    }
  }, [sessionId]);


  const handleCheckoutClick = async () => {
    setLoading(true)
    let checkoutRequestBody: CheckoutRequestBody = {
      userEmail,
      companyName,
      lookupKeys: selectedProducts,
    }
    if (!checkoutErrors) {
      const redirectRes = await createCheckoutSession(checkoutRequestBody)
      window.location.href = redirectRes?.data?.sessionUrl.replace("/order", "/css")
    }
  }

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <CustomAlert />
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: '#fff', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ display: 'grid', alignContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Link to={'/login'} style={{ display: 'flex', alignItems: 'center' }}><KivaCssLogo width='124px' /></Link>
          </Box>
        </Toolbar>
      </AppBar>

      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 6, pb: 6 }}>
        <Box sx={{ display: 'grid', margin: 'auto', alignContent: 'center', justifyContent: 'center', flexGrow: 1, mb: 2 }}>
          <KivaCssLogo width='180px' />
        </Box>
        <Typography variant="h6" align="center" color="text.secondary" component="p">
          Comparison Shopping Services
        </Typography>

        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography>
            As a Google CSS -partner we can offer you <b>20% discount on your Google Shopping Ads CPC</b>.
          </Typography>
          <Typography variant="h6" sx={{ mt: 4 }}>Features</Typography>
          <List>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Self-service</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Support from Kiva marketing specialists</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>No extra costs, only monthly fee</ListItemText>
            </ListItem>
          </List>

          <Typography variant="h6" sx={{ mt: 4 }}>Supported countries</Typography>
          <List>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Finland</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>United Kingdom</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Sweden</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Austria</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Denmark</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Spain</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Poland</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Germany</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Netherlands</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Check /></ListItemIcon><ListItemText>Norway</ListItemText>
            </ListItem>
          </List>

          <Typography variant="h6" sx={{ mt: 4 }}>Order process</Typography>
          <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12}>
              <Typography>
                Ordering CSS is simple process and can be done in just couple of minutes:
                <ListLong>
                  <ListItem>
                    <ListItemIcon>1.</ListItemIcon><ListItemText>Fill in the form below</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>2.</ListItemIcon><ListItemText>Go through the checkout process</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>3.</ListItemIcon><ListItemText>Set your password from email you received</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>4.</ListItemIcon><ListItemText>Login in to Kiva CSS</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>5.</ListItemIcon><ListItemText>Fill in your shop details</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>6.</ListItemIcon><ListItemText>Accept CSS transfer from an email you receive</ListItemText>
                  </ListItem>
                </ListLong>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{ mt: 4 }}>Order here</Typography>
          <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={6} sx={{}}>
              <Typography>Email</Typography>
              <Typography variant='caption' color={'gray'}>Please provide us with your email</Typography>
            </Grid>
            <Grid item xs={6} sx={{ mb: 2 }}>
              <TextField required
                size='small'
                fullWidth
                id="outlined-basic"
                type='email'
                value={userEmail}
                error={Boolean(userEmail) && !emailReg.test(userEmail)}
                helperText={Boolean(userEmail) && !emailReg.test(userEmail) ? "Please enter valid email" : ""}
                onChange={(e: any) => setUserEmail(e.target.value)}
                label="Email"
                variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={6} sx={{}}>
              <Typography>Company Name</Typography>
              <Typography variant='caption' color={'gray'}>Please provide us with your company name</Typography>
            </Grid>
            <Grid item xs={6} sx={{ mb: 2 }}>
              <TextField required
                size='small'
                fullWidth
                id="outlined-basic"
                value={companyName}
                onChange={(e: any) => setCompanyName(e.target.value)}
                label="Company Name"
                variant="outlined" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
                key={"kiva-css"}
                value={"kiva-css"}
                control={<Checkbox onChange={handleSelectProduct} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                label={'Kiva CSS 99 €/month (+VAT 24%)'}
                labelPlacement="end"
                disabled={true}
                checked={selectedProducts.indexOf('kiva-css') > -1}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ margin: '24px auto', alignItems: 'right' }}>
          <LoadingButton title='Checkout' loading={loading} disabled={checkoutErrors} onClick={() => { handleCheckoutClick() }} />
        </Grid>
      </Container>

      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 0,
          py: [3, 2],
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          Kiva Suite{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
      {/* End footer */}
    </React.Fragment >
  )
}

export default CssLandingPage