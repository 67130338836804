import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, PaperProps, Select, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Animation, AnimationEffectPropertyChange, AnimationEffectWait, AnimationProperty, AnimationPropertyNames, AnimationEffect } from "./AnimationTypes"
import styled from "styled-components"
import Draggable from 'react-draggable'
import { Delete } from "@mui/icons-material"

const AnimationContent = styled.div`
  display:flex;
  flex-direction:column;
  row-gap:10px;
  min-width:400px;
`

const Heading = styled.div`
  font-weight:bold;
  margin-top:1px;
  margin-bottom:10px;
`

const EffectHeading = styled.div`
  font-style:italic;
  margin-top:1px;
  margin-bottom:10px;
  font-size:0.9em;
`

const PropertyChangeEffectContainer = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  row-gap:10px;
  column-gap:10px;
`

const AddEffectContainer = styled.div`
  display:grid;
  grid-template-columns:1fr 5em;
  column-gap:10px;
  align-items:center;
`

const EffectsContainer = styled.div`
  position:relative;
  display:grid;
  grid-template-columns:5em 1fr 2em;
  row-gap:20px;
  align-items:center;
`

const DeleteEffect = styled.div`
  color:red;
  cursor:pointer;
`

const emptyWaitEffect: AnimationEffectWait = { type: "Wait", durationMs: 1000 }
const emptyPropertyChangeEffect: AnimationEffectPropertyChange = { type: "PropertyChange", property: "opacity", startValue: 0, endValue: 1, durationMs: 1000 }
const emptyAnimation: Animation = { version: 1, effects: [] }

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export type AnimationDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  updateAnimation: (animation: Animation) => void
  animation?: Animation
}

export const AnimationDialog = (props: AnimationDialogProps) => {
  const { open, setOpen, updateAnimation } = props
  const [animation, setAnimation] = useState<Animation>(props.animation || emptyAnimation)
  const [newEffectType, setNewEffectType] = useState("Wait")

  const addNewEffect = () => {
    setAnimation({ ...animation, effects: animation.effects.concat([newEffectType === "Wait" ? emptyWaitEffect : emptyPropertyChangeEffect]) })
  }

  const setEffect = (index: number, effect: AnimationEffect) => {
    setAnimation({ ...animation, effects: animation.effects.map((e, i) => i === index ? effect : e) })
  }

  const removeEffect = (index: number) => {
    setAnimation({ ...animation, effects: animation.effects.filter((_, i) => index !== i) })
  }

  useEffect(() => {
    setAnimation(props.animation || emptyAnimation)
  }, [props])

  return (<Dialog
    open={open}
    PaperComponent={PaperComponent}
    onClose={() => setOpen(false)}
  >
    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
      Edit Animation
    </DialogTitle>
    <DialogContent>
      <AnimationContent>
        <Heading>Animation effects</Heading>
        <EffectsContainer>
          {animation.effects.map((effect, index) => {
            if (effect.type === "Wait") {
              return <>
                <EffectHeading>Wait</EffectHeading>
                <TextField size="small" value={effect.durationMs} label={"Milliseconds (ms)"}
                  onChange={(ev) => setEffect(index, { ...effect, durationMs: parseInt(ev.target.value, 10) })} />
                <IconButton onClick={() => removeEffect(index)}><Delete style={{ color: 'red' }} /></IconButton>
              </>
            }
            return (<>
              <EffectHeading>Property change</EffectHeading>
              <PropertyChangeEffectContainer>
                <FormControl>
                  <InputLabel id={`effect-${index}-select`}>Property</InputLabel>
                  <Select size="small" labelId={`effect-${index}-select`} label="Property" value={effect.property} onChange={(ev) => setEffect(index, { ...effect, property: ev.target.value as AnimationProperty })}>
                    {AnimationPropertyNames.map(name => <MenuItem value={name}>{name}</MenuItem>)}
                  </Select>
                </FormControl>
                <TextField size="small" label="Duration (ms)" value={effect.durationMs} type="number"
                  onChange={(ev) => setEffect(index, { ...effect, durationMs: parseInt(ev.target.value, 10) })} />
                <TextField size="small" label="Start value" value={effect.startValue}
                  onChange={(ev) => setEffect(index, { ...effect, startValue: parseInt(ev.target.value, 10) })} />
                <TextField size="small" label="End value" value={effect.endValue}
                  onChange={(ev) => setEffect(index, { ...effect, endValue: parseInt(ev.target.value, 10) })} />
              </PropertyChangeEffectContainer>
              <IconButton onClick={() => removeEffect(index)}><Delete style={{ color: 'red' }} /></IconButton>
            </>)
          })}
          <div>Type</div>
          <AddEffectContainer>
            <Select size="small" value={newEffectType} onChange={(ev) => setNewEffectType(ev.target.value)}>
              <MenuItem value={"Wait"}>Wait</MenuItem>
              <MenuItem value={"PropertyChange"}>Property Change</MenuItem>
            </Select>
            <Button variant="contained" size="small" color="primary" onClick={() => addNewEffect()}>Add</Button>
          </AddEffectContainer>
        </EffectsContainer>
      </AnimationContent>
    </DialogContent >
    <FormControl>
      <DialogActions sx={{ gap: 1 }}>
        <Button variant="contained" size="small" color="error" onClick={() => { setOpen(false) }}>Cancel</Button>
        <Button variant="contained" size="small" component="span" color="primary" onClick={() => {
          updateAnimation(animation)
          setOpen(false)
        }}>Set animation</Button>
      </DialogActions>
    </FormControl>
  </Dialog >)
}