import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TopAppBar from './TopAppBar';
import DrawerComponent from './DrawerComponent';
import { useSharedStateValue } from '../context/shared/SharedContext';
import { setDrawerIsOpen } from '../context/shared/SharedActions';
import CustomAlert from '../components/CustomAlert';

export const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding:  0 /*theme.spacing(3)*/,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0 /*`-${drawerWidth}px`*/,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  paddingLeft: '16px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

interface NavigationComponentsProps {
  children: React.ReactNode
}

const NavigationComponents = ({ children }: NavigationComponentsProps) => {
  const [open, setOpen] = React.useState(true);
  const [{drawerIsOpen}, sharedDispatch] = useSharedStateValue()

  React.useEffect(() => {
    sharedDispatch(setDrawerIsOpen(open))
  }, [open])
  
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopAppBar open={open} setOpen={setOpen} />
      <DrawerComponent open={open} setOpen={setOpen} DrawerHeader={DrawerHeader} />
      <Main open={open}>
        <DrawerHeader />
        {children}
        <CustomAlert />
      </Main>
    </Box>
  );
}

export default NavigationComponents
