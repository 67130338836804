import styled from 'styled-components';
import { FontFamilies } from './Colors';

const Div = styled.div`
    width:100%;
    display: flex;
    min-height: 10em;
    margin: 20px;
    align-items: center;
    justify-content: center;
    font-family: ${FontFamilies};
`

export type InfoTextProps = {
  children: React.ReactNode
}

export const InfoText = (props: InfoTextProps) => {
  return (<Div>{props.children}</Div>)
}