
import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Divider, Grid, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { createCheckoutSession } from '../../api/Stripe';
import { CheckoutRequestBody, kivaSuiteProducts } from './utils';
import { countryCodes } from '../Utils';


const ProductDisplay = ({ selectedProducts, handleSelectProduct }: {
  selectedProducts: string[],
  handleSelectProduct: any
}) => {

  const [userEmail, setUserEmail] = useState('')
  const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [companyName, setCompanyName] = useState('')
  const [googleAnalytics4Id, setGoogleAnalytics4Id] = useState('')

  const [facebookPageId, setFacebookPageId] = useState('')
  const [adAccountId, setAdAccountId] = useState('')

  const checkoutErrors: boolean = !userEmail || !emailReg.test(userEmail) || !companyName || selectedProducts.length < 1 || ((selectedProducts.indexOf('kiva-analytics') !== -1 && (!googleAnalytics4Id)))

  const handleCheckoutClick = async () => {

    let checkoutRequestBody: CheckoutRequestBody = {
      userEmail,
      companyName,
      lookupKeys: selectedProducts,
    }
    if (selectedProducts.indexOf('kiva-analytics') !== -1) {
      checkoutRequestBody = {
        ...checkoutRequestBody, googleAnalytics4Id
      }
    }

    if (!checkoutErrors) {
      const redirectRes = await createCheckoutSession(checkoutRequestBody)
      window.location.href = redirectRes?.data?.sessionUrl
    }
  }

  return (
    <Container maxWidth="md" component="main">
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{}}>
          <Typography variant='h6'>Email</Typography>
          <Typography variant='caption' color={'gray'}>Please provide us with your email</Typography>
        </Grid>
        <Grid item xs={6} sx={{ mb: 2 }}>
          <TextField required
            size='small'
            fullWidth
            id="outlined-basic"
            type='email'
            value={userEmail}
            error={Boolean(userEmail) && !emailReg.test(userEmail)}
            helperText={Boolean(userEmail) && !emailReg.test(userEmail) ? "Please enter valid email" : ""}
            onChange={(e: any) => setUserEmail(e.target.value)}
            label="Email"
            variant="outlined" />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{}}>
          <Typography variant='h6'>Company Name</Typography>
          <Typography variant='caption' color={'gray'}>Please provide us with your company name (use your name or any other name if it is not found)</Typography>
        </Grid>
        <Grid item xs={6} sx={{ mb: 2 }}>
          <TextField required
            size='small'
            fullWidth
            id="outlined-basic"
            value={companyName}
            onChange={(e: any) => setCompanyName(e.target.value)}
            label="Company Name"
            variant="outlined" />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{}}>
          <Typography variant='h6'>Select Product</Typography>
          <Typography variant='caption' color={'gray'}>Select at least one product to proceed</Typography>
        </Grid>
        <Grid item xs={12} sx={{}}>
          <FormControl component="fieldset">
            <FormLabel sx={{ fontSize: '16px' }} component="legend">Our Products</FormLabel>
            <FormGroup aria-label="position">
              {kivaSuiteProducts.map((product: any) =>
                <FormControlLabel
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
                  key={product.key}
                  value={product.key}
                  control={<Checkbox onChange={handleSelectProduct} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
                  label={product.label}
                  labelPlacement="end"
                  checked={selectedProducts.indexOf(product.key) > -1}
                  disabled={product.key === 'kiva-analytics-installation-fee' ? true : false}
                />
              )}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {selectedProducts.indexOf('kiva-analytics') !== -1 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{}}>
            <Typography>For Kiva Analytics product, please provide us with your Universal Analytics ID and Google Analytics 4 ID or at least one of them:</Typography>
          </Grid>
          <Grid item xs={6} sx={{}}>
            <TextField required size='small'
              fullWidth id="outlined-basic"
              value={googleAnalytics4Id}
              onChange={(e: any) => setGoogleAnalytics4Id(e.target.value)}
              label="Google Analytics 4 ID"
              variant="outlined" />
          </Grid>
        </Grid>
      )}
      {selectedProducts.indexOf('kiva-css') !== -1 && (
        <Grid item xs={12} sx={{}}>
          <Typography>Note! Currently available CSS countries:</Typography>
          <Typography>{countryCodes.map(e => e.name).sort().join(", ")}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={{ margin: '24px auto' }}>
        <Button variant='outlined' disabled={checkoutErrors} sx={{ textTransform: 'none' }} color='inherit' id="checkout-and-portal-button" onClick={handleCheckoutClick}>
          Checkout
        </Button>
      </Grid>
    </Container>)
};

export default ProductDisplay
