import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { apiCreateCustomModel, apiCreateCustomModelTask, apiGetCustomDatasets } from "../../api/Assistant"
import { useEffect, useState } from "react"
import { CustomDataset, CustomModel, CustomModelTask, CustomModelType } from "../../api/Types"


export type CreateCustomModelTeachTaskDialogProps = {
  customerId: number
  customModel: CustomModel
  open: boolean
  setOpen: (open: boolean) => void
  setLoading: (loading: boolean) => void
  onCreate: (tasks: CustomModelTask[]) => void
}

export const CreateCustomModelTeachTaskDialog = (props: CreateCustomModelTeachTaskDialogProps) => {
  const { customerId, customModel, open, setOpen, setLoading, onCreate } = props
  const [dataset, setDataset] = useState<CustomDataset | undefined>()
  const [customDatasets, setCustomDatasets] = useState<CustomDataset[]>([])

  useEffect(() => {
    apiGetCustomDatasets(customerId).then((customDatasets) => setCustomDatasets(customDatasets))
  }, [customerId])

  return (<Dialog
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>
      Create Teach Task
    </DialogTitle>
    <DialogContent>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel size="small">Teaching material</InputLabel>
        <Select label="Teaching material" value={dataset?.id} size="small" onChange={(e) => {
          setDataset(customDatasets.find(cd => cd.id === e.target.value))
        }}>
          {customDatasets.map(cm => <MenuItem value={cm.id}>{cm.name}</MenuItem>)}
        </Select>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="error" onClick={() => { setOpen(false) }}>Cancel</Button>
      <Button disabled={!dataset} variant="contained" color="primary" onClick={() => {
        setLoading(true)
        setOpen(false)
        apiCreateCustomModelTask(customerId, customModel.id, [dataset!.id], customModel.type).then((tasks) => {
          setLoading(false)
          setOpen(false)
          onCreate(tasks)
        }).catch(() => {
          setLoading(false)
          setOpen(false)
        })
      }}>Create</Button>
    </DialogActions>
  </Dialog>)
}