import React, { useReducer, ReactNode, Dispatch } from 'react';
import { createContext } from '..';
import { CustomersAction, CustomerReducer, CustomersState, initialCustomersState } from './CustomerReducer';


const [useCustomerState, CustomersStateProvider] = createContext<CustomersState>()
const [useCustomerDispatch, CustomersDispatchProvider] = createContext<Dispatch<CustomersAction>>()

export type CustomerProviderProps = {
  children: ReactNode
}

export const CustomersProvider = (props: CustomerProviderProps) => {
  const [customers, dispatch] = useReducer(CustomerReducer, initialCustomersState);
  return (<CustomersStateProvider.Provider value={customers}>
    <CustomersDispatchProvider.Provider value={dispatch}>
      {props.children}
    </CustomersDispatchProvider.Provider>
  </CustomersStateProvider.Provider>)
};


export { useCustomerState, useCustomerDispatch }