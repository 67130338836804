import { useEffect, useState } from "react"
import styled from "styled-components"
import { CustomModel, CustomModelTaskStatus } from "../../api/Types"
import { regularBtnStyles } from "../../styles/buttons"
import { Button } from "@mui/material"
import { CreateCustomModelTeachTaskDialog } from "./CreateCustomModelTeachTaskDialog"
import moment from "moment"
import { RefreshButton, RemoveButton } from "../Buttons"
import { apiGetCustomModels, apiRefreshCustomModelTask, apiRemoveCustomModelTask } from "../../api/Assistant"


const CustomModelTask = styled.div`
  display:grid;
  grid-template-columns:10em 15em 10em 2em 2em;
  margin-top:1em;
  margin-bottom:1em;
`

const Info = styled.div`
  margin-top:1em;
  margin-bottom:2em;
`

const HeaderColumn = styled.div`
  font-weight:bold;
`

export type CustomModelViewProps = {
  customerId: number
  customModel: CustomModel
  setLoading: (loading: boolean) => void
}

const statusToColor = (status: CustomModelTaskStatus): string => {
  if (status === "Failed") return "red"
  if (status === "Succeeded") return "green"
  return "orange"
}

export const CustomModelView = (props: CustomModelViewProps) => {
  const { customerId, customModel, setLoading } = props
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false)
  const [tasks, setTasks] = useState(customModel.tasks)

  const onRemoveTask = (taskId: string) => {
    setLoading(true)
    apiRemoveCustomModelTask(customerId, customModel.id, taskId).then((tasks) => {
      setLoading(false)
      setTasks(tasks)
    })
  }

  const onRefresh = (taskId: string) => {
    setLoading(true)
    apiRefreshCustomModelTask(customerId, customModel.id, taskId).then((tasks) => {
      setLoading(false)
      setTasks(tasks)
    })
  }

  return (customModel ? <>
    <h3>{customModel.name} ({customModel.type})</h3>
    <h4>Tasks</h4>
    {tasks.length === 0 && <Info>No tasks yet.</Info>}
    <CustomModelTask>
      {tasks.length > 0 && <>
        <HeaderColumn>Type</HeaderColumn>
        <HeaderColumn>Created</HeaderColumn>
        <HeaderColumn>Status</HeaderColumn>
        <HeaderColumn></HeaderColumn>
        <HeaderColumn></HeaderColumn>
      </>}
      {tasks.sort((a, b) => b.updated.localeCompare(a.updated)).map(t => <>
        <div>{t.type}</div>
        <div>{moment(t.created).fromNow()}</div>
        <div style={{ color: statusToColor(t.status) }}>{t.status}</div>
        <div><RefreshButton onClick={() => onRefresh(t.id)} /></div>
        <div><RemoveButton onClick={() => onRemoveTask(t.id)} /></div>
      </>)}
    </CustomModelTask>
    <Button sx={regularBtnStyles} onClick={() => { setCreateTaskDialogOpen(true) }}>Create teach task</Button>
    <CreateCustomModelTeachTaskDialog open={createTaskDialogOpen} setOpen={setCreateTaskDialogOpen}
      customerId={customerId} customModel={customModel}
      setLoading={setLoading}
      onCreate={(tasks) => { setTasks(tasks) }} />
  </> : <></>)
}