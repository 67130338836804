import React, { createContext, useReducer, useContext } from 'react';
import { initialSharedState } from './SharedReducer';
import { SharedState, SharedAction } from './SharedTypes';

export const StateContext = createContext<[SharedState, React.Dispatch<SharedAction>]>([
  initialSharedState, 
  () => initialSharedState
])

export type SharedProviderProps = {
  reducer: React.Reducer<SharedState, SharedAction>
  children: React.ReactElement
}

export const SharedProvider = ({reducer, children}: SharedProviderProps) => {
  const [sharedState, dispatch] = useReducer(reducer, initialSharedState);
  return (
    <StateContext.Provider value={[sharedState, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}
export const useSharedStateValue = () => useContext(StateContext);