import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(item: string, currentItems: readonly string[], theme: Theme) {
  return {
    fontWeight:
    currentItems.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export type MultipleSelectChipProps = {
  title: string
  items: string[]
  setSelectedItems: (items: string[]) => void
}

/**
 * @component MultipleSelectChip - Reusable component 
 */
const MultipleSelectChip = ({title, items, setSelectedItems}: MultipleSelectChipProps) => {
  const theme = useTheme();
  const [currentItems, setCurrentItems] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof currentItems>) => {
    const { target: { value }, } = event;
    setCurrentItems(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setSelectedItems(typeof value === 'string' ? value.split(',') : value,)
  };

  return (
    <div>
      <FormControl size='small' variant="outlined" sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={currentItems}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={title} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {items.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, currentItems, theme)}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default MultipleSelectChip;
