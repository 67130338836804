import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_LICENSE_KEY || '');

import * as React from 'react';
import Calendar from '@mui/icons-material/Event';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/internals/models/range';
import { primaryColors } from '../styles/colors';
import { buttonClasses } from "@mui/material/Button";
import Chip from '@mui/material/Chip';

import {
  PickersShortcutsItem,
  PickersShortcutsProps,
} from '@mui/x-date-pickers/PickersShortcuts';
import { Divider, Box } from '@mui/material';


export type DateRangePickerMuiPorps = {
  startDate: Date
  endDate: Date
  onChange: (value: DateRange<dayjs.Dayjs>) => void
  onClose: () => void
}


const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(1, 'month');
      return [prevMonth.startOf('month'), prevMonth.endOf('month')];
    },
  },
  {
    label: 'Current Year',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('year'), today];
    },
  },
  {
    label: 'Last Year',
    getValue: () => {
      const today = dayjs();
      const prevYear = today.subtract(1, 'year');
      return [prevYear.startOf('year'), prevYear.endOf('year')];
    },
  },
  {
    label: 'Last 30 days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    }
  },
];

function CustomRangeShortcuts(props: PickersShortcutsProps<DateRange<Dayjs>>) {
  const { items, onChange, isValid } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
        maxWidth: '100%',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '624px',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          order: 0,
          gap: '8px',
          padding: '12px'
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <Box key={item.label} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Chip {...item} sx={{}} />
            </Box>
          );
        })}
      </Box>
      <Divider />
    </Box>

  );
}
/**
 * @info https://mui.com/x/react-date-pickers/date-range-picker/#use-a-single-input-field
 * @info https://mui.com/x/react-date-pickers/custom-field/#use-single-input-fields-on-range-pickers
 * @info https://mui.com/x/api/date-pickers/date-range-picker/
 */
const DateRangePickerMui = ({ startDate, endDate, onChange, onClose }: DateRangePickerMuiPorps) => {

  const [dateRange, setDateRange] = React.useState<DateRange<dayjs.Dayjs>>([dayjs(startDate), dayjs(endDate)]);

  const handleOnChange = (value: DateRange<dayjs.Dayjs>) => {
    setDateRange(value)
    onChange(value);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* DemoContainer = MuiStack-root */}
      <DemoContainer components={['SingleInputDateRangeField']} sx={{ ...stackRootStyles }}>
        <DateRangePicker
          displayWeekNumber
          calendars={2}
          slots={{
            field: SingleInputDateRangeField,
            shortcuts: CustomRangeShortcuts
          }}
          slotProps={{
            textField: { InputProps: { endAdornment: <Calendar /> } },
            shortcuts: { items: shortcutsItems }
          }}
          value={dateRange}
          onChange={handleOnChange}
          sx={{ ...styles }}
          onClose={onClose}
          format="DD.MM.YYYY"
          disableFuture

        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

const styles = {
  '& .MuiInputBase-input': {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  '& .MuiSvgIcon-root': {
    fill: primaryColors[500]
  },
  "& .MuiPickersDay-root": {
    "&.Mui-selected": {
      backgroundColor: primaryColors[500],
    },
  },
  '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
    backgroundColor: primaryColors[500],
  }
}

const stackRootStyles = {
  '&.MuiStack-root': { // '&' OR '&.MuiStack-root' selects current element MuiStack-root.
    paddingTop: '0 !important'
  }
}

export default DateRangePickerMui