import { Button, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { ManagedAdFile, ManagedAdInstance } from "../../api/Types"
import styled from "styled-components"
import { apiGetManagedAdFiles, apiReplaceManagedAdFile } from "../../api/Customer"
import { LoadingModal } from "../Modal"
import { regularBtnStyles } from "../../styles/buttons"


const Container = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr;
  row-gap:1em;
  column-gap:1em;
`

const Heading = styled.h3`
  grid-column-start:1;
  grid-column-end:6;
`

const ImageContainer = styled.div`
  display:grid;
  grid-template-rows:1fr 2em;
  align-items:center;
  justify-items:center;
  row-gap:1em;
  padding:1em;
`

const Image = styled.img`
  width:128px;
  height:128px;
  object-fit:contain;
`


export const ManagedAdFilesView = (props: { customerId: number, creativeId: string, currentInstance: ManagedAdInstance | undefined }) => {
  const { customerId, creativeId, currentInstance } = props
  const [files, setFiles] = useState<ManagedAdFile[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    apiGetManagedAdFiles(customerId, creativeId).then((files) => setFiles(files))
  }, [creativeId])

  const categories: { [size: string]: ManagedAdFile[] } = {}
  for (const file of files) {
    const key = `${file.width}x${file.height}`
    if (!(key in categories)) {
      categories[key] = []
    }
    categories[key].push(file)
  }

  const currentInstanceDimentions: string = currentInstance ? `${currentInstance.width}x${currentInstance.height}` : ""

  const filteredSortedCategories: { [size: string]: ManagedAdFile[] }  = Object.fromEntries(
    Object.entries(categories).filter(([key]) => key !== currentInstanceDimentions).sort(([a], [b])=> a.localeCompare(b))
  );

  const currentInstanceCategory = Object.fromEntries(
    Object.entries(categories).filter(([key]) => key === currentInstanceDimentions));

  const mergedCategories: { [size: string]: ManagedAdFile[] } = {
    ...currentInstanceCategory,
    ...filteredSortedCategories
  };

  return (<Container>
    {loading && <LoadingModal />}
    {
      Object.keys(mergedCategories).map(key => {
        return <>
          <Heading>{key}</Heading>
          {categories[key].map((f, index) =>
            <Paper>
              <ImageContainer>
                <Image src={f.url} id={`image-${key}-${index}`} />
                <input
                  style={{ display: 'none' }}
                  id={`raised-button-file-${key}-${index}`}
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      const formData = new FormData()
                      formData.append('File', event.target.files[0])
                      event.target.value = ""
                      setLoading(true)
                      apiReplaceManagedAdFile(customerId, creativeId, f.filename, formData)
                        .then(() => {
                          setLoading(false)
                          document.getElementById(`image-${key}-${index}`)?.setAttribute("src", `${f.url}?${new Date().getTime()}`)
                        })
                        .catch(() => {
                          setLoading(false)
                        })
                    }
                  }} />
                <label htmlFor={`raised-button-file-${key}-${index}`}>
                  <Button id={`raised-button-file-${key}-${index}`} size="small" color="primary" variant="contained" component="span" sx={regularBtnStyles}>
                    Replace
                  </Button>
                </label>
              </ImageContainer>
            </Paper>
          )}</>
      })
    }
  </Container>)
}