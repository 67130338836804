import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { AdSize, ManagedAdExitEvent } from '../../api/Types';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';


export default function AdSizesCheckboxList(props: {sizes: AdSize[], exitEvents: ManagedAdExitEvent[], setExitEvents: Function}) {
  const supportedAdSizes: Array<AdSize> = [
    {width: 160, height: 600},
    {width: 300, height: 250},
    {width: 300, height: 600},
    {width: 980, height: 400}
  ]
  const [checked, setChecked] = React.useState(Array<AdSize>());
  const checkSupportedSize = (size: AdSize): boolean => {
    return supportedAdSizes.some(e => e.width === size.width && e.height === size.height)
  }

  function setExitEvent(index: number, exitEvent: ManagedAdExitEvent){
    props.setExitEvents(props.exitEvents.map((ev, i) => i === index ? exitEvent : ev))
  }

  const handleToggle = (value: AdSize) => () => {
    const currentIndex = checked?.findIndex(i => i.height === value.height && i.width === value.width);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setExitEvent(0, { ...props.exitEvents[0], adSizes: newChecked })
  };
  React.useEffect(() => {
    setChecked(props.exitEvents[0].adSizes);
  }, [checked]);
  return (
    <List sx={{ width: '100%', maxWidth: 720, bgcolor: 'background.paper'}}>
      <Typography fontSize={11} align='right'>DV360 support</Typography>
      {props.sizes.map((value) => {
        const labelId = `checkbox-list-label-${value}`;
        return (
          <ListItem
            key={value.height + value.width}
            secondaryAction={
              checkSupportedSize(value) ? <CheckIcon color="success"/>:<CancelIcon color="disabled"/>
            }
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked?.findIndex(i => i.height === value.height && i.width === value.width) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`Ad (${value.width}x${value.height})`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}