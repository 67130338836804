import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import CampaignInfo from '../sharedMetaComponents/CampaignInfo';
import AdSetInfo from '../sharedMetaComponents/AdSetInfo';
import AdAutomationKeywords from './AdAutomationKeywords';
import { useStateValue } from '../../../context/meta/MetaContext';
import { useAlertStateValue } from '../../../context/alert/AlertContext';
import { setAlert } from '../../../context/alert/AlertAction';
import { AlertSeverity } from '../../../context/alert/AlertTypes';
import { CircularProgress, StepButton } from '@mui/material';
import AdAutomationInfo from './AdAutomationInfo';
import { setAdAutomations, setAdAutomationSettings } from '../../../context/meta/MetaActions';
import { createNewAdAutomation, getAdAutomations } from '../../../api/Marketing';
import { initialAdAutomationSettings } from '../../../context/meta/MetaReducer';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { useHistory } from 'react-router-dom';
import { MetaErrors } from '../../../context/meta/MetaTypes';
import { primaryColors } from '../../../styles/colors';
import { desktop_labels_l1 } from '../../../styles/textStyles';
import { regularBtnStyles, whiteBlackBorderBtnStyles } from '../../../styles/buttons';

const steps = ['Create campaign', 'Create ad set', 'Set keywords or hashtags'];

const AdAutomationStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [{ adAutomationSettings, adAutomationSettingsErrors }, dispatch] = useStateValue()
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [completed, setCompleted] = React.useState<number[]>([])
  const [createBtnProgress, setCreateBtnProgress] = React.useState(false)
  const customerState = useCustomerState()
  const history = useHistory()



  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const stepZeroErrors: boolean = !adAutomationSettings.campaign.name || adAutomationSettings.campaign.name?.length < 3 || !adAutomationSettings.name || adAutomationSettings.name?.length < 3 || adAutomationSettingsErrors.includes(MetaErrors.campaignNameError) || adAutomationSettingsErrors.includes(MetaErrors.adAutomationNameError)
  const stepOneErrors: boolean = !adAutomationSettings.adSet.name || adAutomationSettings.adSet.name?.length < 3 || adAutomationSettingsErrors.includes(MetaErrors.adSetNameError) || (adAutomationSettings.campaign.objective.toLowerCase().includes('engagement') && !adAutomationSettings.adSet.promoted_object.pixel_id)
  const stepTwoErrors = !adAutomationSettings.keywords?.length || adAutomationSettingsErrors.includes('Keywords could not be empty. Enter at least one keyword which has at least 2 characters.')

  const handleNext = () => {
    window.scrollTo({ top: 0 })
    switch (activeStep) {
      case 0:
        if (stepZeroErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all the required fields before moving forward.", duration: null }))
        } else {
          setCompleted(completed.filter(n => n !== activeStep).concat(activeStep))
          setActiveStep(activeStep + 1);
        }
        break;
      case 1:
        if (stepOneErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all required fields for ad set creation step before moving forward.", duration: null }))
        } else {
          setCompleted(completed.filter(n => n !== activeStep).concat(activeStep))
          setActiveStep(activeStep + 1);
        }
        break;
      case 2:
        if (stepTwoErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please enter at least one keyword", duration: null }))
        } else {
          setCompleted(completed.filter(n => n !== activeStep).concat(activeStep))
        }
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    const previousStep = step - 1
    switch (previousStep) {
      case 0:
        if (stepZeroErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all the required fields before moving forward.", duration: null }))
        } else {
          setActiveStep(step);
        }
        break;
      case 1:
        if (stepOneErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all the required fields for ad set creation step before moving forward.", duration: null }))
        } else {
          setActiveStep(step);
        }
        break;
      case 2:
        if (stepTwoErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please enter at least one keyword", duration: null }))
        } else {
          setActiveStep(step);
        }
        break;
      default:
        setActiveStep(step);
        break;
    }
  };

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return <><AdAutomationInfo /><CampaignInfo /></>;
      case 1:
        return <AdSetInfo />;
      case 2:
        return <AdAutomationKeywords />;
      default:
        return <><AdAutomationInfo /><CampaignInfo /></>;
    }
  };

  const handleCompleted = (index: number) => {

    if (index === 0 && !stepZeroErrors) {
      return true
    }
    if (index === 1 && !stepOneErrors) {
      return true
    }
    if (index === 2 && !stepTwoErrors) {
      return true
    }
    return false
  }

  const handleCreate = async () => {
    setCreateBtnProgress(true)
    const customerId = customerState?.selected?.id
    if (customerId) {
      const createAdAutomationResult = await createNewAdAutomation(customerId, adAutomationSettings)
      if (createAdAutomationResult.success) {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Ad automation created successfully!", duration: 7000 }))
        dispatch(setAdAutomationSettings(initialAdAutomationSettings))
        const adAutomations = await getAdAutomations(customerId)
        dispatch(setAdAutomations(adAutomations))
        setCreateBtnProgress(false)
        setTimeout(() => {
          history.push('/meta-ad-automations')
        }, 500)
      } else {
        setCreateBtnProgress(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: createAdAutomationResult.error, duration: null }))
      }
    }

  }


  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: '24px', mb: '46px' }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ ...whiteBlackBorderBtnStyles, mr: 1, borderRadius: '10px' }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep !== 2 && <Button onClick={handleNext}
          sx={{ ...whiteBlackBorderBtnStyles, borderRadius: '10px', '&:hover': { background: primaryColors[300], borderColor: primaryColors[300]} }}>
          Next
        </Button>}
        {activeStep === 2 && <Button variant='contained' onClick={handleCreate}
          sx={{ ...regularBtnStyles }}>
          {createBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: "#fff" }} /> : 'Create'}
        </Button>}
      </Box>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={handleCompleted(index)} sx={stepIconStyles}>
            <StepButton sx={desktop_labels_l1} onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Box>
          {activeStep !== 2 && <Box sx={{ display: 'flex', flexDirection: 'row', mt: '40px', mb: '40px' }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ ...whiteBlackBorderBtnStyles, mr: 1, borderRadius: '10px' }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep !== 2 && <Button onClick={handleNext}
             sx={{ ...whiteBlackBorderBtnStyles, borderRadius: '10px', '&:hover': { background: primaryColors[300], borderColor: primaryColors[300]} }}>
              Next
            </Button>}
            {activeStep === 2 && <Button sx={{...regularBtnStyles}} onClick={handleCreate}>
              {createBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: primaryColors[950] }} /> : 'Create'}
            </Button>}
          </Box>}
        </React.Fragment>
      </div>
    </Box>
  );
}
export default AdAutomationStepper;

const stepIconStyles = {
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px'
  },
  '& .Mui-active circle': {
    fill: primaryColors[300]
  },
  '& .Mui-active text': {
    /*fill: primaryColors[950]*/
    fontSize: '0.8rem'
  },
  '& .Mui-completed': {
    fill: primaryColors[300]
  }
}

