import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { setMatchedPosts } from '../../../context/meta/MetaActions'
import { useStateValue } from '../../../context/meta/MetaContext'
import { useSharedStateValue } from '../../../context/shared/SharedContext'
import AndOrConditions from './AndOrConditions'
import PostsMatchedTable from './PostsMatchedTable'
import PostsStartEndDate from './PostsStartEndDate'
import PostTypes from './PostTypes'

const PostBoosterFilters = () => {
  const [{ allPosts, matchedPosts, advancedPostBoosting }, dispatch] = useStateValue()
  const [initial, setInitial] = React.useState(true)
  const [{ loading }, sharedDispatch] = useSharedStateValue()

  useEffect(() => {
    if (!loading && allPosts) {
      handleFiltersChange()
    }
  }, [loading])


  const handleFiltersChange = () => {
    if (advancedPostBoosting.filters.conditions && advancedPostBoosting.filters.conditions.length > 0) {
      let finalResult: any = []
      let resultPosts: any = []

      if (advancedPostBoosting.filters.logicalOperator === 'or') {
        advancedPostBoosting.filters.conditions.map(c => {

          if (c.conditionText === 'reactions') {
            switch (c.operator) {
              case '>':
                resultPosts = allPosts.filter(p => p.reactions.summary.total_count > c.value)
                break;
              case '<':
                resultPosts = allPosts.filter(p => p.reactions.summary.total_count < c.value)
                break;
              case '=':
                resultPosts = allPosts.filter(p => p.reactions.summary.total_count === c.value)
                break;
              case '>=':
                resultPosts = allPosts.filter(p => p.reactions.summary.total_count >= c.value)
                break;
              case '<=':
                resultPosts = allPosts.filter(p => p.reactions.summary.total_count <= c.value)
                break;
              default:
                break;
            }

          }

          if (c.conditionText === 'comments') {
            switch (c.operator) {
              case '>':
                resultPosts = allPosts.filter(p => p.comments.summary.total_count > c.value)
                break;
              case '<':
                resultPosts = allPosts.filter(p => p.comments.summary.total_count < c.value)
                break;
              case '=':
                resultPosts = allPosts.filter(p => p.comments.summary.total_count === c.value)
                break;
              case '>=':
                resultPosts = allPosts.filter(p => p.comments.summary.total_count >= c.value)
                break;
              case '<=':
                resultPosts = allPosts.filter(p => p.comments.summary.total_count <= c.value)
                break;
              default:
                break;
            }

          }

          if (c.conditionText === 'post_text') {
            switch (c.textOperator) {
              case 'contains':
                resultPosts = allPosts.filter(p => p.message?.toLowerCase().includes(c.value.toString().toLowerCase()))
                break;
              case "doesn't contain":
                resultPosts = allPosts.filter(p => !p.message?.toLowerCase().includes(c.value.toString().toLowerCase()))
                break;
              default:
                break;
            }

          }
          finalResult = finalResult.concat(resultPosts)

        })  // map function end.
        const ids = finalResult.map((p: any) => p.id)
        // Remove duplicates from the finalResult array | {id} = p destructed value from the post object
        finalResult = finalResult.filter(({ id }: any, index: any) => !ids.includes(id, index + 1))
        // Refilter the array according to the post types
        finalResult = finalResult.filter((p: any) => advancedPostBoosting.filters.types.some(t => t === p.type) ? p : null)
        dispatch(setMatchedPosts(finalResult))

      } else {
        // AND operator

        finalResult = allPosts

        advancedPostBoosting.filters.conditions.map(c => {
          //let resultPosts: any = []
          if (c.conditionText === 'reactions') {
            switch (c.operator) {
              case '>':
                finalResult = finalResult.filter((p: any) => p.reactions.summary.total_count > c.value)
                break;
              case '<':
                finalResult = finalResult.filter((p: any) => p.reactions.summary.total_count < c.value)
                break;
              case '=':
                finalResult = finalResult.filter((p: any) => p.reactions.summary.total_count === c.value)
                break;
              case '>=':
                finalResult = finalResult.filter((p: any) => p.reactions.summary.total_count >= c.value)
                break;
              case '<=':
                finalResult = finalResult.filter((p: any) => p.reactions.summary.total_count <= c.value)
                break;
              default:
                break;
            }

          }

          if (c.conditionText === 'comments') {
            switch (c.operator) {
              case '>':
                finalResult = finalResult.filter((p: any) => p.comments.summary.total_count > c.value)
                break;
              case '<':
                finalResult = finalResult.filter((p: any) => p.comments.summary.total_count < c.value)
                break;
              case '=':
                finalResult = finalResult.filter((p: any) => p.comments.summary.total_count === c.value)
                break;
              case '>=':
                finalResult = finalResult.filter((p: any) => p.comments.summary.total_count >= c.value)
                break;
              case '<=':
                finalResult = finalResult.filter((p: any) => p.comments.summary.total_count <= c.value)
                break;
              default:
                break;
            }

          }

          if (c.conditionText === 'post_text') {
            switch (c.textOperator) {
              case 'contains':
                finalResult = finalResult.filter((p: any) => p.message?.toLowerCase().includes(c.value.toString().toLowerCase()))
                break;
              case "doesn't contain":
                finalResult = finalResult.filter((p: any) => !p.message?.toLowerCase().includes(c.value.toString().toLowerCase()))
                break;
              default:
                break;
            }
          }
        }) // map function end.
        finalResult = finalResult.filter((p: any) => advancedPostBoosting.filters.types.some(t => t === p.type) ? p : null)
        dispatch(setMatchedPosts(finalResult))

      }
    } else {
      const newFilters = allPosts.filter((p: any) => advancedPostBoosting.filters.types.some(t => t === p.type) ? p : null)
      dispatch(setMatchedPosts(newFilters))
    }
  }

  return (
    <Grid container>
      <PostsStartEndDate />
      <PostTypes handleFiltersChange={handleFiltersChange} />
      <AndOrConditions handleFiltersChange={handleFiltersChange} />
      <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
        <Typography variant='h5' sx={{ m: '16px auto' }}>Matched posts</Typography>
      </Grid>
      {loading && <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}><CircularProgress style={{ width: '32px', height: '32px' }} /></Grid>}
      {!loading && allPosts === undefined && <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}><Typography variant='body1'>No posts found.</Typography></Grid>}
      {!loading && allPosts !== undefined && allPosts !== null && allPosts.length === 0 && <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}><Typography variant='body1'>No posts found. Try to apply another dates.</Typography></Grid>}
      {!loading && allPosts !== undefined && allPosts !== null && allPosts.length > 0 && <PostsMatchedTable />}
    </Grid>
  )
}

export default PostBoosterFilters