import React, { createContext, useReducer, useContext } from 'react';
import { initialAdminState } from './AdminReducer';
import { AdminState, AdminAction } from './AdminTypes';

export const StateContext = createContext<[AdminState, React.Dispatch<AdminAction>]>([
  initialAdminState, 
  () => initialAdminState
])

export type AdminProviderProps = {
  reducer: React.Reducer<AdminState, AdminAction>
  children: React.ReactElement
}

export const AdminProvider = ({reducer, children}: AdminProviderProps) => {
  const [adminState, dispatch] = useReducer(reducer, initialAdminState);
  return (
    <StateContext.Provider value={[adminState, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}
export const useAdminStateValue = () => useContext(StateContext);