
import { Box, Grid, Typography } from '@mui/material';
import { useStateValue } from '../../../../context/meta/MetaContext';
import { beautifyString } from '../../../../utils/helpers';

const PostBoosterStepperSummary = () => {
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoostingErrors, matchedPosts, advancedPostBoosting }, dispatch] = useStateValue()

  return (
    <Grid container sx={{ border: '1px solid #ccc', borderRadius: '5px', p: '6px 16px 12px 16px', mb: 1, mt: 6 }}>
      <Grid item xs={12}>
        <Typography style={styles.body2} variant='h6'>
          Summary
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={styles.body2} variant='body2'>
          Matched Posts
        </Typography>
        <Typography style={{ fontWeight: 700, fontSize: '18px', color: '#1876d1' }} variant='caption'>
          {matchedPosts?.length}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={styles.body2} variant='body2'>
          Campaign objective
        </Typography>
        <Typography style={styles.caption} variant='caption'>
          {advancedPostBoosting.campaign?.objective && beautifyString(advancedPostBoosting.campaign?.objective.toLowerCase().includes('outcome') ? advancedPostBoosting.campaign?.objective.slice(8) : advancedPostBoosting.campaign?.objective)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={styles.body2} variant='body2'>
          Optimization goal (ad set)
        </Typography>
        <Typography style={styles.caption} variant='caption'>
          {advancedPostBoosting.adSet?.optimization_goal && beautifyString(advancedPostBoosting.adSet.optimization_goal)}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  body1: {
    margin: '10px auto',
    color: '#000'
  },
  body2: {
    margin: '10px auto'
  },
  caption: {
    color: '#7d7d7d'
  },

}

export default PostBoosterStepperSummary;