import styled from "styled-components"
import { ChatMessage, CustomModel } from "../../api/Types"
import { TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { apiSendCustomModelPrompt } from "../../api/Assistant"
import { LoadingButton } from "../Common/LoadingButton"

const BotContainer = styled.div`
  position:fixed;
  right:40px;
  bottom:40px;
  box-shadow: 2px 2px 12px gray;
  min-width:400px;
  min-height:200px;
  max-width:400px;
  max-height:50%;
  border:1px solid black;
  border-radius: 10px 0px 0px 0px;
`

const Heading = styled.div`
  padding:10px 10px 10px 10px;
  border-radius: 10px 0px 0px 0px;
  background-color:black;
  color:white;
  width:100%;
`

const MessageContainer = styled.div`
  overflow-y:scroll;
  max-height:250px;
`

const Messages = styled.div`
  position:relative;
  padding:5px 5px 5px 5px;
  display:flex;
  flex-direction:column;
  row-gap:5px;
  min-height:150px;
`

const Bot = styled.div`
  margin-right:auto;
  color:black;
  border-radius:5px 0px 0px 0px;
  padding:5px;
  max-width:80%;
  word-break: break-word;
`

const User = styled.div`
  align:flex-right;
  max-width:250px;
  text-align:right;
  margin-left:auto;
  font-style:italic;
`

const Question = styled.div`
  padding:5px 10px 5px 10px;
  background-color:black;
  display:grid;
  column-gap:10px;
  grid-template-columns:1fr 5em;
`

const Logo = styled.img`
  width:80px;
  position:absolute;
  padding:8px;
  top:-20px;
  right:-30px;
  transform:rotate(5deg);
  background-color:black;
  border-radius:50%;
`

const SmallLogo = styled.img`
  width:24px;
  transform:rotate(5deg);
  background-color:black;
  border-radius:50%;
  margin-top:5px;
  margin-bottom:auto;
`

const BotMessage = styled.div`
  display:flex;
  align-items:center;
`

export type CustomBotProps = {
  customerId: number
  customModel: CustomModel
  setLoading: (loading: boolean) => void
}

export const CustomBot = (props: CustomBotProps) => {
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [prompt, setPrompt] = useState("")
  const [loading, setLoading] = useState(false)
  const messagesRef = useRef(null)

  const sendPrompt = (prompt: string) => {
    setPrompt("")
    setLoading(true)
    apiSendCustomModelPrompt(props.customModel.id, [...messages, { role: "user", content: prompt }]).then((newMessages) => {
      setMessages(newMessages)
      setLoading(false);
      setTimeout(() => { (messagesRef.current as any).scrollIntoView({ behavior: 'smooth' }) }, 50)
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setMessages([{ role: "assistant", content: "Miten voin auttaa?" }])
  }, [props.customModel])

  return <BotContainer>
    <Heading>{props.customModel.name} bot</Heading>
    <Logo src="chat-mascot.png" />
    <MessageContainer>
      <Messages>
        {messages.map((m, index) => {
          if (m.role === "assistant") {
            return (<><BotMessage>
              <SmallLogo src="chat-mascot.png" />
              <Bot>{m.content}</Bot>
            </BotMessage>
              {index === messages.length - 1 ? <div ref={messagesRef} /> : undefined}
            </>)
          } else {
            return (<User>{m.content}</User>)
          }
        })}
      </Messages>
    </MessageContainer>
    <Question>
      <TextField size="small" color="warning" variant="standard" fullWidth={true} sx={{ input: { color: 'white' } }} value={prompt} onChange={(ev) => setPrompt(ev.target.value)} />
      <LoadingButton loading={loading} title="Send" onClick={() => { sendPrompt(prompt) }} />
    </Question>
  </BotContainer>
}