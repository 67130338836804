import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import { useStateValue } from '../../../../context/meta/MetaContext';
import { useAlertStateValue } from '../../../../context/alert/AlertContext';
import { setAlert } from '../../../../context/alert/AlertAction';
import { AlertSeverity } from '../../../../context/alert/AlertTypes';
import { CircularProgress, StepButton } from '@mui/material';
import CampaignInfo from '../../sharedMetaComponents/CampaignInfo';
import AdSetInfo from '../../sharedMetaComponents/AdSetInfo';
import AdInfo from '../../sharedMetaComponents/AdInfo'
import Filters from '../Filters'
import ChooseCampaignOrCreateNew from '../ChooseCampaignOrCreateNew';
import { useCustomerState } from '../../../../context/customer/CustomerContext';
import { createNewAds, createNewCampaign, deleteMarkeitngObject } from '../../../../api/Marketing';
import { createNewPostBoostingRule } from '../../../../api/Posts';
import { initialAdvancedPostBoosting } from '../../../../context/meta/MetaReducer';
import { setAdvancedPostBoosting } from '../../../../context/meta/MetaActions';
import { useHistory, useLocation } from 'react-router-dom';
import { URL_REGEX } from '../../../../utils/helpers';
import { MetaErrors, NewOrExsitingCampaing } from '../../../../context/meta/MetaTypes';
import PostBoosterStepperSummary from './PostBoosterStepperSummary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const steps = ['Filters', 'Campaign', 'Ad set', 'Ad'];

const PostBoosterStepper = () => {
  const customerState = useCustomerState()

  const [activeStep, setActiveStep] = React.useState(0);
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoostingErrors, matchedPosts, advancedPostBoosting }, dispatch] = useStateValue()
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [completed, setCompleted] = React.useState<number[]>([])
  const history = useHistory()
  const [createBtnProgress, setCreateBtnProgress] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const stepZeroErrors: boolean = matchedPosts?.length < 1
  const stepOneErrors = advancedPostBoosting.newOrExistingCampaign === NewOrExsitingCampaing.EXISTING_CAMPAIGN ? (!advancedPostBoosting.campaign?.id || !advancedPostBoosting.name || advancedPostBoosting.name?.length < 3 || advancedPostBoostingErrors.includes(MetaErrors.advancedPostBoostingRuleNameError) ? true : false) : (!advancedPostBoosting.campaign.name || advancedPostBoosting.campaign.name?.length < 3 || !advancedPostBoosting.name || advancedPostBoosting.name?.length < 3 || advancedPostBoostingErrors.includes(MetaErrors.campaignNameError) || advancedPostBoostingErrors.includes(MetaErrors.advancedPostBoostingRuleNameError) ? true : false)
  const stepTwoErrors: boolean = !advancedPostBoosting.adSet.name || advancedPostBoosting.adSet.name?.length < 3 || advancedPostBoostingErrors.includes(MetaErrors.adSetNameError) || !advancedPostBoosting.adSet.start_time || !advancedPostBoosting.adSet.end_time || (advancedPostBoosting.campaign.objective.toLowerCase().includes('engagement') && !advancedPostBoosting.adSet.promoted_object.pixel_id)
  const stepThreeErrors = !advancedPostBoosting.ad.urlParameters

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (stepZeroErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "There is no matched posts.", duration: null }))
        } else {
          setCompleted(completed.filter(n => n !== activeStep).concat(activeStep))
          setActiveStep(activeStep + 1);
        }
        break;
      case 1:
        if (stepOneErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please select a campaign or fill the required fields to create one.", duration: null }))
        } else {
          setCompleted(completed.filter(n => n !== activeStep).concat(activeStep))
          setActiveStep(activeStep + 1);
        }
        break;
      case 2:
        if (stepTwoErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all the required fields to create a new Ad set.", duration: null }))
        } else {
          setCompleted(completed.filter(n => n !== activeStep).concat(activeStep))
          setActiveStep(activeStep + 1);
        }
        break;
      case 3:
        if (stepThreeErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill Ad's required fields.", duration: null }))
        }
        break;
      default:
        break;
    }
    //setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    const previousStep = step - 1
    switch (previousStep) {
      case 0:
        if (stepZeroErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "There is no matched posts.", duration: null }))
        } else {
          setActiveStep(step);
        }
        break;
      case 1:
        if (stepOneErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please select a campaign of fill the required fields to create one.", duration: null }))
        } else {
          setActiveStep(step);
        }
        break;
      case 2:
        if (stepTwoErrors) {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all the required fields to create a new Ad set.", duration: null }))
        } else {
          setActiveStep(step);
        }
        break;
      default:
        setActiveStep(step);
        break;
    }

  };

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return <><PostBoosterStepperSummary /><Filters /></>;
      case 1:
        return <><PostBoosterStepperSummary /><ChooseCampaignOrCreateNew /></>;
      case 2:
        return <><PostBoosterStepperSummary /><AdSetInfo /></>;
      case 3:
        return <><PostBoosterStepperSummary /><AdInfo /></>;
      default:
        return <><PostBoosterStepperSummary /><Filters /></>;
    }
  };

  const handleCompleted = (index: number) => {

    if (index === 0 && !stepZeroErrors) {
      return true
    }
    if (index === 1 && !stepOneErrors) {
      return true
    }
    if (index === 2 && !stepTwoErrors) {
      return true
    }
    if (index === 3 && !stepThreeErrors) {
      return true
    }
    return false
  }

  const handleCreate = async () => {
    setOpenModal(false)

    setCreateBtnProgress(true)
    const customerId = customerState?.selected?.id;

    if (customerId) {
      const mPosts = matchedPosts.map(p => { return { id: p.id, type: p.type, promotable_id: p.promotable_id, message: p.message } })
      const advancedPostBoostingUpdated = {
        ...advancedPostBoosting, adSet: {
          ...advancedPostBoosting.adSet,
          name: `${advancedPostBoosting.adSet.name} ${advancedPostBoosting.adSet.currentSavedAudience?.name || ''}`
        },
        name: `${advancedPostBoosting.name} ${advancedPostBoosting.adSet.currentSavedAudience?.name || ''}`
      }
      const newRuleResult = await createNewPostBoostingRule(customerId, advancedPostBoostingUpdated, mPosts)
      if (newRuleResult.success) {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "The rule created successfully!", duration: 7000 }))
        dispatch(setAdvancedPostBoosting(initialAdvancedPostBoosting))
        setCreateBtnProgress(false)
        setTimeout(() => {
          history.push('/post-booster')
        }, 500)
      } else {
        setCreateBtnProgress(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: newRuleResult.message, duration: null }))
      }
    }

  }

  return (
    <Box sx={{ width: '100%', marginTop: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0, mb: 4 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, border: '1px solid', textTransform: 'none' }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep !== 3 && <Button onClick={handleNext}
          sx={{ border: '1px solid', textTransform: 'none' }}>
          Next
        </Button>}
        {activeStep === 3 && <>
          <Button variant='contained' onClick={() => setOpenModal(true)}
            sx={{ textTransform: 'none' }}>
            {createBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: "#fff" }} /> : 'Create'}
          </Button>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Verify the number of posts to be boosted"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are going to boost <span style={{ fontWeight: 'bold' }}>{matchedPosts.length}</span> {matchedPosts.length === 1 ? 'post' : 'posts'}, would you like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{ textTransform: 'none', color: '#000' }} onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button sx={{ textTransform: 'none' }} onClick={handleCreate} autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </>

        }
      </Box>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={handleCompleted(index)}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          <Box sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Box>
          {/*
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, border: '1px solid', textTransform: 'none' }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep !== 3 && <Button onClick={handleNext}
              sx={{ border: '1px solid', textTransform: 'none' }}>
              Next
            </Button>}
          </Box>*/}
        </React.Fragment>
      </div>
    </Box>
  );
}
export default PostBoosterStepper;

