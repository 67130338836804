import { Redirect, Route, RouteProps } from 'react-router';
import { useAuthDispatch, useAuthState } from '../context/auth/AuthContext';
import NavigationComponents from '../NavigationComponents';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { auth0Logout } from '../context/auth/AuthActions';


export default function ProtectedRoute({ path, children, ...routeProps }: RouteProps) {
  const auth = useAuthState()
  const dispatch = useAuthDispatch()
  const history = useHistory()

  const urlParams = new URLSearchParams(routeProps.location?.search);
  const success = urlParams.get('success');
  const payload = urlParams.get('payload');

  useEffect(() => {
    if (success && payload) {
      const user = JSON.parse(Base64.decode(payload.toString()))
      dispatch({ type: 'LOGIN_SUCCESS', user: user });
      localStorage.setItem('currentUser', JSON.stringify(user));
      history.push('/')
    }
  }, [routeProps.location?.search])


  return <Route path={path} render={() => {

    if (auth.loading) {
      return <div>Loading...</div>;
    }

    if (auth.errorMessage) {
      return <div>Oops... {auth.errorMessage}</div>;
    }
    if (auth.isSignedIn) {
      return <NavigationComponents>{children}</NavigationComponents>
    }
    return <Redirect to={{ pathname: '/login' }} />

  }

  } {...routeProps} />;

}