import { makeStyles } from '@mui/styles';
import React from 'react'
import { primaryColors } from './colors';


export const useCustomMuiStyles:any = makeStyles({
  inputFieldRoot: {
    // input label when focused
    "& label.Mui-focused": {
      color: primaryColors[500]
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColors[500]
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: primaryColors[500]
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColors[500]
      }
    }
  }
});