import { AuthenticatedUser, noAuthenticatedUser } from '../../api/Types';

const initialStorageUser = localStorage.getItem('currentUser')
const initialUser = initialStorageUser ? JSON.parse(initialStorageUser) as AuthenticatedUser : noAuthenticatedUser

export type AuthErrorMessage = "GoogleAuthError" | "UserNotAuthorised" | 'Auth0LoginError' | null

export type AuthState = {
  user: AuthenticatedUser
  isSignedIn: boolean
  loading: boolean
  errorMessage: AuthErrorMessage
}

type AuthActionLogin = {
  type: 'REQUEST_LOGIN'
}

type AuthActionLoginSuccess = {
  type: 'LOGIN_SUCCESS'
  user: AuthenticatedUser
}

type AuthActionLogout = {
  type: 'LOGOUT'
}

type AuthActionLoginError = {
  type: 'LOGIN_ERROR'
  error: AuthErrorMessage
}

export type AuthAction = AuthActionLogin |
AuthActionLoginSuccess |
AuthActionLogout |
AuthActionLoginError

export const initialAuthState: AuthState = {
  user: initialUser,
  isSignedIn: initialUser.email !== '',
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (state = initialAuthState, action: AuthAction): AuthState => {
  
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...state,
        isSignedIn: false,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: action.user,
        loading: false,
        isSignedIn: true
      };
    case 'LOGOUT':
      localStorage.removeItem('currentUser')
      return {
        ...state,
        user: noAuthenticatedUser,
        loading: false,
        isSignedIn: false,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        loading: false,
        isSignedIn: false,
        errorMessage: action.error,
      };
    default:
      throw new Error('Unhandled action type');
  }
};