import { ChangeEvent, Component } from 'react';
import styled from 'styled-components';
import { apiAddKeywordCategory, apiGetKeywordCategories, apiGetPageKeywordStat, apiRemoveKeywordCategory, apiSetHiddenCategoryKeyword } from '../api/Customer';
import { CustomerTree, KeywordCategoriesResponse, PageKeywordStat, PageKeywordStatResponse } from '../api/Types';
import { useCustomerState } from '../context/customer/CustomerContext';
import { LoadingModal } from './Modal';
import { DateTime } from 'luxon';
import { Box, Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, Stack, Tab, Tabs, TextField, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ColumnProps, SearchGrid } from './SearchGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useAlertStateValue } from '../context/alert/AlertContext';
import { AlertAction, AlertSeverity } from '../context/alert/AlertTypes';
import { setAlert } from '../context/alert/AlertAction';
import { setLoading } from '../context/shared/SharedActions';

const Container = styled.div`
  margin: 50px 24px;
`

const Header = styled.div`
  margin-left:0.1em;
  margin-top:1em;
  margin-bottom:1.0em;
  font-size:2.0em;
`

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const landingPageFormatter = (value: any): JSX.Element => {
  if (!value) { return <>-</> }
  return <>
    <NoMaxWidthTooltip title={<pre>{value}</pre>}><div>{`${value.substring(value.lastIndexOf("/"))}`}</div></NoMaxWidthTooltip>
  </>
}

const keywordPageFormatter = (value: any, all: any): JSX.Element => {
  if (!value) { return <>-</> }
  return <>
    <NoMaxWidthTooltip title={<pre>{value}</pre>}>
      <div style={{ userSelect: 'none' }}>
        {value}
      </div>
    </NoMaxWidthTooltip>
  </>
}

const getColumns = (): ColumnProps[] => {
  return [
    { field: 'keyword', headerName: 'Keyword', flex: 2, type: 'string' },
    { field: 'clicks', headerName: 'Clicks', width: 120, type: 'number', style: { 'textAlign': 'center' } },
    { field: 'ctr', headerName: 'CTR', width: 100, type: 'percentage', style: { 'textAlign': 'center' } },
    { field: 'position', headerName: 'Rank', width: 100, type: 'number', style: { 'textAlign': 'center' } },
    { field: 'cpc', headerName: 'CPC', width: 100, type: 'price', style: { 'textAlign': 'center' } },
    {
      field: 'landingPage', headerName: 'Landing page', flex: 4, type: 'string',
      formatter: landingPageFormatter.bind(this)
    },
  ]
};

const removeFormatter = (onRemove: (row: any) => void, _: any, row: any): JSX.Element => {
  return row.keyword !== '*' ?
    <FontAwesomeIcon icon={faTrash} onClick={() => onRemove(row)} style={{ cursor: 'pointer' }} /> :
    <></>
}

const headingRemoveFormatter = (onRemove: () => void): JSX.Element => {
  return <FontAwesomeIcon icon={faTrash} onClick={() => onRemove()} style={{ cursor: 'pointer' }} />
}

const getVolumeColumns = (onRemove: (row: any) => void, onRemoveSelected: () => void): ColumnProps[] => {
  return [
    { field: 'selected', headerName: '', width: 55, type: 'select' },
    { field: 'category', headerName: 'Category', flex: 5, type: 'string', grouped: true },
    {
      field: 'keyword', headerName: 'Keyword', flex: 7, type: 'string',
      formatter: keywordPageFormatter.bind(this)
    },
    { field: 'clicks', headerName: 'Clicks', flex: 1, type: 'number' },
    {
      field: 'landingPage', headerName: 'Landing Page', flex: 1, type: 'string',
      formatter: landingPageFormatter.bind(this)
    },
    { field: 'position', headerName: 'Rank', flex: 1, type: 'number', maxValue: 1000 },
    { field: 'searchVolume', headerName: 'Volume', flex: 1, type: 'number' },
    { field: 'cpc', headerName: 'CPC', flex: 1, type: 'price' },
    { field: 'ctr', headerName: 'CTR', flex: 1, type: 'percentage' },
    { field: 'potential', headerName: 'Potential', flex: 1, type: 'number' },
    {
      field: 'trends',
      headerName: 'Trend',
      flex: 1,
      type: 'trendFactor'
    },
    {
      headerName: '',
      field: 'hidden',
      type: 'string',
      formatter: removeFormatter.bind(this, onRemove),
      headingFormatter: headingRemoveFormatter.bind(this, onRemoveSelected)
    }
  ]
}

export type KeywordAnalysisProps = {
  selected?: CustomerTree
  alertDispatch: React.Dispatch<AlertAction>
}

type KeywordAnalysisState = {
  loading: boolean
  keywordStats: PageKeywordStatResponse
  keywordCategories: KeywordCategoriesResponse
  activeKeywordCategories: string[]
  keywords: string
  category: string
  activeTab: number
  showNonRanked: boolean,
  selectedKeywords: string[]
  keywordFilter: string
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`keyword-tabpanel-${index}`}
      aria-labelledby={`keyword-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: 800 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `keyword-tab-${index}`,
    'aria-controls': `keyword-tabpanel-${index}`,
  };
}

export class KeywordAnalysis extends Component<KeywordAnalysisProps, KeywordAnalysisState> {
  constructor(props: KeywordAnalysisProps) {
    super(props);
    this.state = {
      loading: false,
      keywords: '',
      category: '',
      keywordStats: { websiteUrl: '', stats: [] },
      keywordCategories: { categories: [] },
      activeKeywordCategories: [],
      activeTab: 0,
      showNonRanked: true,
      selectedKeywords: [],
      keywordFilter: ''
    };
  }

  componentDidMount() {
    this.getCategories()
    this.updateKeywordStats()
  }

  componentDidUpdate(prevProps: KeywordAnalysisProps) {
    if (prevProps.selected?.id !== this.props.selected?.id && this.props.selected) {
      this.getCategories()
      this.updateKeywordStats()
    }
  }

  updateKeywordStats() {
    this.setState({ loading: true })
    if (this.props.selected) {
      const startDate = DateTime.local().minus({ month: 1 }).toFormat("yyyy-LL-dd")
      const endDate = DateTime.local().toFormat("yyyy-LL-dd")
      apiGetPageKeywordStat(this.props.selected.id, startDate, endDate).then((newStats) => {
        this.setState({ keywordStats: newStats, loading: false })
      }).catch(() => {
        this.setState({ loading: false })
        this.props.alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: 'Error in loading keyword statistics.', duration: null }))
      })
    }
  }

  getCategories() {
    if (this.props.selected) {
      apiGetKeywordCategories(this.props.selected?.id).then((response) => {
        this.setState({
          keywordCategories: response,
          activeKeywordCategories: response.categories.map(c => c.keyword)
        })
      })
    }
  }

  updateCategory(event: ChangeEvent<HTMLInputElement>) {
    this.setState({ category: event.target.value })
  }

  addCategory() {
    const { keywordCategories, category } = this.state
    if (!keywordCategories.categories.find(c => c.keyword === category) && this.props.selected) {
      this.setState({ loading: true, category: '' })
      apiAddKeywordCategory(this.props.selected?.id, category).then((response) => {
        this.setState({
          keywordCategories: response,
          activeKeywordCategories: response.categories.map(c => c.keyword), loading: false
        })
      }).catch(() => {
        this.setState({ loading: false })
      })
    }
  }

  removeCategory(category: string) {
    apiRemoveKeywordCategory(this.props.selected!.id, category).then((response) => {
      this.setState({ keywordCategories: response, activeKeywordCategories: response.categories.map(c => c.keyword), loading: false })
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  handleTabChange(_: React.SyntheticEvent, newActiveTab: number) {
    this.setState({ activeTab: newActiveTab })
  }

  setShowNonRanked(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target
    this.setState({
      showNonRanked: checked
    })
  }

  makeValuesFromCategories() {
    const { keywordFilter, showNonRanked, keywordCategories, selectedKeywords, keywordStats,
      activeKeywordCategories } = this.state
    let categories: PageKeywordStat[] = []
    for (const c of keywordCategories.categories) {
      categories = categories.concat(c.relatedKeywords.map(k => ({ ...k, baseUrl: keywordStats.websiteUrl, category: c.keyword })))
    }
    const clicks = categories.reduce((total, c) => total + c.clicks, 0.0)
    const ctrs = categories.filter(c => c.ctr > 0).map(c => c.ctr * c.clicks)
    const avgCtr = ctrs.length > 0 ? ctrs.reduce((total, c) => total + c, 0.0) / clicks : 0.0
    return categories.filter(c => activeKeywordCategories.indexOf(c.category || c.keyword) !== -1 && !c.hidden && c.keyword.indexOf(keywordFilter) !== -1 && (showNonRanked || c.position < 1000000)).map(c => ({
      ...c,
      ctr: c.ctr || avgCtr,
      potential: (c.ctr || avgCtr) * (c.searchVolume || 0.0),
      trends: c.trends ? c.trends[c.trends.length - 1] - c.trends[0] : 0,
      selected: selectedKeywords.find((k => k === c.keyword)) !== undefined
    }))
  }
  downloadKeywords() {
    const values = this.makeValuesFromCategories()

    const columns = [
      { field: 'category', headerName: 'Category' },
      { field: 'keyword', headerName: 'Keyword' },
      { field: 'clicks', headerName: 'Clicks', type: 'number' },
      { field: 'landingPage', headerName: 'Landing Page' },
      { field: 'position', headerName: 'Rank', type: 'number' },
      { field: 'searchVolume', headerName: 'Volume', type: 'number' },
      { field: 'cpc', headerName: 'CPC', type: 'number' },
      { field: 'ctr', headerName: 'CTR', type: 'number' },
      { field: 'potential', headerName: 'Potential', type: 'number' },
      { field: 'trends', headerName: 'Trend', type: 'number' }]

    const rows = values.map(c => {
      const row: string[] = []
      for (const col of columns) {
        const value = (c as any)[col.field]
        if (col.field === "position") {
          row.push(value >= 100000 ? "N/A" : value)
        }
        else if (col.type === "number") {
          row.push(value.toFixed(5).replace(".", ","))
        }
        else {
          row.push(value)
        }
      }
      return row
    })
    const csv = columns.map(c => c.headerName).join("\t").concat("\n").concat(
      rows.map(r => r.join("\t")).join("\n")
    )
    const file = new Blob([csv], { type: 'text/plain' });

    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = `keywords-${new Date().toISOString()}.csv`;
    element.click()
  }

  render() {
    const { keywordStats, keywordFilter, loading, category, activeTab, keywordCategories, showNonRanked,
      selectedKeywords, activeKeywordCategories } = this.state
    const customerId = this.props.selected?.id
    let categories: PageKeywordStat[] = []
    for (const c of keywordCategories.categories) {
      categories = categories.concat(c.relatedKeywords.map(k => ({ ...k, baseUrl: keywordStats.websiteUrl, category: c.keyword })))
    }

    return <Container>
      <Header>Keyword Analysis</Header>
      <Box sx={{ width: '100%', minHeight: 800 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={this.handleTabChange.bind(this)}>
            <Tab label={loading ? "Site stats" : `${keywordStats.websiteUrl}`} {...a11yProps(0)} />
            <Tab label="Categories" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={1}>
          <Grid container spacing={2} marginBottom={'1em'}>
            <Grid item xs={4}>
              <TextField fullWidth={true} value={category} size="small" onChange={this.updateCategory.bind(this)}
                placeholder={'category'} />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={this.addCategory.bind(this)}>Add category</Button>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} marginBottom={2} marginTop={2}>
            {keywordCategories.categories.map(c => {
              return (
                <Grid key={c.keyword} item xs={1}>
                  <Chip variant={activeKeywordCategories.indexOf(c.keyword) !== -1 ? 'filled' : 'outlined'}
                    label={c.keyword} onDelete={this.removeCategory.bind(this, c.keyword)}
                    style={{ cursor: 'pointer' }} onClick={() => {
                      if (activeKeywordCategories.indexOf(c.keyword) !== -1) {
                        this.setState({ activeKeywordCategories: activeKeywordCategories.filter(k => k !== c.keyword) })
                      } else {
                        this.setState({ activeKeywordCategories: activeKeywordCategories.concat(c.keyword) })
                      }
                    }} />
                </Grid>
              )
            })}
          </Stack>
          <FormGroup style={{ marginBottom: '1.0em', marginTop: '2.0em' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField size="small" label="Keyword filter" value={keywordFilter}
                  onChange={(event) => this.setState({ keywordFilter: event.target.value })} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={showNonRanked} onChange={this.setShowNonRanked.bind(this)} />} label="Show non-ranked" />
              </Grid>
              <Button style={{ marginLeft: "auto", marginTop: "auto", maxHeight: '2.5em' }} color="secondary"
                variant="contained" onClick={() => this.downloadKeywords()}>
                Download&nbsp;&nbsp;<FontAwesomeIcon icon={faDownload} />
              </Button>

            </Grid>

          </FormGroup>
          <SearchGrid
            values={this.makeValuesFromCategories()}
            onSelect={(values: any[]) => {
              let keywords = selectedKeywords
              for (const value of values) {
                if (keywords.indexOf(value.keyword) !== -1) {
                  keywords = keywords.filter(k => k !== value.keyword)
                } else {
                  keywords = keywords.concat([value.keyword])
                }
              }
              this.setState({ selectedKeywords: keywords })
            }}
            columns={getVolumeColumns((row: any) => {
              apiSetHiddenCategoryKeyword(customerId!, [{ category: row.category, keyword: row.keyword }], true).then((response) => {
                this.setState({ keywordCategories: response })
              })
            },
              () => {
                const rows = selectedKeywords.map(k => categories.find(c => c.keyword === k)).filter(r => r)
                const cks = rows.map((row) => ({ category: row?.category, keyword: row?.keyword })) as
                  { category: string, keyword: string }[]
                apiSetHiddenCategoryKeyword(customerId!, cks, true).then((response) =>
                  this.setState({ keywordCategories: response, keywordFilter: "" })
                )
              })}
            pageSize={50}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={0}>
          <SearchGrid values={keywordStats.stats} columns={getColumns()} pageSize={30} />
        </TabPanel>
      </Box>
      {loading && <LoadingModal />}
    </Container>
  }
}

const KeywordAnalysisView = () => {
  const state = useCustomerState()
  const [_, alertDispatch] = useAlertStateValue()
  return (<KeywordAnalysis selected={state.selected} alertDispatch={alertDispatch} />)
}

export default KeywordAnalysisView;

