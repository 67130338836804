import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { StyledMenu } from './Ads/StyledMenu';
import { neutralColors } from '../styles/colors';
import { closeIcon } from '../utils/icons';
import FilterField from './Ads/FilterField';
import { desktop_h2, desktop_p2 } from '../styles/textStyles';

export type CheckboxListProps = {
  title: string,
  allItems: {label: string, value: string}[],
  items: string[],
  updateItems: (items: string[]) => void
}
/**
 * Reusable component
 *
 */
const CheckboxList = ({ title, allItems, items, updateItems  }: CheckboxListProps) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectAllItems, setSelectAllItems] = useState(allItems.length === items.length ? true : false)

  const [filter, setFilter] = useState('')

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  const selectOnly = (value: string) => {
    updateItems([value])
  }

  const selectAll = () => {
    updateItems(allItems.map(i => i.value))
  }

  const handleSelectAllItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    console.log("checked", checked)
    if (checked) {
      selectAll()
    } else {
      updateItems([])
    }
    setSelectAllItems(checked)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const i = event.target.value;
    let updatedItems = null;

    if (checked && items.indexOf(i) === -1) {
      updatedItems = [...items, i]
    } else {
      updatedItems = items.filter(item => item !== i)
    }

    updateItems(updatedItems)

    if (updatedItems?.length === allItems.length) {
      setSelectAllItems(true)
    } else {
      setSelectAllItems(false)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={''}
        sx={{ 
          textTransform: 'none', 
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)', 
          background: "#fff", 
          color: "#000",
          width:'100%',
          borderRadius: '5px',
          border: '0.5px solid #000',
          ":hover": { background: '#fff' }
        }}
      >
        {title} {selectAllItems ? "(all)" : `(${items.length})`}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <Grid container sx={{ padding: '32px', width: '100%', background: neutralColors[50] }}>

          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
            <Box >
              <Typography variant='h2' sx={desktop_h2}>{title}</Typography>

            </Box>
            <Box sx={{}}>

              <IconButton onClick={handleClose}>
                {closeIcon()}
              </IconButton>

            </Box>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width:'100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
              <FilterField filter={filter} handleFilterChange={handleFilterChange}/>
            </Box>
            <Box>
              <FormControlLabel
                label={"All"}
                sx={desktop_p2}
                control={<Checkbox checked={selectAllItems} onChange={handleSelectAllItems}
                sx={{}}
                />}
              />
            </Box>
            {allItems.filter(i => i.label.toString().toLowerCase().includes(filter?.toLowerCase())).map((i: any) => {
              const checked = (items).indexOf(i.value) > -1;

              return (<Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
                <FormControlLabel
                  label={i.label}
                  sx={desktop_p2}
                  control={<Checkbox checked={checked} value={i.value} onChange={handleCheckboxChange}
                  sx={{}}
                  
                  />}
                />

                <Button onClick={(event) => {
                  event.stopPropagation();
                  if (checked && (items || "").length === 1) {
                    selectAll()
                    setSelectAllItems(true)
                  }
                  else {
                    selectOnly(i.value)
                    setSelectAllItems(false)
                  }
                }}
                sx={desktop_p2}
                >
                  {checked && items.length === 1 ? "ALL" : "ONLY"}
                </Button>

              </Grid>)

            })}
          </Box>                  

        </Grid>

      </StyledMenu>
    </Box>
  );
}

export default CheckboxList