import React, { createContext, useReducer, useContext } from 'react';
import { CompetitorAction, CompetitorState } from './CompetitorTypes';
import { initialCompetitorState } from './CompetitorReducer';

export const CompetitorStateContext = createContext<[CompetitorState, React.Dispatch<CompetitorAction>]>([
  initialCompetitorState, 
  () => initialCompetitorState
])

export type CompetitorAdsProviderProps = {
  reducer: React.Reducer<CompetitorState, CompetitorAction>
  children: React.ReactElement
}

export const CompetitorProvider = ({reducer, children}: CompetitorAdsProviderProps) => {
  const [competitorAdsState, dispatch] = useReducer(reducer, initialCompetitorState);
  return (
    <CompetitorStateContext.Provider value={[competitorAdsState, dispatch]}>
      {children}
    </CompetitorStateContext.Provider>
  )
}
export const useCompetitorStateValue = () => useContext(CompetitorStateContext);