import { useState } from 'react'
import Typography from '@mui/material/Typography';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomationSettingsErrors, setAdvancedPostBoosting, setAdvancedPostBoostingErrors } from '../../../context/meta/MetaActions';
import { BudgetType, BidStrategy, MetaErrors, BudgetLevel } from '../../../context/meta/MetaTypes';
import { beautifyString, convertObjectToArray } from '../../../utils/helpers'
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import { useCustomerState } from '../../../context/customer/CustomerContext';

/**
 * @component Budget
 */
const Budget = () => {
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting, advancedPostBoostingErrors }, dispatch] = useStateValue()

  const location = useLocation()
  const metaAdAutoLocation = location.pathname?.includes('meta-ad-automations')

  const budgetIsCampaignLevel = (metaAdAutoLocation && adAutomationSettings.budgetProps.budgetLevel === BudgetLevel.CAMPAIGN_LEVEL) || (!metaAdAutoLocation && advancedPostBoosting.budgetProps.budgetLevel === BudgetLevel.CAMPAIGN_LEVEL)

  const [budget, setBudget] = useState(metaAdAutoLocation ? adAutomationSettings.budgetProps.budget : advancedPostBoosting.budgetProps.budget)
  const [budgetError, setBudgetError] = useState(false)

  const [budgetType, setBudgetType] = useState(metaAdAutoLocation ? adAutomationSettings.budgetProps.budget_type : advancedPostBoosting.budgetProps.budget_type)

  const [bidStrategy, setBidStrategy] = useState(metaAdAutoLocation ? adAutomationSettings.budgetProps.bid_strategy : advancedPostBoosting.budgetProps.bid_strategy)

  const [bidAmount, setBidAmount] = useState(metaAdAutoLocation ? adAutomationSettings.budgetProps.bid_amount : advancedPostBoosting.budgetProps.bid_amount)

  const [bidAmountError, setBidAmountError] = useState(false)

  const customerState = useCustomerState()

  const handleBudgetChange = (e: any) => {
    const b = e.target.value
    setBudget(b)
    if (Number(b) && Number(b) >= 1 && Number(b) <= 1000) {
      setBudgetError(false)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.budgetError)))
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.budgetError)))
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({ ...adAutomationSettings, budgetProps: { ...adAutomationSettings.budgetProps, budget: Number(b) } }))
      } else {
        dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, budgetProps: { ...advancedPostBoosting.budgetProps, budget: Number(b) } }))
      }
    } else {
      setBudgetError(true)
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.budgetError).concat([MetaErrors.budgetError])))
      } else {
        dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.budgetError).concat([MetaErrors.budgetError])))
      }
    }
  }

  const handleBudgetTypeChange = (e: any) => {
    setBudgetType(e.target.value)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, budgetProps: { ...adAutomationSettings.budgetProps, budget_type: e.target.value } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, budgetProps: { ...advancedPostBoosting.budgetProps, budget_type: e.target.value } }))
    }
  }

  const handleBidStrategyChange = (e: SelectChangeEvent<BidStrategy>) => {
    const s = e.target.value as BidStrategy
    setBidStrategy(s)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, budgetProps: { ...adAutomationSettings.budgetProps, bid_strategy: s } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, budgetProps: { ...advancedPostBoosting.budgetProps, bid_strategy: s } }))
    }

  }

  const handleBidAmountChange = (e: any) => {
    const bAmount = e.target.value
    setBidAmount(bAmount)
    if (Number(bAmount) && Number(bAmount) >= 0.1 && Number(bAmount) <= 100) {
      setBidAmountError(false)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.bidAmountError)))
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.bidAmountError)))
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({ ...adAutomationSettings, budgetProps: { ...adAutomationSettings.budgetProps, bid_amount: Number(bAmount) } }))
      } else {
        dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, budgetProps: { ...advancedPostBoosting.budgetProps, bid_amount: Number(bAmount) } }))
      }
    } else {
      setBidAmountError(true)
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.bidAmountError).concat([MetaErrors.bidAmountError])))
      } else {
        dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.bidAmountError).concat([MetaErrors.bidAmountError])))
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Budget</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="standard-basic"
          label={budgetIsCampaignLevel ? "Campaign budget" : "Ad set budget for each post (€)"}
          name={budgetIsCampaignLevel ? "Campaign budget" : "Ad set budget"}
          variant="standard"
          type='number'
          fullWidth
          value={budget}
          onChange={handleBudgetChange}
          InputLabelProps={{
            shrink: true
          }}
          error={budgetError}
          helperText={budgetError ? "Enter a budget between 1 and 1000 €." : ""}
          sx={{ fontSize: '14px' }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="budget-type-select-label">Budget type</InputLabel>
          <Select
            labelId="budget-type-select-label"
            id="budget-type-select"
            value={budgetType}
            onChange={handleBudgetTypeChange}
            label="Budget type"
          >
            <MenuItem sx={{ fontSize: '14px' }} value={BudgetType.daily}>Daily</MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} value={BudgetType.lifetime}>Lifetime</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="bid-strategy-select-label">Bid strategy</InputLabel>
          <Select
            labelId="bid-strategy-select-label"
            id="bid-strategy-goal"
            value={bidStrategy}
            onChange={handleBidStrategyChange}
            label="Bid strategy"
          >
            {convertObjectToArray(BidStrategy).map((s: any) => {
              let text = ''
              if (s.toLowerCase().includes("lowest_cost_without_cap")) {
                text = "(automatic)"
              } else if (s.toLowerCase().includes("lowest_cost_with_bid_cap")) {
                text = "(manual)"
              }
              return <MenuItem sx={{ fontSize: '14px' }} key={s} value={s}>{beautifyString(s)} {text && text} </MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      {/* "LOWEST_COST_WITHOUT_CAP", // automatic bidding. No need for bid_amount field */}
      {!bidStrategy.includes("LOWEST_COST_WITHOUT_CAP") && <Grid item xs={6}>
        <TextField
          id="standard-basic"
          label="Bid amount (0.1 - 100 €)"
          name='Bid amount'
          type='number'
          variant="standard"
          fullWidth
          value={bidAmount}
          onChange={handleBidAmountChange}
          InputLabelProps={{
            shrink: true
          }}
          error={bidAmountError}
          helperText={bidAmountError ? 'Enter a bid amount between 0.1 and 100 € (10 - 10000 cents).' : ''}
        />
      </Grid>}

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>

    </>
  )
}

export default Budget;