import { Animation, AnimationEffect, AnimationEffectPropertyChange } from "./AnimationTypes"


export type AnimationFramesProps = {
  id: string
  animation: Animation
}


type AnimationEffectExt = AnimationEffect & { st: number, et: number }

export const AnimationFrames = (props: AnimationFramesProps) => {
  const { id, animation } = props
  const effects = animation.effects
  const totalTime = getAnimationTotalTime(animation)

  const effectsExt = effects.reduce(({ all, total }, c) => ({
    total: total + c.durationMs,
    all: all.concat([{
      ...c,
      st: Math.round(total * 100 / totalTime),
      et: Math.round((total + c.durationMs) * 100 / totalTime)
    }])
  }), { all: [] as AnimationEffectExt[], total: 0 }).all

  const startFrame = `0% {${effectsExt.filter(e => e.type == "PropertyChange").map(e => e as AnimationEffectPropertyChange)
    .map(e => `${e.property}: ${e.startValue}`)}}`

  return <style>
    @keyframes {id} {
      `{\n${startFrame}\n${effectsExt.map(e => {
        if (e.type === "PropertyChange") {
          return `${e.st}% {${e.property}: ${e.startValue};}\n` +
            `${e.et}% {${e.property}: ${e.endValue};}`
        }
        return ''
      }).join('\n')}\n}`
    }
  </style>
}

export const getAnimationTotalTime = (animation: Animation): number => {
  return animation.effects.reduce((total, c) => total + c.durationMs, 0)
}