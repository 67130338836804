
import styled from 'styled-components';
import { UnitFontFamily } from './Colors';

export const MeasurementHeading = styled.div`
  color: #6f6f6f;
  font-family: ${UnitFontFamily};
  font-size: 1.0em;
  font-weight: lighter;
`

export const MeasurementContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.3fr 1fr;
  align-items: flex-end;
`

type ValueProps = {
  fontSize?: string;
  marginBottom?: string;
  color?: string;
  children: any;
}

export const Value = (props: ValueProps) => {
  return <div style={{
    fontFamily: UnitFontFamily,
    fontSize: props.fontSize || "1.4em",
    color: props.color || "#000000",
    marginBottom: props.marginBottom || "0px"
  }} >
    {props.children}
  </div>
}

export const Unit = styled.div`
font-size: 0.9em;
color: #8f8f8f;
margin-top: 8px;
font-family: ${UnitFontFamily};
`

export const DeviationContainer = styled.div`
display: flex;
align-items: flex-end;
`

export const LegendValueContainer = styled.div`
display: grid;
grid-template-columns: 1.3em 1fr;
column-gap: 5px;
max-height: 1em;
`
const Line = styled.div`
border-radius: 5px;
min-height: 5px;
max-height: 5px;
align-self: center;
`

const LegendText = styled.div`
align-self: left;
font-weight: lighter;
font-size: 0.8em;
font-family: ${UnitFontFamily};
`

export type MeasurementInfoProps = {
  title: string
  value: number
  unit: string
  color?: string
  fontSize?: string
}

export const MeasurementInfo = (props: MeasurementInfoProps) => {
  return (<MeasurementContainer>
    <MeasurementHeading>{props.title}</MeasurementHeading>
    <Value fontSize={props.fontSize} color={props.color || "#000000"}>{props.value.toLocaleString()}</Value>
    <Unit>{props.unit}</Unit>
  </MeasurementContainer>)
}

export type MeasurementDeviationInfoProps = {
  title: string
  value: number
  percentage: number
  unit: string
  color?: string
  negativeColor?: string
}

export const MeasurementDeviationInfo = (props: MeasurementDeviationInfoProps) => {
  const { color, negativeColor } = props
  return (<MeasurementContainer>
    <MeasurementHeading>{props.title}</MeasurementHeading>
    <DeviationContainer>
      {props.value >= 0 ? <>
        <Value color={color}>{props.value.toLocaleString()}</Value>
                &nbsp;
        <Value color={color} fontSize="0.9em" marginBottom="0.25em">
          {!isNaN(props.percentage) ? `${props.percentage.toFixed(1)}%` : ''}
        </Value>
      </> : <>
        <Value color={negativeColor || "#cf0000"}> {props.value.toLocaleString()}</Value>
                &nbsp;
        <Value color={negativeColor || "#cf0000"} fontSize="0.9em" marginBottom="0.25em">
          {!isNaN(props.percentage) ? `${props.percentage.toFixed(1)}%` : ''}
        </Value>
      </>}
    </DeviationContainer>
    <Unit>{props.unit}</Unit>
  </MeasurementContainer>)
}

export type LegendValueProps = {
  title: string
  color: string
}

export const LegendValue = (props: LegendValueProps) => {
  return (<LegendValueContainer>
    <Line style={{ backgroundColor: props.color }} />
    <LegendText color={props.color}>{props.title}</LegendText>
  </LegendValueContainer >)
}