import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { apiGetAssetsUserHasAccessTo, apiSaveChosenMetaAssets } from '../api/Marketing';
import { useCustomerState } from '../context/customer/CustomerContext';
import { useAdminStateValue } from '../context/admin/AdminContext';
import { MetaAssets } from '../context/meta/MetaTypes';
import { setMetaAssets } from '../context/meta/MetaActions';
import { useAlertStateValue } from '../context/alert/AlertContext';
import { setAlert } from '../context/alert/AlertAction';
import { AlertSeverity } from '../context/alert/AlertTypes';
import { setLinkedInAssets } from '../context/admin/AdminActions';
import { apiGetLinkedInSettings, apiSaveSelectedAssets } from '../api/Customer';
import { apiGetUserAssets } from '../api/Auth';
import { LinkedInAdAccount } from '../context/admin/AdminTypes';

interface SelectLinkedInAssetsDialogProps {
  isOpen: boolean
}

export default function SelectLinkedInAssetsDialog({ isOpen }: SelectLinkedInAssetsDialogProps) {
  const [open, setOpen] = React.useState(isOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const customerState = useCustomerState()
  const [{ linkedInAssets }, dispatch] = useAdminStateValue()
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [{ alert }, alertDispatch] = useAlertStateValue()



  useEffect(() => {
    if (customerState?.selected?.id && isOpen) {
      apiGetUserAssets(customerState.selected.id.toString()).then((result) => {
        dispatch(setLinkedInAssets({...linkedInAssets, adAccounts: result.adAccounts}))
      })
    }
  }, [customerState?.selected?.id])


  const handleAdAccountChange = (e: SelectChangeEvent<string|number>) => {
    const aAccount = linkedInAssets.adAccounts.find(i => i.id === e.target.value)
    if(aAccount){
      dispatch(setLinkedInAssets({
        ...linkedInAssets,
        currentAssets: {
          ...linkedInAssets.currentAssets,
          adAccount: aAccount
        }
      }))
    }

  }

  const handleSave = () => {
    setSaveBtnProgress(true)
    if (customerState?.selected?.id && linkedInAssets.currentAssets.adAccount.id) {
      apiSaveSelectedAssets(customerState?.selected?.id, { "linkedInAssets": linkedInAssets.currentAssets }).then(result => {
        setSaveBtnProgress(false)
        if (result?.success) {
          setOpen(false);
          dispatch(setLinkedInAssets({ ...linkedInAssets, savedAssetsIntoDB: {adAccount: linkedInAssets.currentAssets.adAccount}, openAssetsDialog: false }))
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Assets saved successfully!", duration: 7000 }))
        } else {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
        }
      }).catch((error: any) => {
        console.log(error)
        setSaveBtnProgress(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
      })
    }
  };

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="">
          Assets to Use
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
            </Grid>

            <Grid item xs={12} sx={{}}>
              <Typography variant='body1' sx={{}}>Select an ad account that you would like to use for the selected customer</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl size='small' variant="outlined" sx={{ width: '100%' }}>
                <InputLabel id="ad-automation-status-select-label">Ad account</InputLabel>
                <Select
                  labelId="ad-automation-status-select-label"
                  id="ad-automation-status"
                  value={linkedInAssets.currentAssets.adAccount.id}
                  onChange={handleAdAccountChange}
                  label="Ad Account"
                >
                  {linkedInAssets.adAccounts?.map((a: LinkedInAdAccount) =>
                    <MenuItem sx={{ fontSize: '14px' }} key={a.id} value={a.id}>{a.name} (id: {a.id})</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button sx={{ textTransform: 'none' }} variant='outlined' onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{ textTransform: 'none' }} variant='contained' disabled={linkedInAssets.currentAssets.adAccount ? false : true} onClick={handleSave}>
            {saveBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: "#fff" }} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}