import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomationSettingsErrors, setAdvancedPostBoosting, setAdvancedPostBoostingErrors } from '../../../context/meta/MetaActions';
import { AdSetBillingEvent, BudgetType, EngagementOptimizationGoals, AwarenessOptimizationGoals, TrafficOptimizationGoals, LeadsOptimizationGoals, MarketingObjStatus, EngagementConversions, TrafficConversions, LeadsConversions, EngagementWebsiteConversionOptimizationGoals, TrafficWebsiteConversionOptimizationGoals, MessagingAppsConversionOptimizationGoals, OnYourAdConversionOptimizationGoals, FacebookPageConversionOptimizationGoals, MessengerAndWhatsAppConversionsOptimizationGoals, BidStrategy, AdPromotedObjectCustomEventType, MetaErrors, BudgetLevel, NewOrExsitingCampaing } from '../../../context/meta/MetaTypes';
import AdSetTargeting from './AdSetTargeting';
import { capitalizeFirstLetter, beautifyString, convertObjectToArray } from '../../../utils/helpers'
import Divider from '@mui/material/Divider';
import AdSetSchedule from './AdSetSchedule';
import { useLocation } from 'react-router-dom';
import { getBusinessOwnedPixels } from '../../../api/Marketing';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import Budget from './Budget';
import { Refresh } from '@mui/icons-material';
import styled from 'styled-components';

const SelectRefresh = styled.div`
  display:grid;
  grid-template-columns:1fr 2em;
  align-items:bottom;
`

/**
 * @description This component is used to create an ad set for both the ad automation and the advanced post booster.
 */
const AdSetInfo = () => {
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting, advancedPostBoostingErrors }, dispatch] = useStateValue()

  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')

  const [name, setName] = useState(metaAdAutoLocation ? adAutomationSettings.adSet.name : advancedPostBoosting.adSet.name)
  const [nameError, setNameError] = useState(false)

  const [status, setStatus] = useState(metaAdAutoLocation ? adAutomationSettings.adSet.status : advancedPostBoosting.adSet.status)

  const [conversion, setConversion] = useState(metaAdAutoLocation ? adAutomationSettings.adSet.conversion : advancedPostBoosting.adSet.conversion)
  const [billingEvent, setBillingEvent] = useState(metaAdAutoLocation ? adAutomationSettings.adSet.billing_event : advancedPostBoosting.adSet.billing_event)
  const [optimizationGoal, setOptimizationGoal] = useState(metaAdAutoLocation ? adAutomationSettings.adSet.optimization_goal : advancedPostBoosting.adSet.optimization_goal)
  const [currentOptimizationGoals, setCurrentOptimizationGoals] = useState<any[]>(convertObjectToArray(EngagementWebsiteConversionOptimizationGoals))

  const [currentBillingEvents, setCurrentBillingEvents] = useState<any[]>(["IMPRESSIONS"])

  const [loadingPixels, setLoadingPixels] = useState(true)

  const [pixels, setPixels] = useState<any[]>([])
  const [selectedPixelId, setSelectedPixelId] = useState<string>(metaAdAutoLocation ? adAutomationSettings.adSet.promoted_object.pixel_id : advancedPostBoosting.adSet.promoted_object.pixel_id)
  const [promotedObjectEventType, setPromotedObjectEventType] = useState<AdPromotedObjectCustomEventType>(metaAdAutoLocation ? adAutomationSettings.adSet.promoted_object.custom_event_type : advancedPostBoosting.adSet.promoted_object.custom_event_type)


  const [campaignObjective] = useState(metaAdAutoLocation ? adAutomationSettings.campaign.objective : advancedPostBoosting.campaign.objective)

  const customerState = useCustomerState()

  const reloadPixels = (useCache: boolean) => {
    setLoadingPixels(true)
    customerState?.selected?.id && getBusinessOwnedPixels(customerState?.selected?.id, useCache).then((result) => {
      setLoadingPixels(false)
      setPixels(result.pixels)
    }).catch((error: any) => {
      setLoadingPixels(false)
    });
  }

  useEffect(() => {
    reloadPixels(true)
  }, [customerState])


  useEffect(() => {
    setStatus(metaAdAutoLocation ? adAutomationSettings.adSet.status : advancedPostBoosting.adSet.status)
    setOptimizationGoal(metaAdAutoLocation ? adAutomationSettings.adSet.optimization_goal : advancedPostBoosting.adSet.optimization_goal)
    if (campaignObjective.toLowerCase().includes("awareness")) {
      setCurrentOptimizationGoals(convertObjectToArray(AwarenessOptimizationGoals))
    }
    if (campaignObjective.toLowerCase().includes("traffic")) {
      setCurrentOptimizationGoals(convertObjectToArray(TrafficOptimizationGoals))
    }

  }, [adAutomationSettings, advancedPostBoosting])

  const handleNameChange = (e: any) => {
    const n = e.target.value
    setName(n)
    // Update the appropriate state depending on the current location.
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, name: n } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, name: n } }))
    }
    if (n && n.length >= 3) {
      setNameError(false)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.adSetNameError)))
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.adSetNameError)))
    } else {
      setNameError(true)
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.adSetNameError).concat([MetaErrors.adSetNameError])))
      } else {
        dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.adSetNameError).concat([MetaErrors.adSetNameError])))
      }
    }

  }

  const handleStatusChange = (e: SelectChangeEvent<MarketingObjStatus>) => {
    const status = e.target.value as MarketingObjStatus
    setStatus(status)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({
        ...adAutomationSettings,
        adSet: { ...adAutomationSettings.adSet, status: status },
        active: status === MarketingObjStatus.ACTIVE ? true : false,
        campaign: { ...adAutomationSettings.campaign, status: status }
      }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, status: status } }))
    }
  }

  const handleConversionChange = (e: SelectChangeEvent<any>) => {
    const conEvent = e.target.value
    setConversion(conEvent)
    switch (conEvent) {
      case "WEBSITE":
        if (metaAdAutoLocation && adAutomationSettings.campaign?.objective?.toLowerCase().includes("engagement") || !metaAdAutoLocation && advancedPostBoosting.campaign?.objective?.toLowerCase().includes("engagement")) {
          setCurrentOptimizationGoals(convertObjectToArray(EngagementWebsiteConversionOptimizationGoals))
        } else {
          setCurrentOptimizationGoals(convertObjectToArray(TrafficWebsiteConversionOptimizationGoals))
        }
        setOptimizationGoal(TrafficWebsiteConversionOptimizationGoals.IMPRESSIONS)
        break;
      case "MESSAGING_APPS":
        setCurrentOptimizationGoals(convertObjectToArray(MessagingAppsConversionOptimizationGoals))
        setOptimizationGoal(MessagingAppsConversionOptimizationGoals.CONVERSATIONS)
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS])
        break;
      case "ON_YOUR_AD":
        setCurrentOptimizationGoals(convertObjectToArray(OnYourAdConversionOptimizationGoals))
        setOptimizationGoal(OnYourAdConversionOptimizationGoals.THRUPLAY)
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS, AdSetBillingEvent.THRUPLAY])
        break;
      case "FACEBOOK_PAGE":
        setCurrentOptimizationGoals(convertObjectToArray(FacebookPageConversionOptimizationGoals))
        setOptimizationGoal(FacebookPageConversionOptimizationGoals.IMPRESSIONS)
        break;
      case "MESSENGER":
        setCurrentOptimizationGoals(convertObjectToArray(MessengerAndWhatsAppConversionsOptimizationGoals))
        setOptimizationGoal(MessengerAndWhatsAppConversionsOptimizationGoals.IMPRESSIONS)
        break;
      case "WHATSAPP":
        setCurrentOptimizationGoals(convertObjectToArray(MessengerAndWhatsAppConversionsOptimizationGoals))
        setOptimizationGoal(MessengerAndWhatsAppConversionsOptimizationGoals.IMPRESSIONS)
        break;
      default:
        setCurrentOptimizationGoals(convertObjectToArray(TrafficWebsiteConversionOptimizationGoals))
        setOptimizationGoal(TrafficWebsiteConversionOptimizationGoals.IMPRESSIONS)
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS])
        break;
    }
    setBillingEvent(AdSetBillingEvent.IMPRESSIONS)
  }

  const handleOptimizationGoalChange = (e: SelectChangeEvent<any>) => {
    const oGoal = e.target.value
    setOptimizationGoal(oGoal)

    switch (oGoal) {
      case "LINK_CLICKS":
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS, AdSetBillingEvent.LINK_CLICKS])
        break;
      case "THRUPLAY":
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS, AdSetBillingEvent.THRUPLAY])
        break;
      case "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS":
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS, AdSetBillingEvent.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS])
        break;
      case "PAGE_LIKES":
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS, AdSetBillingEvent.PAGE_LIKES])
        break;
      default:
        setCurrentBillingEvents([AdSetBillingEvent.IMPRESSIONS])
        break;
    }

    setBillingEvent(AdSetBillingEvent.IMPRESSIONS)

    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, optimization_goal: oGoal, billing_event: AdSetBillingEvent.IMPRESSIONS } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, optimization_goal: oGoal, billing_event: AdSetBillingEvent.IMPRESSIONS } }))
    }
  }

  const handleBillingEventChange = (e: SelectChangeEvent<AdSetBillingEvent>) => {
    const bEvent = e.target.value as AdSetBillingEvent
    setBillingEvent(bEvent)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, billing_event: bEvent } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, billing_event: bEvent } }))
    }
  }

  const handlePixelSelection = (e: SelectChangeEvent<any>) => {
    const id: string = e.target.value
    setSelectedPixelId(id)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, promoted_object: { ...adAutomationSettings.adSet.promoted_object, pixel_id: id } } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, promoted_object: { ...advancedPostBoosting.adSet.promoted_object, pixel_id: id } } }))
    }
  }

  const handlePromotedObjectEventTypeChange = (e: SelectChangeEvent<AdPromotedObjectCustomEventType>) => {
    const eType = e.target.value as AdPromotedObjectCustomEventType
    setPromotedObjectEventType(eType)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, promoted_object: { ...adAutomationSettings.adSet.promoted_object, custom_event_type: eType } } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, promoted_object: { ...advancedPostBoosting.adSet.promoted_object, custom_event_type: eType } } }))
    }
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        {/*<Typography variant="h6">Create a new ad set</Typography>*/}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Name and status</Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="standard-basic"
          label="Ad set name"
          name='Ad set name'
          variant="standard"
          fullWidth
          value={name}
          onChange={handleNameChange}
          error={nameError}
          helperText={nameError ? "Enter at least 3 characters." : ""}
          InputLabelProps={{
            shrink: true
          }}
          sx={{ fontSize: '14px' }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="budget-type-select-label">Ad set status</InputLabel>
          <Select
            labelId="budget-type-select-label"
            id="budget-type-select"
            value={status}
            onChange={handleStatusChange}
            label="Budget type"
          >
            {convertObjectToArray(MarketingObjStatus).map((s: MarketingObjStatus) => {
              return <MenuItem key={s} value={s} sx={{ fontSize: '14px' }}>{capitalizeFirstLetter(s)}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>

      {/* Budget */}
      {(metaAdAutoLocation && adAutomationSettings.budgetProps.budgetLevel === BudgetLevel.AD_SET_LEVEL
        || !metaAdAutoLocation && advancedPostBoosting.newOrExistingCampaign === NewOrExsitingCampaing.NEW_CAMPAIGN && advancedPostBoosting.budgetProps.budgetLevel === BudgetLevel.AD_SET_LEVEL
        || !metaAdAutoLocation && advancedPostBoosting.newOrExistingCampaign === NewOrExsitingCampaing.EXISTING_CAMPAIGN && !advancedPostBoosting.campaign.lifetime_budget && !advancedPostBoosting.campaign.daily_budget) &&
        <Budget />}

      {(metaAdAutoLocation && !adAutomationSettings.campaign.objective.toLowerCase().includes("awareness") || !metaAdAutoLocation && !advancedPostBoosting.campaign.objective.toLowerCase().includes("awareness")) && <>
        <Grid item xs={12}>
          <Typography variant="h6">Conversion</Typography>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="conversion-select-label">Conversion location</InputLabel>
            <Select
              labelId="conversion-select-label"
              id="conversion"
              value={conversion}
              onChange={handleConversionChange}
              label="conversion"
            >
              {metaAdAutoLocation && adAutomationSettings.campaign.objective.toLowerCase().includes("engagement") && convertObjectToArray(EngagementConversions).map((con: EngagementConversions) => {
                return <MenuItem sx={{ fontSize: '14px' }} key={con} value={con}>{beautifyString(con)}</MenuItem>
              })}
              {metaAdAutoLocation && adAutomationSettings.campaign.objective.toLowerCase().includes("traffic") && convertObjectToArray(TrafficConversions).map((con: TrafficConversions) => {
                return <MenuItem sx={{ fontSize: '14px' }} key={con} value={con}>{beautifyString(con)}</MenuItem>
              })}
              {metaAdAutoLocation && adAutomationSettings.campaign.objective.toLowerCase().includes("leads") && convertObjectToArray(LeadsConversions).map((con: LeadsConversions) => {
                return <MenuItem sx={{ fontSize: '14px' }} key={con} value={con}>{beautifyString(con)}</MenuItem>
              })}

              {!metaAdAutoLocation && advancedPostBoosting.campaign?.objective.toLowerCase().includes("engagement") && convertObjectToArray(EngagementConversions).map((con: EngagementConversions) => {
                return <MenuItem sx={{ fontSize: '14px' }} key={con} value={con}>{beautifyString(con)}</MenuItem>
              })}
              {!metaAdAutoLocation && advancedPostBoosting.campaign?.objective.toLowerCase().includes("traffic") && convertObjectToArray(TrafficConversions).map((con: TrafficConversions) => {
                return <MenuItem sx={{ fontSize: '14px' }} key={con} value={con}>{beautifyString(con)}</MenuItem>
              })}
              {!metaAdAutoLocation && advancedPostBoosting.campaign?.objective.toLowerCase().includes("leads") && convertObjectToArray(LeadsConversions).map((con: LeadsConversions) => {
                return <MenuItem sx={{ fontSize: '14px' }} key={con} value={con}>{beautifyString(con)}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
          <Divider />
        </Grid>
      </>}

      <Grid item xs={12}>
        <Typography variant="h6">Optimization and delivery</Typography>
      </Grid>


      <Grid item xs={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="optimization-goal-select-label">Optimization goal</InputLabel>
          <Select
            labelId="optimization-goal-select-label"
            id="optimization-goal"
            value={optimizationGoal}
            onChange={handleOptimizationGoalChange}
            label="Optimization goal"
          >
            {currentOptimizationGoals.map((og: any) => {
              return <MenuItem sx={{ fontSize: '14px' }} key={og} value={og}>{beautifyString(og)}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="billing-event-select-label">Billing event</InputLabel>
          <Select
            labelId="billing-event-select-label"
            id="billing-event"
            value={billingEvent}
            onChange={handleBillingEventChange}
            label="Billing event"
          >
            {currentBillingEvents.map((be: any) => {
              return <MenuItem sx={{ fontSize: '14px' }} key={be} value={be}>{beautifyString(be)}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>


      {campaignObjective.toLowerCase().includes("engagement") && <>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h6">Conversion event</Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 2, color: "#7B7B7B", fontSize: '14px' }}>Note that the selected pixel must be assigned to the relevant ad account, which has been used for the authorization. For more info: <a rel="noreferrer" href="https://akohubteam.medium.com/how-to-assign-your-facebook-pixel-to-your-ad-accounts-219e97394619" target={'_blank'}>Assign pixel to ad account</a> </Typography>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="ad-pixel-select-label">Pixel</InputLabel>
            <SelectRefresh>
              <Select
                labelId="ad-pixel-select-label"
                id="ad-pixel"
                value={selectedPixelId}
                onChange={handlePixelSelection}
                label="Pixel"
              >
                {pixels?.length > 0 && pixels.map((p: any) => {
                  return <MenuItem
                    sx={{ fontSize: '14px' }}
                    key={p.id}
                    value={p.id}>
                    {`${p.name} - (id: ${p.id})`}
                  </MenuItem>
                })}
              </Select>
              {loadingPixels ? <CircularProgress style={{ width: '1.0em', height: '1.0em', marginTop: '1.5em', marginLeft: '0.5em' }} /> : <IconButton style={{ marginTop: '0.5em' }} onClick={() => reloadPixels(false)}><Refresh /></IconButton>}
            </SelectRefresh>

          </FormControl>
        </Grid>


        <Grid item xs={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="ad-pixel-select-label">Event type</InputLabel>
            <Select
              labelId="ad-pixel-select-label"
              id="ad-pixel"
              value={promotedObjectEventType}
              onChange={handlePromotedObjectEventTypeChange}
              label="Pixel"
            >
              {convertObjectToArray(AdPromotedObjectCustomEventType).map((t: any) => {
                return <MenuItem
                  sx={{ fontSize: '14px' }}
                  key={t}
                  value={t}>
                  {beautifyString(t)}
                </MenuItem>
              })}
            </Select>

          </FormControl>
        </Grid>

      </>}

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>

      <AdSetSchedule />

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <AdSetTargeting />
      </Grid>
    </Grid>
  )
}

export default AdSetInfo;