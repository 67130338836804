import React from 'react'
import { AdUnits, AdUnitsCharts, AdsAction, AdsState, CategoryItem, SET_AD_UNITS, SET_AD_UNITS_CHARTS } from './AdsTypes';
import { DateTime } from 'luxon';

/*
const lasthMonth = DateTime.local().startOf("month").minus({ months: 1 })
const startDate = lasthMonth.toJSDate()
const endDate = lasthMonth.endOf("month").toJSDate()
*/

const startDate = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000))
const endDate = new Date()

export const allCategories: CategoryItem[] = [
  { value: "bingAds", label: "Bing Ads" },
  { value: "facebook", label: "Meta" },
  { value: "app", label: "Google App" },
  { value: "display", label: "Google Display" },
  { value: "displayVideo", label: "Google Display & Video" },
  { value: "discovery", label: "Google Discovery" },
  { value: "shopping", label: "Google Shopping" },
  { value: "search", label: "Google Search" },
  { value: "smart", label: "Google Smart" },
  { value: "video", label: "Google Video" },
  { value: "performanceMax", label: "Google Performance Max" },
  { value: "linkedInAds", label: "LinkedIn Ads" },
  { value: "readpeakAds", label: "Readpeak Ads" },
  { value: "youtube", label: "Youtube" },
]

export const allCategoriesAsStrings: string[] = allCategories.map((c: CategoryItem) => c.value)


export const initialAdUnits: AdUnits = {
  startDate,
  endDate,
  creatives: { start: 0, total: 0, creatives: [], dimensions: {}, campaigns: [], adGroups: [], floodlightActivities: [], categoriesImpressions: {} },
  loading: false,
  sort: "impressions",
  direction: "desc",
  statuses: ["Active", "Paused", "Archived"],
  allCampaigns: [],
  campaigns: [],
  allAdGroups: [],
  adGroups: [],
  dimensions: [],
  allFloodlightActivities: [{ title: "All Activities" }],
  allDimensions: [],
  start: 0,
  showZeros: false,
  categories: allCategoriesAsStrings,
  floodlightActivity: "",
  conversions: "totalConversions",
  showSelectCustomer: false,
  gridViewItemsCount: 3
}

export const initialAdUnitsCharts: AdUnitsCharts = {
  chartMetrics: [],
  chartLoading: false
}

export const initialAdsState: AdsState = {
  adUnits: initialAdUnits,
  adUnitsCharts: initialAdUnitsCharts
};


const AdsReducer = (state: AdsState, action: AdsAction): AdsState => {
  switch (action.type) {
    case SET_AD_UNITS:
      return {
        ...state,
        adUnits: action.data,
      };
    case SET_AD_UNITS_CHARTS:
      return {
        ...state,
        adUnitsCharts: action.data,
      };
    default:
      return state;
  }
};

export default AdsReducer