import React, { ChangeEvent, Component, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { apiOAuthLogin, apiOAuthSearchConsoleLogin, apiOAuthGoogleAdsLogin, apiOAuthBingAdsLogin } from '../api/Auth';
import { apiClearMetaSettings, apiCreateCustomer, apiCreateNewUser, apiDeleteCustomer, apiGetAdUnitsSettings, apiGetBingAdsSettings, apiGetCustomerServices, apiGetCustomers, apiGetGoogleAdsSettings, apiGetMetaSettings, apiGetNotificationRules, apiGetSavedAssets, apiGetSearchConsoleSettings, apiGetUsers, apiPutAdUnitConfiguration, apiPutDv360CaptchaText, apiPutDv360OneTimeText, apiPutMetaSettings, apiPutNotificationRules, apiSetCustomerParent, apiSetServiceActivation, apiUpdateCustomerName, apiUpdateServiceConfiguration } from '../api/Customer';
import { AdUnitSettings, MetaSettings, CustomerTree, SearchConsoleSettings, GoogleAdsSettings, NotificationRule, CustomerService, ServiceTypes, KivaAnalyticsConfiguration, BingAdsSettings, NewUser, CreateNewUserRes } from '../api/Types';
import { setAlert } from '../context/alert/AlertAction';
import { useAlertStateValue } from '../context/alert/AlertContext';
import { useStateValue as useMetaStateValue } from '../context/meta/MetaContext';
import { Alert, AlertAction, AlertSeverity } from '../context/alert/AlertTypes';
import { useCustomerDispatch, useCustomerState } from '../context/customer/CustomerContext';
import { debounce, findCustomerParentFromTree, flattenCustomers } from '../utils/Generators';
import { RuleEditor } from './Rules';
import { Alert as MaterialAlert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, MenuItem, Select, Switch, TextField, Typography, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { apiFlushCache } from '../api/Creative';
import { CustomersAction } from '../context/customer/CustomerReducer';
import { Checkbox } from './Checkbox';
import { LoadingModal } from './Modal';
import { findActiveServices } from '../utils/helpers';
import { AdminAccordion } from './AdminAccordion';
import { getMetaAuthStatus, apiGetSavedMetaAssets } from '../api/Marketing';
import { Checkbox as MaterialCheckbox } from '@mui/material';
import { DisplayVideoAdmin } from './DisplayVideoAdmin';
import { MetaAction, MetaState } from '../context/meta/MetaTypes';
import { setMetaAssets } from '../context/meta/MetaActions';
import ChooseMetaAssetsDialog from './ChooseMetaAssetsDialog';
import { Error } from '@mui/icons-material';
import { GoogleAnalyticsAdmin } from './GoogleAnalyticsAdmin';
import { SpendsAdmin } from './Admin/SpendsAdmin';
import { LinkedInAdmin } from './Admin/LinkedInAdmin';
import { ReadpeakAdmin } from './Admin/ReadpeakAdmin';
import { BingAdmin } from './Admin/BingAdmin';
import { MetaAdmin } from './Admin/MetaAdmin';
import { desktop_h1 } from '../styles/textStyles';
import { NotificationsAdmin } from './Admin/NotificationsAdmin';
import { SlackAdmin } from './Admin/SlackAdmin';
import { regularBtnStyles } from '../styles/buttons';
import { useAdminStateValue } from '../context/admin/AdminContext';
import { setGoogleAdsConfig, setLinkedInAssets } from '../context/admin/AdminActions';
import { AdminAction, AdminState as AdminStateManagement, SavedAssets } from '../context/admin/AdminTypes';
import { DatasetAdmin } from './Admin/DatasetAdmin';
import GoogleAdsAdmin from './Admin/GoogleAdsAdmin';

const ErrorMessage = styled.div`
    display:block;
    color: red;
    border-radius:1.0em;
    padding:1.0em;
    margin-top:24px;
`

const LoadingError = styled.div`
  display:grid;
  grid-template-columns: 2em 1fr;
  align-content:center;
  color:red;
`

const OkMessage = styled.div`
    display:block;
    color: green;
    border-radius:1.0em;
    padding:1.0em;
    margin-top:24px;
`

const ServiceConfiguration = styled.div`
    margin:1em;
    display:grid;
    grid-template-columns: 1fr 1fr;
    row-gap:1em;
    column-gap:1em;
`

const DeleteContainer = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    column-gap:5px;
    position:absolute;
    top:5em;
    right:10px;
`

const Warning = styled.div`
    color:red;
    font-weight:bold;
    margin-top:1.0em;
    margin-bottom:1.0em;
`

export type AdminProps = {
  selected?: CustomerTree,
  customers: CustomerTree[],
  alert: Alert,
  alertDispatch: React.Dispatch<AlertAction>
  customerDispatch: React.Dispatch<CustomersAction>
  metaState: MetaState,
  metaDispatch: React.Dispatch<MetaAction>
  adminState: AdminStateManagement,
  adminDispatch: React.Dispatch<AdminAction>
}

type AdminState = {
  searchConsoleSettings: SearchConsoleSettings
  customerId?: number
  loading: boolean
  loadError: boolean
  errorMessage: string
  okMessage: string,
  users: { name: string, email: string }[]
  rules: NotificationRule[]
  customerServices: CustomerService[]
  activeServices: any[]
  customerName: string
  deleteDialogOpen: boolean,
  newUser: NewUser,
  showPassword: { type: string, checked: boolean },
}

export class Admin extends Component<AdminProps, AdminState> {
  saveOneTimeDebounced = debounce(apiPutDv360OneTimeText, 1000)
  saveCustomerNameDebounced = debounce(apiUpdateCustomerName, 1000)

  saveUpdateServiceConfigurationDebounced = debounce(apiUpdateServiceConfiguration, 1000)

  constructor(props: AdminProps) {
    super(props);
    this.state = {
      searchConsoleSettings: {
        websiteUrls: ['']
      },
      loading: false,
      loadError: false,
      errorMessage: '',
      okMessage: '',
      users: [],
      rules: [],
      customerServices: [],
      activeServices: [],
      customerName: props.selected?.name || "",
      deleteDialogOpen: false,
      newUser: {
        name: '',
        email: '',
        reqCustomerId: ''
      },
      showPassword: {
        type: 'password',
        checked: false,
      }
    };
  }

  doMetaLogin() {
    const selected = this.props.selected;
    if (selected) {
      this.setState({ loading: true })
      apiOAuthLogin("meta", selected.id).then((response) => {
        window.location.href = response.url
      }).catch((_ => {
        this.setState({ loading: false })
        this.props.alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in authorization. Please try again later.", duration: null }))
      }))
    }
  }

  doSearchConsoleLogin() {
    const selected = this.props.selected;
    const { searchConsoleSettings } = this.state;

    if (searchConsoleSettings.websiteUrls[0].length <= 4) {
      this.setState({ errorMessage: "Please, provide proper website URL." })
      setTimeout(() => {
        this.setState({ errorMessage: "" })
      }, 5000);
    } else {
      if (selected) {
        apiOAuthSearchConsoleLogin(selected.id, searchConsoleSettings.websiteUrls[0]).then((response) => {
          window.location.href = response.url
        }).catch((_ => {
          alert("Error in authentication. Please try again later.")
        }))
      }
    }
  }

  componentDidMount() {
    this.loadNewCustomer()

  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.selected?.id !== this.props.selected?.id) {
      this.loadNewCustomer()
    }
  }

  loadNewCustomer() {
    const selected = this.props.selected
    if (selected) {
      this.setState({ customerName: selected?.name || "", customerServices: [], loading: true, loadError: false })
      Promise.all([
        apiGetSearchConsoleSettings(selected.id).then((searchConsoleSettings) => {
          this.setState({ searchConsoleSettings: searchConsoleSettings || [{ websiteUrls: [''] }] })
        }),
        apiGetGoogleAdsSettings(selected.id).then((googleAdsSettings) => {
          this.props.adminDispatch(setGoogleAdsConfig({
            ...this.props.adminState.googleAdsConfig,
            savedSettingsIntoDB: googleAdsSettings
          }))
        }),
        apiGetCustomerServices(selected.id).then((customerServices: CustomerService[]) => {
          this.setState({ customerServices })
        }),
        apiGetSavedMetaAssets(selected.id).then((result: any) => {
          this.props.metaDispatch(setMetaAssets({ ...this.props.metaState.metaAssets, savedAssetsIntoDB: result }))
        }),
        apiGetSavedAssets(selected.id).then((result: SavedAssets) => {
          this.props.adminDispatch(setLinkedInAssets({
            ...this.props.adminState.linkedInAssets,
            savedAssetsIntoDB: {
              ...this.props.adminState.linkedInAssets.savedAssetsIntoDB,
              adAccount: result.linkedInAssets.adAccount
            }
          }))
        })
      ]).then(() => {
        this.setState({ loading: false, loadError: false })
      }).catch((error) => {
        this.setState({ loading: false, loadError: true })
      })
      const activeServices = selected ? findActiveServices(selected.id, this.props.customers) || [] : []
      this.setState({ activeServices })
    }
  }

  onSearchConsoleChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      searchConsoleSettings: {
        websiteUrls: [event.target.value],
      }
    })
  }

  onServiceActivityChange(type: string, _: React.SyntheticEvent, checked: boolean) {
    const { selected } = this.props
    if (selected) {
      apiSetServiceActivation(selected.id, type, checked).then(() => {
        this.setState({
          customerServices: this.state.customerServices.map(s => ({
            ...s,
            active: s.type === type ? checked : s.active
          }))
        })
      })
    }
  }

  onUpdateServiceConfiguration(type: ServiceTypes, member: string, checkedValue: boolean, event: any) {
    const { customerServices } = this.state
    const { selected } = this.props
    const index = customerServices.findIndex(cs => cs.type === type);
    if (index !== -1 && selected) {
      (customerServices[index].configuration as any)[member] = checkedValue ?
        event.target.checked :
        (event.target.value === "" ? undefined : event.target.value)
      this.setState({ customerServices })
      this.saveUpdateServiceConfigurationDebounced(selected.id, type, customerServices[index].configuration)
    }
  }

  onCustomerNameChange(event: any) {
    const { selected } = this.props
    if (selected) {
      this.setState({ customerName: event.target.value })
      localStorage.setItem('selectedCustomer', event.target.value)
      this.saveCustomerNameDebounced(selected.id, event.target.value)
    }
  }

  setCustomerParent(id: number, parentId: string) {
    apiSetCustomerParent(id, parentId).then(() => {
      window.location.reload()
    })
  }

  showNotificationsAccordion() {
    if (this.state.activeServices.indexOf("KivaAds") === -1 && this.state.activeServices.indexOf("ManagedAds") !== -1) {
      return false
    } else if (this.state.activeServices.indexOf("KivaAds") !== -1 || this.state.activeServices.indexOf("AdsSpendReporting") !== -1) {
      return true
    }
    return false
  }

  onNewUserInfoChange(field: string, event: ChangeEvent<HTMLInputElement>) {
    switch (field) {
      case 'name':
        this.setState({
          newUser: {
            ...this.state.newUser,
            name: event.target.value
          }
        })
        break;
      case 'email':
        this.setState({
          newUser: {
            ...this.state.newUser,
            email: event.target.value
          }
        })
        break;
      default:
        break;
    }
  }

  onNewUserCustomerChange(event: SelectChangeEvent<number | string>) {
    this.setState({
      newUser: {
        ...this.state.newUser,
        reqCustomerId: event.target.value
      }
    })
  }

  onClearMetaSettings() {
    const { selected } = this.props
    if (selected) {
      this.setState({ loading: true })
      apiClearMetaSettings(selected.id).then(() => {
        window.location.reload()
      }).catch(() => {
        this.setState({ loadError: true })
      })
    }
  }

  doCreateNewUser() {
    const selected = this.props.selected;
    const { newUser } = this.state;
    const newUserReqCustomerId = newUser.reqCustomerId as number

    if (!newUser.name || !newUser.email || !newUserReqCustomerId) {
      this.props.alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all fields to continue", duration: null }))
    } else {
      if (selected && newUserReqCustomerId) {
        apiCreateNewUser(newUserReqCustomerId, newUser.name, newUser.email).then((response: CreateNewUserRes) => {
          if (response.success) {
            this.props.alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: 'Created successfully!', duration: null }))
            this.setState({
              newUser: {
                ...this.state.newUser,
                name: '',
                email: '',
                reqCustomerId: '',
                createdCredentials: response.createdCredentials
              }
            })

          } else {
            this.props.alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: response.message || '', duration: null }))
          }

        }).catch((error: any) => {
          this.props.alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
        })
      }
    }
  }

  deleteCustomer() {
    const { selected, customerDispatch, alertDispatch } = this.props;
    apiDeleteCustomer(selected!.id).then(() => {
      apiGetCustomers().then((customers) => {
        localStorage.setItem("selectedCustomer", "Kiva")
        if (customers) {
          customerDispatch({ type: "CUSTOMERS_LOADED", customers })
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Customer was removed.", duration: null }))
          setTimeout(() => { window.location.reload() }, 1000)
        }
      })
    }).catch(() => {
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Deleting customer failed. Check your access rights.", duration: null }))
    })
  }


  handleShowPassword() {
    this.setState({
      showPassword: {
        checked: !this.state.showPassword.checked,
        type: this.state.showPassword.type === 'password' ? 'text' : 'password'
      }
    })
  }

  render() {
    const { searchConsoleSettings, loading, errorMessage, okMessage,
      users, rules, customerServices, customerName,
      deleteDialogOpen, activeServices, newUser, showPassword, loadError } = this.state
    const { selected, customers, metaState, customerDispatch, adminState } = this.props

    const itemStyle = (level: number) => ({
      marginLeft: `${level * 0.5}em`,
      fontSize: `${1.0 - level * 0.1}em`,
      fontStyle: level & 1 ? 'normal' : 'italic'
    })

    const parentId = selected && findCustomerParentFromTree(customers, selected.id)?.id
   
    const metaConfigured = metaState.metaAssets.savedAssetsIntoDB?.facebookPageName

    return <Box sx={{ margin: '50px' }}>
      {selected?.roles.indexOf("subscriptionAdmin") !== -1 &&
        <DeleteContainer>
          <Button color="primary" variant="contained" onClick={() => { apiFlushCache() }}>Clear Cache</Button>
          <Button color="error" variant="contained" onClick={() => this.setState({ deleteDialogOpen: true })}>Delete</Button>
        </DeleteContainer>
      }
      <Typography variant='h1' sx={{ ...desktop_h1, mb: '32px' }}>Admin</Typography>
      {loadError && <LoadingError>
        <Error />
        <span>An error occurred in loading customer information, try refreshing the page.</span>
      </LoadingError>}
      {
        !loadError &&
        <div>
          {/* General Info  */}
          <AdminAccordion title='General Info'>
            <Grid container>
              {parentId && <Grid item xs={12} sx={gridItemStyles}>
                <Typography sx={textFieldTextStyles}>Parent</Typography>
                <Box>
                  {selected && <Select size="small" value={parentId} onChange={(evt) => this.setCustomerParent(selected.id, evt.target.value as string)}>
                    {flattenCustomers(customers).map(c =>
                      <MenuItem key={c.id} value={c.id} style={itemStyle(c.level)}>{c.name}</MenuItem>)}
                  </Select>}
                </Box>
              </Grid>
              }
              <Grid item xs={12} sx={gridItemStyles}>
                <Typography sx={textFieldTextStyles}>Name</Typography>
                <TextField size='small' variant="outlined"
                  value={customerName}
                  disabled
                  onChange={this.onCustomerNameChange.bind(this)} />
              </Grid>
            </Grid>
          </AdminAccordion>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => this.setState({ deleteDialogOpen: false })}
          >
            <DialogTitle>
              DELETE CUSTOMER CONFIRMATION
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete customer {`"${customerName}"`}?
                <Warning>This operation cannot be undone.</Warning>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => this.setState({ deleteDialogOpen: false })}>NO</Button>
              <Button variant="contained" color="error" onClick={() => {
                this.setState({ deleteDialogOpen: false })
                this.deleteCustomer()
              }}>YES</Button>
            </DialogActions>
          </Dialog>

          {/* Display & Video  */}
          {(activeServices.indexOf("KivaAds") !== -1 || activeServices.indexOf("ManagedAds") !== -1) &&
            <AdminAccordion title="Display & Video">
              {selected && <DisplayVideoAdmin customerId={selected.id} />}
            </AdminAccordion>}

          {/* Meta  */}
          {(activeServices.indexOf("KivaAdAutomations") !== -1 || activeServices.indexOf("KivaPostBooster") !== -1 || activeServices.indexOf("KivaAds") !== -1) &&
            <AdminAccordion title="Meta">
              {selected && <MetaAdmin customerId={selected.id} />}
            </AdminAccordion>
          }

          {/* Google ads */}
          {(activeServices.indexOf("KivaAds") !== -1 || activeServices.indexOf("ManagedAds") !== -1 || activeServices.indexOf("AdsSpendReporting") !== -1) &&
            <AdminAccordion title="Google Ads">
              {selected && <GoogleAdsAdmin customerId={selected.id} />}              
            </AdminAccordion>
          }

          {/* Linkedin Ads */}
          {(activeServices.indexOf("KivaAds") !== -1 || activeServices.indexOf("ManagedAds") !== -1 || activeServices.indexOf("AdsSpendReporting") !== -1) && selected &&
            <AdminAccordion title="LinkedIn Ads">
              <LinkedInAdmin customerId={selected.id.toString()} />
            </AdminAccordion>}

          {/* Readpeak Ads */}
          {(activeServices.indexOf("KivaAds") !== -1 || activeServices.indexOf("ManagedAds") !== -1 || activeServices.indexOf("AdsSpendReporting") !== -1) && selected &&
            <AdminAccordion title="Readpeak Ads">
              <ReadpeakAdmin customerId={selected.id.toString()} />
            </AdminAccordion>
          }

          {/* YouTube Content is related to the content of YouTube channel rather than to YouTube ads. */}
          {/*(activeServices.indexOf("KivaAds") !== -1 || activeServices.indexOf("ManagedAds") !== -1 || activeServices.indexOf("AdsSpendReporting") !== -1) && selected &&
            <AdminAccordion title="YouTube Content">
              <YouTubeAdmin customerId={selected.id.toString()} />
            </AdminAccordion>
          */}

          {(activeServices.indexOf("KivaAds") !== -1 || activeServices.indexOf("ManagedAds") !== -1 || activeServices.indexOf("AdsSpendReporting") !== -1) && selected &&
            <AdminAccordion title="Bing Ads">
              <BingAdmin customerId={selected.id} />
            </AdminAccordion>}

          {activeServices.indexOf("AdsSpendReporting") !== -1 && selected && <AdminAccordion title="Google Analytics">
            <GoogleAnalyticsAdmin customerId={selected.id} />
          </AdminAccordion>}
          {activeServices.indexOf("AdsSpendReporting") !== -1 && selected && <AdminAccordion title="Spends Reporting">
            <SpendsAdmin customerId={selected.id} alertDispatch={this.props.alertDispatch} />
          </AdminAccordion>}
          {/* Search Console  */}
          {selected?.name.includes('Kiva') && <AdminAccordion title='Search Console'>
            <Grid container>

              <Grid item xs={12} sx={gridItemStyles}>
                <Typography sx={textFieldTextStyles}>Website URL</Typography>
                <TextField size='small' variant="outlined" value={searchConsoleSettings.websiteUrls[0]} onChange={this.onSearchConsoleChange.bind(this)} />
              </Grid>

              <Grid item xs={12} sx={gridItemStyles}>
                <Button variant='contained' color="primary" sx={{ ...regularBtnStyles }} onClick={this.doSearchConsoleLogin.bind(this)}>Authenticate Search</Button>
              </Grid>
            </Grid>
          </AdminAccordion>
          }

          {/* Slack  */}
          {this.showNotificationsAccordion() &&
            <AdminAccordion title='Slack'>
              {selected && <SlackAdmin customerId={selected.id.toString()} />}
            </AdminAccordion>}

          <AdminAccordion title='Datasets'>
            {selected && <DatasetAdmin customerId={selected.id.toString()} />}
          </AdminAccordion>

          {/* Notifications  */}
          {this.showNotificationsAccordion() &&
            <AdminAccordion title='Notifications'>
              {selected && <NotificationsAdmin customerId={selected.id.toString()} />}
            </AdminAccordion>}

          {/* Add new user */}
          {selected?.name.indexOf('Kiva') !== -1 && selected?.roles.indexOf("subscriptionAdmin") !== -1 &&
            <AdminAccordion title="Add New User">
              <Grid container>
                <Grid item xs={12} sx={gridItemStyles}>
                  <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4} sx={gridItemStyles}>
                      <TextField fullWidth size='small' label="User Full Name" variant="outlined" value={newUser.name} onChange={this.onNewUserInfoChange.bind(this, 'name')} />
                    </Grid>

                    <Grid item xs={4} sx={gridItemStyles}>
                      <TextField fullWidth size='small' label="User Email" variant="outlined" value={newUser.email} onChange={this.onNewUserInfoChange.bind(this, 'email')} />
                    </Grid>

                    <Grid item xs={4} sx={gridItemStyles}>
                      <FormControl fullWidth size='small'>
                        <InputLabel>Customer</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={newUser.reqCustomerId}
                          label="Customer"
                          onChange={this.onNewUserCustomerChange.bind(this)}
                        >
                          {selected && selected.children.sort((a, b) => (a.name > b.name) ? 1 : -1).map((c: any) => {
                            return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>

                  <Grid item xs={6} sx={gridItemStyles}>
                    <Button sx={{ ...regularBtnStyles, mt: 1 }} onClick={this.doCreateNewUser.bind(this)}>Create New User</Button>
                  </Grid>

                  {newUser.createdCredentials && <Grid item xs={12} sx={gridItemStyles}>
                    <Typography variant='body1' sx={{ mb: 1, mt: 4 }}>Please provide the new user with the following credentials: </Typography>
                    <Box><TextField sx={{ mt: 1, mb: 1, width: '260px' }} id="standard-basic" label="Email" variant="standard" value={newUser.createdCredentials?.email || "email"} /></Box>
                    <Box>
                      <TextField sx={{ mt: 1, width: '260px' }} label="Password" type={showPassword.type} variant="standard" value={newUser.createdCredentials?.password || "4534534sdfsdfsdfsdfsdfsfsdfsdfsdfsd534"} />
                      <Box>
                        <FormControlLabel control={<MaterialCheckbox size='small' checked={showPassword.checked}
                          onChange={this.handleShowPassword.bind(this)}
                          sx={{}} />} label="show password" />
                      </Box>
                    </Box>
                    <Typography variant='body1' sx={{ mt: 2 }}>Note: Welcome and reset password emails have been sent to <b>{newUser.createdCredentials?.email}</b>.</Typography>
                  </Grid>}

                </Grid>
              </Grid>
            </AdminAccordion>}

          {/* Services */}
          {selected?.roles.indexOf("subscriptionAdmin") !== -1 &&
            <AdminAccordion title='Services'>
              <Grid container>
                {customerServices && customerServices.map(s =>
                  <Grid item xs={6} key={s.type} sx={{ mt: 1, mb: 1 }}>
                    <FormControlLabel control={<Switch checked={s.active} />} label={s.title}
                      onChange={this.onServiceActivityChange.bind(this, s.type)} />
                    {s.type === "KivaAnalytics" && s.configuration && <ServiceConfiguration>
                      <TextField label="Google Analytics 4 ID" variant="outlined" size="small"
                        value={(s.configuration as KivaAnalyticsConfiguration).googleAnalytics4Id}
                        onChange={this.onUpdateServiceConfiguration.bind(this, s.type, "googleAnalytics4Id", false)}
                        disabled={!s.active} />
                      <TextField label="Kiva Analytics ID" variant="outlined" size="small"
                        value={(s.configuration as KivaAnalyticsConfiguration).kivaAnalyticsId}
                        onChange={this.onUpdateServiceConfiguration.bind(this, s.type, "kivaAnalyticsId", false)}
                        disabled={!s.active} />
                    </ServiceConfiguration>}
                  </Grid>)}
              </Grid>
            </AdminAccordion>
          }
          {
            errorMessage.length > 0 && <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
          }
          {
            okMessage.length > 0 && <OkMessage>
              {okMessage}
            </OkMessage>
          }
        </div>
      }
      {loading && <LoadingModal />}
    </Box >
  }
}


export const AdminView = () => {
  const state = useCustomerState()
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const customerDispatch = useCustomerDispatch()
  const [metaState, metaDispatch] = useMetaStateValue()
  const [adminState, adminDispatch] = useAdminStateValue()
  const history = useHistory()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const params = ["meta-auth", "linkedInAds-auth", "displayvideo-auth", "searchConsole-auth", "googleAnalytics-auth", "googleAds-auth"]
    for (const param of params) {
      const authParam = urlParams.get(param);
      if (authParam === "failed") {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "The authorization has failed. Please check your configuration and your access rights and try again.", duration: null }))
        history.push('/admin')
        break;
      }
      if (authParam === "succeeded") {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Authorized successfully!", duration: 7000 }))
        if (param === "meta-auth") {
          metaDispatch(setMetaAssets({ ...metaState.metaAssets, openMetaAssetsDialog: true }))
        }else if (param === "linkedInAds-auth"){
          adminDispatch(setLinkedInAssets({ ...adminState.linkedInAssets, openAssetsDialog: true }))
        }else if (param === "googleAds-auth"){
          adminDispatch(setGoogleAdsConfig({ ...adminState.googleAdsConfig, openConfigDialog: true }))
        }
        history.push('/admin')
        break;
      }
    }

  }, [window.location.href])

  return <Admin selected={state.selected} customerDispatch={customerDispatch} customers={state.customers} alert={alert} alertDispatch={alertDispatch} metaState={metaState} metaDispatch={metaDispatch} adminState={adminState} adminDispatch={adminDispatch} />
}

const textFieldTextStyles = {
  mb: 0.3
}

const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}