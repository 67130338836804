import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Component, Fragment } from 'react';
import { HookReturnValue } from 'react-use-googlelogin/dist/types';
import styled from 'styled-components';
import { apiCreateCustomer, apiGetCustomers } from '../api/Customer';
import { CustomerTree } from '../api/Types';
import { AuthAction } from '../context/auth/AuthReducer';
import { selectCustomer, unselectCustomer } from '../context/customer/CustomerActions';
import { CustomersAction } from '../context/customer/CustomerReducer';
import { getURLParameter } from '../utils/Generators';
import { FontFamilies } from './Colors';


const Container = styled.div`
    display:flex;
    position:relative;
    min-width:5em;
    padding-bottom:5px;
`

const List = styled.div`
    display:flex;
    min-width:12em;
    margin-left: -5px;
    position:absolute;
    z-index: 1000;
    top:100%;
    flex-direction: column;
    background-color:#ffffff;
    border: 1px solid #fafafa;
    box-shadow: 3px 3px 2px rgba(0,0,0,0.2);
    border-radius:5px;
    max-height:30em;
    overflow-y:auto;
`

const HeadLink = styled.a`
    margin-left:0.5em;
    margin-right:0.5em;
    color: #7f7f7f;
    text-decoration: none;
    font-size: 16px;
    font-family: ${FontFamilies};
    font-weight: lighter;
    &:hover {
        color: #9f9f9f;
    }
    &:active {
        color: #000000;
    }
`

const Link = styled.a`
    display:grid;
    grid-template-columns: 1fr 2em;
    color: #7f7f7f;
    text-decoration: none;
    font-size: 15px;
    font-family: ${FontFamilies};
    font-weight: lighter;
    &:hover {
        color: #9f9f9f;
    }
    &:active {
        color: #000000;
    }
    padding:5px;
`


const ListItem = styled.div`
`


const CustomerFullName = styled.div`
  display: flex;
`

const SubListContainer = styled.div`
    margin-top:2px;
    margin-left:8px;
`


export type DropdownItem = {
  id: number
  title: string
}

export type DropdownProps = {
  authDispatch: React.Dispatch<AuthAction>,
  dispatch: React.Dispatch<CustomersAction>,
  googleAuth: HookReturnValue,
  customers: CustomerTree[],
  selected?: CustomerTree
  isAdmin: boolean
}

type DropdownState = {
  isOpen: boolean
  selected?: CustomerTree,
  createDialogOpen: boolean
  newCustomerName: string
  searchText: string
}

const findCustomer = (selected: string, customers: CustomerTree[]): CustomerTree | undefined => {
  for (const customer of customers) {
    if (customer.name === selected) {
      return customer
    }
  }
  for (const customer of customers) {
    const foundCustomer = findCustomer(selected, customer.children)
    if (foundCustomer) {
      return foundCustomer
    }
  }
  return
}

const getCustomerFullname = (id: number, customers: CustomerTree[]): { id: number, name: string }[] => {
  for (const customer of customers) {
    if (customer.id === id) {
      return [{ id, name: customer.name }]
    }
  }
  for (const customer of customers) {
    const subnames = getCustomerFullname(id, customer.children)
    if (subnames.length > 0) {
      return [{ id: customer.id, name: customer.name }].concat(subnames)
    }
  }
  return []
}

export class CustomersDropdown extends Component<DropdownProps, DropdownState> {
  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      isOpen: false,
      createDialogOpen: false,
      newCustomerName: "",
      searchText: ""
    };
    this.loadCustomers()
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  findCustomerFromTree(customers: CustomerTree[], id: number): CustomerTree | undefined {
    for (const c of customers) {
      if (c.id === id) {
        return c
      }
      const result = this.findCustomerFromTree(c.children, id)
      if (result) {
        return result
      }
    }
    return undefined
  }

  loadCustomers() {
    const { dispatch, authDispatch } = this.props
    apiGetCustomers().then((customers) => {
      if (customers) {
        dispatch({ type: "CUSTOMERS_LOADED", customers })
        const initialSelected = localStorage.getItem('selectedCustomer')
        const urlSelected = getURLParameter("customer")
        const selected = urlSelected ? findCustomer(urlSelected, customers) :
          (initialSelected ? findCustomer(initialSelected, customers) : undefined)
        this.onSelected(selected ? selected.id : customers[0]?.id)
      } else {
        dispatch({ type: "CUSTOMERS_ERROR", errorMessage: "NoCustomers" })
      }
    }).catch((_error) => {
      authDispatch({ type: 'LOGOUT' });
    })
  }

  onSelected(id: number) {
    const { customers, dispatch } = this.props
    const selected = this.findCustomerFromTree(customers, id)

    unselectCustomer(dispatch)
    if (selected) {
      localStorage.setItem('selectedCustomer', selected.name)
      this.setState({ isOpen: false, selected })
      selectCustomer(selected, dispatch)
    }
  }

  customerSearch(customer: CustomerTree, search: string): boolean {
    if (customer.name.indexOf(search) !== -1 || search.length === 0) {
      return true
    }
    for (const child of customer.children) {
      if (this.customerSearch(child, search)) {
        return true
      }
    }
    return false
  }

  outputList(customers: CustomerTree[], search: string, isAdmin: boolean) {
    return (<>
      {customers.sort((a, b) => a.name.localeCompare(b.name)).filter(c => this.customerSearch(c, search) || search.length === 0).map(c => {
        return (<Fragment key={c.id}>
          <ListItem>
            <Link href="#" onClick={this.onSelected.bind(this, c.id)}>
              {c.name}
              {isAdmin ? <FontAwesomeIcon icon={faFolderPlus} onClick={() => this.setState({ createDialogOpen: true })} /> : <div></div>}
            </Link>
          </ListItem>
          {c.children.length > 0 ? <SubListContainer>
            {this.outputList(c.children, search, isAdmin)}
          </SubListContainer> : null}
        </Fragment>)
      })
      }
    </>)
  }

  render() {
    const { selected, isOpen, createDialogOpen, newCustomerName, searchText } = this.state
    const { customers, isAdmin } = this.props
    return (<Container onBlur={(event) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        this.setState({ isOpen: false })
      }
    }}>
      <Dialog
        open={createDialogOpen}
        onClose={() => this.setState({ createDialogOpen: false })}
      >
        <DialogTitle>
          Create Customer
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to create new customer / department under {`"${selected?.name}"`}. Please give name for the new organization.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={newCustomerName}
            onChange={(event) => { this.setState({ newCustomerName: event.target.value }) }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => this.setState({ createDialogOpen: false })}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => {
            apiCreateCustomer(selected!.id, newCustomerName).then(() => {
              localStorage.setItem('selectedCustomer', newCustomerName)
              this.loadCustomers()
            })
            this.setState({ createDialogOpen: false })
          }}>Create</Button>
        </DialogActions>
      </Dialog>
      {selected ? <CustomerFullName>
        {getCustomerFullname(selected.id, customers).map((n, index) => <Fragment key={n.id}>
          {index > 0 && <div>/</div>}
          <HeadLink href="#" onClick={() => {
            if (selected.id === n.id && !isOpen) {
              this.setState({ isOpen: true })
            } else {
              this.onSelected(n.id)
            }

          }}>{n.name}</HeadLink>
        </Fragment>)}
      </CustomerFullName> : "<Select>"}
      {isOpen ? <List>
        <TextField label="Search" value={searchText} size="small" variant="standard"
          style={{ marginLeft: '0.3em', marginBottom: '1.0em', marginRight: '0.3em' }}
          onChange={(evt) => { this.setState({ searchText: evt.target.value }) }} />
        {this.outputList(customers, searchText, isAdmin)}
      </List> : null}
    </Container>)
  }
}
