import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { regularBtnStyles } from '../../styles/buttons'
import { useAlertStateValue } from '../../context/alert/AlertContext'
import { setAlert } from '../../context/alert/AlertAction'
import { AlertSeverity } from '../../context/alert/AlertTypes'
import { apiOAuthGoogleAdsLogin } from '../../api/Auth'
import { useAdminStateValue } from '../../context/admin/AdminContext'
import SelectGoogleAdsConfigDialog from './SelectGoogleAdsConfigDialog'
import { LoadingModal } from '../Modal'
import { desktop_h3, desktop_p2, montserratBoldFontFamily, montserratSemiBoldFontFamily } from '../../styles/textStyles'

interface GoogleAdsAdminProps {
  customerId: number
}
const GoogleAdsAdmin = ({ customerId }: GoogleAdsAdminProps) => {
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [loading, setLoading] = useState(false)
  const [{ googleAdsConfig }, dispatch] = useAdminStateValue()

  const isGoogleAdsConfigured = googleAdsConfig.savedSettingsIntoDB?.loginAccount.id && googleAdsConfig.savedSettingsIntoDB?.adAccounts.length > 0

  const doGoogleAdsLogin = () => {
    if (customerId) {
      apiOAuthGoogleAdsLogin(customerId).then((response) => {
        window.location.href = response.url
      }).catch(error => {
        console.log(error)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in authentication. Please try again later.", duration: 7000 }))
      })
    }
  }
  return (
    <Grid container>
      {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
        <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sx={{ pt: 0 }}>
            <Button sx={{ ...regularBtnStyles, mt: 1 }} onClick={doGoogleAdsLogin}>Authorize Ads</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={isGoogleAdsConfigured ? {...desktop_h3, fontFamily: montserratBoldFontFamily} : {...desktop_p2}}>{isGoogleAdsConfigured ? 'Current configuration' : 'Not configured yet.'}</Typography>
          </Grid>

          {isGoogleAdsConfigured && <>
            <Grid item xs={6} sx={{ mb: 2 }}>
              <TextField fullWidth size='small' label="Login ad account (MMC)" variant="outlined" value={`${googleAdsConfig.savedSettingsIntoDB.loginAccount.name} - ${googleAdsConfig.savedSettingsIntoDB.loginAccount.id}`} />
            </Grid>
            <Grid item xs={6} sx={{ mb: 2 }}>
              {
                googleAdsConfig.savedSettingsIntoDB.adAccounts.length > 0 && googleAdsConfig.savedSettingsIntoDB.adAccounts.map((a) => (
                  <TextField sx={{mb: 2}} fullWidth size='small' label="Ad account" variant="outlined" value={`${a.name} - ${a.id}`} />
                ))
              }

            </Grid>

            <Grid item xs={12}>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{...desktop_h3, mb: 1, fontFamily: montserratBoldFontFamily}}>Sub-accounts</Typography>
                </Grid>
                {googleAdsConfig.savedSettingsIntoDB.childCustomers.length < 1 && <Grid item xs={12}>
                  <Typography sx={{...desktop_p2}}>Not found.</Typography>
                </Grid>}

                {googleAdsConfig.savedSettingsIntoDB.childCustomers.length > 0 && <Grid item xs={12} sx={gridItemStyles}>
                  <Grid container sx={{ maxWidth: 800 }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                    {googleAdsConfig.savedSettingsIntoDB.childCustomers.sort().map((c: any) =>

                      <Grid item xs={6} key={c.id} sx={gridItemStyles}>
                        <Typography sx={{...desktop_p2, mb: 2, fontFamily: montserratSemiBoldFontFamily}}>{c.name}</Typography>
                        {c.googleAdsSettings.adAccounts.length > 0 ? c.googleAdsSettings.adAccounts.map((a: any) =>
                        (<TextField sx={{mb: 2}} fullWidth size='small' label="Ad account" variant="outlined" value={`${a.name} - ${a.id}`} />)

                        ) 
                        : <Typography>-</Typography> }
                      </Grid>

                    )}</Grid>
                </Grid>}

              </Grid>

            </Grid>

          </>}
          <Grid item xs={6}>
            <SelectGoogleAdsConfigDialog isOpen={googleAdsConfig.openConfigDialog} />
          </Grid>
        </Grid>
      </Grid>}
    </Grid>)
}

const textFieldTextStyles = {
  mb: 0.3
}

const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}

export default GoogleAdsAdmin