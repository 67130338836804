


import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NotificationAttribute, NotificationChannel, NotificationRule, Operator, Interval, MetaNotificationAttribute } from '../api/Types';
import { maxStringLength } from '../utils/Generators';
import { plusIcon, trashIcon } from '../utils/icons';
import { desktop_h3, desktop_p1, desktop_p2, montserratBoldFontFamily, montserratSemiBoldFontFamily } from '../styles/textStyles';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { regularBtnStyles } from '../styles/buttons';
import CheckboxListAllEmptyArray from './Ads/CheckboxListAllEmptyArray';
import CheckboxList from './CheckboxList';

const RuleRow = styled.div`
  box-sizing: border-box;
  display:flex;
  flex-direction: row;
  column-gap:5px;
  width: calc(100% - 35px);
  row-gap:10px;
  border-radius:1.0em;
`

const RuleItem = styled.div`
  margin-top:0.2em;
  padding-top:0.25em;
  padding-bottom:0.25em;
  padding-left:0.5em;
  padding-right:0.25em;
  border: 1px solid lightgray;
  border-radius:5px;
  font-size:0.9em;
  color: #3f3f3f;
  overflow-x:hidden;
`


export type NumericRule = {
  channel: string
  attribute: string
  operator: string
  value: number
  receivers: string[]
}

export type LabelValueUnit = {
  label: string
  value: string | number
  unit?: string,
  attributes?: LabelValueUnit[]
}

export type RuleEditorProps = {
  channels: LabelValueUnit[]
  attributes: LabelValueUnit[],
  operators: { label: string, value: string }[]
  receivers: string[]
  rules: NotificationRule[]
  onRulesChange: (rules: NotificationRule[]) => void
}



export const RuleEditor = (props: RuleEditorProps) => {
  const { attributes, operators, receivers, rules, channels, onRulesChange } = props

  const [allAttributes, setAllAttributes] = useState([attributes].concat([ { label: "Post Booster Ads", value: "postBoosterAds" }]).concat(channels.map(ch => ch.attributes || [])).flat())
  const [channel, setChannel] = useState<NotificationChannel>(channels[0].value as NotificationChannel)
  const [channelAttributes, setChannelAttributes] = useState(attributes)
  const [attribute, setAttribute] = useState<NotificationAttribute | MetaNotificationAttribute>(attributes[0].value as NotificationAttribute | MetaNotificationAttribute)
  const [operator, setOperator] = useState<Operator>(operators[0].value as Operator)
  const [value, setValue] = useState<number>(1)
  const [interval, setInterval] = useState<Interval>("Daily")
  const [ruleReceivers, setRuleReceivers] = useState<string[]>([])

  useEffect(() => {
    const channelDef = channels.find(ch => ch.value === channel)
    console.log("channelDef", channelDef)
    if (channelDef) {
      const attrs = channelDef.attributes || (channelDef.label !== "Meta" ? attributes : [...attributes, { label: "Post Booster Ads", value: "postBoosterAds" } ])
      setChannelAttributes(attrs)
      setAttribute(attrs[0].value as NotificationAttribute | MetaNotificationAttribute)
    }
  }, [channel])

  useEffect(() => {
    if (attribute === "postBoosterAds") {
      setInterval("OnAdCreationSuccessOrFailure")
    }
  }, [attribute])


  return (
  <Box>
  <Grid xs={12} container spacing={2}>
    <Grid item xs={2}>
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id={"channel-label-id"}>Channel</InputLabel>
      <Select key={"channel"} label="Channel" labelId="channel-label-id" defaultValue={channels[0].value}
        value={channel} variant={"outlined"} onChange={(event) => setChannel(event.target.value as NotificationChannel)}>
        {channels.map((attr, index) => <MenuItem key={index} value={attr.value}>{attr.label}</MenuItem>)}
      </Select>
    </FormControl>
    </Grid>
    <Grid item xs={2}>
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id={"attribute-label-id"}>Attribute</InputLabel>
      <Select key={"attributes"} label="Attribute" labelId="attribute-label-id" defaultValue={channelAttributes[0].value}
        value={attribute} variant={"outlined"} onChange={(event) => setAttribute(event.target.value as NotificationAttribute)}>
        {channelAttributes.map((attr, index) => <MenuItem key={index} value={attr.value}>{attr.label}</MenuItem>)}
      </Select>
    </FormControl>
    </Grid>
   {attribute !== "postBoosterAds" && <Grid item xs={1.5}><FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id={"operator-label-id"}>Operator</InputLabel>
      <Select key={"operators"} label={"Operator"} labelId="operator-label-id" defaultValue={operators[0].value}
        value={operator} variant={"outlined"} onChange={(event) => setOperator(event.target.value as Operator)}>
        {operators.map((attr, index) => <MenuItem key={index} value={attr.value}>{attr.label}</MenuItem>)}
      </Select>
    </FormControl></Grid>}

    {attribute !== 'postBoosterAds' && <Grid item xs={1}><TextField size="small" sx={{ width: '100%' }} key={"input"} type="number" label={`${channelAttributes.find(a => a.value === attribute)?.unit || 'Value'}`} defaultValue={1} value={value} variant={"outlined"}
      onChange={(event) => setValue(parseInt(event.target.value, 10))} /></Grid>
    }
    <Grid item xs={2}>
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id={"interval-label-id"}>Interval</InputLabel>
      <Select key={"intervals"} label="Interval" labelId="interval-label-id" defaultValue={"daily"}
        value={attribute === "postBoosterAds" ? "OnAdCreationSuccessOrFailure" : interval} variant={"outlined"} onChange={(event) => setInterval(event.target.value as Interval)}>
        <MenuItem key={1} disabled={attribute === 'postBoosterAds'} value={"Hourly"}>Hourly</MenuItem>
        <MenuItem key={2} disabled={attribute === 'postBoosterAds'} value={"Daily"}>Daily</MenuItem>
        <MenuItem key={3} disabled={attribute === 'postBoosterAds'} value={"Weekly"}>Weekly</MenuItem>
        <MenuItem key={4} disabled={attribute === 'postBoosterAds'} value={"Monthly"}>Monthly</MenuItem>
        <MenuItem key={5} disabled={attribute !== 'postBoosterAds'} value={"OnAdCreationSuccessOrFailure"}>On ad creation success or failure</MenuItem>
      </Select>
    </FormControl>
    </Grid>
    <Grid item xs={2}>
      <CheckboxList title='Receivers' allItems={receivers.map(r => ({label: r, value: r}))} items={ruleReceivers} updateItems={(items: string[]) => setRuleReceivers(items)} />
    </Grid>
    <Grid item xs={1.5}>
    <Button sx={{...regularBtnStyles}} onClick={() => {
      onRulesChange(rules.concat([{
        type: "NotificationRule",
        channel, attribute, operator, value, receivers: ruleReceivers,
        interval
      }]))
    }}>{plusIcon()}</Button>
    </Grid>
  </Grid>

  <Grid sx={{ mt: 4, mb: 4}}>
    <Divider/>
  </Grid>

  <Box>
    <Typography variant='h3' sx={{...desktop_h3, mt: 4, mb: 4}}>Active rules</Typography>
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Channel</TableCell>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Attribute</TableCell>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Operator</TableCell>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Value</TableCell>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Interval</TableCell>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Receivers</TableCell>
              <TableCell sx={{...desktop_p2, fontFamily: montserratSemiBoldFontFamily}}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules?.map((r, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {channels.find(c => c.value === r.channel)?.label}
                </TableCell>
                <TableCell>{allAttributes.find(a => a.value === r.attribute)?.label}</TableCell>
                <TableCell>{r.attribute !== 'postBoosterAds' ? operators.find(o => o.value === r.operator)?.label : "-"}</TableCell>
                {r.attribute !== 'postBoosterAds' && <TableCell>{r.value}&nbsp;{allAttributes.find(a => a.value === r.attribute)?.unit || ""}</TableCell>}
                {r.attribute === 'postBoosterAds' && <TableCell>-</TableCell>}
                <TableCell>{r.interval}</TableCell>
                <TableCell>
                  <Tooltip title={<>{r.receivers.map(r => <div>{r}</div>)}</>}>
                    <Box>{maxStringLength(r.receivers.join(","), 25)}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                <IconButton onClick={() => {
                  onRulesChange(rules.filter((_, currIndex) => currIndex !== index))
                }}>{trashIcon()}</IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    
  </Box>
  </Box>)
}