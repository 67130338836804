import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { Checkbox, CircularProgress, Divider, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { useCustomerState } from '../../context/customer/CustomerContext';
import { useAdminStateValue } from '../../context/admin/AdminContext';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setGoogleAdsConfig } from '../../context/admin/AdminActions';
import { GoogleAdsChildCustomer, GoogleAdsCustomerClient, GoogleAdsLoginAccount, GoogleAdsSettings } from '../../context/admin/AdminTypes';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { apiSaveGoogleAdsSettings } from '../../api/Customer';
import { setAlert } from '../../context/alert/AlertAction';
import { apiGetUserAssets } from '../../api/Auth';
import MultipleSelectChip from './MultipleSelectChip';
import { desktop_h3, desktop_p1 } from '../../styles/textStyles';
import { regularBtnStyles, whiteBlackBorderBtnStyles } from '../../styles/buttons';
import { primaryColors } from '../../styles/colors';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectLinkedInAssetsDialogProps {
  isOpen: boolean
}

export default function SelectLinkedInAssetsDialog({ isOpen }: SelectLinkedInAssetsDialogProps) {
  const [open, setOpen] = React.useState(isOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const customerState = useCustomerState()
  const [{ googleAdsConfig }, dispatch] = useAdminStateValue()
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [childCustomers, setChildCustomers] = useState<GoogleAdsChildCustomer[]>([])
  const [items, setItems] = useState<string[]>([])

  useEffect(() => {
    if (customerState?.selected?.id && isOpen) {
      apiGetUserAssets(customerState.selected.id.toString()).then((result) => {
        console.log("apiGetUserAssets result", result)
        dispatch(setGoogleAdsConfig({ ...googleAdsConfig, loginAccounts: result.loginAccounts }))
      })
    }
  }, [customerState?.selected?.id])

  useEffect(() => {
    if (customerState?.selected && googleAdsConfig.currentSettings.loginAccount.id) {
      const childCs: GoogleAdsChildCustomer[] = customerState.selected.children.map(c => {
        return {
          id: c.id.toString(),
          name: c.name,
          googleAdsSettings: {
            adAccounts: [],
            loginAccount: {
              id: googleAdsConfig.currentSettings.loginAccount.id,
              name: googleAdsConfig.currentSettings.loginAccount.name
            }
          }
        }
      })
      setChildCustomers(childCs)
    }
  }, [customerState?.selected, googleAdsConfig.currentSettings.loginAccount])

  const handleCancel = () => {
    setOpen(false)
  }

  const handleLoginAccountChange = (e: SelectChangeEvent<string | number>) => {
    const loginAccount: GoogleAdsLoginAccount | undefined = googleAdsConfig.loginAccounts.find(i => i.id === e.target.value)
    if (loginAccount) {
      dispatch(setGoogleAdsConfig({
        ...googleAdsConfig,
        currentSettings: {
          ...googleAdsConfig.currentSettings,
          loginAccount: loginAccount,
          adAccounts: []
        }
      }))

      const clientCustomerStrings: string[] = loginAccount.customerClients.map((c: GoogleAdsCustomerClient)=> {
        return { str: `${c.descriptiveName} (id: ${c.id})` };
      }).map((i: { str: string }) => i.str);

      setItems(clientCustomerStrings)
    }
  }

  const handleSelectedAdAccounts = (adAccounts: string[]) => {
    const ids = adAccounts.map(a => a.split("(id: ")[1].split(")")[0])
    const aAccounts = googleAdsConfig.currentSettings.loginAccount.customerClients?.filter((c: GoogleAdsCustomerClient) => ids.includes(c.id))

    dispatch(setGoogleAdsConfig({
      ...googleAdsConfig,
      currentSettings: {
        ...googleAdsConfig.currentSettings,
        adAccounts: aAccounts?.map(a => { return { id: a.id, name: a.descriptiveName } }) || [],
      }
    }))
  }

  const handleSave = () => {
    setSaveBtnProgress(true)
    if (customerState?.selected?.id && googleAdsConfig.currentSettings.loginAccount.id && googleAdsConfig.currentSettings.adAccounts.length > 0) {
      const googleAdsSettings: GoogleAdsSettings = {
        loginAccount: { id : googleAdsConfig.currentSettings.loginAccount.id, name: googleAdsConfig.currentSettings.loginAccount.name },
        adAccounts: googleAdsConfig.currentSettings.adAccounts,
        childCustomers: googleAdsConfig.currentSettings.childCustomers
      }
      apiSaveGoogleAdsSettings(customerState?.selected?.id, googleAdsSettings).then((result: {success: boolean, message?: any}) => {
        setSaveBtnProgress(false)
        if (result?.success) {
          setOpen(false);
          dispatch(setGoogleAdsConfig({ ...googleAdsConfig, savedSettingsIntoDB: googleAdsConfig.currentSettings, openConfigDialog: false }))
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Configured successfully!", duration: 7000 }))
        } else {
          console.log("apiSaveGoogleAdsSettings error", result)
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
        }
      }).catch((error: any) => {
        console.log("apiSaveGoogleAdsSettings error", error)
        setSaveBtnProgress(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
      })
    }
  };

  const handleChildCustomerSelectedAdAccounts = (adAccounts: string[], id: string) => {

    const ids = adAccounts.map(a => a.split("(id: ")[1].split(")")[0])
    const aAccounts = googleAdsConfig.currentSettings.loginAccount.customerClients?.filter((c: GoogleAdsCustomerClient) => ids.includes(c.id))
    const selectedAccounts = aAccounts?.map(a => { return { id: a.id, name: a.descriptiveName } }) || []
    const updatedChildCustomers = childCustomers.map(c => {
      if(c.id === id){
        return {...c, googleAdsSettings: { ...c.googleAdsSettings, adAccounts: selectedAccounts } }
      }
      return c
    })
    setChildCustomers(updatedChildCustomers)

    dispatch(setGoogleAdsConfig({
      ...googleAdsConfig,
      currentSettings: {
        ...googleAdsConfig.currentSettings,
        childCustomers: updatedChildCustomers,
      }
    }))

  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="">
          Google Ads Configuration
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
            </Grid>

            <Grid item xs={12} sx={{}}>
              <Typography variant='h3' sx={{...desktop_h3, mb:1}}>Login Ad Account</Typography>
              <Typography variant='body1' sx={{mb:3}}>Select login ad account (MMC) that you would like to use for the selected customer</Typography>
              <Grid item xs={12}>
                <FormControl size='small' variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel id="google-login-ad-account">Login ad account (MMC)</InputLabel>
                  <Select
                    labelId="google-login-ad-account"
                    id="google-login-ad-account"
                    value={googleAdsConfig.currentSettings.loginAccount.id}
                    onChange={handleLoginAccountChange}
                    label="Login ad account (MMC)"
                  >
                    {googleAdsConfig.loginAccounts?.map((a) =>
                      <MenuItem sx={{ fontSize: '14px' }} key={a.id} value={a.id}>{a.name} (id: {a.id})</MenuItem>
                    )}

                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
              <Divider />
            </Grid>

            <Grid item xs={12} sx={{}}>
              <Typography variant='h3' sx={{...desktop_h3, mb:1}}>Ad Accounts</Typography>
              <Typography variant='body1' sx={{mb:3}}>Select ad accounts that you would like to use for the selected customer</Typography>
            <Grid item xs={12}>
                <MultipleSelectChip title='Ad accounts' items={items} setSelectedItems={handleSelectedAdAccounts}/>
            </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
              <Divider />
            </Grid>

            <Grid item xs={12} sx={{}}>
              <Typography variant='h3' sx={{...desktop_h3, mb:1}}>Child Customers Ad Accounts</Typography>
              <Typography variant='body1' sx={{mb:2}}>Select ad accounts that you would like to use for each child customer</Typography>
            </Grid>
            {customerState?.selected?.children && customerState?.selected?.children.length > 0 && customerState?.selected?.children.map(c => (
              <Grid item xs={12} sx={{mb: 1}}>
                <Typography sx={{...desktop_p1, mb:1}}>{c.name}</Typography>
                <MultipleSelectChip title='Ad accounts' items={items} setSelectedItems={(adAccounts: string[]) => handleChildCustomerSelectedAdAccounts(adAccounts, c.id.toString())}/>
              </Grid>
            ))}
            {(!customerState?.selected?.children || customerState?.selected?.children.length < 1) &&
              <Grid item xs={12} sx={{mb: 1}}>               
                <Typography sx={{...desktop_p1}}>No child customers.</Typography>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button sx={{ ...whiteBlackBorderBtnStyles, padding: '8.5px 22px', }} variant='outlined' onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{ ...regularBtnStyles }} variant='contained' disabled={googleAdsConfig.currentSettings.loginAccount.id && googleAdsConfig.currentSettings.adAccounts.length > 0 ? false : true} onClick={handleSave}>
            {saveBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: primaryColors[950] }} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}