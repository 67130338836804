import React, { createContext, useReducer, useContext } from 'react';
import { initialAlertState } from './AlertReducer';
import { AlertState, AlertAction } from './AlertTypes';

export const StateContext = createContext<[AlertState, React.Dispatch<AlertAction>]>([
  initialAlertState, 
  () => initialAlertState
])

export type MetaProviderProps = {
  reducer: React.Reducer<AlertState, AlertAction>
  children: React.ReactElement
}

export const AlertProvider = ({reducer, children}: MetaProviderProps) => {
  const [alertState, dispatch] = useReducer(reducer, initialAlertState);
  return (
    <StateContext.Provider value={[alertState, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}
export const useAlertStateValue = () => useContext(StateContext);