import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { desktop_h2, desktop_p1, montserratBoldFontFamily } from '../styles/textStyles';

interface AdminAccordionProps {
  title: string
  children: React.ReactNode
}
export const AdminAccordion = ({ title, children }: AdminAccordionProps) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant='h2' sx={{...desktop_p1, fontFamily: montserratBoldFontFamily, margin:'12px 0'}}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}