import { rootUrl } from '../../api/Types';
import { LogoutOptions } from '@auth0/auth0-react';
import { Dispatch } from "react";
import { apiDoAuth0Login, apiDoAuth0Logout } from "../../api/Auth";
import { AuthAction } from "./AuthReducer";


export async function loginUserAuth0(dispatch: Dispatch<AuthAction>) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    const redirectRes = await apiDoAuth0Login()
    window.location.href = redirectRes.data.url
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: 'Auth0LoginError' });
  }
}

export async function auth0Logout(logout: (options?: LogoutOptions | undefined) => void, dispatch: any) {
  logout({
    logoutParams: {
      returnTo: `${rootUrl}/login`,
    },
  });
  await apiDoAuth0Logout()
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
}