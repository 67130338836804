import { Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { getAllCampaigns } from '../../../api/Marketing'
import { Colors } from '../../../constants/colors'
import { useCustomerState } from '../../../context/customer/CustomerContext'
import { setAdAutomationSettingsErrors, setAdvancedPostBoosting, setAdvancedPostBoostingErrors } from '../../../context/meta/MetaActions'
import { useStateValue } from '../../../context/meta/MetaContext'
import { initialCampaign } from '../../../context/meta/MetaReducer'
import { BudgetLevel, NewOrExsitingCampaing } from '../../../context/meta/MetaTypes'
import { beautifyString, capitalizeFirstLetter, convertToFinnishDate } from '../../../utils/helpers'
import CampaignInfo from '../sharedMetaComponents/CampaignInfo'
import CampaignDetails from './AddPostsDialog/CampaignDetails'
import { seedCampaigns } from './seedCampaigns';
import CircleIcon from '@mui/icons-material/Circle';


const ChooseCampaignOrCreateNew = () => {
  const [{ allPosts, matchedPosts, advancedPostBoosting, adAutomationSettings, advancedPostBoostingErrors }, dispatch] = useStateValue()

  //const [newOrExsitingCampaing, setNewOrExsitingCampaing] = useState<string>('existing-campaign')
  const [allCampaigns, setAllCampaigns] = useState<any[]>([])
  const [campaigns, setCampaigns] = useState<any[]>([])
  const [campaignsByKiva, setCampaignsByKiva] = useState<any[]>([])
  const [rbValue, setRbValue] = useState('all')


  const customerState = useCustomerState()
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>(advancedPostBoosting.campaign.id ? advancedPostBoosting.campaign.id : '')
  const [selectedCampaign, setSelectedCampaign] = useState<any>(advancedPostBoosting.campaign.id ? advancedPostBoosting.campaign : {})

  const [ruleName, setRuleName] = useState(advancedPostBoosting.name)
  const [ruleNameError, setRuleNameError] = useState(false)

  useEffect(() => {
    customerState?.selected?.id && getAllCampaigns(customerState?.selected?.id).then((result) => {
      setAllCampaigns(result.allCampaigns)
      setCampaigns(result.allCampaigns)
      setCampaignsByKiva(result.allCampaigns.filter((c: any) => c.createdByPostBooster))
    }).catch(error => {
      setAllCampaigns([])
      setCampaigns([])
      setCampaignsByKiva([])
      console.error(error)
    });
  }, [customerState])

  const handleRuleNameChange = (e: any) => {
    const n = e.target.value
    const ruleNameError = "Rule name should be at least 3 characters"
    setRuleName(n)
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, name: n }))
    if (n && n.length >= 3) {
      setRuleNameError(false)
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== ruleNameError)))
    } else {
      setRuleNameError(true)
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== ruleNameError).concat([ruleNameError])))
    }
  }


  const handleCampaignSelection = (e: SelectChangeEvent<any>) => {
    const id: string = e.target.value
    setSelectedCampaignId(id)
    const campaign = allCampaigns.find((c: any) => c.id.toString() === id.toString())
    setSelectedCampaign(campaign)
    if (Number(campaign?.lifetime_budget) > 0 || Number(campaign?.daily_budget) > 0) {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, campaign: campaign, budgetProps: { ...advancedPostBoosting.budgetProps, budgetLevel: BudgetLevel.CAMPAIGN_LEVEL } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, campaign: campaign, budgetProps: { ...advancedPostBoosting.budgetProps, budgetLevel: BudgetLevel.AD_SET_LEVEL } }))
    }
  }

  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v: string = e.target.value
    setRbValue(v);
    setSelectedCampaignId('')
    setSelectedCampaign({})

    if (v === 'byadmanager') {
      const adManagerCampaigns = allCampaigns.filter((cam: any) => !cam.createdByPostBooster).filter(c => !c.isAdAutomationCampaign)
      setCampaigns(adManagerCampaigns)

    } else if (v === 'bykiva') {
      const kivaCampaigns = allCampaigns.filter((cam: any) => cam.createdByPostBooster)
      setCampaigns(kivaCampaigns)
    } else {
      setCampaigns(allCampaigns)
    }
  }



  const handleCreateOrUseExistingCampaign = (e: ChangeEvent<HTMLInputElement | any>) => {
    const value: string = e.target.value
    //setNewOrExsitingCampaing(value)
    if (value === NewOrExsitingCampaing.NEW_CAMPAIGN) {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, newOrExistingCampaign: value, campaign: initialCampaign }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, newOrExistingCampaign: value }))
    }

  }
  return (
    <Box sx={{ mt: 5 }}>

      <Grid item xs={12} sx={{ mb: 3 }}>
        <Typography variant='h6' sx={{}}>Rule name</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="standard-basic"
          label="Post boosting rule name"
          name='Post boosting rule name'
          variant="standard"
          fullWidth
          value={ruleName}
          onChange={handleRuleNameChange}
          error={ruleNameError}
          helperText={ruleNameError ? "Enter at least 3 characters." : ""}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 5, mt: 7 }}>
        <Divider />
      </Grid>

      <Grid item xs={12} sx={{ mb: 3 }}>    <Typography variant='h6' sx={{}}>Campaign</Typography></Grid>

      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1' sx={{ mb: 1, color: 'rgba(0, 0, 0, 0.6)' }}>Campaign</Typography>
        <ToggleButtonGroup
          color="primary"
          value={advancedPostBoosting.newOrExistingCampaign}
          exclusive
          onChange={handleCreateOrUseExistingCampaign}
          aria-label="Platform"
        >
          <ToggleButton sx={{ width: '120px', textTransform: 'none' }} value={NewOrExsitingCampaing.NEW_CAMPAIGN}>Create new</ToggleButton>
          <ToggleButton sx={{ width: '120px', textTransform: 'none' }} value={NewOrExsitingCampaing.EXISTING_CAMPAIGN}>Use existing</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {advancedPostBoosting.newOrExistingCampaign === NewOrExsitingCampaing.NEW_CAMPAIGN && <CampaignInfo />}
      {advancedPostBoosting.newOrExistingCampaign === NewOrExsitingCampaing.EXISTING_CAMPAIGN &&
        <Grid container sx={{ maxWidth: 600, mt: '16px' }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Campaigns</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={rbValue}
                onChange={handleRadioButtonChange}
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel value="byadmanager" control={<Radio />} label="by Ads Manager" />
                <FormControlLabel value="bykiva" control={<Radio />} label="by Kiva Dashboard" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="campaign-objective-select-label">Campaign</InputLabel>
              <Select
                labelId="campaign-objective-select-label"
                id="campaign-objective"
                value={selectedCampaignId}
                onChange={handleCampaignSelection}
                label="Budget type"
              >
                {campaigns.length > 0 && campaigns.map((c: any) => {
                  return <MenuItem disabled={c.isAdAutomationCampaign}
                    sx={{ fontSize: '14px' }}
                    key={c.id}
                    value={c.id}>
                    <CircleIcon sx={{ fontSize: '12px', marginRight: '7px', color: c.status === "PAUSED" ? Colors.yellow : Colors.green }} />
                    {`${c.isAdAutomationCampaign ? 'Ad automation | ' : ''} ${c.name}`}
                    <span style={{ marginLeft: '4px', color: "#808080" }}> | {c.stop_time ? ` Until ${convertToFinnishDate(c.stop_time)}` : ' Ongoing'}
                      {(c.lifetime_budget || c.daily_budget) && ' | Campaign level budget'}
                    </span>
                  </MenuItem>
                })}
              </Select>

              {selectedCampaign.id && <Grid container rowSpacing={4} sx={{ mt: 0.5 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">Campaign details</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={styles.body2} variant='body2'>
                    Status
                  </Typography>
                  <Typography style={styles.caption} variant='caption' sx={{ color: selectedCampaign.status === "PAUSED" ? Colors.yellow : Colors.green }}>
                    {capitalizeFirstLetter(selectedCampaign.status)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={styles.body2} variant='body2' sx={{}}>
                    Objective
                  </Typography>
                  <Typography style={styles.caption} variant='caption'>
                    {beautifyString(selectedCampaign.objective)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={styles.body2} variant='body2'>
                    Created time
                  </Typography>
                  <Typography style={styles.caption} variant='caption'>
                    {convertToFinnishDate(selectedCampaign.created_time)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={styles.body2} variant='body2'>
                    End time
                  </Typography>
                  <Typography style={styles.caption} variant='caption'>
                    {selectedCampaign.stop_time && convertToFinnishDate(selectedCampaign.stop_time)}
                    {!selectedCampaign.stop_time && '-'}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={styles.body2} variant='body2'>
                    Budget
                  </Typography>
                  <Typography style={styles.caption} variant='caption'>
                    {selectedCampaign.lifetime_budget && `${selectedCampaign.lifetime_budget / 100} € (Lifetime)`}
                    {selectedCampaign.daily_budget && `${selectedCampaign.daily_budget / 100} € (Daily)`}
                    {!selectedCampaign.lifetime_budget && !selectedCampaign.daily_budget && "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={styles.body2} variant='body2'>
                    Bid strategy
                  </Typography>
                  <Typography style={styles.caption} variant='caption'>
                    {selectedCampaign.bid_strategy && beautifyString(selectedCampaign.bid_strategy)}
                    {!selectedCampaign.bid_strategy && "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>}

            </FormControl>
          </Grid>
        </Grid>}
    </Box>
  )
}


const styles = {
  body1: {
    margin: '10px auto',
    color: '#000'
  },
  body2: {
    margin: '10px auto'
  },
  caption: {
    color: '#7d7d7d'
  },
}

export default ChooseCampaignOrCreateNew