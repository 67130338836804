import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { apiGetAssetsUserHasAccessTo, apiSaveChosenMetaAssets } from '../api/Marketing';
import { useCustomerState } from '../context/customer/CustomerContext';
import { useStateValue } from '../context/meta/MetaContext';
import { MetaAssets } from '../context/meta/MetaTypes';
import { setMetaAssets } from '../context/meta/MetaActions';
import { useAlertStateValue } from '../context/alert/AlertContext';
import { setAlert } from '../context/alert/AlertAction';
import { AlertSeverity } from '../context/alert/AlertTypes';

interface ChooseMetaAssetsDialogProps {
  isOpen: boolean
}

export default function ChooseMetaAssetsDialog({ isOpen }: ChooseMetaAssetsDialogProps) {
  const [open, setOpen] = React.useState(isOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const customerState = useCustomerState()
  const [{ metaAssets }, dispatch] = useStateValue()
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [{ alert }, alertDispatch] = useAlertStateValue()

  useEffect(() => {
    if (customerState?.selected?.id && isOpen) {
      apiGetAssetsUserHasAccessTo(customerState?.selected?.id).then((result) => {
        dispatch(setMetaAssets({ ...metaAssets, userAdAccounts: result.adAccounts, userFacebookPages: result.facebookPages }))
      })
    }
  }, [customerState.selected])

  const handleFacebookPageChange = (e: any) => {
    dispatch(setMetaAssets({
      ...metaAssets,
      currentAssets: {
        ...metaAssets.currentAssets,
        facebookPageId: e.target.value,
        facebookPageName: metaAssets.userFacebookPages.find((p: any) => p.id === e.target.value).name
      }
    }))
  }

  const handleAdAccountChange = (e: SelectChangeEvent<string>) => {
    dispatch(setMetaAssets({
      ...metaAssets,
      currentAssets: {
        ...metaAssets.currentAssets,
        adAccountId: e.target.value,
        adAccountName: metaAssets.userAdAccounts.find((a: any) => a.account_id === e.target.value).name
      }
    }))
  }

  const handleSave = () => {
    setSaveBtnProgress(true)
    if (customerState?.selected?.id && metaAssets.currentAssets.facebookPageId && metaAssets.currentAssets.adAccountId) {
      apiSaveChosenMetaAssets(customerState?.selected?.id, metaAssets.currentAssets).then(result => {
        setSaveBtnProgress(false)
        if (result?.success) {
          setOpen(false);
          dispatch(setMetaAssets({ ...metaAssets, savedAssetsIntoDB: metaAssets.currentAssets, openMetaAssetsDialog: false }))
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Assets saved successfully!", duration: 7000 }))
        } else {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
        }
      }).catch((error: any) => {
        console.log(error)
        setSaveBtnProgress(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
      })
    }
  };

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="">
          Assets to Use
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
            </Grid>

            <Grid item xs={12} sx={{}}>
              <Typography variant='body1' sx={{}}>Choose the Facebook page and ad account that you would like to use for the selected customer</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl size='small' variant="outlined" sx={{ width: '100%' }}>
                <InputLabel id="ad-automation-status-select-label">Facebook page</InputLabel>
                <Select
                  labelId="ad-automation-status-select-label"
                  id="ad-automation-status"
                  value={metaAssets.currentAssets.facebookPageId}
                  onChange={handleFacebookPageChange}
                  label="Facebook Page"
                >
                  {metaAssets.userFacebookPages?.map((p: any) =>
                    <MenuItem sx={{ fontSize: '14px' }} key={p.id} value={p.id}>{p.name}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl size='small' variant="outlined" sx={{ width: '100%' }}>
                <InputLabel id="ad-automation-status-select-label">Ad account</InputLabel>
                <Select
                  labelId="ad-automation-status-select-label"
                  id="ad-automation-status"
                  value={metaAssets.currentAssets.adAccountId}
                  onChange={handleAdAccountChange}
                  label="Ad Account"
                >
                  {metaAssets.userAdAccounts?.map((a: any) =>
                    <MenuItem sx={{ fontSize: '14px' }} key={a.account_id} value={a.account_id}>{a.name}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button sx={{ textTransform: 'none' }} variant='outlined' onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{ textTransform: 'none' }} variant='contained' disabled={metaAssets.currentAssets.facebookPageId && metaAssets.currentAssets.adAccountId ? false : true} onClick={handleSave}>
            {saveBtnProgress ? <CircularProgress style={{ width: '20px', height: '20px', color: "#fff" }} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
