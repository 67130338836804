import { useEffect, useState } from 'react'
import { InputBase, Box, Divider, IconButton, CircularProgress, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PostsTable from './PostsTable';
import { useStateValue } from '../../../context/meta/MetaContext';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { getFacebookPosts } from '../../../api/Posts';
import { setAllPosts } from '../../../context/meta/MetaActions';
import { seedPosts } from './seedPosts';
import { useAlertStateValue } from '../../../context/alert/AlertContext';
import { setAlert } from '../../../context/alert/AlertAction';
import { AlertSeverity } from '../../../context/alert/AlertTypes';
import PostsMatchedTable from './PostsMatchedTable';
import PostsStartEndDate from './PostsStartEndDate';
import { useSharedStateValue } from '../../../context/shared/SharedContext';


const BasicPostBoosting = () => {

  const state = useCustomerState()

  const [{ allPosts, matchedPosts }, dispatch] = useStateValue()
  const [filter, setFilter] = useState('');
  const [{ alert }, alertDispatch] = useAlertStateValue()

  const [{ loading }, sharedDispatch] = useSharedStateValue()

  // handle user input in the search field
  const handleFilterchange = (event: any) => {
    setFilter(event.target.value);
  };

  return (
    <>
      <Box sx={{ maxWidth: 800 }}>
        <PostsStartEndDate />
      </Box>
      {loading && <Box sx={{ maxWidth: 800, textAlign: 'center', mt: 2 }}><CircularProgress style={{ width: '32px', height: '32px' }} /></Box>}
      {!loading && allPosts === undefined && <Box sx={{ maxWidth: 800, textAlign: 'center', mt: 2 }}><Typography variant='body1'>No posts found.</Typography></Box>}
      {!loading && allPosts !== undefined && allPosts !== null && allPosts.length === 0 && <Box sx={{ maxWidth: 800, textAlign: 'center', mt: 2 }}><Typography variant='body1'>No posts found. Try to apply another date range.</Typography></Box>}
      {!loading && allPosts !== undefined && allPosts !== null && allPosts.length > 0 &&
        <Box sx={{ mt: 1, marginLeft: '0.1em' }}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
          >
            <form>
              <Box display="flex" alignItems="center">
                <InputBase
                  placeholder="Filter by message..."
                  value={filter}
                  onChange={handleFilterchange}
                />
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Box>
              <Divider sx={{ mb: 5 }} />
            </form>
          </Box>
          <PostsTable posts={filter ? allPosts.filter((item: any) => { return item.message?.toLowerCase().includes(filter.toLowerCase()) }) : allPosts} />
        </Box>}
    </>
  )
}

export default BasicPostBoosting