import * as React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import Stack from '@mui/material/Stack';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GridViewIcon from '@mui/icons-material/GridView';
import GridOnIcon from '@mui/icons-material/GridOn';
import { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { primaryColors } from '../styles/colors';


type ToggleButtonPorps = {
  selectedColor: string
}

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }: ToggleButtonPorps) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: primaryColors[950],
    backgroundColor: selectedColor,
  },
}));


export type GridViewItemsCountToggleProps = {
  items: number,
  handleChange: (event: React.MouseEvent<HTMLElement>, newItems: number) => void
}

export default function ToggleButtonSizes({items, handleChange}: GridViewItemsCountToggleProps) {
    
  const children = [
    <ToggleButton value="3" key="3"
      sx={{color: items === 3 ? primaryColors[950] : '#707070', background:  items === 3 ? primaryColors[100] : '#fff'}} 
      selectedColor={primaryColors[100]}>
      <GridOnIcon />
    </ToggleButton>,
    <ToggleButton value="2" key="2" 
      sx={{color: items === 2 ? primaryColors[950] : '#707070', background:  items === 2 ? primaryColors[100] : '#fff'}} 
      selectedColor={primaryColors[100]}>
      <GridViewIcon/>
    </ToggleButton>
  ];

  const control = {
    value: items,
    onChange: handleChange,
    exclusive: true,
  };

  return (

    <Stack spacing={2} alignItems="center">
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        {children}
      </ToggleButtonGroup>
    </Stack>
  );
}
