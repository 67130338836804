import { Attribution, AttributionType, VisitorFilters } from "./Types"
import { postApi } from "./Utils"


export const apiGetTotalRevenue = async (customerId: number, startDate: string, endDate: string,
  attributionType: AttributionType): Promise<{ revenue: number, count: number }> => {
  return await postApi(`attribution/customer/${customerId}`, {
    startDate,
    endDate,
    type: attributionType,
    aggregations: [],
    order: "revenue",
    direction: "desc"
  })
}

export const apiGetChannelAttributions = async (customerId: number, startDate: string, endDate: string,
  attributionType: AttributionType): Promise<Attribution[]> => {
  return await postApi(`attribution/customer/${customerId}`, {
    startDate,
    endDate,
    type: attributionType,
    aggregations: ["channel"],
    order: "revenue",
    direction: "desc"
  })
}

export const apiGetDailyAttributions = async (customerId: number,
  startDate: string, endDate: string,
  attributionType: AttributionType, aggregation: string,
  channel?: string, campaign?: string, landingPage?: string,
  conversionType?: string, stackChannels?: boolean): Promise<Attribution[]> => {
  return await postApi(`attribution/customer/${customerId}`, {
    startDate,
    endDate,
    type: attributionType,
    channel,
    campaign,
    landingPage,
    aggregations: stackChannels ? [aggregation, 'channel'] : [aggregation],
    conversionType,
    order: "date",
    direction: "asc"
  })
}

export const apiGetAttributionFilters = async (customerId: number, startDate: string, endDate: string): Promise<VisitorFilters> => {
  return await postApi(`attribution/customer/${customerId}/filters`, {
    startDate,
    endDate
  })
}