import styled, { keyframes } from 'styled-components';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById("root")!;

export const Modal = (props: any) => {
  return ReactDOM.createPortal(
    <>{props.children}</>,
    modalRoot
  );
};

export const Overlay = styled.div`
  position:fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  z-index:10000;
  background-color: rgba(255,255,255,0.5);
  margin:auto;
`

const Label = styled.div`
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  background-color:#f0f0f0;
  border-radius:2em;
  padding-left:5em;
  padding-right:5em;
  padding-top:1em;
  padding-bottom:1em;
  font-size:1.1em;
  display:grid;
  grid-template-columns: 1.2em 1fr;
  align-items:center;
`

const SpinKeyframes = keyframes`
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
}
`

const Spinner = styled.div`
  width:12px;
  height:12px;
  transform-origin: center;

    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #383636; /* Black */
    border-bottom: 2px solid #383636; /* Black */
    border-radius: 50%;
`

export const AnimatedSpinner = styled(Spinner)`
  animation: ${SpinKeyframes} 1s infinite linear;
`

export type RegisteredToggle = {
  elementIds: string[]
  setIsOpen: (open: boolean) => void
  isOpen: boolean
  hideAngle?: boolean
}

const toggles: RegisteredToggle[] = []

document.addEventListener("mousedown", (ev: MouseEvent) => {
  for (const toggle of toggles) {
    let isHit = false
    for (const elementId of toggle.elementIds) {
      const elem = document.getElementById(elementId)
      if (elem) {
        const rect = elem.getBoundingClientRect()
        if (ev.clientX >= rect.left && ev.clientY >= rect.top &&
          ev.clientX <= rect.right && ev.clientY <= rect.bottom) {
          isHit = true
        }
      }
    }
    if (!isHit) {
      toggle.setIsOpen(false)
    }
  }
})

export const registerToggle = (props: RegisteredToggle) => {
  toggles.push(props)
}

export const closeAllToggles = () => {
  for (const toggle of toggles) {
    toggle.setIsOpen(false)
  }
}

export const LoadingModal = (props: { message?: string }) => {
  return (<Modal>
    <Overlay>
      <Label>
        <AnimatedSpinner>
        </AnimatedSpinner>
        <span>{props.message || "Loading..."}</span>
      </Label>
    </Overlay>
  </Modal>)
}