import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { AdSize, ManagedAdPlatform, ManagedAdPlatformMeta, MetaAdSet, MetaCampaign } from "../../api/Types"
import { apiGetManagedAds, apiGetMetaAdsets, apiGetMetaCampaigns, apiPutManagedAdPlatform } from "../../api/Customer"
import { DeleteItem, useIsMount } from "../Utils"
import styled from "styled-components"
import { debouncePromise } from "../../utils/Generators"
import { regularBtnStyles } from "../../styles/buttons"

const Title = styled.div`
  font-size:1.2em;
  margin-bottom:1em;
`

const debouncedApiPutManagedAdPlatform = debouncePromise(apiPutManagedAdPlatform, 1000)

export const ManagedAdPlatformViewMeta = (props: {
  customerId: number,
  creativeId: string,
  platform: ManagedAdPlatformMeta,
  onDelete: () => void,
  onUpdate: (platform: ManagedAdPlatform) => void
}) => {
  const { customerId, creativeId, onDelete, onUpdate } = props
  const propsPlatform = props.platform
  const [metaCampaigns, setMetaCampaigns] = useState<MetaCampaign[]>([])
  const [metaAdsets, setMetaAdsets] = useState<MetaAdSet[]>([])
  const [sizes, setSizes] = useState<AdSize[]>([])
  const [platform, setPlatform] = useState(propsPlatform)
  const [hasSuitableType, setHasSuitableType] = useState(true)

  function refreshAdsets() {
    if (platform.campaignId) {
      apiGetMetaAdsets(customerId, platform.campaignId).then((metaAdsets) => {
        setMetaAdsets(metaAdsets)
      }).catch(() => setMetaAdsets([]))
    }
  }

  function refreshAd() {
    apiGetManagedAds(customerId).then((managedAds) => {
      const ad = managedAds.find(a => a.creativeId === creativeId)
      if (ad) {
        setHasSuitableType(ad.types.find(t => t.type === "Image" || t.type === "Video") !== undefined)
        setSizes(ad.instances.map(s => ({ width: s.width, height: s.height })))
      }
    })
  }

  useEffect(() => {
    apiGetMetaCampaigns(customerId).then((metaCampaigns) => {
      setMetaCampaigns(metaCampaigns)
    })
    refreshAd()
    refreshAdsets()
  }, [creativeId])

  function updatePlatform(partial: any) {
    setPlatform({ ...platform, ...partial })
    debouncedApiPutManagedAdPlatform(customerId, creativeId, { ...platform, ...partial }).then(() => {
      if (partial.campaignId) {
        apiGetMetaAdsets(customerId, partial.campaignId).then((metaAdsets) => setMetaAdsets(metaAdsets)).then(() => onUpdate(platform))
      }
    })
  }

  if (!hasSuitableType) {
    return <Paper>
      <Grid container padding={2}>
        <Grid item xs={12}><Title>Meta: New ad type is needed</Title></Grid>
        <Grid item xs={12}>
          Your ad has no image or video type. You can add type from the preview top-left corner plus sign.
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Button variant="contained"
            onClick={() => refreshAd()} sx={regularBtnStyles}>Try again</Button>
        </Grid>
      </Grid>
    </Paper>
  }
  return (<Paper>
    <Grid container padding={2}>
      <Grid item xs={12}><Title>Meta</Title></Grid>
      <Grid container xs={11}>
        <Grid item xs={12} padding={1}>
          <FormControl fullWidth={true}>
            <TextField label="Ad name" size="small" value={platform.adName}
              onChange={(ev) => updatePlatform({ adName: ev.target.value as string })} />
          </FormControl>
        </Grid>
        <Grid item xs={6} padding={1}>
          <FormControl fullWidth={true}>
            <InputLabel id={`adtype-label-id-${platform.id}`} size="small">Type</InputLabel>
            <Select label="Type" size="small" labelId={`adtype-label-id-${platform.id}`}
              value={platform.adType}
              onChange={(ev) => {
                updatePlatform({ adType: ev.target.value })
              }}>
              <MenuItem value={'Image'}>Image</MenuItem>
              <MenuItem value={`Video`}>Video</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} padding={1}>
          <FormControl fullWidth={true}>
            <InputLabel id={`size-label-id-${platform.id}`} size="small">Size</InputLabel>
            <Select label="Size" size="small" labelId={`size-label-id-${platform.id}`}
              value={`${platform.width}x${platform.height}`}
              onChange={(ev) => {
                const width = parseInt(ev.target.value.split("x")[0], 10)
                const height = parseInt(ev.target.value.split("x")[1], 10)
                updatePlatform({ width, height })
              }}>
              {(sizes || []).map(size => <MenuItem value={`${size.width}x${size.height}`}>{`${size.width}x${size.height}`}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} padding={1}>
          <FormControl fullWidth={true}>
            <InputLabel id={`campaign-label-id-${platform.id}`} size="small">Campaign</InputLabel>
            <Select label="Campaign" size="small" labelId={`campaign-label-id-${platform.id}`} value={platform.campaignId}
              onChange={(ev) => updatePlatform({ campaignId: ev.target.value as string, adsetId: undefined })}>
              {metaCampaigns.map(campaign => <MenuItem value={campaign.id}>{campaign.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} padding={1}>
          <FormControl fullWidth={true}>
            <InputLabel id={`adset-label-id-${platform.id}`} size="small">Adset</InputLabel>
            <Select label="Adset" size="small" labelId={`adset-label-id-${platform.id}`} disabled={!metaAdsets || metaAdsets.length === 0}
              value={platform.adsetId}
              onChange={(ev) => updatePlatform({ adsetId: ev.target.value as string })}>
              {(metaAdsets || []).map(adset => <MenuItem value={adset.id}>{adset.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} padding={1}>
          <FormControl fullWidth={true}>
            <TextField label="URL" size="small" value={platform.url}
              onChange={(ev) => updatePlatform({ url: ev.target.value as string })} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={1} paddingLeft={"1em"} marginTop={"2em"}>
        <DeleteItem onClick={() => { onDelete() }} />
      </Grid>
    </Grid>
  </Paper>)
}