
export type AnimationEffectWait = {
  type: "Wait"
  durationMs: number
}


export const AnimationPropertyNames = ["opacity", "left", "top", "right", "bottom"]

export type AnimationProperty = "opacity" | "left" | "top" | "right" | "bottom"

export type AnimationEffectPropertyChange = {
  type: "PropertyChange"
  property: AnimationProperty
  startValue: number
  endValue: number
  durationMs: number
}

export type AnimationEffect = AnimationEffectWait | AnimationEffectPropertyChange

export type Animation = {
  version: 1
  effects: AnimationEffect[]
}