import styled from 'styled-components';
import { FontFamilies, LightBorderDef, UnitFontFamily } from '../components/Colors';
import { MeasurementInfo, MeasurementDeviationInfo, MeasurementHeading } from '../components/Headings';
import { LegendValue } from '../components/Headings';
import { LineChart } from '../charts/LineChart';
import { getMax, range, toNumberDict, weekdayToName } from '../utils/Generators';
import { DateTime } from 'luxon'
import { HourlyConversions } from '../api/Types';
import { cumulativeHourlyConversions, sumHourlyConversions } from './Helpers';
import { PolyLine } from '../charts/Types';

const Container = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const YearGridContainer = styled.div`
  display: flex;
  margin-top: 20px;
  border-top: ${LightBorderDef};
  border-bottom: ${LightBorderDef};
`

const WeekGridContainer = styled.div`
  display: flex;
  margin-top: 20px;
  border-bottom: ${LightBorderDef};
  position: relative;
  min-height: 15em;
  flex-direction: column;
`

const DiagramContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  flex: 7;
`

const StatsContainer = styled.div`
  flex: 4;
  display: grid;
  grid-template-rows: 1fr 1px 1fr;
  border-left: ${LightBorderDef};
`
const DeviationContainer = styled.div`
  margin: 20px;
`

const YearForecastNumbers = styled.div`
  margin: 20px;
`

const Divider = styled.div`
  max-height: 1px;
  border-top: ${LightBorderDef};
`

const WeekDayGridContainer = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: ${LightBorderDef};
  padding-top: 1.5em;
`

const ShortForecastContainer = styled.div`
  display:grid;
  flex-direction: column;
  position: relative;
`

const ForecastGoalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`

const Footer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`

const Legend = styled.div`
  display:grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
`

const ChartContainer = styled.div`
    padding-top: 15px;
    width: 100%;
    position: relative;
`

const SmallChartContainer = styled.div`
    margin-left: 5px;
    margin-right: 30px;
    width: calc(100%-30px);
    min-height: 180px;
    position: relative;
`

const Note = styled.div`
    color: gray;
    font-family: ${UnitFontFamily};
    font-size: 0.8em;
`

const ForecastSummarySpan = styled.div`
    font-family: ${FontFamilies}
    font-size: 0.8em;
    margin: 10px;
    display: grid;
    grid-template-rows: 1.2em 1fr 1fr 1fr;
    grid-row-gap: 5px;
`


export interface YearForecastProps {
  modelDate: string
  allHourlyForecast: HourlyConversions[]
  allHourlyActuals: HourlyConversions[]
  allHourlyGoals: HourlyConversions[]
}

const toYearPoints = (cumulatives: HourlyConversions[]): PolyLine => {
  return cumulatives.map(c => ([
    c.month - 1.0 + c.day / 31.0,
    c.total
  ]))
}

const toWeekPoints = (conversions: HourlyConversions[]): PolyLine => {
  const points: PolyLine = range(conversions.reduce((m, c) => Math.max(m, c.weekday), 0)).map(r => ([r, 0.0]))
  for (const c of conversions) {
    points[c.weekday - 1][1] += c.total
  }
  return points.map(p => ([p[0] + 0.5, p[1]]))
}

const toWeeklyPoints = (conversions: HourlyConversions[]): PolyLine => {
  const points: PolyLine = range(conversions.reduce((m, c) => Math.max(m, c?.week), 0)).map(r => ([r, 0.0]))
  for (const c of conversions) {
    if (points[c?.week - 1]) {
      points[c?.week - 1][1] += c.total
    }
  }
  return points.map(p => ([p[0], p[1]]))
}

const toDayPoints = (conversions: HourlyConversions[]): PolyLine => {
  const hmap = toNumberDict(conversions, c => c.hour + 1)
  return range(conversions.reduce((m, c) => Math.max(m, c.hour + 2), 0))
    .map(h => ([h, hmap[h] ? hmap[h].total : 0]))
}

const maxY = (line: PolyLine) => {
  return line.reduce((m, c) => Math.max(m, c[1]), 0.0)
}

const generateHorzlines = (max: number, step: number, maxLines: number): { y: number, label: string }[] => {
  let values = range(Math.round(max / step)).slice(1).map(v => v * step)
  while (values.length > maxLines) {
    step *= 2
    values = range(Math.round(max / step)).slice(1).map(v => v * step)
  }
  return values.map(v => ({ y: v, label: v.toString() }))
}

type ForecastSummaryProps = {
  title: string
  actual: number
  forecast: number
  goal: number
}

const ForecastSummary = (props: ForecastSummaryProps) => {
  const { title, actual, goal, forecast } = props
  const difference = actual - goal
  const diffPercentage = (difference > 0 ? "+" : "") + ((difference * 100) / goal).toFixed(0)
  const forecastDifference = actual - forecast
  const forecastDiffPercentage = (forecastDifference > 0 ? "+" : "") + ((forecastDifference * 100) / goal).toFixed(0)
  return <ForecastSummarySpan>
    <div><b>{title}</b></div>
    <div>Toteutunut: {actual} kpl</div>
    <div>Tavoite: {goal} kpl</div>
    <div style={{ color: difference < 0 ? 'red' : 'green' }}>Tavoitteesta: {difference >= 0 ? "+" : ""}{difference} kpl ({diffPercentage}%)</div>
    <div style={{ color: forecastDifference < 0 ? 'red' : 'green' }}>Ennusteesta: {forecastDifference >= 0 ? "+" : ""}{forecastDifference} kpl ({forecastDiffPercentage}%)</div>
  </ForecastSummarySpan>
}

export const YearForecast = (props: YearForecastProps) => {
  const {
    modelDate,
    allHourlyGoals,
    allHourlyForecast,
    allHourlyActuals } = props

  const year = parseInt(allHourlyForecast[0].date.substring(0, 4), 10)

  const hourlyGoals = allHourlyGoals.filter(h => h.date.startsWith(year.toString()))
  const hourlyForecast = allHourlyForecast.filter(h => h.date.startsWith(year.toString()))
  const hourlyActuals = allHourlyActuals.filter(h => h.date.startsWith(year.toString()))

  const yearGoal = Math.round(sumHourlyConversions(hourlyGoals))
  const yearForecast = Math.round(sumHourlyConversions(hourlyForecast))
  const yearActual = Math.round(sumHourlyConversions(hourlyActuals))
  const chartMaximum = Math.round(Math.max(yearActual, Math.max(yearGoal, yearForecast)) * 1.2)
  const now = year < new Date().getFullYear() ? DateTime.fromFormat(modelDate, 'yyyy-MM-dd').endOf("year") : DateTime.now()
  const weekForecasts = hourlyForecast.filter(g => g.week === now.weekNumber)
  const dayForecasts = hourlyForecast.filter(g => g.week === now.weekNumber && g.day === now.day)
  const weekGoals = hourlyGoals.filter(g => g.week === now.weekNumber)
  const dayGoals = hourlyGoals.filter(g => g.month === now.month && g.day === now.day)
  const weekActuals = hourlyActuals.filter(g => g.week === now.weekNumber)
  const dayActuals = hourlyActuals.filter(g => g.month === now.month && g.day === now.day)

  const weekGoal = Math.round(sumHourlyConversions(weekGoals))
  const weekForecast = Math.round(sumHourlyConversions(weekForecasts))
  const dayGoal = Math.round(sumHourlyConversions(dayGoals))
  const dayForecast = Math.round(sumHourlyConversions(dayForecasts))

  const yearForecastPoints = toYearPoints(cumulativeHourlyConversions(hourlyForecast))
  const yearGoalPoints = toYearPoints(cumulativeHourlyConversions(hourlyGoals))
  const yearActualPoints = toYearPoints(cumulativeHourlyConversions(hourlyActuals))

  const week = now.weekNumber - 1
  const weeklyForecastPoints = toWeeklyPoints(hourlyForecast).slice(0, -1)
  const weeklyGoalPoints = toWeeklyPoints(hourlyGoals).slice(0, -1)
  const weeklyActualPoints = toWeeklyPoints(hourlyActuals).filter(p => p[0] < week)

  const weeklyMax = Math.ceil(Math.max(maxY(weeklyForecastPoints), maxY(weeklyGoalPoints), maxY(weeklyActualPoints)) / 200.0 + 1) * 200

  const dayWeekMax = Math.ceil((getMax([getMax(toWeekPoints(weekForecasts).map(f => f[1])), getMax(toWeekPoints(weekActuals).map(a => a[1]))])) / 50.0) * 50
  const dayMax = Math.ceil((getMax([getMax(dayForecasts.map(f => f.total)), getMax(dayActuals.map(a => a.total))])) / 10.0) * 10

  const dayActualSumSoFar = dayActuals.reduce((a, d) => a + d.total, 0)
  const maxHour = dayActuals.reduce((max, c) => Math.max(max, c.hour), 0)
  const dayForecastSoFar = Math.floor(dayForecasts.filter(h => h.hour <= maxHour).reduce((a, d) => a + d.total, 0))
  const dayGoalSoFar = Math.floor(dayGoals.filter(h => h.hour <= maxHour).reduce((a, d) => a + d.total, 0))

  const weekActualSumSoFar = weekActuals.reduce((a, d) => a + d.total, 0)
  const maxWeekHour = weekActuals.reduce((a, d) => Math.max(a, d.weekday * 24 + d.hour), 0)
  const weekForecastSoFar = Math.floor(weekForecasts.filter(h => h.weekday * 24 + h.hour <= maxWeekHour).reduce((a, d) => a + d.total, 0))
  const weekGoalSoFar = Math.floor(weekGoals.filter(h => h.weekday * 24 + h.hour <= maxWeekHour).reduce((a, d) => a + d.total, 0))

  const yearActualSumSoFar = hourlyActuals.reduce((a, d) => a + d.total, 0)
  const maxYearHour = hourlyActuals.reduce((a, d) => Math.max(a, (d.month * 31 + d.day) * 24 + d.hour), 0)
  const yearForecastSoFar = Math.floor(hourlyForecast.filter(h => (h.month * 31 + h.day) * 24 + h.hour <= maxYearHour).reduce((a, d) => a + d.total, 0))
  const yearGoalSoFar = Math.floor(hourlyGoals.filter(h => (h.month * 31 + h.day) * 24 + h.hour <= maxYearHour).reduce((a, d) => a + d.total, 0))

  return (<Container>
    <YearGridContainer>
      <DiagramContainer>
        <Legend>
          <MeasurementInfo title={"Yearly forecast"} value={yearForecast} unit={"contracts"} />
          <LegendValue title={"Forecast"} color={"green"} />
          <LegendValue title={"Actual"} color={"blue"} />
          <LegendValue title={"Goal"} color={"gray"} />
        </Legend>
        <ChartContainer>
          <LineChart ratio={0.2} vertLines={24} unit={'pcs'} labels={{
            titles: [
              "Jan", "Feb", "Mar", "Apr",
              "May", "Jun", "Jul", "Aug", "Sep", "Oct",
              "Nov", "Dec"], anchor: "left",
          }} lines={[
            {
              color: "green",
              width: 1,
              points: yearForecastPoints
            },
            {
              color: "blue",
              width: 2,
              endmarker: "circle",
              points: yearActualPoints
            },
            {
              color: "gray",
              width: 1,
              points: yearGoalPoints
            }
          ]} xAxis={{ min: 0, max: 12 }} yAxis={{
            min: 0, max: chartMaximum
          }} horzLines={[]} endMarkerToolTip={<ForecastSummary title={`Year ${year}`}
            actual={yearActualSumSoFar}
            forecast={yearForecastSoFar}
            goal={yearGoalSoFar} />} />
        </ChartContainer>
      </DiagramContainer>
      <StatsContainer>
        <DeviationContainer>
          {yearGoal > 0 &&
            <MeasurementDeviationInfo title={"Forecast vs goal"}
              value={yearForecast - yearGoal} unit={"contracts"}
              percentage={((yearForecast - yearGoal) * 100 / yearGoal)} />}
          {yearGoal <= 0 &&
            <MeasurementDeviationInfo title={"Forecast vs actual"}
              value={yearForecast - yearActual} unit={"contracts"}
              percentage={((yearForecast - yearActual) * 100 / yearActual)} />}
        </DeviationContainer>
        <Divider />
        <YearForecastNumbers>
          <MeasurementInfo title={"Yearly goal"} value={yearGoal} unit={"contracts"} />
        </YearForecastNumbers>
      </StatsContainer>
    </YearGridContainer>
    <WeekDayGridContainer>
      <ShortForecastContainer>
        <ForecastGoalContainer>
          <MeasurementInfo title={"Weekly forecast"} value={weekForecast} unit={""} />
          <MeasurementInfo title={"Weekly goal"} value={weekGoal} unit={""}
            color={"gray"} fontSize="1.2em" />
        </ForecastGoalContainer>
        <SmallChartContainer>
          <LineChart ratio={0.35} vertLines={14} unit={'kpl'} labels={{
            offset: 0.5,
            titles: [
              "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"], anchor: "middle"
          }} lines={[
            {
              color: "blue",
              endmarker: "circle",
              width: 1,
              points: toWeekPoints(weekActuals)
            },
            {
              color: "green",
              width: 1,
              points: toWeekPoints(weekForecasts)
            }
          ]} xAxis={{ min: 0, max: 7 }} yAxis={{ min: 0, max: dayWeekMax }} padding={14} paddingLeft={38} horzLines={generateHorzlines(dayWeekMax, 50, 10)} endMarkerToolTip={<ForecastSummary title={`Week ${weekActuals[0]?.week}`}
            actual={weekActualSumSoFar}
            forecast={weekForecastSoFar}
            goal={weekGoalSoFar} />} />
        </SmallChartContainer>
      </ShortForecastContainer>
      <ShortForecastContainer>
        <ForecastGoalContainer>
          <MeasurementInfo title={"Daily forecast"} value={dayForecast} unit={""} />
          <MeasurementInfo title={"Daily goal"} value={dayGoal} unit={""} color={"gray"}
            fontSize="1.2em" />
        </ForecastGoalContainer>
        <SmallChartContainer>
          <LineChart ratio={0.35} vertLines={24} unit={'pcs'} labels={{
            fullRange: true,
            titles: range(25).map(v => v.toString().padStart(2, "0")), anchor: "middle"
          }} lines={[
            {
              color: "blue",
              endmarker: "circle",
              width: 1,
              points: toDayPoints(dayActuals)
            },
            {
              color: "green",
              width: 1,
              points: toDayPoints(dayForecasts)
            }
          ]} xAxis={{ min: 0, max: 24 }} yAxis={{ min: 0, max: dayMax }} padding={14} paddingLeft={35} horzLines={generateHorzlines(dayMax, 5, 10)} endMarkerToolTip={<ForecastSummary title={`${weekdayToName(dayForecasts[0].weekday, true)}, klo ${maxHour + 1}`}
            actual={dayActualSumSoFar}
            forecast={dayForecastSoFar}
            goal={dayGoalSoFar} />} />
        </SmallChartContainer>
      </ShortForecastContainer>
    </WeekDayGridContainer>
    <WeekGridContainer>
      <Legend>
        <MeasurementHeading>Weekly forecast</MeasurementHeading>
        <LegendValue title={"Forecast"} color={"green"} />
        <LegendValue title={"Actual"} color={"blue"} />
        <LegendValue title={"Goal"} color={"gray"} />
      </Legend>
      <ChartContainer>
        <LineChart paddingLeft={50} width={2000} ratio={0.2} vertLines={weeklyForecastPoints.length - 1}
          unit={'kpl'}
          labels={{
            titles: range(weeklyForecastPoints.length).map(n => (n + 1).toString()), anchor: "middle",
            fullRange: true
          }} lines={[
            {
              color: "green",
              width: 1,
              points: weeklyForecastPoints
            },
            {
              color: "blue",
              width: 2,
              endmarker: "circle",
              points: weeklyActualPoints
            },
            {
              color: "gray",
              width: 1,
              points: weeklyGoalPoints
            }
          ]} xAxis={{ min: 0, max: weeklyForecastPoints.length - 1 }} yAxis={{
            min: 0, max: weeklyMax,
          }} horzLines={generateHorzlines(weeklyMax, 25, 10)}
          endMarkerToolTip={<ForecastSummary title={`Year ${year}`}
            actual={yearActualSumSoFar}
            forecast={yearForecastSoFar}
            goal={yearGoalSoFar} />} />
      </ChartContainer>
    </WeekGridContainer>
    <Footer>
      <Note>Forecast updated {DateTime.now().toFormat("d.L.y")}</Note>
      <Note>Copyright {new Date().getFullYear()} Kiva Helsinki Oy.</Note>
    </Footer>
  </Container>)
}