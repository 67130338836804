import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useState, useEffect } from "react"
import { BarChart } from "../charts/BarChart"
import moment from "moment"


const gridItemStyles = {
  mt: 1.0,
  mb: 1.0
}


export type BudgetManagerProps = {
  title: string
  totalTitle: string
  weeklyValues: number[]
  onCancel: () => void
  onSave: (weeklyValyes: number[]) => void
}


export const BudgetManager = (props: BudgetManagerProps) => {
  const { onCancel, onSave, title, totalTitle } = props

  const firstWeekToAdjust = moment().week()
  const [totalBudget, setTotalBudget] = useState(props.weeklyValues.reduce((t, c) => t + c, 0))
  const [weeklyValues, setWeeklyValues] = useState(props.weeklyValues)

  useEffect(() => {
    setWeeklyValues(props.weeklyValues)
  }, [props.weeklyValues])
  return (<Dialog open={true} fullWidth={true} maxWidth='lg'>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item xs={2} sx={gridItemStyles}>
          <TextField type='number' size='small' variant="outlined" label={totalTitle}
            value={totalBudget}
            onChange={(ev) => setTotalBudget(parseFloat(ev.target.value))} />
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" size='small' color="secondary" onClick={() => {
            const currentBudget = weeklyValues.reduce((total, c) => total + c, 0)
            const factor = totalBudget / currentBudget
            setWeeklyValues(weeklyValues.map(w => Math.round(w * factor)))
          }}>Update</Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={gridItemStyles}>
          <BarChart barPrefix="week" values={weeklyValues} lockCount={firstWeekToAdjust} onValuesChange={(newValues) => {
            setWeeklyValues(newValues)
            setTotalBudget(weeklyValues.reduce((total, c) => total + c, 0))
          }} />
        </Grid>
      </Grid>
      <Grid container alignContent={"center"} justifyContent={"right"} spacing={1}>
        <Grid item xs={1}>
          <Button variant="contained" size='small' fullWidth color="error" onClick={onCancel}>Cancel</Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" size='small' fullWidth color="primary" onClick={() => {
            onSave(weeklyValues)
          }}>Save</Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>)
}