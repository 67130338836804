import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { setAdAutomationSettings } from '../../../context/meta/MetaActions';
import { useStateValue } from '../../../context/meta/MetaContext';
import { initialAdAutomationSettings } from '../../../context/meta/MetaReducer';
import AdAutomationStepper from './AdAutomationStepper';
import { desktop_h1 } from '../../../styles/textStyles';

const CreateAdAutomation = () => {
  const state = useCustomerState()
  const [{ adAutomationSettings, adAutomationSettingsErrors }, dispatch] = useStateValue()


  useEffect(() => {
    dispatch(setAdAutomationSettings(initialAdAutomationSettings))
  }, [])


  return (
    <Box sx={{ margin: '50px' }}>
      <Typography variant='h1' sx={{ ...desktop_h1, mb: '24px' }} >Create new ad automation</Typography>
      <Grid container sx={{ maxWidth: 800 }}>
        <AdAutomationStepper />
      </Grid>
    </Box>
  );
}
export default CreateAdAutomation;
