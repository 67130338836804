import { HourlyConversions } from "../api/Types";


export const sumHourlyConversions = (conversions: HourlyConversions[]): number =>
  conversions.reduce((total, g) => total + g.total, 0)


export const cumulativeHourlyConversions = (conversions: HourlyConversions[]): HourlyConversions[] => {
  const sorted = conversions.sort((a, b) =>
    (a.month * 31 * 24 + a.day * 24 + a.hour) -
        (b.month * 31 * 24 + b.day * 24 + b.hour))
  if (sorted.length > 0) {
    let total = 0
    const cumulatives = []
    for (const hourly of conversions) {
      total += hourly.total
      cumulatives.push({ ...hourly, total })
    }
    return cumulatives
  }
  return []
}