import React from 'react'
import { CompetitorAction, CompetitorState, CompetitorAdUnits, SET_COMPETITOR_AD_UNITS, MetaCompetitorAdType, SET_COMPETITOR_FACEBOOK_PAGES } from './CompetitorTypes';
import { CategoryItem } from '../ads/AdsTypes';


export const allCompetitorAdsCategories: CategoryItem[] = [
  { value: "meta", label: "Meta" }
]

export const allCompetitorAdsCategoriesAsStrings: string[] = allCompetitorAdsCategories.map((c: CategoryItem) => c.value)


export const initialCompetitorAdUnits: CompetitorAdUnits = {
  startDate: new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)),
  endDate: new Date(Date.now() - (1 * 24 * 60 * 60 * 1000)),
  searchKeyword: "",
  status: "ACTIVE",
  creatives: { creatives: [] },
  categories: allCompetitorAdsCategoriesAsStrings,
  facebookPageIds: [],
  adType: MetaCompetitorAdType.ALL,
  loading: false,
  gridViewItemsCount: 3
}

export const initialCompetitorState: CompetitorState = {
  competitorAdUnits: initialCompetitorAdUnits,
  competitorFacebookPages: []
};

const CompetitorReducer = (state: CompetitorState, action: CompetitorAction): CompetitorState => {
  switch (action.type) {
    case SET_COMPETITOR_AD_UNITS:
      return {
        ...state,
        competitorAdUnits: action.data
      };
    case SET_COMPETITOR_FACEBOOK_PAGES:
      return {
        ...state,
        competitorFacebookPages: action.data
      };
    default:
      return state;
  }
};

export default CompetitorReducer