import { Component } from 'react';
import styled from 'styled-components';
import { numberToString, toPercentage, toPrice } from '../utils/Generators';
import { faSortAmountDown, faSortAmountUp, faAngleLeft, faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Checkbox } from '@mui/material';

const Container = styled.div`
  border: 1px solid lightgray;
  border-radius:5px;
  color:#1f1f1f;
`

const RowContainer = styled.div`
  max-height:700px;
  min-height:700px;
  overflow-y:auto;
`

const Rows = styled.div`
  margin:0.1em;
  display:grid;
`

const Column = styled.div`
  font-size:0.8em;
  margin:0.5em;
  overflow-x: hidden;
  word-wrap: unset;
  white-space: nowrap;
  align-content: center;
  margin-top:auto;
  margin-bottom:auto;
`

const Heading = styled.div`
  display:grid;
  grid-template-columns: 1fr 1em;
  margin-left:0.5em;
  margin-top:0.5em;
  margin-bottom:0.5em;
  padding-right:0.5em;
  border-right: 2px solid lightgray;
  cursor:pointer;
  align-content:center;
  user-select: none;
`

const Sort = styled.div`
  font-size:0.8em;
`

const Line = styled.div`
  border-bottom: 1px solid lightgray;
  margin-bottom:0.5em;
`

const PageInfo = styled.div`
  text-align:right;
  margin-right:1.0em;
  margin-top:-0.2em;
`

const Footer = styled.div`
  display:grid;
  grid-template-columns: 1fr 2.0em 2.0em;
  align-content:center;
  margin-bottom:0.3em;
  user-select: none;
`


export type ColumnProps = {
  headerName: string
  type: 'select' | 'string' | 'number' | 'percentage' | 'price' | 'trendFactor'
  field: string
  flex?: number
  width?: number
  style?: any
  maxValue?: number
  grouped?: boolean
  subValue?: boolean
  formatter?: (value: any, row: any) => JSX.Element,
  headingFormatter?: (value: any) => JSX.Element,
}

export type SearchGridProps = {
  values: any[]
  columns: ColumnProps[]
  pageSize: number,
  onSelect?: (value: any) => void
}

export type SearchGridState = {
  sortColumn: number
  sortDirection: number
  pageStart: number
}

const columnFormatter = (onSelect: () => void, column: ColumnProps, value: any): JSX.Element | string => {
  switch (column.type) {
    case 'select': return <Checkbox checked={value} onClick={onSelect} />
    case 'string': return value
    case 'number': {
      if (column.maxValue && value > column.maxValue) {
        return "-"
      }
      return value && value.toFixed ? numberToString(value) : "-"
    }
    case 'percentage': return value ? toPercentage(value) : "-"
    case 'price': return value ? toPrice(value) : "-"
    case 'trendFactor': return value ?
      <FontAwesomeIcon icon={faArrowRight} style={
        {
          transform: `rotate(${value * 90.0}deg)`,
          fontWeight: 'bold',
          color: `rgb(${Math.abs(-1.0 - value) * 128}, ${Math.abs(1.0 - value) * 128}, 64)`
        }} /> :
      "-"
  }
}

const mapColumnToWidth = (c: ColumnProps): string => {
  if (c.flex) {
    return `${c.flex}fr`
  }
  if (c.width) {
    return `${c.width}px`
  }
  return '1fr'
}

const rowSorter = (column: ColumnProps, direction: number, row1: any, row2: any): number => {
  const v1 = row1[column.field]
  const v2 = row2[column.field]
  switch (column.type) {
    case 'string':
      return (v1 || "").localeCompare(v2 || "") * direction
    case 'number':
    case 'price':
    case 'percentage':
    case 'trendFactor':
    default:
      if (isNaN(parseInt(v1, 10))) return -v2 * direction;
      if (isNaN(parseInt(v2, 10))) return v1 * direction;
      return (v1 - v2) * direction
  }
}

const Header = (props: {
  column: ColumnProps, sortColumn: boolean, sortDirection: number,
  style?: any, onSort: () => void,
}) => {
  const { headerName, style, headingFormatter } = props.column
  const { onSort, sortColumn, sortDirection } = props
  return <Heading key={`header${headerName}`} onClick={onSort}
    style={style}>
    {headingFormatter ? headingFormatter(props.column) : <>
      <span>{headerName}</span>
      <Sort>{sortColumn ? <FontAwesomeIcon icon={sortDirection > 0 ? faSortAmountUp : faSortAmountDown} /> : undefined}</Sort>
    </>}
  </Heading>
}


const Page = (props: {
  enabled: boolean,
  onClick: () => void,
  icon: IconProp
}) => {
  const { enabled, onClick, icon } = props
  return <FontAwesomeIcon icon={icon} style={{
    cursor: enabled ? 'pointer' : 'not-allowed',
    color: enabled ? 'black' : 'gray'
  }} onClick={enabled ? onClick : () => { return }} />
}

export class SearchGrid extends Component<SearchGridProps, SearchGridState> {
  constructor(props: SearchGridProps) {
    super(props);
    this.state = {
      sortColumn: 1,
      sortDirection: 1,
      pageStart: 0
    };
  }

  componentDidUpdate(prevProps: SearchGridProps): void {
    if (prevProps.values.length !== this.props.values.length) {
      this.setState({ pageStart: 0 })
    }
  }

  render() {
    const { values, columns, pageSize, onSelect } = this.props
    const { sortColumn, sortDirection, pageStart } = this.state
    const firstPage = pageStart <= 0
    const lastPage = pageStart >= values.length - pageSize
    const sortedSlicedValues = values.sort(rowSorter.bind(this, columns[sortColumn], sortDirection)).slice(pageStart, pageStart + pageSize)
    return <Container>
      <RowContainer>
        <Rows style={{ gridTemplateColumns: columns.map(c => mapColumnToWidth(c)).join(' ') }}>
          {columns.map((c, index) => {
            if (c.type === 'select') {
              return <Checkbox checked={sortedSlicedValues.find(v => !v.selected) === undefined} onClick={() => {
                onSelect && onSelect(sortedSlicedValues)
              }} />
            }
            return <Header key={index} column={c}
              sortColumn={sortColumn === index}
              sortDirection={sortDirection}
              style={{ textAlign: 'center' }}
              onSort={() => { this.setState({ sortColumn: index, sortDirection: sortDirection * -1 }) }} />
          })}
          {columns.map((_, index) => (<Line key={`line${index}`} />))}
          {sortedSlicedValues.map((value, rowIndex) => {
            return columns.map((c, index) => {
              const equalPrev = rowIndex > 0 && values[rowIndex - 1][c.field] === values[rowIndex][c.field]
              const parsedValue = c.formatter ? c.formatter(value[c.field], value) :
                columnFormatter(() => { onSelect && onSelect([value]) }, c, value[c.field])
              const label = !equalPrev || !c.grouped ? parsedValue : ""
              return <Column style={{ ...c.style }} key={index}><div>{label}</div></Column>
            })
          })}
        </Rows>
      </RowContainer>
      <Line />
      <Footer>
        <PageInfo>{pageStart}-{pageStart + pageSize} of {values.length}</PageInfo>
        <Page enabled={!firstPage} icon={faAngleLeft} onClick={() => this.setState({ pageStart: pageStart - pageSize })} />
        <Page enabled={!lastPage} icon={faAngleRight} onClick={() => this.setState({ pageStart: pageStart + pageSize })} />
      </Footer>
    </Container >
  }
}