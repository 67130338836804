import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { setAlert } from '../../context/alert/AlertAction';
import CustomAlert from '../CustomAlert';
import { KivaSuiteLogo } from '../Logo';
import SuccessDisplay from './SuccessDisplay';
import ProductDisplay from './ProductDisplay';
import { Link } from 'react-router-dom';

/**
 *
 * Landing page
 */
const LandingPage = () => {

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>('');
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])
  const [{ alert }, alertDispatch] = useAlertStateValue()


  const handleSelectProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value === 'kiva-analytics') {
      if (selectedProducts.includes(value)) {
        setSelectedProducts(selectedProducts.filter(s => s !== value).filter(s => s !== 'kiva-analytics-installation-fee'));
      } else {
        setSelectedProducts(selectedProducts.concat(value, 'kiva-analytics-installation-fee'));
      }

    } else {
      if (selectedProducts.includes(value)) {
        setSelectedProducts(selectedProducts.filter(s => s !== value));
      } else {
        setSelectedProducts(selectedProducts.concat(value));
      }
    }
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    const msg = query.get('msg')

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }
    if (query.get('canceled')) {
      setSuccess(false);
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Order canceled", duration: null }))

    }
    if (msg && msg === 'validationerror') {
      setSuccess(false);
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please fill all required fields correctly!", duration: null }))
    }
    if (msg && msg === 'emailerror') {
      setSuccess(false);
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "You have already an account in Kiva Suite. Please login to manage your subscription.", duration: null }))
    }
  }, [sessionId]);


  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <CustomAlert />
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: '#fff', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ display: 'grid', alignContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Link to={'/login'} style={{ display: 'flex', alignItems: 'center'}}><KivaSuiteLogo width='124px' /></Link>
          </Box>
        </Toolbar>
      </AppBar>

      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 6, pb: 6 }}>
        <Box sx={{ display: 'grid', margin: 'auto', alignContent: 'center', justifyContent: 'center', flexGrow: 1, mb: 2 }}>
          <KivaSuiteLogo width='180px' />
        </Box>
        <Typography variant="h6" align="center" color="text.secondary" component="p">
          Analytics, digital marketing and comparison shopping services
        </Typography>


        {!success && message === '' && (
          <ProductDisplay selectedProducts={selectedProducts} handleSelectProduct={handleSelectProduct} />
        )}

        {success && sessionId !== '' && <SuccessDisplay sessionId={sessionId} />}

        {message && <>
          <Typography variant="h6" align="center" color="text.secondary" component="p">
            {message}
          </Typography>
          <ProductDisplay selectedProducts={selectedProducts} handleSelectProduct={handleSelectProduct} />
        </>
        }

      </Container>

      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 4,
          py: [3, 2],
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          Kiva Suite{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
      {/* End footer */}
    </React.Fragment>
  )
}

export default LandingPage