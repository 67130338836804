import { AdminAction, AdminState, SET_GOOGLE_ADS_CONFIG, SET_LINKED_IN_ASSETS, CLEAR_LINKEDIN_ASSETS } from "./AdminTypes";

export const initialAdminState: AdminState = {
  linkedInAssets: {
    adAccounts: [],
    currentAssets: {
      adAccount: { id: "", name: "", type: "" }
    },
    savedAssetsIntoDB: {
      adAccount: { id: "", name: "", type: "" }
    },
    openAssetsDialog: false
  },
  googleAdsConfig: {
    loginAccounts: [],
    adAccounts: [],
    currentSettings: {
      loginAccount: { id: "", name: "", customerClients: [] },
      adAccounts: [],
      childCustomers: []
    },
    savedSettingsIntoDB: {
      loginAccount: { id: "", name: "" },
      adAccounts: [],
      childCustomers: []
    },
    openConfigDialog: false
  }
}

const AdminReducer = (state = initialAdminState, action: AdminAction): AdminState => {
  switch (action.type) {
    case SET_LINKED_IN_ASSETS:
      return {
        ...state,
        linkedInAssets: action.data,
      };
    case SET_GOOGLE_ADS_CONFIG:
      return {
        ...state,
        googleAdsConfig: action.data
      }
    case CLEAR_LINKEDIN_ASSETS:
      return {
        ...state,
        linkedInAssets: {
          adAccounts: [],
          currentAssets: {
            adAccount: { id: "", name: "", type: "" }
          },
          savedAssetsIntoDB: {
            adAccount: { id: "", name: "", type: "" }
          },
          openAssetsDialog: false
        },
      }
    default:
      return state;
  }
};

export default AdminReducer;