import { dracula, atomOneLight, CopyBlock } from "react-code-blocks";

// https://www.npmjs.com/package/react-code-blocks
const CopyCodeBlock = ({ code, language, showLineNumbers }: any) => {
  return (
    <CopyBlock
      text={code}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={dracula}
      codeBlock
    />
  );
}
export default CopyCodeBlock;