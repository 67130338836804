import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export type BarChartProps = {
  options: any,
  data: any
}

const LineChart = (props: BarChartProps) => {
  
  return (
    <Line options={props.options} data={props.data} />
  )
}

export default LineChart