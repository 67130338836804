

import { Grid, Typography } from '@mui/material';
import { RuleEditor } from '../Rules';
import { apiGetCustomerDataSources, apiGetNotificationRules, apiGetSlackSettings, apiGetUsers, apiPutNotificationRules } from '../../api/Customer';
import { useEffect, useState } from 'react';
import { ManagedAdDataSource, NotificationRule, NotificationRulesResponse, SlackSettings, UsersResponse } from '../../api/Types';
import { desktop_h3 } from '../../styles/textStyles';

export type NotificationAdminProps = {
  customerId: string
}

export const NotificationsAdmin = (props: NotificationAdminProps) => {
  const { customerId } = props

  const [users, setUsers] = useState<UsersResponse | undefined>()
  const [slackChannels, setSlackChannels] = useState<string[]>([])
  const [rules, setRules] = useState<NotificationRulesResponse | undefined>()
  const [datasources, setDatasources] = useState<ManagedAdDataSource[]>([])
  useEffect(() => {
    apiGetUsers(customerId).then((users) => {
      setUsers(users)
    })
    apiGetNotificationRules(customerId).then((rules) => {
      setRules(rules)
    })
    apiGetCustomerDataSources(customerId).then((datasources) => {
      setDatasources(datasources)
    })
    apiGetSlackSettings(customerId).then((settings) => setSlackChannels(settings.channels.map(c => `#${c}`)))
  }, [customerId])

  function onRulesChange(newRules: NotificationRule[]) {
    if (JSON.stringify(newRules) !== JSON.stringify(rules)) {
      apiPutNotificationRules(customerId, newRules).then(() => {
        setRules(newRules)
      }).catch(() => {
        setRules(rules)
      })
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant='h3' sx={{...desktop_h3, mt: 4, mb: 2}}>New Rule</Typography>
      </Grid>
      <Grid item xs={12}>
        {users && rules &&
          <RuleEditor attributes={[
            { label: "Impressions", value: "impressions" },
            { label: "Clicks", value: "clicks" },
            { label: "Spend", value: "spend", "unit": "€" }
          ]} operators={[
            { label: "Less than", value: "smallerThan" },
            { label: "More than", value: "greaterThan" },
          ]} receivers={users.map(u => u.email).concat(slackChannels)} channels={[
            { label: "Display & Video", value: "dv360Ads" },
            { label: "Google Ads", value: "googleAds" },
            { label: "Meta", value: "metaAds" },
            { label: "Bing Ads", value: "bingAds" },
            { label: "Readpeak Ads", value: "readpeakAds" },
            {
              label: "Customer datasource", value: "datasource", attributes: datasources.map(ds => ({
                value: ds.key,
                label: ds.description,
                unit: ds.unit
              }))
            }
          ]} rules={rules} onRulesChange={(rules) => onRulesChange(rules)}
          />}
      </Grid>
    </Grid>
  )
}
