import styled from "styled-components";
import { useState, useEffect } from "react"

const ChartDiv = styled.div`
    position: relative;
    width: 100%;
    min-height: 200px;
    display:grid;
    margin:10px;
`

const BarDiv = styled.div`
  max-height:200px;
  min-height:200px;
  border-right:1px solid #fafafa;
  position:relative;
  cursor:pointer;
  &:hover {
    background-color:#fafafa;
  }
  user-select:none;
`

const LockedBarDiv = styled.div`
  max-height:200px;
  min-height:200px;
  border-right:1px solid #fafafa;
  position:relative;
  cursor:not-allowed;
  user-select:none;
`

const Scale = styled.div`
  text-align:right;
  padding-right:2px;
  font-size:0.8em;
  margin-top:-10px;
`

const BarLabelDiv = styled.div`
  min-height:100px;
  position:relative;
  user-select:none;
`

const BudgetBar = styled.div`
  position:absolute;
  bottom:0px;
  left:0px;
  width:100%;
  background-color:#f0f0f0;
  border-top:1px solid gray;
  border-left:1px solid gray;
  border-right:1px solid gray;
  pointer-events:none;
  user-select:none;
  border-radius:5px 5px 0px 0px;
`

const LockedBudgetBar = styled.div`
  position:absolute;
  bottom:0px;
  left:0px;
  width:100%;
  background-color:#e0e0e0;
  border-top:1px solid gray;
  border-left:1px solid gray;
  border-right:1px solid gray;
  pointer-events:none;
  user-select:none;
  border-radius:5px 5px 0px 0px;
`

const Label = styled.div`
  text-orientation:sideways;
  writing-mode:vertical-rl;
  position:absolute;
  top:5px;
  font-size:0.8em;
  user-select:none;
`

export type BarChartProps = {
  values: number[]
  labels?: string[]
  barPrefix: string
  fixedTotal?: number
  lockCount?: number
  onValuesChange: (newValues: number[]) => void
}


export const Bar = (props: { id: string, locked: boolean, onSetValue: (value: number) => void, leftMost: boolean, value: number }) => {
  const { id, leftMost, value, onSetValue, locked } = props
  return locked ?
    <LockedBarDiv style={{ borderLeft: leftMost ? '1px solid #fafafa' : '0px solid' }}>
      <LockedBudgetBar style={{ height: `${value * 100}%` }} />
    </LockedBarDiv> :
    <BarDiv style={{ borderLeft: leftMost ? '1px solid #fafafa' : '0px solid' }}
      onMouseDown={(ev) => {
        if (ev.buttons === 1 && !locked) {
          onSetValue((200 - ev.nativeEvent.offsetY) / 200.0)
        }
      }}
      onMouseMove={(ev) => {
        if (ev.buttons === 1 && !locked) {
          onSetValue((200 - ev.nativeEvent.offsetY) / 200.0)
        }
      }}
    >
      <BudgetBar style={{ height: `${value * 100}%` }} />
    </BarDiv>
}

export const BarLabel = (props: { value: string }) => {
  const { value } = props
  return (<BarLabelDiv>
    <Label>{value}</Label>
  </BarLabelDiv>)
}

export const BarChart = (props: BarChartProps) => {
  const { barPrefix, labels, onValuesChange } = props
  const [values, setValues] = useState<number[]>([])
  const max = values.reduce((all, v) => Math.max(all, v), values[0]) * 1.1

  const log = Math.pow(10, Math.floor(Math.log10(max)))
  const scaleMax = Math.ceil(max / log) * log

  useEffect(() => {
    setValues(props.values)
  }, [props.values])
  return (<ChartDiv style={{ gridTemplateColumns: `repeat(${values.length + 1}, 1fr)` }}>
    <Scale>{scaleMax}</Scale>
    {values.map((v, index) => <Bar id={`BarChartBar${index}`} locked={(props.lockCount || 0) >= index + 1} onSetValue={(value) => {
      const newValues = values.map((oldv, i) => index === i ? Math.round(value * scaleMax) : oldv)
      onValuesChange(newValues)
    }} leftMost={index === 0} value={v / scaleMax} />)}
    <Scale>0</Scale>
    {labels && labels.map(l => <BarLabel value={l} />)}
    {!labels && values.map((_, index) => <BarLabel value={`${barPrefix} ${index + 1}`} />)}
  </ChartDiv>)
}