import { YearForecast } from "../forecasts/YearForecast"
import styled from "styled-components"
import { useCustomerState } from "../context/customer/CustomerContext"
import { InfoText } from "./InfoText"
import { useEffect, useState } from "react"
import { apiGetDefaultPredictionSpends, apiGetPredictionScenarios, apiPostPredictions, apiPutPredictionScenario } from "../api/Customer"
import { ConversionPredictionList, ConversionPredictions, CustomerTree, PredictionScenario } from "../api/Types"
import { Button, MenuItem, TextField, Select, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl } from "@mui/material"
import { LoadingModal } from "./Modal"
import { FontFamilies } from "./Colors"
import { BudgetManager } from "./BudgetManager"
import { Create } from '@mui/icons-material';
import moment from "moment"
import { v4 as uuidv4 } from 'uuid'
import { range } from "../utils/Generators"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"

const Background = styled.div`
  display:grid;
  grid-template-rows:3em 1fr;
  position:relative;
  margin: 50px;
`

const ForecastHeader = styled.div`
`

const SelectTitle = styled.div`
  align-self:center;
  text-align:right;
`

const Header = styled.div`
  font-family: ${FontFamilies};
  font-size: 1.6em;
  white-space: nowrap;
  font-weight: 600;
`

const ModelParameters = styled.div`
  display:grid;
  grid-template-columns: 15em 1fr 1fr;
  padding:5px 5px 5px 15px;
  column-gap:10px;
  border:1px solid lightgray;
  border-radius:10px;
`

const Recalculate = styled.div`
  margin-left:auto;
  padding:5px 15px 5px 15px;
`

const ModelSelect = styled.div`
  display:grid;
  grid-template-columns: 5em 10em 2.5em 8em 10em;
  column-gap:20px;
  position:absolute;
  top:0px;
  right:0px;
`

const Parameters = styled.div`
  margin-top:10px;
  padding-top:5px;
  display:grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  column-gap:10px;
  margin-right:auto;
`

const BudgetTotal = styled.div`
align-self:center;
text-align:left;
`

export type PredictionProps = {
  selected?: CustomerTree
}

export const PredictionView = () => {
  const state = useCustomerState()
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState<ConversionPredictionList>([])
  const [predictions, setPredictions] = useState<ConversionPredictions | undefined>()
  const [needsCalculation, setNeedsCalculation] = useState(false)
  const [budgetOpen, setBudgetOpen] = useState(false)
  const [goalOpen, setGoalOpen] = useState(false)
  const [scenarioDialogOpen, setScenarioDialog] = useState(false)
  const [scenarios, setScenarios] = useState<PredictionScenario[]>([])
  const [newScenarioName, setNewScenarioName] = useState("")
  const [scenario, setScenario] = useState<PredictionScenario | undefined>()
  const [predictionStartDate, setPredictionStartDate] = useState<string>(moment().startOf("month").format("YYYY-MM-DD"))
  const { selected } = state

  function recalculatePrediction() {
    if (selected) {
      setLoading(true)
      setPredictions(undefined)
      apiPostPredictions(selected.id, moment(predictionStartDate).format("YYYY-MM-DD"), scenario).then((predictions) => {
        setLoading(false)
        setPredictions(predictions)
      })
    }
  }

  function resetBudget() {
    if (selected && scenario) {
      setLoading(true)
      apiGetDefaultPredictionSpends(selected.id).then((spends) => {
        const newScenario = { ...scenario, weeklyBudgets: spends }
        setScenario(newScenario)
        apiPutPredictionScenario(selected.id, newScenario).then(() => {
          apiPostPredictions(selected.id, moment(predictionStartDate).format("YYYY-MM-DD"), newScenario).then((predictions) => {
            setLoading(false)
            setPredictions(predictions)
          })
        }
        ).catch(() => setLoading(false))
      })
    }
  }

  useEffect(() => {
    if (selected) {
      apiGetPredictionScenarios(selected.id).then((scenarios) => {
        if (scenarios.length > 0) {
          setScenario(scenarios[0])
          setScenarios(scenarios)
          apiPostPredictions(selected.id, moment(predictionStartDate).format("YYYY-MM-DD"), scenarios[0]).then((predictions) => {
            setLoading(false)
            setPredictions(predictions)
          })
        } else {
          setLoading(false)
        }
      })
    }
  }, [selected])

  return <Background>
    <Header>Sales forecast</Header>
    {list && <>
      <ForecastHeader>
        <ModelSelect>
          <SelectTitle>Scenario</SelectTitle>
          {scenario && <Select size="small" onChange={(ev) => {
            const newScenario = scenarios.find(s => s.id === ev.target.value)
            setScenario(newScenario)
            if (selected) {
              setLoading(true)
              apiPostPredictions(selected.id, moment(predictionStartDate).format("YYYY-MM-DD"), newScenario).then((predictions) => {
                setLoading(false)
                setPredictions(predictions)
              })
            }
          }}
            value={scenario.id}>
            {scenarios.map(s => <MenuItem key={`scenario-${s.id}`} value={s.id}>{s.name}</MenuItem>)}
          </Select>}
          <IconButton onClick={() => setScenarioDialog(true)}>
            <Create />
          </IconButton>
          <SelectTitle>Prediction start</SelectTitle>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker onChange={(value) => {
                setPredictionStartDate(value ? moment(new Date(Date.parse(value.toString()))).format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD"))
                setNeedsCalculation(true)
              }}
                value={dayjs(predictionStartDate)}
                //renderInput={(params) => <TextField size="small" {...params} />}
                slots={{ 
                  textField: (params) => <TextField size="small" {...params} />
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </ModelSelect>
      </ForecastHeader>
      <Parameters>
        <ModelParameters>
          <BudgetTotal>Yearly budget: {scenario?.weeklyBudgets.reduce((t, c) => t + c, 0) || "N/A"} €</BudgetTotal>
          <Button variant="contained" color="secondary" size="small" onClick={() => setBudgetOpen(true)}>Adjust</Button>
          <Button variant="contained" color="warning" size="small" onClick={() => resetBudget()}>Reset</Button>
        </ModelParameters>
        <ModelParameters style={{ gridTemplateColumns: "15em 1fr" }}>
          <BudgetTotal>Yearly goal: {scenario?.weeklyGoals.reduce((t, c) => t + c, 0) || "N/A"} pcs</BudgetTotal>
          <Button variant="contained" color="secondary" size="small" onClick={() => setGoalOpen(true)}>Adjust</Button>
        </ModelParameters>
        {needsCalculation && <Recalculate>
          <Button
            variant="contained" color="primary" size="small" onClick={() => recalculatePrediction()}>Recalculate</Button>
        </Recalculate>}
      </Parameters>
    </>
    }
    {
      loading && <LoadingModal />}
    {
      predictions?.hourlyActuals ? <>
        <YearForecast modelDate={moment().format("YYYY-MM-DD")}
          allHourlyForecast={predictions?.hourlyForecasts || []}
          allHourlyActuals={predictions?.hourlyActuals || []}
          allHourlyGoals={predictions?.hourlyGoals || []} /></> :
        (loading ? <></> : <InfoText>There is no visualization available for this customer, please select another one.</InfoText>)
    }
    {
      budgetOpen && scenario && <BudgetManager title={"Budget Manager"} totalTitle={"Year total (€)"} weeklyValues={scenario.weeklyBudgets} onCancel={() => setBudgetOpen(false)}
        onSave={(weeklyBudgets) => {
          const updatedScenario = { ...scenario, weeklyBudgets }
          if (selected) {
            apiPutPredictionScenario(selected?.id, updatedScenario).then(() => {
              setScenarios(scenarios.map(s => s.id === updatedScenario.id ? updatedScenario : s))
              setScenario(updatedScenario)
              setBudgetOpen(false)
              setNeedsCalculation(true)
            })
          }
        }} />
    }
    {
      goalOpen && scenario && <BudgetManager title={"Goal Manager"} totalTitle={"Year total (pcs)"} weeklyValues={scenario.weeklyGoals} onCancel={() => setGoalOpen(false)}
        onSave={(weeklyGoals) => {
          const updatedScenario = { ...scenario, weeklyGoals }
          if (selected) {
            apiPutPredictionScenario(selected?.id, updatedScenario).then(() => {
              setScenarios(scenarios.map(s => s.id === updatedScenario.id ? updatedScenario : s))
              setScenario(updatedScenario)
              setGoalOpen(false)
              setNeedsCalculation(true)
            })
          }
        }} />
    }
    <Dialog
      open={scenarioDialogOpen}
    >
      <DialogTitle>
        Create Scenario
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please give name for the new scenario.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          value={newScenarioName}
          variant="standard"
          onChange={(ev) => { setNewScenarioName(ev.target.value) }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => setScenarioDialog(false)}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => {
          const newScenario = {
            id: uuidv4(), name: newScenarioName, year: moment().year(),
            weeklyBudgets: scenario?.weeklyBudgets.map(s => s) || range(52).map(_ => 100),
            weeklyGoals: scenario?.weeklyGoals.map(s => s) || range(52).map(_ => 100),
          }
          if (selected) {
            apiPutPredictionScenario(selected?.id, newScenario).then(() => {
              setNewScenarioName("")
              setScenarios(scenarios.concat([newScenario]))
              setScenarioDialog(false)
              setScenario(newScenario)
            })
          }
        }}>Create</Button>
      </DialogActions>
    </Dialog>
  </Background >
}