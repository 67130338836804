import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Box } from '@mui/material';
import { radioClasses } from "@mui/material/Radio";
import { desktop_p2 } from '../../../styles/textStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useStateValue } from '../../../context/ads/AdsContext';
import { setAdUnits } from '../../../context/ads/AdsActions';


export type ConversionItem = {
  id: string,
  title: string
}

const allConversions: ConversionItem[] = [  
{ id: "totalConversions", title: "Total" },
{ id: "mobileTotalConversions", title: "Mobile" },
{ id: "tabletTotalConversions", title: "Tablet" },
{ id: "desktopTotalConversions", title: "Desktop" },
{ id: "postClickConversions", title: "Post-click all" },
{ id: "postViewConversions", title: "Post-view all" }
]



export type ConversionsProps = {
  handleAdsSearchBtnClick: () => void
}

const Conversions = ({  handleAdsSearchBtnClick }: ConversionsProps) => {

  const [{ adUnits }, dispatch] = useStateValue()

  const { conversions } = adUnits

  const setConversions = (event: SelectChangeEvent) => {
    dispatch(setAdUnits({ ...adUnits, conversions: event.target.value }))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl sx={{ width: 142 }} size="small">
      <InputLabel id="demo-select-small-label">Conversions</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={conversions}
        label="Conversions"
        onChange={setConversions}
      >
        {allConversions.map(c =>  <MenuItem key={c.id} value={c.id}>{c.title}</MenuItem>)}
      </Select>
    </FormControl>
    </Box>
  );
}

export default Conversions