import { Dispatch } from "react";
import { apiGetCustomers } from "../../api/Customer";
import { CustomerTree } from "../../api/Types";
import { CustomersAction } from "./CustomerReducer";

export async function getCustomers(dispatch: Dispatch<CustomersAction>) {
  try {
    dispatch({ type: 'REQUEST_CUSTOMERS' });
    const customers = await apiGetCustomers()
    if (customers) {
      dispatch({ type: 'CUSTOMERS_LOADED', customers });
    } else {
      dispatch({ type: 'CUSTOMERS_ERROR', errorMessage: 'NoCustomers' });
    }
    return;
  } catch (error) {
    dispatch({ type: 'CUSTOMERS_ERROR', errorMessage: 'LoadingError' });
  }
}

export async function selectCustomer(selected: CustomerTree, dispatch: Dispatch<CustomersAction>) {
  try {
    dispatch({ type: 'CUSTOMERS_SELECTED', selected });
    return;
  } catch (errorMessage) {
    dispatch({ type: 'CUSTOMERS_ERROR', errorMessage: "LoadingError" });
  }
}

export async function unselectCustomer(dispatch: Dispatch<CustomersAction>) {
  try {
    dispatch({ type: 'CUSTOMERS_UNSELECTED' });
    return;
  } catch (errorMessage) {
    dispatch({ type: 'CUSTOMERS_ERROR', errorMessage: "LoadingError" });
  }
}