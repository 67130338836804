import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material"
import { ManagedAdDataSource, ManagedAdRule, ManagedAdRuleOperation } from "../../api/Types"
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { regularBtnStyles } from "../../styles/buttons"

const Title = styled.div`
  font-size:1.1em;
`

const RuleContainer = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 2em;
  row-gap:10px;
  column-gap:10px;
  margin-bottom:0.2em;
`

export type ManagedAdRulesProps = {
  rules: ManagedAdRule[]
  onRulesChange: (rules: ManagedAdRule[]) => void
  datasources: ManagedAdDataSource[]
}

const removeStyle = {
  color: 'red',
  alignSelf: 'center',
  gridColumnStart: 3,
  gridRowStart: 1,
  gridRowEnd: 3,
  cursor: 'pointer'
}

export const ManagedAdRules = (props: ManagedAdRulesProps) => {
  const { rules, onRulesChange, datasources } = props
  const newRule = {
    type: "Threshold",
    operationAbove: 'None',
    operationBelow: 'None',
    datasourceKey: '',
    value: 0
  }

  const setRule = (index: number, rule: ManagedAdRule) => {
    onRulesChange(rules.map((r, i) => i === index ? rule : r))
  }

  return (<Grid container spacing={2}>
    {rules.length === 0 && <Typography sx={{ml: 2, mt: 2}}>No rules defined.</Typography>}
    {rules.map((r, index) => {
      return (<Grid item xs={4}>
        <RuleContainer key={index}>
          <FormControl>
            <InputLabel id="type" size="small">Type</InputLabel>
            <Select label="Type" labelId="type" size="small" value={r.type} onChange={(event) => setRule(index, { ...r, type: event.target.value as any })}>
              <MenuItem value="Threshold">Threshold</MenuItem>
              <MenuItem value="Interval">Interval</MenuItem>
            </Select>
          </FormControl>
          {r.type === "Threshold" && <FormControl>
            <TextField label="Threshold" type="number" value={r.value} size="small"
              onChange={((event) => setRule(index, {
                ...r,
                value: parseFloat(event.target.value)
              }))} />
          </FormControl>}
          {r.type === "Interval" && <FormControl>
            <TextField label="Min" type="number" value={r.valueMin} size="small"
              onChange={((event) => setRule(index, {
                ...r,
                valueMin: parseFloat(event.target.value)
              }))} />
          </FormControl>}
          {r.type === "Interval" && <FormControl>
            <TextField label="Max" type="number" value={r.valueMax} size="small"
              onChange={((event) => setRule(index, {
                ...r,
                valueMax: parseFloat(event.target.value)
              }))} />
          </FormControl>}
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Tooltip title="Delete rule" arrow>
              <FontAwesomeIcon icon={faTrash} style={removeStyle} onClick={() => onRulesChange(rules.filter((_, i) => i !== index))} />
            </Tooltip>
          </Box>
          <FormControl>
            <InputLabel id="below" size="small">Below</InputLabel>
            <Select label="Below" labelId="below" size="small" value={r.operationBelow}
              onChange={(event) => { setRule(index, { ...r, operationBelow: event.target.value as ManagedAdRuleOperation }) }}>
              <MenuItem value="Activate">Activate</MenuItem>
              <MenuItem value="Pause">Pause</MenuItem>
              <MenuItem value="None">None</MenuItem>
            </Select>
          </FormControl>
          {r.type === "Interval" && <FormControl>
            <InputLabel id="between" size="small">Between</InputLabel>
            <Select label="Betweem" labelId="between" size="small" value={r.operationBetween}
              onChange={(event) => { setRule(index, { ...r, operationBetween: event.target.value as ManagedAdRuleOperation }) }}>
              <MenuItem value="Activate">Activate</MenuItem>
              <MenuItem value="Pause">Pause</MenuItem>
              <MenuItem value="None">None</MenuItem>
            </Select>
          </FormControl>}
          <FormControl>
            <InputLabel id="above" size="small">Above</InputLabel>
            <Select label="Above" labelId="above" size="small" value={r.operationAbove}
              onChange={(event) => setRule(index, { ...r, operationAbove: event.target.value as ManagedAdRuleOperation })}>
              <MenuItem value="Activate">Activate</MenuItem>
              <MenuItem value="Pause">Pause</MenuItem>
              <MenuItem value="None">None</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
            <InputLabel size="small">Data source</InputLabel>
            <Select error={!r.datasourceKey} size="small" label="Data source" value={r.datasourceKey}
              onChange={(event) => { setRule(index, { ...r, datasourceKey: event.target.value }) }}>
              {datasources.map(p => <MenuItem key={p.key} value={p.key}>{p.key}({p.value}{p.unit})</MenuItem>)}
            </Select>
            {!r.datasourceKey &&<Typography variant="caption" color="error">Data source can not be empty. Please select a data source or remove the rule.</Typography>}
          </FormControl>
        </RuleContainer>
      </Grid>)
    })}
    <Grid item xs={12} marginTop={4}>
      <Button variant="contained" size="small" style={{ width: '8em' }} onClick={() => onRulesChange([newRule].concat(rules as any) as ManagedAdRule[])}
        sx={regularBtnStyles}>Add rule</Button>
    </Grid>
  </Grid>)
}