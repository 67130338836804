import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCustomerState } from '../../context/customer/CustomerContext';
import { regularBtnStyles } from '../../styles/buttons';
import { CustomDataset, CustomModel } from '../../api/Types';
import { apiGetCustomDataset, apiGetCustomDatasets, apiGetCustomModels } from '../../api/Assistant';
import { CustomDatasetRowView } from './CustomDatasetRowView';
import { LoadingModal } from '../Modal';
import { CreateTeachingMaterialsDialog } from './CreateTeachingMaterialsDialog';
import { CreateCustomModelDialog } from './CreateCustomModelDialog';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CustomModelView } from './CustomModelView';
import { CustomBot } from './CustomBot';


const Container = styled.div`
  margin:1em;
`

const Header = styled.div`
  margin-top:1em;
  margin-bottom:0.5em;
  font-size:2.0em;
`

const SubHeader = styled.div`
  margin-top:1em;
  margin-bottom:0.5em;
  font-size:1.1em;
`

const CreateContainer = styled.div`
  grid-column-start:1;
  grid-column-end:3;
  grid-template-columns:2fr 1fr 2fr 1fr;
  display:grid;
  column-gap:0px;
  margin-bottom:20px;
`

const DatasetContainer = styled.div`

`

const ModelContainer = styled.div`

`

export const CustomAssistantView = () => {
  const customer = useCustomerState()
  const [loading, setLoading] = useState(false)
  const [createDatasetOpen, setCreateDatasetOpen] = useState(false)
  const [customDatasets, setCustomDatasets] = useState<CustomDataset[]>([])
  const [selectedDataset, setSelectedDataset] = useState<undefined | CustomDataset>()
  const [selectedModel, setSelectedModel] = useState<undefined | CustomModel>()
  const [customModels, setCustomModels] = useState<CustomModel[]>([])
  const [createModelOpen, setCreateModelOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState("Models")

  useEffect(() => {
    if (customer.selected) {
      setLoading(true)
      Promise.all([apiGetCustomDatasets(customer.selected.id).then((customDatasets: CustomDataset[]) => {
        setLoading(false)
        setCustomDatasets(customDatasets)
      }),
      apiGetCustomModels(customer.selected.id).then((customModels: CustomModel[]) => {
        setCustomModels(customModels)
      })]).then(() => setLoading(false)).catch(() => setLoading(false))
    }
  }, [customer])

  function selectCustomDataset(id: string) {
    setLoading(true)
    apiGetCustomDataset(customer.selected!.id, id).then((dataset: CustomDataset) => {
      setLoading(false)
      setSelectedDataset(dataset)
    }).catch(() => { setLoading(false) })
  }

  return (customer.selected ? <Container>
    <Header>Custom Assistant</Header>
    <TabContext value={selectedTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(_, newValue) => setSelectedTab(newValue)}>
          <Tab label="Teaching Materials" value="Materials" />
          <Tab label="Models" value="Models" />
          <Tab label="Batch Creation" value="Batch" />
          <Tab label="Chatbot" value="Bot" />
        </TabList>
      </Box>
      <TabPanel value="Models">
        <CreateContainer>
          <FormControl sx={{ marginRight: 1, minWidth: 300 }}>
            <InputLabel size="small">Models</InputLabel>
            <Select label="Models" value={selectedModel?.id} size="small" onChange={(e) => {
              setSelectedModel(customModels.find(cm => cm.id === e.target.value))
            }}>
              {customModels.map(cm => <MenuItem value={cm.id}>{cm.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 150 }}>
            <Button sx={regularBtnStyles} onClick={() => { setCreateModelOpen(true) }}>Create new</Button>
          </FormControl>
        </CreateContainer>
        {selectedModel && customer && <CustomModelView setLoading={setLoading} customerId={customer.selected.id} customModel={selectedModel} />}
      </TabPanel>
      <TabPanel value="Batch"></TabPanel>
      <TabPanel value="Materials">
        <CreateContainer>
          <FormControl sx={{ marginRight: 1, minWidth: 300 }}>
            <InputLabel size="small">Teaching materials</InputLabel>
            <Select label="Teaching materials" value={selectedDataset?.id} size="small" onChange={(e) => { selectCustomDataset(e.target.value as string) }}>
              {customDatasets.map(cm => <MenuItem value={cm.id}>{cm.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 150 }}>
            <Button sx={regularBtnStyles} onClick={() => { setCreateDatasetOpen(true) }}>Create new</Button>
          </FormControl>
        </CreateContainer>
        {selectedDataset && <DatasetContainer>
          <SubHeader>{selectedDataset.name}</SubHeader>
          <CustomDatasetRowView customerId={customer.selected!.id} datasetId={selectedDataset.id} setLoading={setLoading} />
        </DatasetContainer>}
      </TabPanel>
      <TabPanel value="Bot">
        <CreateContainer>
          <FormControl sx={{ marginRight: 1, minWidth: 300 }}>
            <InputLabel size="small">Models</InputLabel>
            <Select label="Models" value={selectedModel?.id} size="small" onChange={(e) => {
              setSelectedModel(customModels.find(cm => cm.id === e.target.value))
            }}>
              {customModels.map(cm => <MenuItem value={cm.id}>{cm.name}</MenuItem>)}
            </Select>
          </FormControl>
        </CreateContainer>
        {selectedModel && <CustomBot customerId={customer.selected!.id} customModel={selectedModel} setLoading={setLoading} />}
      </TabPanel>
    </TabContext>

    <CreateTeachingMaterialsDialog customerId={customer.selected!.id}
      open={createDatasetOpen}
      setLoading={setLoading}
      setOpen={setCreateDatasetOpen}
      onCreate={(customDatasets) => setCustomDatasets(customDatasets)}
    />
    <CreateCustomModelDialog customerId={customer.selected!.id}
      open={createModelOpen}
      setLoading={setLoading}
      setOpen={setCreateModelOpen}
      onCreate={(customModels) => setCustomModels(customModels)}
    />
    {loading && <LoadingModal />}
  </Container> : null)
}