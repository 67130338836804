export type CheckoutRequestBody = {
  userEmail: string
  companyName: string
  lookupKeys: string[],
  googleAnalytics4Id?: string,
  facebookPageId?: string,
  adAccountId?: string
}

export const kivaSuiteProducts = [
  {
    key: 'kiva-analytics',
    label: 'Kiva Analytics 249 €/month (+VAT 25,5%)',
  },
  {
    key: 'kiva-analytics-installation-fee',
    label: 'Kiva Analytics installation fee 950 €/one time (+VAT 25,5%)',
  },
  {
    key: 'meta-post-booster',
    label: 'Meta Post Booster 99 €/month (+VAT 25,5%)',
  },
  {
    key: 'kiva-css',
    label: 'Kiva CSS 99 €/month (+VAT 25,5%)',
  },
  {
    key: 'ads-reporting',
    label: 'Ads Reporting 99 €/month (+VAT 25,5%)',
  }
];
