import styled from "styled-components"
import { RGBColorToCss, addMouseDownMoveListener, mapCoordinateToDragType, snapToGrid } from "../Utils"
import { AdComponent, HtmlAdComponent, ImageAdComponent, RectangleAdComponent, TextAdComponent } from "./Types"
import { AnimationFrames, getAnimationTotalTime } from "./Animation"


const ComponentContainer = styled.div`
  border: 1px dotted white;
  border-radius:0px;
  margin:0px;
  padding:0px;
  overflow:hidden;
  position:relative;
`

const TextContainer = styled.div`
  margin:0px;
  padding:0px;
  position:absolute;
  pointer-events:none;
`

const RectangleContainer = styled.div`
  margin:0px;
  padding:0px;
  position:absolute;
  pointer-events:none;
`

const HtmlContainer = styled.div`
  margin:0px;
  padding:0px;
  position:relative;
  pointer-events:none;
`


const ImageField = styled.img`
  user-select:none;
  pointer-events:none;
`

const ResizeContainer = styled.div`
  position:absolute;
  bottom:-1px;
  right:-1px;
  color:white;
  box-shadow: 0px 0px 2px black;
  border:1px solid; white;
  cursor: nwse-resize;
  border-top-left-radius:5px;

  &:hover {
    background-color:black;
  }
`

const MoveContainer = styled.div`
  position:absolute;
  top:-1px;
  left:-1px;
  color:white;
  box-shadow: 0px 0px 2px black;
  border:1px solid white;
  cursor: move;

  &:hover {
    background-color:black;
  }
`

export interface DrawComponentProps {
  snapGridEnabled: boolean,
  editMode: boolean,
  zoom: number,
  gridSize: number,
  c: AdComponent,
  createUndopoint: () => void,
  updateComponent: (c: AdComponent) => void,
  removeComponent: () => void
}

export const DrawComponent = (props: DrawComponentProps) => {
  const { gridSize, c, createUndopoint, updateComponent, editMode, zoom, snapGridEnabled } = props
  const t = c as TextAdComponent
  const i = c as ImageAdComponent
  const h = c as HtmlAdComponent
  const r = c as RectangleAdComponent
  const grabSize = Math.max(5, gridSize)

  const getRectangleBorderRadius = () => {
    return (c.type === "Rectangle" && r !== undefined && r.borderRadius.setSeparately ?
      `${r.borderRadius.topLeft}${r.borderRadius.unit} ${r.borderRadius.topRight}${r.borderRadius.unit} ${r.borderRadius.bottomRight}${r.borderRadius.unit} ${r.borderRadius.bottomLeft}${r.borderRadius.unit}`
      : `${r.borderRadius?.all}${r.borderRadius?.unit}`)
  }


  return (<ComponentContainer style={{
    position: 'absolute',
    zIndex: c.area.zIndex,
    left: c.area.x,
    top: c.area.y,
    width: c.area.width,
    height: c.area.height,
    backgroundColor: t.backgroundColor ? RGBColorToCss(t.backgroundColor) : (editMode ? 'rgba(0,0,0,0.05)' : undefined),
    borderStyle: editMode === true ? 'solid' : 'dotted',
    boxShadow: editMode === true ? '0px 0px 2px black' : 'none',
    borderColor: 'white',
    ...(!editMode ? { userSelect: 'none', pointerEvents: 'none' } : {}),
    cursor: editMode === true ? 'grab' : undefined,
    borderRadius: c.type === "Rectangle" ? getRectangleBorderRadius() : 0
  }} onMouseDown={(event) => {
    if (editMode && !c.area.xyLocked) {
      event.preventDefault()
      const dragType = mapCoordinateToDragType(event.nativeEvent.offsetX, event.nativeEvent.offsetY, c.area.width, c.area.height, grabSize)
      if (!dragType) {
        createUndopoint()
        addMouseDownMoveListener({ x: 0, y: 0, area: c.area }, (state, event) => {
          state.area.x = Math.floor(state.area.x + event.movementX / zoom)
          state.area.y = Math.floor(state.area.y + event.movementY / zoom)
          updateComponent({ ...c, area: state.area })
        }, (state) => updateComponent({ ...c, area: snapGridEnabled ? snapToGrid(state.area, gridSize) : state.area }))
      }
    }
  }} >
    {t.animation ? <AnimationFrames id={`frames-${c.id}`} animation={t.animation} /> : undefined}
    {c.type === "Text" && <TextContainer data-animated-component={true} style={{
      fontFamily: t.font,
      fontSize: t.fontSize,
      color: RGBColorToCss(t.color),
      backgroundColor: RGBColorToCss(t.backgroundColor),
      fontWeight: t.bold ? "bold" : "normal",
      fontStyle: t.italic ? "italic" : "normal",
      textDecoration: t.underline ? "underline" : "",
      textAlign: t.alignHorizontal,
      overflow: 'hidden',
      lineHeight: `${t.lineHeight || 100}%`,
      width: '100%',
      ...(t.alignVertical === "center" && { top: '50%', transform: 'translateY(-50%)' }),
      ...(t.alignVertical === "bottom" && { bottom: '0px', position: 'absolute' }),
      ...(t.animation ? {
        animationDuration: `${getAnimationTotalTime(t.animation) / 1000}s`,
        animationName: `frames-${c.id}`,
        animationTimingFunction: 'ease-out'
      } : {})
    }}>{t.isDynamic ? `"${t.text}"` : t.text}</TextContainer>
    }
    {c.type === "Rectangle" && <RectangleContainer
      style={{
        backgroundColor: RGBColorToCss(r.backgroundColor),
        width: '100%'
      }}
    ></RectangleContainer>}
    {c.type === "Image" && <ImageField data-animated-component={true} src={i.imageFile}
      style={{
        objectFit: i.objectFit, ...(i.objectFit !== 'none' ? { width: '100%', height: '100%' } : {}),
        ...(t.animation ? {
          animationDuration: `${getAnimationTotalTime(t.animation) / 1000}s`,
          animationName: `frames-${c.id}`,
          animationTimingFunction: 'ease-out'
        } : {})
      }} />}
    {c.type === "Html" && <HtmlContainer style={{ width: h.area.width, height: h.area.height }}>
      <iframe style={{ border: 0, overflow: 'hidden' }} scrolling="no" srcDoc={h.html} width={h.area.width} height={h.area.height} />
    </HtmlContainer>}
    {editMode && <ResizeContainer style={{ minWidth: grabSize, minHeight: grabSize }} onMouseDown={(event) => {
      if (editMode && !c.area.widthHeightLocked) {
        event.preventDefault()
        createUndopoint()
        addMouseDownMoveListener({ x: 0, y: 0, area: c.area }, (state, event) => {
          state.area.width = Math.floor(state.area.width + event.movementX / zoom)
          state.area.height = Math.floor(state.area.height + event.movementY / zoom)
          updateComponent({ ...c, area: state.area })
        }, (state) => updateComponent({ ...c, area: snapGridEnabled ? snapToGrid(state.area, gridSize) : state.area }))
      }
    }}></ResizeContainer>}
    {editMode && <MoveContainer style={{ minWidth: grabSize, minHeight: grabSize }}
      onMouseDown={(event) => {
        if (editMode && !c.area.xyLocked) {
          event.preventDefault()
          createUndopoint()
          addMouseDownMoveListener({ x: 0, y: 0, area: c.area }, (state, event) => {
            state.area.x = Math.floor(state.area.x + event.movementX / zoom)
            state.area.y = Math.floor(state.area.y + event.movementY / zoom)
            updateComponent({ ...c, area: state.area })
          }, (state) => updateComponent({ ...c, area: snapGridEnabled ? snapToGrid(state.area, gridSize) : state.area }))
        }
      }}></MoveContainer>}
  </ComponentContainer >)
}
