import { Alert, AlertAction, GET_ALERT, REMOVE_ALERT, SET_ALERT } from "./AlertTypes";

export const getAlert = (): AlertAction => {
  return { type: GET_ALERT }
}

export const setAlert = (alert: Alert): AlertAction => {
  return { type: SET_ALERT, alert }
}

export const removeAlert = (): AlertAction => {
  return { type: REMOVE_ALERT }
}