import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { closeIcon } from '../../utils/icons';
import { neutralColors } from '../../styles/colors';
import { desktop_h2, desktop_p2 } from '../../styles/textStyles';
import { StyledMenu } from './StyledMenu';
import AdsSearchBtn from './AdsSearchBtn';
import { useStateValue } from '../../context/ads/AdsContext';
import { setAdUnits } from '../../context/ads/AdsActions';

const allStatuses : string[] = ["Active", "Paused", "Archived"]

export type StatusesProps = {
  handleAdsSearchBtnClick: () => void
}

const Statuses = ({ handleAdsSearchBtnClick }: StatusesProps) => {

  const [{ adUnits }, dispatch] = useStateValue();
  const { statuses } = adUnits

  const selectSingleStatus = (value: string) => {
    dispatch(setAdUnits({ ...adUnits, statuses: [value] }))

  }
  const selectAllStatuses = () => {
    dispatch(setAdUnits({ ...adUnits, statuses: allStatuses }))
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleAdsSearchBtnClick();
  };

  const [selectAll, setSelectAll] = useState<boolean>(statuses.length === 3 ? true : false)

  const handleSelectAllStatuses = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      selectAllStatuses()
    } else {
      dispatch(setAdUnits({ ...adUnits, statuses: statuses }))
    }
    setSelectAll(checked)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const i = event.target.value;
    let updatedStatuses = null;

    if (checked && (statuses).indexOf(i) === -1) {
      updatedStatuses = [...statuses, i]
    } else {
      updatedStatuses = statuses.filter(c => c !== i)
    }
    dispatch(setAdUnits({ ...adUnits, statuses: updatedStatuses }))

    if (updatedStatuses?.length === allStatuses.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={''}
        sx={{
          textTransform: 'none',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
          background: "#fff",
          minWidth:'124px',
          color: "#000",
          borderRadius: '5px',
          border: '0.5px solid #000',
          ":hover": { background: '#fff' }
        }}
      >
        Statuses {selectAll ? "(all)" : `(${statuses.length})`}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <Grid container sx={{ padding: '32px', width: '100%', background: neutralColors[50] }}>

          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
            <Box >
              <Typography variant='h2' sx={desktop_h2}>Statuses</Typography>
            </Box>
            <Box sx={{}}>
              <IconButton onClick={handleClose}>
                {closeIcon()}
              </IconButton>
            </Box>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width: '100%' }}>
            <Box>
              <FormControlLabel
                label={"All"}
                sx={desktop_p2}
                control={<Checkbox checked={selectAll} onChange={handleSelectAllStatuses}
                  sx={{}}
                />}
              />
            </Box>
            {allStatuses.map((i: any) => {
              const checked = (statuses?.length === allStatuses.length && selectAll) ? true : (statuses).indexOf(i) > -1;

              return (<Grid key={`${i}`} container sx={{ justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}  >
                <FormControlLabel
                  label={i}
                  sx={desktop_p2}
                  control={<Checkbox checked={checked} value={i} onChange={handleCheckboxChange}
                    sx={{}}
                  />}
                />

                <Button onClick={(event) => {
                  event.stopPropagation();
                  if (checked && (statuses.length === 1)) {
                    selectAllStatuses()
                    setSelectAll(true)
                  }
                  else {
                    selectSingleStatus(i)
                    setSelectAll(false)
                  }
                }}
                  sx={desktop_p2}
                >

                  {checked && (statuses.length === 1) ? "ALL" : "ONLY"}
                </Button>
              </Grid>)

            })}
          </Box>

          <Grid container sx={{ justifyContent: 'right', marginTop: '64px' }} >
            <AdsSearchBtn handleAdsSearchBtnClick={handleClose} />
          </Grid>

        </Grid>
      </StyledMenu>
    </div>
  );
}

export default Statuses