import { Button, FormControl } from '@mui/material'
import React from 'react'
import { regularBtnStyles } from '../../styles/buttons'

export type AdsSearchBtnProps = {
  handleAdsSearchBtnClick: () => void
}
const AdsSearchBtn = ({handleAdsSearchBtnClick}: AdsSearchBtnProps) => {
  return (
    <FormControl>
      <Button onClick={handleAdsSearchBtnClick} sx={regularBtnStyles} variant="contained">Search</Button>
  </FormControl>
  )
}

export default AdsSearchBtn