import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiAdManagedAdTypeFromHtml, apiDeleteManagedAd, apiDeleteManagedAdInstance, apiGetCustomerDataSources, apiGetManagedAdContent, apiGetManagedVideoPreview, apiSetManagedAdName, apiSetManagedAdRules, apiSetManagedAdSynchronize, apiSetUpdates, apiUpdateManagedAdFromTemplate, apiUploadManagedAdMaterial } from '../../api/Customer';
import { ManagedAd, ManagedAdDataSource, ManagedAdInstance, ManagedAdType, ManagedAdTypeDef } from '../../api/Types';
import { debounce } from '../../utils/Generators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { DeleteItem, useIsMount } from '../Utils';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { setAlert } from '../../context/alert/AlertAction';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { ManagedAdRules } from './ManagedAdRules';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { Add, Download, ExpandLess, Html, Image, VideoCameraBack } from '@mui/icons-material';
import { LoadingModal } from '../Modal';
import { TemplateParametersView } from './TemplateParametersView';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ManagedAdPlatformView } from './ManagedAdPlatformView';
import { ManagedAdFilesView } from './ManagedFilesView';
import { regularBtnStyles, svgStyles } from '../../styles/buttons';
import SaveIcon from '@mui/icons-material/Save';
import DeleteDialog from '../DeleteDialog';

const Controls = styled.div`
  position:absolute;
  top:0.5m;
  right:0em;
  cursor:pointer;
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  column-gap:5px;
`

const VideoPreview = styled.video`
  margin: 10px 10px 70px 10px;
`

const ImagePreview = styled.img`
  margin: 10px 10px 70px 10px;
`

const PreviewContainer = styled.div`
  min-width:300px;
`

const AdHeading = styled.div`
  padding:1em;
  display:grid;
  grid-template-columns:1fr 4em 4em 2.5em;
`


const debouncedSetManagedAdName = debounce(apiSetManagedAdName)
const debouncedSetManagedAdRules = debounce(apiSetManagedAdRules)
const debouncedSetUpdates = debounce(apiSetUpdates)

const ManagedAdView = (props: { customerId: number, ad: ManagedAd, onDelete: () => void }) => {
  const isMount = useIsMount()
  const { customerId, ad, onDelete } = props

  const [requiresUpdate, setRequiresUpdate] = useState(false)
  const [types, setTypes] = useState(ad.types)
  const [updateTime, setUpdateTime] = useState(ad.updates || { type: "daily", hour: 23, minute: 0 })
  const [docContent, setDocContent] = useState("")
  const [loading, setLoading] = useState(false)
  const [heavyLoading, setHeavyLoading] = useState(false)
  const [datasources, setDataSources] = useState([] as ManagedAdDataSource[])
  const [instances, setInstances] = useState(ad.instances)
  const [currentInstance, setCurrentInstance] = useState<ManagedAdInstance | undefined>(ad.instances[0])
  const [synchronize, setSynchronize] = useState(ad.synchronize)
  const [rules, setRules] = useState(ad.rules)
  const [editName, setEditName] = useState(false)
  const [adName, setAdName] = useState(ad.name)
  const [_, alertDispatch] = useAlertStateValue()
  const [previewType, setPreviewType] = useState<"HTML" | "Image" | "Video">("HTML")
  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | undefined>()
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [addTypeDialogOpen, setAddTypeDialogOpen] = useState(false)
  const [newAdType, setNewAdType] = useState("Video")
  const [newAdTypeDurationMs, setNewAdTypeDurationMs] = useState(0)
  const [tabValue, setTabValue] = useState("1")

  useEffect(() => {
    if (requiresUpdate) {
      setRequiresUpdate(false)
      setLoading(true)
      setImagePreviewUrl(undefined)
      setVideoPreviewUrl(undefined)
      setPreviewType("HTML")
      Promise.all([
        apiGetCustomerDataSources(customerId.toString()).then((updatedDatasources) => {
          setDataSources(updatedDatasources)
        }),
        apiGetManagedAdContent(currentInstance?.previewUrl || ad.previewUrl).then((response) => {
          setDocContent(response);
        })
      ]).then(() => setLoading(false)).catch(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [requiresUpdate])

  const createVideoAd = () => {
    if (currentInstance) {
      setHeavyLoading(true)
      apiGetManagedVideoPreview(customerId, ad.creativeId, currentInstance.width, currentInstance.height).then((response) => {
        setImagePreviewUrl(response.imageUrl)
        setVideoPreviewUrl(response.videoUrl)
        setHeavyLoading(false)
      }).catch(() => {
        setHeavyLoading(false)
      })
    }
  }

  const updateFromTemplate = () => {
    if (ad.templateId) {
      setHeavyLoading(true)
      apiUpdateManagedAdFromTemplate(customerId, ad.creativeId).then(() => {
        setHeavyLoading(false)
        setRequiresUpdate(true)
      }).catch(() => {
        setHeavyLoading(false)
      })
    }
  }

  useEffect(() => {
    if ((previewType === "Image" || previewType === "Video") && (!imagePreviewUrl || !videoPreviewUrl)) {
      createVideoAd()
    }
  }, [previewType])

  useEffect(() => {
    if (!isMount) {
      debouncedSetManagedAdName(customerId, ad.creativeId, adName)
    }
  }, [adName])

  useEffect(() => {
    if (!isMount) {
      debouncedSetManagedAdRules(customerId, ad.creativeId, rules)
    }
  }, [rules])

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleDeleteManagedAd = () => {
    setLoading(true)
    apiDeleteManagedAd(customerId, ad.creativeId).then(() => {
      setLoading(false)
      onDelete()
    }).catch(() => setLoading(false))
  }

  return (
    <Accordion sx={{}} expanded={expanded}>
      {loading && <LoadingModal />}
      <AdHeading>
        <Typography sx={{ width: '80%', flexShrink: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {editName ? <TextField size='small' sx={{ width: '260px' }} value={adName} onKeyDown={(event) => { if (event.key === 'Enter') setEditName(false) }} onChange={(event) => setAdName(event.target.value)} />
            : <>
              <span>{adName}</span>
            </>}
          <Tooltip sx={{ ml: 2 }} title={editName ? "Save name" : "Edit name"} placement="top" arrow onClick={() => setEditName(!editName)}>
            <IconButton>{editName ? <SaveIcon sx={{...svgStyles("20px", "20px")}}/> : <EditIcon sx={{...svgStyles("20px", "20px")}} />}</IconButton>
          </Tooltip>
        </Typography>

        <Typography sx={{ width: '80%', flexShrink: 0 }}>
          <Tooltip sx={{ ml: 2 }} title="Update from template" placement="top" arrow>
            <IconButton disabled={!ad.templateId} style={{}}><Download onClick={() => updateFromTemplate()} /></IconButton>
          </Tooltip>
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
         
            <DeleteDialog title={"Delete Dynamic Ad"} text={<>Are you sure you would like to delete the dynamic ad named "<b>{adName}</b>"?</>} handleDeleteClick={handleDeleteManagedAd}/>
        </Typography>
        <IconButton onClick={() => {
          if (!expanded) {
            setRequiresUpdate(true)
          }
          setExpanded(!expanded)
        }}>{expanded ? <ExpandLess /> : <ExpandMoreIcon />}</IconButton>
      </AdHeading>
      {expanded !== false && <AccordionDetails>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <div style={{ alignItems: 'center', display: 'flex', marginBottom: '1em' }}>
              {!loading && <Controls>
                <DeleteItem onClick={() => { setDeleteDialogOpen(true) }} />
                <FontAwesomeIcon icon={faRefresh} onClick={() => {
                  setDocContent("")
                  setLoading(true)
                  setRequiresUpdate(true)
                }} />
              </Controls>}
              <ToggleButtonGroup size="small" exclusive={true}
                value={previewType} onChange={(_, value) => setPreviewType(value)}>
                {types.find(t => t.type === "HTML") && <ToggleButton value="HTML"><Html /></ToggleButton>}
                {types.find(t => t.type === "Video") && <ToggleButton value="Video"><VideoCameraBack /></ToggleButton>}
                {types.find(t => t.type === "Image") && <ToggleButton value="Image"><Image /></ToggleButton>}
              </ToggleButtonGroup>
              {types.length < 3 && <IconButton onClick={() => setAddTypeDialogOpen(true)}><Add /></IconButton>}
              <ToggleButtonGroup size="small" exclusive={true} value={currentInstance} style={{ marginLeft: '1em' }} onChange={(_, value) => {
                setLoading(true)
                setCurrentInstance(value)
                setRequiresUpdate(true)
                setDocContent("")
              }}>
                {instances.map(i => <ToggleButton value={i}>
                  {i.width}x{i.height}
                </ToggleButton>)}
              </ToggleButtonGroup>
            </div>
            <Grid item xs={12}>
              <PreviewContainer style={{ minWidth: currentInstance?.width, minHeight: currentInstance?.height, position: 'relative', backgroundColor: '#f0f0f0', boxShadow: '1px 1px 3px gray' }}>
                {previewType === "HTML" && currentInstance && <iframe srcDoc={docContent} width={currentInstance?.width + 10} height={currentInstance?.height + 10} />}
                {previewType === "Video" && videoPreviewUrl && <VideoPreview src={videoPreviewUrl} width={currentInstance?.width} height={currentInstance?.height} controls />}
                {previewType === "Image" && imagePreviewUrl && <ImagePreview src={imagePreviewUrl} width={currentInstance?.width} height={currentInstance?.height} />}
              </PreviewContainer>
            </Grid>
          </Grid>

          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(_, value) => setTabValue(value)}>
                  <Tab label="Parameters" value="1" />
                  <Tab label="Rules" value="2" />
                  <Tab label="Platforms" value="3" />
                  <Tab label="Files" value="4" />
                  <Tab label="Settings" value="5" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {!heavyLoading && <TemplateParametersView customerId={customerId} creativeId={ad.creativeId} datasources={datasources}
                  onUpdated={() => setRequiresUpdate(true)} />}
              </TabPanel>
              <TabPanel value="2">
                {rules && <ManagedAdRules rules={rules} datasources={datasources} onRulesChange={(rules) => setRules(rules)} />}
              </TabPanel>
              <TabPanel value="3">
                <ManagedAdPlatformView customerId={customerId} creativeId={ad.creativeId} />
              </TabPanel>
              <TabPanel value="4">
                <ManagedAdFilesView customerId={customerId} creativeId={ad.creativeId} currentInstance={currentInstance} />
              </TabPanel>
              <TabPanel value="5">
                <Stack spacing={2} marginTop={2} marginBottom={4} maxWidth={400}>
                  <FormControl>
                    <TextField type={"time"} size="small" label="Time of update"
                      value={`${updateTime.hour.toString().padStart(2, "0")}:${updateTime.minute.toString().padStart(2, "0")}`}
                      onChange={(ev) => {
                        const value = ev.target.value
                        const updates = {
                          ...updateTime, hour: parseInt(value.substring(0, 2), 10),
                          minute: parseInt(value.substring(3), 10)
                        }
                        setUpdateTime({
                          ...updateTime,
                          hour: parseInt(value.substring(0, 2), 10),
                          minute: parseInt(value.substring(3), 10)
                        })
                        debouncedSetUpdates(customerId, ad.creativeId, updates)
                      }}
                      inputProps={{
                        step: 900
                      }} />
                  </FormControl>
                  <FormGroup>
                    <FormControlLabel control={
                      <Switch checked={synchronize} onChange={(event) => {
                        setLoading(true)
                        setSynchronize(event.target.checked)
                        apiSetManagedAdSynchronize(customerId, ad.creativeId, event.target.checked).then(() => {
                          setLoading(false)
                        }).catch(() => {
                          setSynchronize(false)
                          setLoading(false)
                          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Synchronize failed. Check your settings.", duration: 3000 }))
                        })
                      }}
                      />} label="Synchronize" />
                  </FormGroup>
                  <FormGroup>
                    <Button sx={{ ...regularBtnStyles, maxWidth: '12em' }} onClick={() => {
                      setLoading(true)
                      apiSetManagedAdSynchronize(customerId, ad.creativeId, true).then(() => {
                        setLoading(false)
                      }).catch(() => {
                        setLoading(false)
                        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Update failed. Check your settings.", duration: 3000 }))
                      })
                    }}
                    >Update now</Button>
                  </FormGroup>
                </Stack>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </AccordionDetails>}
      {heavyLoading && <LoadingModal />}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Delete Instance Size
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete the size {currentInstance?.width}x{currentInstance?.height}?
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => { setDeleteDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => {
            if (currentInstance) {
              setLoading(true)
              apiDeleteManagedAdInstance(customerId, ad.creativeId, currentInstance).then((success) => {
                setLoading(false)
                setDeleteDialogOpen(false)
                if (success) {
                  const newInstances = instances.filter(ci => ci.width !== currentInstance.width || ci.height !== currentInstance.height)
                  setInstances(newInstances)
                  setCurrentInstance(newInstances[0])
                  setRequiresUpdate(true)
                }
                else {
                  alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Remove failed.", duration: 3000 }))
                }
              })
            }
          }}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addTypeDialogOpen}
        onClose={() => setAddTypeDialogOpen(false)}
      >
        <DialogTitle>
          Add Type from HTML
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id={`select-type-label`}>Type</InputLabel>
            <Select size="small" labelId="select-type-label" label="Type" value={newAdType} onChange={(ev) => setNewAdType(ev.target.value)}>
              <MenuItem value="Image" disabled={types.find(t => t.type === "Image") !== undefined}>Image</MenuItem>
              <MenuItem value="Video" disabled={types.find(t => t.type === "Video") !== undefined}>Video</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <TextField size="small" type="number" value={newAdTypeDurationMs}
              label={newAdType === "Video" ? "Duration(ms)" : "Point of image (ms)"}
              onChange={(ev) => setNewAdTypeDurationMs(parseInt(ev.target.value, 10))} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => { setAddTypeDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => {
            setLoading(true)
            setAddTypeDialogOpen(false)
            apiAdManagedAdTypeFromHtml(customerId, ad.creativeId, newAdType as ManagedAdType, newAdTypeDurationMs).then((newTypes: ManagedAdTypeDef[]) => {
              setLoading(false)
              setTypes(newTypes)
            }).catch(() => {
              setLoading(false)
              alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Add failed.", duration: 3000 }))
            })
          }}>Add</Button>
        </DialogActions>
      </Dialog>
    </Accordion >)
}

export default ManagedAdView