import { SET_LOADING, SET_Navigation_Drawer_State, SharedAction } from "./SharedTypes";

export const setDrawerIsOpen = (open: boolean) : SharedAction => {
  return { type: SET_Navigation_Drawer_State, data: open }
}

export const setLoading = (loading: boolean) : SharedAction => {
  return { type: SET_LOADING, data: loading }
}

