import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { AdSize, ManagedAdExitEvent, ManagedAdPlatform, ManagedAdPlatformTypes, MetaAdSet, MetaCampaign } from "../../api/Types"
import { apiAddManagedAdPlatform, apiDeleteManagedAdPlatform, apiGetManagedAdExitEvents, apiGetManagedAdPlatforms, apiGetManagedAds, apiGetMetaAdsets, apiGetMetaCampaigns, apiPutManagedAdPlatform, apiSetManagedAdExitEvents } from "../../api/Customer"
import { debounce } from "../../utils/Generators"
import { DeleteItem, useIsMount } from "../Utils"
import styled from "styled-components"
import { regularBtnStyles } from "../../styles/buttons"
import { ManagedAdPlatformViewMeta } from "./ManagedAdPlatformViewMeta"
import AdSizesCheckBoxList from "../Ads/AdSizesCheckBoxList"

const Title = styled.div`
  font-size:1.2em;
  margin-bottom:1em;
`

const SubTitle = styled.div`
  font-size:1.1em;
`

const debouncedSetExitEvents = debounce(apiSetManagedAdExitEvents)

export const ManagedAdPlatformView = (props: { customerId: number, creativeId: string }) => {
  const { customerId, creativeId } = props
  const [platforms, setPlatforms] = useState<ManagedAdPlatform[]>([])
  const [exitEvents, setExitEvents] = useState<ManagedAdExitEvent[]>([])
  const [newPlatformType, setNewPlatformType] = useState<ManagedAdPlatformTypes>("DisplayVideo")
  const [sizes, setSizes] = useState<AdSize[]>([])

  const isMount = useIsMount()
  function setExitEvent(index: number, exitEvent: ManagedAdExitEvent) {
    setExitEvents(exitEvents.map((ev, i) => i === index ? exitEvent : ev))
  }

  function refreshPlatforms() {
    apiGetManagedAdPlatforms(customerId, creativeId).then((platforms) => setPlatforms(platforms))
  }

  function refreshAd() {
    apiGetManagedAds(customerId).then((managedAds) => {
      const ad = managedAds.find(a => a.creativeId === creativeId)
      if (ad) {
        setSizes(ad.instances.map((s) => ({ width: s.width, height: s.height })))
      }
    })
  }

  function addNewPlatform() {
    apiAddManagedAdPlatform(customerId, creativeId, newPlatformType).then(() => {
      refreshPlatforms()
    })
  }

  function removePlatform(platform: ManagedAdPlatform) {
    apiDeleteManagedAdPlatform(customerId, creativeId, platform.id).then(() => refreshPlatforms())
  }

  useEffect(() => {
    if (!isMount) {
      debouncedSetExitEvents(customerId, creativeId, exitEvents)
    }
  }, [exitEvents])

  useEffect(() => {
    apiGetManagedAdExitEvents(customerId, creativeId).then((exitEvents) => {
      setExitEvents(exitEvents.length > 0 ? exitEvents : [{ name: "clickTag", url: "https://" , adSizes: []}])
    })
    refreshAd()
    refreshPlatforms()
  }, [creativeId])

  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <SubTitle>Target System</SubTitle>
    </Grid>
    <Grid item xs={3}>
      <Select size="small" value={newPlatformType} fullWidth
        onChange={(event) => { setNewPlatformType(event.target.value as any) }}>
        <MenuItem value="DisplayVideo" disabled={platforms.find(p => p.type === "DisplayVideo") !== undefined}>Display & Video</MenuItem>
        <MenuItem value="Meta" disabled={platforms.find(p => p.type === "Meta") !== undefined}>Meta</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={9}>
      <Button variant="contained"
        onClick={() => addNewPlatform()} sx={regularBtnStyles}>Add Platform</Button>
    </Grid>
    {
      platforms.map((p) => <Grid item xs={6}>
        <Paper>
          {p.type === "DisplayVideo" &&
            <Grid container padding={2}>
              <Grid item xs={12}><Title>Display & Video</Title></Grid>
              {exitEvents.length > 0 && <Grid item xs={10}>
                <Stack spacing={3}>
                  <FormControl>
                    <TextField value={exitEvents[0].name} label="Name" size="small"
                      onChange={(event) => setExitEvent(0, { ...exitEvents[0], name: event.target.value })} />
                  </FormControl>
                  <FormControl>
                    <TextField value={exitEvents[0].url} label="URL" size="small"
                      onChange={(event) => setExitEvent(0, { ...exitEvents[0], url: event.target.value })} />
                  </FormControl>
                  <FormControl>
                  <AdSizesCheckBoxList sizes={sizes} exitEvents={exitEvents} setExitEvents={setExitEvents}/>
                  </FormControl>
                </Stack>
              </Grid>}
              <Grid item xs={2} paddingLeft={"1em"} marginTop={"2em"}>
                <DeleteItem onClick={() => { removePlatform(p) }} />
              </Grid>
            </Grid>}
          {p.type === "Meta" && <ManagedAdPlatformViewMeta customerId={customerId}
            creativeId={creativeId}
            platform={p}
            onDelete={() => removePlatform(p)}
            onUpdate={() => refreshPlatforms()}
          />}
        </Paper>
      </Grid>)

    }
  </Grid >)

}