import { faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"



export const RemoveButton = (props: { onClick: () => void }) => {
  const { onClick } = props
  return (<FontAwesomeIcon icon={faTrash} color={'red'} onClick={() => onClick()} style={{ cursor: 'pointer' }} />)
}

export const RefreshButton = (props: { onClick: () => void }) => {
  const { onClick } = props
  return (<FontAwesomeIcon icon={faRefresh} onClick={() => onClick()} style={{ cursor: 'pointer' }} />)
}