import { Alert, AlertTitle, Box, Button, Grid, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { blackBtn, regularBtnStyles } from '../../../styles/buttons';
import AdAutomations from './AdAutomations';
import AdAutomationSettingsDialog from './AdAutomationSettingsDialog';
import { useEffect, useState } from 'react';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { getMetaAuthStatus } from '../../../api/Marketing';
import { desktop_h1, desktop_p1, desktop_p3 } from '../../../styles/textStyles';
import { statusColors, yellowColors } from '../../../styles/colors';


const MetaAdAutomationsPage = () => {
  const history = useHistory()
  const state = useCustomerState()
  const [showAlert, setShowAlert] = useState(false)


  useEffect(() => {
    state?.selected?.id && getMetaAuthStatus(state?.selected?.id).then((metaAuthStatus) => {
      if (!metaAuthStatus.authorized) {
        setShowAlert(true)
      }
    }).catch(error => {
      console.error(error.message)
    });
  }, [state])


  const handleCreate = () => {
    history.push('/meta-ad-automations/create')
  }

  return (
    <Box sx={{ margin: '50px' }}>
        <Grid sx={{ maxWidth: '800px' }}>
          <Typography variant='h1' sx={desktop_h1}>Meta Post Booster</Typography>
          {showAlert && <Alert sx={infoAlertStyles} style={{marginTop:'32px'}} severity="warning">
            <AlertTitle>Warning</AlertTitle>
            To use this feature, please authorize Kiva Suite application from <b>Admin &rarr; Meta</b>. <strong><Link to="/admin">Click here to authorize!</Link></strong>
          </Alert>}
          <Typography sx={desktop_p1} style={{marginTop:'56px', marginBottom: '40px'}} component={'p'}>This feature targets the posts that will be published in the
            future. It creates automatically ads for the future posts, where the texts contain certain keywords.</Typography>
          <Alert severity='info' sx={infoAlertStyles}>
            Please pay attention that the automation does not work for Facebook posts without link, photo or video.
          </Alert>
          <Box sx={{marginTop: '40px'}}>
            <Button onClick={handleCreate} sx={regularBtnStyles}>Create</Button>
          </Box>
        </Grid>
        <Box sx={{mt:4}}>
          <AdAutomations />
        </Box>
    </Box>
  )
}


const infoAlertStyles = {
  ...desktop_p3,
  background: yellowColors[50], 
  color: '#000',
  '& .MuiAlert-icon': {color: '#000'}
}

export default MetaAdAutomationsPage;