import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField } from "@mui/material"
import { useState } from "react"
import { regularBtnStyles } from "../../styles/buttons"
import styled from "styled-components"
import { apiFetchCustomDatasetFaq } from "../../api/Assistant"
import { CustomDatasetQuestionAnswerResponse, FaqFilters } from "../../api/Types"
import { Close } from "@mui/icons-material"


const Fields = styled.div`
  display:grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 1em;
`

const Faq = styled.div`
`

const PageDescription = styled.div`
  font-size:0.8em;
`

const CloseContainer = styled.div`
  position:absolute;
  top:1em;
  right:1em;
`

export type FlexibleLoaderDialogProps = {
  customerId: number
  setOpen: (open: boolean) => void
  setLoading: (loading: boolean) => void
  onImport: (rows: { question: string, answer: string }[]) => void
  open: boolean
}

export const FaqFlexibleLoaderDialog = (props: FlexibleLoaderDialogProps) => {
  const { customerId, open, setOpen, setLoading, onImport } = props
  const [url, setUrl] = useState("")
  const [filters, setFilters] = useState<FaqFilters>({ question: '[class="question"]', answer: '[class="answer"]' })
  const [qas, setQas] = useState<CustomDatasetQuestionAnswerResponse | undefined>()

  const fetchFaq = () => {
    setLoading(true)
    apiFetchCustomDatasetFaq(customerId, url, filters).then((response: any) => {
      setLoading(false)
      setQas(response)
    })
  }

  return (<Dialog fullWidth
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>
      Import FAQ
    </DialogTitle>
    <DialogContent>
      <CloseContainer>
        <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
      </CloseContainer>
      <Fields>
        <FormControl fullWidth>
          <TextField style={{ marginTop: '0.5em' }} label="FAQ URL" value={url} size="small" onChange={(ev) => { setUrl(ev.target.value) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Question selector" value={filters.question} size="small"
            onChange={(ev) => { setFilters({ ...filters, question: ev.target.value }) }} />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Answer selector" value={filters.answer} size="small"
            onChange={(ev) => { setFilters({ ...filters, answer: ev.target.value }) }} />
        </FormControl>
        <Button sx={{ ...regularBtnStyles, maxWidth: '200px' }} disabled={!url.length} onClick={() => { fetchFaq() }}>Fetch</Button>
      </Fields>
      {qas ? <h4>Found {qas.length} questions</h4> : <h4>No FAQ loaded yet.</h4>}
      {qas && <Faq>
        {qas.map(qa => <>
          <h5>{qa.question}</h5>
          <PageDescription>{qa.answer}</PageDescription>
        </>)}
      </Faq>}
      <hr />
      {qas && qas.length > 0 && <Button sx={{ ...regularBtnStyles, maxWidth: '200px' }} onClick={() => {
        setOpen(false)
        onImport(qas)
      }}>
        Import {qas.length} questions
      </Button>}
    </DialogContent>
    <DialogActions>
    </DialogActions>
  </Dialog>)
}