import { getApi, postApi } from "./Utils";

export type ShopConfig = {
  productsUrl: string;
  productUrlTemplate?: string;
  categoryTag?: string;
}

export type ShopBody = {
  name: string;
  websiteUrl: string;
  currency: string
  language: string
  config: ShopConfig;
  merchantCountries: string[];
  merchantId: string
}
export type FeedStatus = {
  valid: boolean
}

export type ProductUrlBody = {
  feed: string
}

export const apiSendShop = async (customerId: number, body: ShopBody): Promise<ShopBody> => {
  const response = await postApi<ShopBody>(`css/shops/${customerId}`, body)
  return response
}

export const apiGetShop = async (customerId: number): Promise<ShopBody> => {
  const response = await getApi<ShopBody>(`css/shops/${customerId}`)
  return response
}

export const apiValidateFeed = async(customerId: number, body: ProductUrlBody): Promise<FeedStatus> => {
  const response = await postApi<FeedStatus>(`css/shops/validatefeed/${customerId}`, body)
  return response
}