import { Base64 } from "js-base64"
import { ChatMessage, CustomDataset, CustomDatasetPage, CustomDatasetPagesResponse, CustomDatasetQuestionAnswerResponse, CustomModel, CustomModelTask, CustomModelType, FaqFilters, PageFilters } from "./Types"
import { postApi, getApi, deleteApi, patchApi, putApi } from "./Utils"

export type PromptContent = {
  role: string
  content: string
  llm?: string
  userModified?: boolean
}

export type Conversation = {
  id: string
  dateTime: string
  prompts: PromptContent[]
}

export const apiSendPrompt = async (customerId: number, llm: string, prompts: { role: string, content: string }[], conversationId?: string): Promise<{ conversationId: string, reply: string, conversation: Conversation }> => {
  const response = await postApi<{ reply: string, conversationId: string, conversation: Conversation }>(`assistant/customer/${customerId}/prompt`, { prompts, llm, conversationId })
  return response
}

export const apiUpdatePrompts = async (customerId: number, prompts: { role: string, content: string }[], conversationId?: string): Promise<{ conversationId: string, reply: string }> => {
  const response = await patchApi<{ reply: string, conversationId: string }>(`assistant/customer/${customerId}/prompts`, { prompts, conversationId })
  return response
}

export const apiGetConversations = async (customerId: number): Promise<Conversation[]> => {
  return await getApi<Conversation[]>(`assistant/customer/${customerId}/conversations`)
}

export const apiRemoveConversation = async (customerId: number, conversationId: string): Promise<Conversation[]> => {
  return await deleteApi<Conversation[]>(`assistant/customer/${customerId}/conversations/${conversationId}`)
}

export const apiGetCustomDatasets = async (customerId: number): Promise<CustomDataset[]> => {
  return await getApi(`assistant/customer/${customerId}/dataset`)
}

export const apiCreateCustomDataset = async (customerId: number, name: string): Promise<CustomDataset[]> => {
  return await postApi(`assistant/customer/${customerId}/dataset`, { name, type: 'QuestionAnswer' })
}

export const apiGetCustomDataset = async (customerId: number, id: string): Promise<CustomDataset> => {
  return await getApi(`assistant/customer/${customerId}/dataset/${id}`)
}

export const apiAddCustomDatasetRows = async (customerId: number, datasetId: string, rows: { question: string, answer: string }[]): Promise<{ id: string, question: string, answer: string }> => {
  return await postApi(`assistant/customer/${customerId}/dataset/${datasetId}/rows`, rows)
}

export const apiGetCustomDatasetRows = async (customerId: number, datasetId: string, offset: number): Promise<{ total: number, rows: { id: string, question: string, answer: string }[] }> => {
  return await getApi(`assistant/customer/${customerId}/dataset/${datasetId}/rows/${offset}`)
}

export const apiRemoveCustomDatasetRow = async (customerId: number, datasetId: string, rowId: string): Promise<void> => {
  return await deleteApi(`assistant/customer/${customerId}/dataset/${datasetId}/row/${rowId}`)
}

export const apiDeleteCustomDataset = async (customerId: number, datasetId: string): Promise<void> => {
  return await deleteApi(`assistant/customer/${customerId}/dataset/${datasetId}`)
}

export const apiUpdateCustomDatasetRow = async (customerId: number, datasetId: string, row: { id: string, question: string, answer: string }): Promise<{ id: string, question: string, answer: string }> => {
  return await putApi(`assistant/customer/${customerId}/dataset/${datasetId}/rows`, row)
}

export const apiFetchCustomDatasetPages = async (customerId: number, sitemapUrl: string, filters: PageFilters, previewCount: number): Promise<CustomDatasetPagesResponse> => {
  return await postApi(`assistant/customer/${customerId}/dataset/pages`, { sitemapUrl, filters, previewCount })
}

export const apiFetchCustomDatasetPagesFilters = async (customerId: number, sitemapUrl: string): Promise<PageFilters | null> => {
  return await getApi(`assistant/customer/${customerId}/dataset/pages/${Base64.encode(sitemapUrl)}/filters`, null)
}


export const apiGetCustomModels = async (customerId: number): Promise<CustomModel[]> => {
  return await getApi(`assistant/customer/${customerId}/models`)
}

export const apiCreateCustomModel = async (customerId: number, name: string, type: CustomModelType): Promise<CustomModel[]> => {
  return await postApi(`assistant/customer/${customerId}/models`, { name, type })
}

export const apiCreateCustomModelTask = async (customerId: number, modelId: string, datasets: string[], modelType: CustomModelType): Promise<CustomModelTask[]> => {
  return await postApi(`assistant/customer/${customerId}/models/${modelId}/task`, { datasets, modelType })
}

export const apiRemoveCustomModelTask = async (customerId: number, modelId: string, taskId: string): Promise<CustomModelTask[]> => {
  return await deleteApi(`assistant/customer/${customerId}/models/${modelId}/task/${taskId}`)
}

export const apiRefreshCustomModelTask = async (customerId: number, modelId: string, taskId: string): Promise<CustomModelTask[]> => {
  return await getApi(`assistant/customer/${customerId}/models/${modelId}/task/${taskId}`)
}

export const apiFetchCustomDatasetFaq = async (customerId: number, url: string, filters: FaqFilters): Promise<CustomDatasetQuestionAnswerResponse> => {
  return await postApi(`assistant/customer/${customerId}/dataset/faq`, { url, filters })
}

export const apiSendCustomModelPrompt = async (modelId: string, messages: ChatMessage[]): Promise<ChatMessage[]> => {
  return await postApi(`custombot/${modelId}/prompts`, messages)
}