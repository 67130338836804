import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { closeIcon } from '../../utils/icons';
import { neutralColors } from '../../styles/colors';
import { desktop_h2, desktop_p2 } from '../../styles/textStyles';
import { StyledMenu } from '../Ads/StyledMenu';
import FilterField from '../Ads/FilterField';
import AdsSearchBtn from '../Ads/AdsSearchBtn';
import { allCompetitorAdsCategories, allCompetitorAdsCategoriesAsStrings } from '../../context/competitors/CompetitorReducer';
import { useCustomerState } from '../../context/customer/CustomerContext';
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext';
import { setCompetitorAdUnits } from '../../context/competitors/CompetitorActions';

export type ChannelsProps = {
  handleAdsSearchBtnClick: () => void
}

const Channels = ({ handleAdsSearchBtnClick }: ChannelsProps) => {

  const customerState = useCustomerState()
  const { selected } = customerState
  const [{ competitorAdUnits }, competitorDispatch] = useCompetitorStateValue()
  const { categories } = competitorAdUnits
  
  const selectedCategories = allCompetitorAdsCategories.filter(i => categories.includes(i.value))

  const selectOnly = (value: string) => {
    competitorDispatch(setCompetitorAdUnits({...competitorAdUnits, categories: [value]}))
  }

  const selectAll = () => {
    competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, categories: allCompetitorAdsCategoriesAsStrings }))
  }
  
  const updateStateCategories = (categories: string[]) => {
    competitorDispatch(setCompetitorAdUnits({
      ...competitorAdUnits,
      categories: categories
    }))
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleAdsSearchBtnClick();
  };

  const [selectAllChannels, setSelectAllChannels] = useState(categories.length === allCompetitorAdsCategories.length ? true : false)

  const [filter, setFilter] = useState('')

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  const handleSelectAllChannels = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      selectAll()
    } else {
      updateStateCategories([])
    }
    setSelectAllChannels(checked)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const cat = event.target.value;
    let updatedCategorires = null;

    if (checked && (categories).indexOf(cat) === -1) {
      updatedCategorires = [...categories, cat]
    } else {
      updatedCategorires = categories.filter(c => c !== cat)
    }

    updateStateCategories(updatedCategorires)

    if (updatedCategorires?.length === allCompetitorAdsCategories.length) {
      setSelectAllChannels(true)
    } else {
      setSelectAllChannels(false)
    }
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={''}
        sx={{ 
          textTransform: 'none', 
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)', 
          background: "#fff", 
          color: "#000",
          minWidth:'124px',
          borderRadius: '5px',
          border: '0.5px solid #000',
          ":hover": { background: '#fff' }
        }}
      >
        Channels {selectAllChannels ? "(all)" : `(${selectedCategories.length})`}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <Grid container sx={{ padding: '32px', width: '100%', background: neutralColors[50] }}>
          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
            <Box >
              <Typography variant='h2' sx={desktop_h2}>Channels</Typography>
            </Box>
            <Box sx={{}}>

              <IconButton onClick={handleClose}>
                {closeIcon()}
              </IconButton>

            </Box>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width:'100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
              <FilterField filter={filter} handleFilterChange={handleFilterChange}/>
            </Box>
            <Box>
              <FormControlLabel
                label={"All"}
                sx={desktop_p2}
                control={<Checkbox checked={selectAllChannels} onChange={handleSelectAllChannels}
                sx={{}}
                />}
              />
            </Box>
            {allCompetitorAdsCategories.filter(i => i.label.toString().toLowerCase().includes(filter?.toLowerCase())).map((i: any) => {
              const checked = (categories).indexOf(i.value) > -1;

              return (<Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
                <FormControlLabel
                  label={i.label}
                  sx={{...desktop_p2}}
                  control={<Checkbox checked={checked} value={i.value} onChange={handleCheckboxChange}
                  sx={{}}
                  />}
                />
                <Box sx={{display: "flex", alignItems: 'center', justifyContent:"flex-end", gap: '32px'}}>
                  <Button onClick={(event) => {
                    event.stopPropagation();
                    if (checked && (categories || "").length === 1) {
                      selectAll()
                      setSelectAllChannels(true)
                    }
                    else {
                      selectOnly(i.value)
                      setSelectAllChannels(false)
                    }
                  }}
                  sx={desktop_p2}
                  >
                    {checked && (categories || "").length === 1 ? "ALL" : "ONLY"}
                  </Button>
                </Box>

              </Grid>)
            })}
          </Box>                  
          <Grid container sx={{ justifyContent: 'right', marginTop:'64px' }} >
            <AdsSearchBtn handleAdsSearchBtnClick={handleClose} />
          </Grid>
        </Grid>

      </StyledMenu>
    </div>
  );
}

export default Channels