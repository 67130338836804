import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiGetChartMetricsParams, GetCreativesParams, apiGetChartMetrics, apiGetCreatives } from '../api/Creative';
import { CampaignCreative, CreativeResponse, CustomerTree } from '../api/Types';
import { useCustomerState } from '../context/customer/CustomerContext';
import { conversionItems, CreativePreview } from './CreativePreview';
import { InfoText } from './InfoText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';
import { debounce } from '../utils/Generators';
import { closeAllToggles, LoadingModal } from './Modal';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Checkbox,
  FormControl, FormGroup, Grid, IconButton, InputLabel, ListItemText, MenuItem,
  Select, SelectChangeEvent, TextField, Typography
} from '@mui/material';
import Charts from './Charts';
import AccordionContainer from './AccordionContainer';
import { neutralColors } from '../styles/colors';
import DateRangePickerMui from './DateRangePickerMui';
import dayjs from 'dayjs';
import AdFilters  from './Ads/MoreFilters';
import Channels from './Ads/Channels';

import { desktop_h1, desktop_p1 } from '../styles/textStyles';

import GridViewItemsCountToggle from './GridViewItemsCountToggle';
import { CreativeChartMetrics, CreativeChartMetricsResponse } from './Charts/Types';
import { formatDateAsYearMonthDay } from '../utils/helpers';
import { arrowNarrowDownIcon, arrowNarrowUpIcon, closeIcon, filterIcon } from '../utils/icons';
import { useStateValue } from '../context/ads/AdsContext';
import { setAdUnits, setAdUnitsCharts } from '../context/ads/AdsActions';

import Campaigns from './Ads/Campaigns';

import e from 'express';
import { DateRange } from '@mui/x-date-pickers-pro/internals/models';
import CircularProgressLoading from './CircularProgressLoading';
import AdGroups from './Ads/AdGroups';
import Statuses from './Ads/Statuses';
import KeywordSearch from './Ads/MoreFilters/KeywordSearch';
import Dimensions from './Ads/MoreFilters/Dimensions';
import Conversions from './Ads/MoreFilters/Conversions';
import Activities from './Ads/MoreFilters/Activities';
import { useRef } from 'react';

const emptyCreatives = { start: 0, total: 0, creatives: [], campaigns: [], adGroups: [], dimensions: {}, floodlightActivities: [], categoriesImpressions: {} }

export const pageSize = 24

export const AdUnitView = () => {
  const customerState = useCustomerState()
  const { selected } = customerState

  const [{ adUnits, adUnitsCharts }, dispatch] = useStateValue()
  const { loading, creatives, startDate, endDate, sort, direction, start, statuses, searchKeyword,
    allCampaigns, campaigns, allAdGroups, adGroups, dimensions, floodlightActivity, allFloodlightActivities, allDimensions,
    conversions, categories, showSelectCustomer, showZeros, gridViewItemsCount } = adUnits

  const { chartMetrics, chartLoading } = adUnitsCharts

  const [moreFiltersOpen, setMoreFiltersOpen] = useState(false)

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const [previousSelected, setPreviousSelected] = useState<CustomerTree>()

  useEffect(() => { 
    if (selected && selected.id) {

      if(!previousSelected){
        setPreviousSelected(selected)
      }

      dispatch(setAdUnits({
        ...adUnits,
        creatives: emptyCreatives, loading: false, start: 0, allCampaigns: [], allAdGroups: [],
        allFloodlightActivities: [{ id: "all", title: "All Activities" }],
        allDimensions: []
      }))

      getCreatives()
      getChartMetrics({
        startDate: formatDateAsYearMonthDay(new Date(new Date().getFullYear() - 1, 0, 1)),
        endDate: formatDateAsYearMonthDay(new Date()),
        searchKeyword: searchKeyword,
        statuses: statuses,
        campaigns: campaigns,
        categories: categories,
        dimensions: dimensions,
        sort: sort,
        start: start,
        direction: direction,
        count: pageSize
      })

      setIsInitialLoading(true)
    }

  }, [selected, selected?.id])

  useEffect(() => {
    if(categories.length > 0 && !categories.includes("facebook")){
      dispatch(setAdUnits({ ...adUnits, adGroups: [] }))
    }
  }, [categories])

  const getCreatives = () => {
    closeAllToggles()
    if (selected) {
      if (start === 0) {
        dispatch(setAdUnits({ ...adUnits, creatives: emptyCreatives }))
      }
      dispatch(setAdUnits({ ...adUnits, loading: true, customerId: selected.id, showSelectCustomer: false }))
      apiGetCreatives(selected!.id, {
        searchKeyword,
        startDate, endDate, sort,
        direction, statuses,
        campaigns: campaigns,
        adGroups: adGroups, 
        dimensions: dimensions,
        floodlightActivity, showZeros, start,
        categories,
        count: pageSize
      }).then((resp: CreativeResponse) => {
        const isSelectedCustomerChanged: boolean = (previousSelected && previousSelected.id !== selected.id) || false
        if (start === 0 || isSelectedCustomerChanged) {
          dispatch(setAdUnits({
            ...adUnits,
            creatives: resp, loading: false,
            allCampaigns: resp.campaigns,
            allAdGroups: resp.adGroups,
            start: isSelectedCustomerChanged ? 0 : start,
            allFloodlightActivities: resp.floodlightActivities !== null ? [
              { id: "all", title: "All Activities" },
              ...resp.floodlightActivities.map(a => ({ id: a, title: a }))
            ] : [{ id: "all", title: "All Activities" }],
            allDimensions: mapAllDimensions(resp.dimensions)
          }))
          setPreviousSelected(selected)
        } else {
          creatives.creatives.push(...resp.creatives)
          dispatch(setAdUnits({ ...adUnits, creatives, loading: false }))
        }
        setIsInitialLoading(false)
      }).catch(() => {
        dispatch(setAdUnits({
          ...adUnits,
          creatives: emptyCreatives, loading: false, start: 0, allCampaigns: [], allAdGroups: [],
          allFloodlightActivities: [{ id: "all", title: "All Activities" }],
          allDimensions: []
        }))
        setIsInitialLoading(false)
      })
    } else if (!adUnits.showSelectCustomer && !selected) {
      dispatch(setAdUnits({ ...adUnits, showSelectCustomer: true, loading: false }))
      setIsInitialLoading(false)
    }
  }

  const getCreativesUsingParams = (params: GetCreativesParams) => {
    closeAllToggles()
    if (selected) {
      if (params.start === 0) {
        dispatch(setAdUnits({ ...adUnits, creatives: emptyCreatives }))
      }
      dispatch(setAdUnits({
        ...adUnits,
        startDate: params.startDate,
        endDate: params.endDate,
        start: params.start,
        sort: params.sort,
        direction: params.direction,
        floodlightActivity: params.floodlightActivity,
        loading: true,
        customerId: selected.id,
        showSelectCustomer: false
      }))
      apiGetCreatives(selected!.id, params).then((resp: CreativeResponse) => {
        const isSelectedCustomerChanged: boolean = (previousSelected && previousSelected.id !== selected.id) || false
        if (params.start === 0 || isSelectedCustomerChanged) {
          dispatch(setAdUnits({
            ...adUnits,
            startDate: params.startDate,
            endDate: params.endDate,
            start: isSelectedCustomerChanged ? 0 : params.start,
            sort: params.sort,
            direction: params.direction,
            floodlightActivity: params.floodlightActivity,
            creatives: resp, loading: false,
            allCampaigns: resp.campaigns,
            allAdGroups: resp.adGroups,
            allFloodlightActivities: resp.floodlightActivities !== null ? [
              { id: "all", title: "All Activities" },
              ...resp.floodlightActivities.map(a => ({ id: a, title: a }))
            ] : [{ id: "all", title: "All Activities" }],
            allDimensions: mapAllDimensions(resp.dimensions)
          }))
          setPreviousSelected(selected)
        } else {
          creatives.creatives.push(...resp.creatives)
          dispatch(setAdUnits({
            ...adUnits, creatives, loading: false,
            startDate: params.startDate,
            endDate: params.endDate,
            start: params.start,
            sort: params.sort,
            direction: params.direction,
            floodlightActivity: params.floodlightActivity
          }))
        }
        if(isLoadingMore){
          setIsLoadingMore(false)
        }
      }).catch(() => {
        dispatch(setAdUnits({
          ...adUnits,
          creatives: emptyCreatives, loading: false, start: 0, allCampaigns: [], allAdGroups: [],
          allFloodlightActivities: [{ id: "all", title: "All Activities" }],
          allDimensions: []
        }))
      })
    } else if (!adUnits.showSelectCustomer && !selected) {
      dispatch(setAdUnits({ ...adUnits, showSelectCustomer: true, loading: false }))
    }
  }


  const items = [
    { id: "title", title: "Ad name" },
    { id: "clicks", title: "Click" },
    { id: "ctr", title: "Clickthrough" },
    { id: "cost", title: "Cost" },
    { id: "cpc", title: "Cost per click" },
    { id: "impressions", title: "Impression" },
    { id: "totalConversions", title: "Total convs" },
    { id: "mobileTotalConversions", title: "Mobile convs" },
    { id: "tabletTotalConversions", title: "Tablet convs" },
    { id: "desktopTotalConversions", title: "Desktop convs" },
    { id: "postClickConversions", title: "Post-click convs" },
    { id: "postViewConversions", title: "Post-view convs" }
  ]

  const currentDate = new Date();
  // Set the start date to last year's January 01
  const lastYearStartDate = new Date(currentDate.getFullYear() - 1, 0, 1);
  const chartMetricsStartDate = formatDateAsYearMonthDay(lastYearStartDate);
  const chartMetricsEndDate = formatDateAsYearMonthDay(currentDate);

  const handleSearchButtonClick = () => {
    getCreatives()
    const apiGetMetricsParams = {
      startDate: chartMetricsStartDate,
      endDate: chartMetricsEndDate,
      searchKeyword,
      statuses,
      campaigns,
      adGroups,
      categories,
      dimensions,
      sort,
      start,
      direction,
      count: pageSize
    }
    getChartMetrics(apiGetMetricsParams)
  }

  const getChartMetrics = (apiGetChartMetricsParams: ApiGetChartMetricsParams) => {
    dispatch(setAdUnitsCharts({ ...adUnitsCharts, chartLoading: true }))
    selected?.id && apiGetChartMetrics(selected?.id, apiGetChartMetricsParams).then((res: CreativeChartMetricsResponse) => {
      dispatch(setAdUnitsCharts({ ...adUnitsCharts, chartMetrics: res.metrics, chartLoading: false }))
    }).catch((err) => {
      dispatch(setAdUnitsCharts({ ...adUnitsCharts, chartLoading: false }))
    })
  }

  const mapAllDimensions = (dimensions: { [index: string]: number }) => {
    const mapped = [...Object.keys(dimensions).map(key => ({ id: key, count: dimensions[key], title: `${key} (${dimensions[key]} pcs)` }))]
    return mapped.sort((a, b) => b.count - a.count)
  }

  const handleDateRangeChange = (dateRange: DateRange<dayjs.Dayjs>) => {
    if(dateRange && dateRange[0] && dateRange[1]){
      getCreativesUsingParams({
        searchKeyword,
        startDate: dateRange[0].toDate(), 
        endDate: dateRange[1].toDate(),
        sort,
        direction, statuses,
        campaigns: campaigns,
        adGroups: adGroups,
        dimensions: dimensions,
        floodlightActivity, showZeros,
        start: 0,
        categories,
        count: pageSize
      })
    }
  }

  const setSort = (event: SelectChangeEvent) => {
    const newSort = event.target.value
    getCreativesUsingParams({
      searchKeyword,
      startDate, endDate,
      sort: newSort,
      direction, statuses,
      campaigns: campaigns,
      adGroups: adGroups,
      dimensions: dimensions,
      floodlightActivity, showZeros, start: 0,
      categories,
      count: pageSize
    })
  }

  const flipDirection = () => {
    getCreativesUsingParams({
      searchKeyword,
      startDate, endDate,
      sort,
      direction: adUnits.direction === 'desc' ? 'asc' : 'desc',
      start: 0,
      statuses,
      campaigns: campaigns,
      adGroups: adGroups,
      dimensions: dimensions,
      floodlightActivity, showZeros,
      categories,
      count: pageSize
    })
  }

  const flipShowZeros = (_: any, checked: boolean) => {
    dispatch(setAdUnits({
      ...adUnits,
      start: 0, showZeros: checked
    }))
  }

  const loadMore = () => {
    setIsLoadingMore(true)
    getCreativesUsingParams({
      searchKeyword,
      startDate, endDate,
      sort,
      direction, statuses,
      campaigns: campaigns,
      adGroups: adGroups,
      dimensions: dimensions,
      floodlightActivity, showZeros,
      start: adUnits.start + pageSize,
      categories,
      count: pageSize
    })

  }

  const getScrollPosition = () => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  const setScrollPosition = (pos:any) => window.scrollTo({ top: pos, behavior: 'auto' });

  const handleLoadMoreWithScroll = () => {
    const scrollPosition = getScrollPosition();
    loadMore();
    setScrollPosition(scrollPosition);
  };

  const handleGridViewItemsCountChange = (event: React.MouseEvent<HTMLElement>, newItems: number) => {
    dispatch(setAdUnits({ ...adUnits, gridViewItemsCount: newItems, loading: false }))
  }

  const categoriesWithAdGroup = ["facebook", "app", "display", "discovery", "shopping", "search", "smart", "video", "performanceMax", "youtube", "displayVideo"]

  const currentTotal = Math.min(start + pageSize, creatives.total)
  return (
    <Box sx={{ margin: '50px' }}>

      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ width: '50%' }}>
          <Typography variant='h1' sx={desktop_h1}>Ads Reporting</Typography>
        </Box>
        {/* Date picker */}
        <Box sx={{ alignItems: 'center', minWidth: '280px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Box sx={{}}>
            {!loading && <Box sx={{ fontSize: '0.9em' }}>{currentTotal} of {creatives.total} ad units</Box>}
          </Box>
          <DateRangePickerMui startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} onClose={() => ''} />
        </Box>
      </Grid>

      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', mb: '36px', mt: '36px', display: 'flex', rowGap: '16px' }}>
        
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'flex-start', }}>
          <Channels handleAdsSearchBtnClick={handleSearchButtonClick} />
          <Campaigns handleAdsSearchBtnClick={handleSearchButtonClick} />
          <Statuses handleAdsSearchBtnClick={handleSearchButtonClick} />
          {categories.some(c => categoriesWithAdGroup.includes(c)) && <AdGroups handleAdsSearchBtnClick={handleSearchButtonClick} />}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>

          <Button
            id="demo-customized-button"
            variant="contained"
            disableElevation
            onClick={() => setMoreFiltersOpen(!moreFiltersOpen)}
            endIcon={moreFiltersOpen ? closeIcon() : filterIcon()}
            sx={{
              textTransform: 'none',
              boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
              background: "#fff",
              color: "#000",
              borderRadius: '5px',
              border: '0.5px solid #000',
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              ":hover": { background: '#fff' },
              '& .MuiButton-endIcon': {
                margin: 0
              }
            }}
          >
            More Filters
          </Button>

          <Box>
            <FormControl fullWidth>
              <InputLabel>Order</InputLabel>
              <FormGroup row>
                <Select id="orderSelect"
                  value={sort}
                  size="small"
                  label="Order"
                  onChange={setSort}
                >
                  {items.map(i => <MenuItem key={`order-${i.id}`} value={i.id}>{i.title}</MenuItem>)}
                </Select>
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', "& :hover": { cursor: 'pointer' } }} onClick={flipDirection}>
            <IconButton>{direction !== 'asc' ? arrowNarrowDownIcon() : arrowNarrowUpIcon()}</IconButton>
          </Box>
          <Box>
            <GridViewItemsCountToggle items={gridViewItemsCount} handleChange={handleGridViewItemsCountChange} />
          </Box>
        </Box>
      </Grid>


      {moreFiltersOpen && <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ alignItems: 'center', width: '50%' }}>
          <KeywordSearch keyword={searchKeyword} onChange={() => ''} handleAdsSearchBtnClick={handleSearchButtonClick} />
        </Box>

        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
          <Dimensions handleAdsSearchBtnClick={handleSearchButtonClick} />
          <Conversions handleAdsSearchBtnClick={handleSearchButtonClick} />
          <Activities getCreativesUsingParams={getCreativesUsingParams} handleAdsSearchBtnClick={handleSearchButtonClick} />
        </Box>
      </Grid>}

      <AccordionContainer title="Charts" styles={{ mt: 2, mb: 2, boxShadow: 'none', borderRadius: '5px', border: `1px solid ${neutralColors[200]}`, position: 'unset' /* removes the upper line/border */ }}>
        <Charts metrics={chartMetrics} loading={chartLoading} />
      </AccordionContainer>
      <>
        {creatives.creatives.length === 0 && !loading && <InfoText>No Ads found.</InfoText>}
        {isInitialLoading && <CircularProgressLoading />}
       
        {!isInitialLoading && 
        <>
          <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${gridViewItemsCount}, 1fr)`, gap: '32px 24px' }} >        
            {creatives.creatives.map(c =>
              <CreativePreview key={c.id} {...c} conversions={(c as any)[conversions] as number}
                conversionName={conversionItems.find(i => i.id === conversions)?.title || "Total"} />)}
          </Box>
          <Box sx={totalContainerStyles}>
            {creatives.total > 0 && !loading && <Box sx={{ fontSize: '0.9em' }}>{currentTotal} of {creatives.total} ad units</Box>}
          </Box>
          {isLoadingMore && loading && <CircularProgressLoading />}
          <Box sx={totalContainerStyles}>
            {creatives.creatives.length < creatives.total && !loading && <Box sx={loadMoreBtnStyles} onClick={handleLoadMoreWithScroll}>Load more</Box>}
          </Box>
        </>}
      </>
      
    </Box>)
}

const totalContainerStyles = {
  marginTop: '1.0em',
  width: '100%',
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex'
}

const loadMoreBtnStyles = {
  cursor: 'pointer',
  borderRadius: '24px',
  backgroundColor: '#f2f2f2',
  textAlign: 'center',
  maxWidth: '7em',
  minWidth: '7em',
  alignSelf: 'center',
  padding: '5px',
  '&:hover': {
    backgroundColor: '#D3D3D3'
  }
}

