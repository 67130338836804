
import React, { createContext, useReducer, useContext } from 'react';
import { initialMetaState } from './MetaReducer';
import { MetaState, MetaAction } from './MetaTypes';

export const StateContext = createContext<[MetaState, React.Dispatch<MetaAction>]>([
  initialMetaState, 
  () => initialMetaState
])

export type MetaProviderProps = {
  reducer: React.Reducer<MetaState, MetaAction>
  children: React.ReactElement
}

export const MetaProvider = ({reducer, children}: MetaProviderProps) => {
  const [metaState, dispatch] = useReducer(reducer, initialMetaState);
  return (
    <StateContext.Provider value={[metaState, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}
export const useStateValue = () => useContext(StateContext);

