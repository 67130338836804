import { Visitor, VisitorFilters } from "./Types"
import { postApi } from "./Utils"

export const apiGetDailyVisitors = async (customerId: number, startDate: string, endDate: string, variable: string, aggregation: string,
  channel?: string, campaign?: string, landingPage?: string): Promise<Visitor[]> => {
  return await postApi(`visitors/customer/${customerId}`, {
    startDate,
    endDate,
    variable,
    channel,
    aggregations: [aggregation],
    campaign,
    landingPage
  })
}

export const apiGetVisitorChannels = async (customerId: number, startDate: string, endDate: string): Promise<VisitorFilters> => {
  return await postApi(`visitors/customer/${customerId}/filters`, {
    startDate,
    endDate
  })
}

export const apiGetKivaAnalyticsSessions = async (customerId: number, startDate: string, endDate: string, aggregation: string, variable: string): Promise<Visitor[]> => {
  return await postApi(`visitors/customer/${customerId}/kiva-analytics`, {
    startDate,
    endDate,
    variable,
    aggregation
  })
}