import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiExportSpends, apiGetSpends, apiGetSpendsTree, TotalCampaignSpend, TotalCreativeSpendsResponse, TotalCreativeSpendsTreeResponse } from '../api/Creative';
import { CustomerTree, SpendTree } from '../api/Types';
import { useCustomerState } from '../context/customer/CustomerContext';
import { getURLParameter, toPrice } from '../utils/Generators';
import moment from 'moment';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Select, TextField, Typography } from '@mui/material';
import { LoadingModal } from './Modal';
import dayjs from 'dayjs';
import { regularBtnStyles, whiteBlackBorderBtnStyles } from '../styles/buttons';
import DateRangePickerMui from './DateRangePickerMui';
import { desktop_h1 } from '../styles/textStyles';
import { DateRange } from '@mui/x-date-pickers-pro/internals/models';
import CircularProgressLoading from './CircularProgressLoading';

const ReportContainer = styled.div`

`

const SpendMatrix = styled.div`
  margin:1.0em;
  display:grid;
  grid-template-columns: 2em 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap:5px;
`

const SpendItemCampaign = styled.div`
  grid-column-start:2;
  grid-column-end:10;
`

const SpendHeader = styled.div`
  font-weight:bold;
  margin-bottom:5px;
`

const SpendHeaderTotal = styled.div`
  font-weight:bold;
  text-align:right;
`

const SpendItem = styled.div`
  font-size:0.9em;
`

const OpenItem = styled.div`
  cursor:pointer;
`

const SpendItemNa = styled.div`
`

const SpendHeaderNa = styled.div`
  font-weight:bold;
`

const SpendItemTotal = styled.div`
  text-align:right;
  font-size:0.9em;
`

const CampaignSpendItemTotal = styled.div`
  border-top:1px solid lightgray;
  text-align:right;
  font-size:0.9em;
  font-weight:bold;
  grid-column-start:1;
  grid-column-end:5;
`

const Filters = styled.div`
  display:grid;
  grid-template-columns:10em 10em 10em 10em;
  column-gap:10px;
  margin-top:2.0em;
  margin-bottom:2.0em;
`

const QuickFilters = styled.div`
  margin:1.0em;
`

const Export = styled.div`
  display:grid;
  grid-template-columns:12em;
  column-gap:10px;
  margin-top:1.5em;
  margin-bottom:1.0em;
`

const Divider = styled.div`
  grid-column-start:1;
  grid-column-end:10;
  minHeight:2px;
  minWidth:100%;
  border-bottom:1px solid lightgray;
`

const CampaignSpendRow = styled.div`
  display:grid;
  grid-template-columns:10em 10em 1fr 10em;
  font-size:0.9em;
  user-select:none;
  padding-left:0.4em;
  padding-right:0.4em;
  &:hover {
    background-color:#e0e0e0;
    color:black;
    border-radius:3px;
  }
`

const CampaignSpendHeaderRow = styled.div`
  display:grid;
  grid-template-columns:10em 10em 1fr 10em;
  font-size:0.9em;
  padding-left:0.4em;
  padding-right:0.4em;
  user-select:none;
`

const CampaignSpendsContainer = styled.div`
  background-color:#fafafa;
  padding:3px;
  border-radius:3px;
`

const partnerIdToName = (partnerId: string): string => {
  switch (partnerId) {
    case 'googleAds': return 'Google Ads'
    case 'metaAds': return 'Meta Ads'
  }
  if (partnerId.startsWith("linkedInAds")) {
    return 'LinkedIn Ads'
  }
  if (partnerId.startsWith("bingAds")) {
    return 'Bing Ads'
  }
  if (partnerId.startsWith("readpeakAds")) {
    return 'Readpeak Ads'
  }
  return 'Display&Video'
}

const setSpendTreeOpen = (spendTrees: SpendTree[], customerId: string, open: boolean): SpendTree[] => {
  return spendTrees.map(sp => {
    return {
      ...sp, open: sp.customerId === customerId ? open : sp.open,
      children: setSpendTreeOpen(sp.children, customerId, open)
    }
  })
}

const getPartnerSpend = (spendReport: TotalCreativeSpendsResponse, partnerId: string): number => {
  return spendReport.partnerSpends.reduce((total, spend) => total +
    (spend.partnerId === partnerId ? spend.spend : 0), 0)
}

const mapToSpendTree = async (customer: CustomerTree, startDate: string, endDate: string): Promise<SpendTree[]> => {
  const spends: SpendTree[] = []

  function mapSpendsTree(treeSpend: TotalCreativeSpendsTreeResponse): any {
    return { open: false, ...treeSpend, children: treeSpend.children.map(c => mapSpendsTree(c)) }
  }
  spends.push(mapSpendsTree(await apiGetSpendsTree(customer.id, startDate, endDate)))
  return spends
}

const CampaignSpends = (props: { campaignSpends: TotalCampaignSpend[] }) => {
  const { campaignSpends } = props
  const sorted = campaignSpends.filter(cs => cs.campaignName).sort((a, b) => a.partnerId.localeCompare(b.partnerId) * 100000 +
    a.campaignName.localeCompare(b.campaignName))
  return <CampaignSpendsContainer>
    <CampaignSpendHeaderRow>
      <SpendHeader>Channel</SpendHeader>
      <SpendHeader>Category</SpendHeader>
      <SpendHeader>Campaign</SpendHeader>
      <SpendHeaderTotal>Spend</SpendHeaderTotal>
    </CampaignSpendHeaderRow>
    {sorted.map((cs, index) => <>
      <CampaignSpendRow>
        <div>{(index === 0 || sorted[index - 1].partnerId !== cs.partnerId) && partnerIdToName(cs.partnerId)}</div>
        <div>{cs.category}</div>
        <div>{cs.campaignName}</div>
        <SpendItemTotal>{toPrice(cs.spend)}</SpendItemTotal>
      </CampaignSpendRow>
    </>)}<CampaignSpendItemTotal>Total {toPrice(sorted.reduce((total, cs) => total + cs.spend, 0))}</CampaignSpendItemTotal>
  </CampaignSpendsContainer>
}

const mapCustomerToSpends = (channelFilter: string, campaignFilter: string, st: SpendTree, level: number, setOpen: (customerId: string, open: boolean) => void) => {
  const style = { fontSize: `${1.0 - level * 0.1}em`, marginLeft: `${0.5 * level}em` }
  return st.spendReport ? <>
    <OpenItem style={style} onClick={() => setOpen(st.customerId, !st.open)}>
      {st.open ? '-' : '+'}
    </OpenItem>
    <SpendItem style={style}>{st.name}</SpendItem>
    <SpendItem>{toPrice(getPartnerSpend(st.spendReport, "googleAds"))}</SpendItem>
    <SpendItem>{toPrice(getPartnerSpend(st.spendReport, "metaAds"))}</SpendItem>
    <SpendItem>{toPrice(getPartnerSpend(st.spendReport, "linkedInAds"))}</SpendItem>
    <SpendItem>{toPrice(getPartnerSpend(st.spendReport, "displayVideo"))}</SpendItem>
    <SpendItem>{toPrice(getPartnerSpend(st.spendReport, "bingAds"))}</SpendItem>
    <SpendItem>{toPrice(getPartnerSpend(st.spendReport, "readpeakAds"))}</SpendItem>
    <SpendItemTotal>{toPrice(st.spendReport.spend)}</SpendItemTotal>
    {st.open && st.spendReport?.campaignSpends.length > 0 && <SpendItemCampaign>
      <CampaignSpends campaignSpends={st.spendReport.campaignSpends.filter(cs => {
        return (cs.campaignName || "").toLowerCase().indexOf(campaignFilter.toLowerCase()) !== -1 &&
          partnerIdToName(cs.partnerId).toLowerCase().indexOf(channelFilter.toLowerCase()) !== -1
      })} />
    </SpendItemCampaign>}
  </> : <>
    <OpenItem></OpenItem>
    <SpendItem style={style}>{st.name}</SpendItem>
    <SpendItemNa>-</SpendItemNa>
    <SpendItemNa>-</SpendItemNa>
    <SpendItemNa>-</SpendItemNa>
    <SpendItemNa>-</SpendItemNa>
    <SpendItemNa>-</SpendItemNa>
    <SpendItemNa>-</SpendItemNa>
    <SpendItemNa>-</SpendItemNa>
  </>
}

const renderTree = (channelFilter: string, campaignFilter: string, customer: SpendTree, level: number, setOpen: (customerId: string, open: boolean) => void): JSX.Element => {
  return (<>
    {mapCustomerToSpends(channelFilter, campaignFilter, customer, level, setOpen)}
    {customer.open && customer.children.map(child => renderTree(channelFilter, campaignFilter, child, level + 1, setOpen))}
  </>)
}

export const AdsSpendReportingView = () => {
  const { selected } = useCustomerState()
  const [spendTrees, setSpendTrees] = useState<SpendTree[]>([])
  const [startDate, setStartDate] = useState(moment().subtract({ months: 1 }).startOf("month").toDate())
  const [endDate, setEndDate] = useState(moment().subtract({ months: 1 }).endOf("month").toDate())
  const [loading, setLoading] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [exportUrl, setExportUrl] = useState<undefined | string>()
  const [channelFilter, setChannelFilter] = useState("")
  const [campaignFilter, setCampaignFilter] = useState("")

  const updateSpendTree = () => {
    if (selected) {
      setLoading(true)
      mapToSpendTree(selected, moment(startDate.toISOString()).format("YYYY-MM-DD"),
        moment(endDate.toISOString()).format("YYYY-MM-DD")).then((spends) => { setSpendTrees(spends); setLoading(false); }).catch(() => setLoading(false))
    }
  }
  const exportToSheet = () => {
    if (selected) {
      setLoading(true)
      apiExportSpends(selected.id, moment(startDate.toISOString()).format("YYYY-MM-DD"),
        moment(endDate.toISOString()).format("YYYY-MM-DD")).then((response) => {
          if (response.redirectUrl) {
            window.location.href = response.redirectUrl
          } else {
            setExportUrl(response.url)
          }
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
    }
  }

  const setOpen = (customerId: string, open: boolean) => {
    setSpendTrees(setSpendTreeOpen(spendTrees, customerId, open))
  }

  useEffect(() => {
    if (getURLParameter("export") === "true") {
      setExportDialogOpen(true)
    }
  }, [window.location.href])
  useEffect(() => {
    updateSpendTree()
  }, [selected])


  const handleDateRangeChange = (dateRange: DateRange<dayjs.Dayjs>) => {
    if(dateRange && dateRange[0] && dateRange[1]){
      const sDate = dateRange[0].toDate()
      const eDate = dateRange[1].toDate()
      setStartDate(sDate)
      setEndDate(eDate)
      if (selected) {
        setLoading(true)
        mapToSpendTree(selected, moment(sDate.toISOString()).format("YYYY-MM-DD"),
          moment(eDate.toISOString()).format("YYYY-MM-DD")).then((spends) => { setSpendTrees(spends); setLoading(false); }).catch(() => setLoading(false))
      }
    }

  }

  return (<Box sx={{margin: '50px 24px'}}>
    {/* Heading */}
    <Box sx={{ mb: '0.5em'}}>
      <Typography variant='h1' sx={desktop_h1}>Spend Reporting</Typography>
    </Box>

    {/* Date range filters */}
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems:'center', gap: '24px', mt: '2em', mb: '2em'}}>
      <Box>
        <DateRangePickerMui startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} onClose={() => ''}/>
      </Box>
      {/*
      <Box>
        <Button onClick={() => updateSpendTree()} sx={regularBtnStyles}>Refresh</Button>
      </Box>
      */}
      <Box>
        <Button onClick={() => setExportDialogOpen(true)} sx={regularBtnStyles}>Export to sheet</Button>
      </Box>
    </Box>

    {/* Other filters */}
    <Box sx={{mt: '1em', mb: '1em'}}>
      <FormControl>
        <TextField label="Filter by campaign" size="small" value={campaignFilter} onChange={(ev) => setCampaignFilter(ev.target.value)} />
      </FormControl>
    </Box>

    <Dialog
      open={exportDialogOpen}
      onClose={() => setExportDialogOpen(false)}
    >
      <DialogTitle>
        Export to Sheet
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {exportUrl ? <>Export was done. Report is <a target='_blank' href={exportUrl}>here</a></> :
            (getURLParameter("export") ? "Authentication was needed first. Press export to continue." :
              "This will create new sheet to your Google Workspace. Do you wish to continue?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!exportUrl && <Button sx={{...whiteBlackBorderBtnStyles}} onClick={() => {
          window.location.href = window.location.href.replace("?export=true", "")
        }}>Cancel</Button>}
        <Button sx={{...regularBtnStyles}} onClick={() => {
          if (exportUrl) {
            setExportDialogOpen(false)
            window.location.href = window.location.href.replace("?export=true", "")
          } else {
            exportToSheet()
          }
        }}>{exportUrl ? "Close" : "Export"}</Button>
      </DialogActions>
    </Dialog>
    {!loading ? <ReportContainer>
      <SpendMatrix>
        <SpendHeader></SpendHeader>
        <SpendHeader>Customer</SpendHeader>
        <SpendHeader>Google Ads</SpendHeader>
        <SpendHeader>Meta</SpendHeader>
        <SpendHeaderNa>LinkedIn</SpendHeaderNa>
        <SpendHeader>DV360</SpendHeader>
        <SpendHeaderNa>Bing Ads</SpendHeaderNa>
        <SpendHeaderNa>Readpeak</SpendHeaderNa>
        <SpendHeaderTotal>Total</SpendHeaderTotal>
        <Divider />
        {spendTrees.map(customer => renderTree(channelFilter, campaignFilter, customer, 0, setOpen))}
        <Divider />
      </SpendMatrix>
    </ReportContainer> : <CircularProgressLoading/>
    }

  </Box>)
}