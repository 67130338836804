
import React, { createContext, useReducer, useContext } from 'react';
import { initialAdsState } from './AdsReducer';
import { AdsState, AdsAction } from './AdsTypes';

export const StateContext = createContext<[AdsState, React.Dispatch<AdsAction>]>([
  initialAdsState, 
  () => initialAdsState
])

export type AdsProviderProps = {
  reducer: React.Reducer<AdsState, AdsAction>
  children: React.ReactElement
}

export const AdsProvider = ({reducer, children}: AdsProviderProps) => {
  const [adsState, dispatch] = useReducer(reducer, initialAdsState);
  return (
    <StateContext.Provider value={[adsState, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}
export const useStateValue = () => useContext(StateContext);

