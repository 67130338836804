import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { KivaSuiteLogo } from './Logo';

const NotFoundPage = () => {
  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: '#fff', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ display: 'grid', alignContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Link to={'/'} style={{ display: 'flex', alignItems: 'center'}}><KivaSuiteLogo width='124px' /></Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', flexDirection: 'column', marginTop: '50px'}}>
        <Typography variant='h3' sx={{margin: 'auto', textAlign: 'center'}}>404</Typography>
        <Typography variant='h4' sx={{margin: '5px auto 16px', textAlign: 'center'}}>Page not found.</Typography>
        <Link to={'/'} style={{ display: 'flex', alignItems: 'center'}}>Back to home page.</Link>
      </Box>
    </Box>
  )
}
export default NotFoundPage