import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef } from "react";
import { RGBColor } from "react-color";
import styled from "styled-components";

export type AdComponentArea = {
  x: number
  y: number
  xyLocked: boolean
  width: number
  height: number
  widthHeightLocked: boolean
  zIndex: number
}

export type MouseDragType = "topLeft" | "top" | "topRight" | "right" | "bottomRight" | "bottom" | "bottomLeft" | "left"

export const DeleteItem = (props: { onClick: () => void }) => {
  return <FontAwesomeIcon style={{ color: 'red', cursor: 'pointer' }} onClick={props.onClick} icon={faTrash} />
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};


export const snapToGrid = (area: AdComponentArea, gridSize: number) => {
  return {
    ...area,
    x: Math.round(area.x / gridSize) * gridSize,
    y: Math.round(area.y / gridSize) * gridSize,
    width: Math.round(area.width / gridSize) * gridSize,
    height: Math.round(area.height / gridSize) * gridSize
  }
}

export const mapCoordinateToDragType = (x: number, y: number, width: number, height: number, edge: number): MouseDragType | undefined => {
  if (x < edge) {
    if (y < edge) {
      return "topLeft"
    }
    else if (y > height - edge) {
      return "bottomLeft"
    }
    return "left"
  }
  else if (x > width - edge) {
    if (y < edge) {
      return "topRight"
    }
    else if (y > height - edge) {
      return "bottomRight"
    }
    return "right"
  }
  if (y < edge) {
    return "top"
  }
  if (y > height - edge) {
    return "bottom"
  }
}

export const CenterText = styled.div`
  position:absolute;
  transform:translate(-50%, -50%);
  top:50%;
  left:50%;
`

export const snapNumber = (value: number, snapSize: number): number => {
  return Math.floor(value / snapSize) * snapSize
}

type ListenerState = {
  listener: (state: any, event: any) => void
  state: any,
  onEnd: (state: any) => void
}

var mouseDownListeners: ListenerState[] = []

document.addEventListener("mouseup", () => {
  mouseDownListeners.forEach((l) => l.onEnd(l.state))
  mouseDownListeners = []
})

document.addEventListener("mousemove", (event) => {
  mouseDownListeners.forEach((l) => l.listener(l.state, event))
})

export const addMouseDownMoveListener = (state: any, listener: (state: any, event: any) => void, onEnd: (state: any) => void) => {
  mouseDownListeners.push({ listener, state, onEnd })
}

export const swapItems = <T extends unknown>(items: T[], index: number, index2: number): T[] => {
  return items.map((i, currIndex) => currIndex === index ? items[index2] : (currIndex === index2 ? items[index] : i))
}

export const RGBColorToCss = (color: RGBColor): string => {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

export const getUIBooleanAttribute = (key: string, defaultValue: boolean): boolean => {
  if (!localStorage.getItem(key)) {
    return defaultValue
  }
  return localStorage.getItem(key) === "true"
}

export const setUIBooleanAttribute = (key: string, value: boolean) => {
  localStorage.setItem(key, value ? "true" : "false")
}

export const getUIIntAttribute = (key: string, defaultValue: number): number => {
  const item = localStorage.getItem(key)
  return item ? parseInt(item, 10) : defaultValue
}

export const getUIFloatAttribute = (key: string, defaultValue: number): number => {
  const item = localStorage.getItem(key)
  return item ? parseFloat(item) : defaultValue
}

export const setUINumberAttribute = (key: string, value: number) => {
  localStorage.setItem(key, value.toString())
}

export const countryCodes = [
  { name: 'Finland', code: 'FI' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Austria', code: 'AT' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Spain', code: 'ES' },
  { name: 'Poland', code: 'PL' },
  { name: 'Germany', code: 'DE' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Norway', code: 'NO' },
]

export type Currency = {
  name: string,
  symbol: string,
  symbolNative: string,
  decimalDigits: number,
  rounding: number,
  code: string,
  namePlural: string
}

export const currencies: { [code: string]: string } = {
  "EUR": "Euro",
  "USD": "Yhdysvaltojen dollari",
  "SEK": "Ruotsin kruunu",
  "DKR": "Tanskan kruunu",
  "NOK": "Norjan kruunu",
  "GBP": "Iso-Britannian punta",
  "PLN": "Puolan zloty"
}

type FloatRateCurrency = {
  code: string
  alphaCode: string
  numericCode: string
  name: string
  rate: number
  date: string
  inverseDate: number
}

export type Language = {
  name: string
  code: string
}

export const languages: Language[] = [
  { "name": "Bulgarian", "code": "bg" },
  { "name": "Belarusian", "code": "be" },
  { "name": "Croatian", "code": "hr" },
  { "name": "Czech", "code": "cs" },
  { "name": "Danish", "code": "da" },
  { "name": "Dutch", "code": "nl" },
  { "name": "English", "code": "en" },
  { "name": "Estonian", "code": "et" },
  { "name": "Finnish", "code": "fi" },
  { "name": "French", "code": "fr" },
  { "name": "German", "code": "de" },
  { "name": "Greek", "code": "el" },
  { "name": "Hungarian", "code": "hu" },
  { "name": "Icelandic", "code": "is" },
  { "name": "Irish", "code": "ga" },
  { "name": "Italian", "code": "it" },
  { "name": "Latin", "code": "la" },
  { "name": "Latvian", "code": "lv" },
  { "name": "Lithuanian", "code": "lt" },
  { "name": "Macedonian", "code": "mk" },
  { "name": "Norwegian", "code": "no" },
  { "name": "Polish", "code": "pl" },
  { "name": "Portuguese", "code": "pt" },
  { "name": "Romanian", "code": "ro" },
  { "name": "Serbian", "code": "sr" },
  { "name": "Slovak", "code": "sk" },
  { "name": "Slovenian", "code": "sl" },
  { "name": "Spanish", "code": "es" },
  { "name": "Swedish", "code": "sv" },
  { "name": "Ukrainian", "code": "uk" },
];


export const cutStringToMaxLength = (str: string, maxLength: number): string => {
  if (str && str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "..."
  }
  return str
}

export const copyObject = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj))
}

export const reverseArray = (array: any[]): any[] => {
  const reversed: any[] = []
  for (let i = array.length - 1; i >= 0; i--) {
    reversed.push(array[i])
  }
  return reversed
}

export const isInsideRect = (x: number, y: number, area: AdComponentArea): boolean => {
  return x >= area.x && y >= area.y && x <= area.x + area.width && y <= area.y + area.height
}


export const Line = (props: { x1: number, y1: number, x2: number, y2: number, showLength: boolean }) => {
  const { x1, y1, x2, y2, showLength } = props
  const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
  const angle = `${Math.atan2(y2 - y1, x2 - x1)}rad`
  if (length < 2) {
    return <></>
  }
  return <div style={{
    position: 'absolute',
    left: x1, top: y1,
    minWidth: length,
    maxWidth: length,
    backgroundColor: 'white',
    boxShadow: '0px 0px 2px black',
    maxHeight: 1,
    transform: `rotate(${angle})`,
    transformOrigin: 'top left',
  }}>
    {showLength && <div style={{ filter: 'revert', fontWeight: 'bold', paddingLeft: '3px', textShadow: '0px 0px 2px black', transformOrigin: 'top left', color: 'white', transform: `rotate(-${angle});` }}>{Math.round(length)}px</div>
    }
  </div >
}


export const getAreaSide = (area: AdComponentArea, area2: AdComponentArea): 'left' | 'right' | 'top' | 'bottom' | 'none' => {
  if (area2.x + area2.width < area.x) {
    if (area.y < area2.y + area2.height && area.y + area.height > area2.y) {
      return 'left'
    }
  }
  if (area2.x > area.x + area.width) {
    if (area.y < area2.y + area2.height && area.y + area.height > area2.y) {
      return 'right'
    }
  }
  if (area2.y + area2.height < area.y) {
    if (area.x < area2.x + area2.width && area.x + area.width > area2.x) {
      return 'top'
    }
  }
  if (area2.y > area.y + area.height) {
    if (area.x < area2.x + area2.width && area.x + area.width > area2.x) {
      return 'bottom'
    }
  }

  return 'none'
}

export const convertCsvStringToArray = (str: string): any[] => {
  const csvHeader = str.slice(0, str.indexOf("\n")).split(",");
  const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");

  const array = csvRows.map(i => {
    const values = i.split(",");
    const obj = csvHeader.reduce((object: any, header: string, index: number) => {
      object[header] = values[index];
      return object;
    }, {});
    return obj;
  });

  return array
};

export const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}

export const fileSizeToString = (size: number): string => {
  if (size > 1000000) {
    return (size / 1000000).toFixed(1).replace(".", ",") + " Mb"
  }
  return (size / 1000).toFixed(1).replace(".", ",") + " Kb"
}