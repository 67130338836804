import { SharedAction, SharedState, SET_Navigation_Drawer_State, SET_LOADING } from "./SharedTypes";

export const initialSharedState: SharedState = {
  drawerIsOpen: true,
  loading: false
};

const SharedReducer = (state = initialSharedState, action: SharedAction): SharedState => {
  switch (action.type) {
    case SET_Navigation_Drawer_State:
      return {
        ...state,
        drawerIsOpen: action.data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};

export default SharedReducer;
