

import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { MetaSettings, ReadpeakAdsSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiSaveReadpeakCredentials } from '../../api/Auth';
import { apiGetMetaSettings, apiGetReadpeakSettings, apiSaveCompetitorFacebookPages } from '../../api/Customer';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import * as uuid from 'uuid'
import { regularBtnStyles } from '../../styles/buttons';
import { desktop_h3, montserratBoldFontFamily } from '../../styles/textStyles';
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext';

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type MetaCompetitorPagesConfigProps = {
  customerId: number
}

export const MetaCompetitorPagesConfig = ({customerId}: MetaCompetitorPagesConfigProps) => {
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState<any>({ userInput: "", competitorFacebookPages: [] })
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [{ competitorAdUnits }, competitorDispatch] = useCompetitorStateValue()

  useEffect(() => {
    if(customerId){
      apiGetMetaSettings(customerId).then((response: any) => {
        if (response) {
          setSettings({ ...settings, competitorFacebookPages: response.competitorFacebookPages || [] })
        } else {
          setSettings({ ...settings, competitorFacebookPages: [] })
        }
      })

    }
  }, [customerId])


  const handlePagesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, userInput: event.target.value })
  }

  const handleSavePages = () => {
    // Regular expression to match the name and id
    const regex = /([a-zA-Z0-9\s]+)\s+\(id:\s*(\d+)\)/g;
    let match;
    const pages = [];
    while ((match = regex.exec(settings.userInput)) !== null) {
      pages.push({
        name: match[1].trim(),
        id: match[2]
      });
    }
    if(pages.length > 0){
      setSaveBtnProgress(true)
      customerId && apiSaveCompetitorFacebookPages(customerId, pages).then((res:any) => {
        setSettings({userInput: "", competitorFacebookPages: res.competitorFacebookPages})
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Saved successfully!", duration: 7000 }))
        setSaveBtnProgress(false)
      }).catch(error => {
        console.log("Save competitor Facebook pages error", error)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Error while saving competitor's Facebook pages. ${error.message}`, duration: null }))
        setSaveBtnProgress(false)
      })
    }else{
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Please input Facebook pages in the following format: page 1 (id: 123456), page 2 (id: 654321).`, duration: null }))
    }

  }

  return (
  <Grid container>
    {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Typography sx={{...desktop_h3, fontFamily: montserratBoldFontFamily}}>Competitor Facebook Pages</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 4 }}>
        {!settings.competitorFacebookPages && <Typography>Competitor Facebook pages have not been configured yet.</Typography>}
        {settings.competitorFacebookPages?.length > 0 && settings.competitorFacebookPages?.map((p:{id: string, name: string}) => (<Typography>- {p.name} (id: {p.id})</Typography>))}
      </Grid>
      <Grid component={"form"} container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>Facebook pages</Typography>
          <Typography sx={{fontSize: '14px'}}><b>For example:</b> page 1 (id: 123456), page 2 (id: 654321)</Typography>
          <TextField fullWidth multiline maxRows={5} minRows={3} size='small' variant="outlined" value={settings.userInput} onChange={handlePagesChange} />
        </Grid>

        <Grid item xs={12} sx={{ pt: 0 }}>
          <Button sx={{ ...regularBtnStyles, mt: 1 }} onClick={handleSavePages}>
            {saveBtnProgress ?  <CircularProgress  style={{width: '20px', height: '20px', color: "#fff"}} /> : 'Save'}
          </Button>
          <Typography>{settings.userInput}</Typography>
        </Grid>
      </Grid>
    </Grid>}
  </Grid>
  )
}
