import { Avatar, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useCustomerState } from '../context/customer/CustomerContext';
import { desktop_h1} from '../styles/textStyles';
import { neutralColors, primaryColors } from '../styles/colors';
import AccordionContainer from '../components/AccordionContainer';
import { useAuthState } from '../context/auth/AuthContext';
import { regularBtnStyles } from '../styles/buttons';
import { apiDoAuth0ChangePassword } from '../api/Auth';
import { useAlertStateValue } from '../context/alert/AlertContext';
import { AlertSeverity } from '../context/alert/AlertTypes';
import { setAlert } from '../context/alert/AlertAction';


const ProfileView = () => {
  const customerState = useCustomerState()
  const auth = useAuthState()
  const selected = customerState.selected
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [loading, setLoading] = useState(false);

  const handleSavePassword = () => {
    setLoading(true)
    apiDoAuth0ChangePassword({}).then(res => {
      setLoading(false)
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: res?.data?.message, duration: 7000 }))
    }).catch(err => {
      console.log("error", err)
      setLoading(false)
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Some thing went wrong! Please try again later.", duration: null }))
    })
  }

  return (
    <Box sx={{ margin: '50px' }}>

      <Grid container sx={{ alignItems: 'left' }}>
        <Box sx={{}}>
          <Typography variant='h1' sx={{ ...desktop_h1 }}>Your Profile</Typography>
        </Box>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '32px', border: `1px solid ${neutralColors[200]}`, mt: '40px', mb: '40px', padding: '32px', borderRadius: '5px' }}>
            <Box>
              <Avatar
                style={{ width: '60px', height: '60px' }}
                src={auth.user?.picture ? auth.user?.picture : ""}
                alt="profilePicture"
              />
            </Box>
            <Box>
              <Box sx={{ mb: '32px' }}>
                <Typography sx={{}}>Name</Typography>
                <Typography sx={{}}>{auth.user?.name}</Typography>
              </Box>
              <Box>
                <Typography sx={{}}>Email</Typography>
                <Typography sx={{}}>{auth.user?.email}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>

      <Box>
        {auth?.user?.identityProvider === 'auth0' && <AccordionContainer title="Change password"
          styles={{ mt: 2, mb: 2, boxShadow: 'none', borderRadius: '5px', border: `1px solid ${neutralColors[200]}`, position: 'unset' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ maxWidth: '800px' }}>
                <Typography>By clicking the button below, we will send you an email containing a secure link to reset your password.</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, mb: 2 }}>
              <Button sx={{ ...regularBtnStyles }} onClick={handleSavePassword}>
                {loading ? <CircularProgress style={{ width: '20px', height: '20px', color: primaryColors[950] }} /> : 'Change password'}
              </Button>
            </Grid>
          </Grid>
        </AccordionContainer>}
      </Box>
    </Box>
  )
}
export default ProfileView;