import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { capitalizeFirstLetter, beautifyString, convertToFinnishDate, isItOldDate } from '../../../../utils/helpers'
import { Colors } from '../../../../constants/colors';
import CircleIcon from '@mui/icons-material/Circle';

interface CampaignDetailsProps {
  campaign: any
  selectedAdSetId: string | number
  handleAdSetSelection: (e: SelectChangeEvent<any>) => void
}
const CampaignDetails = ({ campaign, selectedAdSetId, handleAdSetSelection }: CampaignDetailsProps) => {
  return (
    <>
      <Grid container rowSpacing={4} sx={{ mt: 0.5 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Campaign details</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography style={styles.body2} variant='body2'>
            Status
          </Typography>
          <Typography style={styles.caption} variant='caption' sx={{ color: campaign.status === "PAUSED" ? Colors.yellow : Colors.green }}>
            {capitalizeFirstLetter(campaign.status)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Objective
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {beautifyString(campaign.objective)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography style={styles.body2} variant='body2'>
            Created time
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {convertToFinnishDate(campaign.created_time)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container rowSpacing={4} sx={{ mt: 0.5 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Ad sets within the selected campaign</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel id="campaign-objective-select-label">Ad set</InputLabel>
            <Select
              labelId="campaign-objective-select-label"
              id="campaign-objective"
              value={selectedAdSetId}
              onChange={handleAdSetSelection}
              label="Budget type"
            >
              {campaign?.adSets?.length && campaign.adSets.map((adSet: any) => {
                const ended: boolean = isItOldDate(adSet.end_time)
                return <MenuItem disabled={ended ? true : false} sx={{ fontSize: '14px' }} key={adSet.id} value={adSet.id}>
                  <CircleIcon sx={{ fontSize: '12px', marginRight: '7px', color: adSet.status === "PAUSED" ? Colors.yellow : Colors.green }} />
                  {adSet.name} {ended ? ` | ${convertToFinnishDate(adSet.end_time)} ENDED` : null}
                </MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

const styles = {
  body1: {
    margin: '10px auto',
    color: '#000'
  },
  body2: {
    margin: '10px auto'
  },
  caption: {
    color: '#7d7d7d'
  },
}

export default CampaignDetails;