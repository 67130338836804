

import { Button, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { DatasetSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiOAuthDatasetLogin } from '../../api/Auth';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { apiGetDatasetSettings, apiRemoveDatasetSettings } from '../../api/Customer';

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type DatasetAdminProps = {
  customerId: string
}

export const DatasetAdmin = (props: DatasetAdminProps) => {
  const { customerId } = props
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState<DatasetSettings>([])
  const [_, alertDispatch] = useAlertStateValue()
  const [newProjectId, setNewProjectId] = useState<string>("")
  const [newDatasetId, setNewDatasetId] = useState<string>("")
  const [conversionEvents, setConversionEvents] = useState<string[]>([])

  useEffect(() => {
    apiGetDatasetSettings(customerId).then((settings) => setSettings(settings))
  }, [customerId])

  function doDatasetLogin() {
    setLoading(true)
    apiOAuthDatasetLogin(customerId, [{
      projectId: newProjectId,
      datasetId: newDatasetId,
      conversionEvents,
      type: "GoogleAnalytics4"
    }]).then((response: any) => {
      window.location.href = response.url
    }).catch(() => {
      setLoading(false)
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in authorization. Please try again later.", duration: null }))
    })
  }

  function removeDatasetAuthorization(projectId: string, datasetId: string) {
    apiRemoveDatasetSettings(customerId, projectId, datasetId).then((newSettings) => {
      setSettings(newSettings)
    }).catch(() => {
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in removing authorization. Please try again later.", duration: null }))
    })
  }

  return (
    <Grid container>
      {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
        <Grid component={"form"} container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {settings.map(ds => <>
            <Grid item xs={4} sx={gridItemStyles} rowSpacing={1}>
              <TextField fullWidth size='small' label='Project ID' variant="outlined" value={ds.projectId}
                disabled={true} />
            </Grid>
            <Grid item xs={3} sx={gridItemStyles} rowSpacing={1}>
              <TextField fullWidth size='small' label='Dataset ID' variant="outlined" value={ds.datasetId}
                disabled={true} />
            </Grid>
            <Grid item xs={3}>
              <TextField fullWidth size='small' label='Type' variant="outlined" value={ds.type === "GoogleAnalytics4" ? "Google Analytics 4" : "Unknown"}
                disabled={true} />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" fullWidth color="warning" onClick={() => removeDatasetAuthorization(ds.projectId, ds.datasetId)}>
                Unauthorize
              </Button>
            </Grid>
            <Grid item xs={10} sx={gridItemStyles} rowSpacing={1}>
              <TextField fullWidth size='small' label='Conversion events (comma separated)' disabled={true} variant="outlined" value={ds.conversionEvents} />
            </Grid>
          </>)}
          <Grid item xs={4} sx={gridItemStyles} rowSpacing={1}>
            <TextField fullWidth size='small' label='Project ID' variant="outlined" value={newProjectId} onChange={(ev) => setNewProjectId(ev.target.value)} />
          </Grid>
          <Grid item xs={3} sx={gridItemStyles} rowSpacing={1}>
            <TextField fullWidth size='small' label='Dataset ID' variant="outlined" value={newDatasetId} onChange={(ev) => setNewDatasetId(ev.target.value)} />
          </Grid>
          <Grid item xs={3}>
            <TextField fullWidth size='small' label='Type' variant="outlined" value={"GoogleAnalytics4"}
              disabled={true} />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" fullWidth color="primary" onClick={doDatasetLogin}>
              Authorize
            </Button>
          </Grid>
          <Grid item xs={10} sx={gridItemStyles} rowSpacing={1}>
            <TextField fullWidth size='small' label='Conversion events (comma separated)' variant="outlined" value={conversionEvents} onChange={(ev) => setConversionEvents(ev.target.value.split(","))} />
          </Grid>
        </Grid>
      </Grid>}
    </Grid>
  )
}
