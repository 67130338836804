import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { setAdvancedPostBoosting, setMatchedPosts } from '../../../context/meta/MetaActions'
import { useStateValue } from '../../../context/meta/MetaContext'

interface PostTypesProps {
  handlePostTypesChange?: any
  handleFiltersChange: any
}
const PostTypes = ({ handleFiltersChange }: PostTypesProps) => {
  const [{ allPosts, matchedPosts, advancedPostBoosting }, dispatch] = useStateValue()

  // Whenever post boosting filters changed, fire the handleFiltersChange function and rerender the component
  React.useEffect(() => {
    handleFiltersChange()
  }, [advancedPostBoosting.filters])

  const handlePostTypesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let updatedTypes: string[] = []
    if (advancedPostBoosting.filters.types.includes(value)) {
      updatedTypes = advancedPostBoosting.filters.types.filter(s => s !== value)
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, filters: { ...advancedPostBoosting.filters, types: updatedTypes } }))
    } else {
      updatedTypes = advancedPostBoosting.filters.types.concat(value)
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, filters: { ...advancedPostBoosting.filters, types: updatedTypes } }))
    }
  }


  const postTypes = [
    {
      key: 'video',
      label: 'Video'
    },
    {
      key: 'photo',
      label: 'Photo'
    },
    {
      key: 'link',
      label: 'Link'
    },
    {
      key: 'post',
      label: 'Post'
    },
    {
      key: 'event',
      label: 'Event'
    },
    {
      key: 'other',
      label: 'Other'
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h5'>Post type</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Type options</FormLabel>
          <FormGroup aria-label="position" row >
            {postTypes.map(t =>
              <FormControlLabel
                key={t.key}
                value={t.key}
                control={<Checkbox onChange={handlePostTypesChange} />}
                label={t.label}
                labelPlacement="end"
                checked={advancedPostBoosting.filters.types.indexOf(t.key) > -1}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mt: 6, mb: 3 }}>
        <Divider />
      </Grid>

    </Grid>
  )
}

export default PostTypes