import { Box, Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { Line, copyObject, cutStringToMaxLength, getAreaSide, getUIBooleanAttribute, getUIFloatAttribute, getUIIntAttribute, isInsideRect, reverseArray, setUIBooleanAttribute, setUINumberAttribute, snapNumber, snapToGrid, swapItems } from "../Utils"
import { AdComponent, AdTemplate, AdTemplateInstance, AdTemplateType, HtmlAdComponent, ImageAdComponent, RectangleAdComponent, TextAdComponent, objectFits } from "./Types"
import { apiDeleteAdTemplateFile, apiPatchAdTemplateFile, apiUploadAdTemplateFile } from "../../api/Customer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { AspectRatio, CheckCircle, CheckCircleOutline, Delete, DeleteRounded, Edit, FlipToBack, FlipToFront, FontDownload, FormatAlignCenter, FormatAlignLeft, FormatAlignRight, FormatBold, FormatItalic, FormatUnderlined, Gif, Html, Image, OpenWith, Palette, PlayArrow, RadioButtonUnchecked, Replay, Settings, Stop, TitleOutlined, UploadFile, VerticalAlignBottom, VerticalAlignCenter, VerticalAlignTop } from "@mui/icons-material"
import { RGBColor, SketchPicker } from 'react-color'
import { AdTemplateFile, AdTemplateFileHtml, AdTemplateFileImage } from "../../api/Types"
import { v4 as uuidv4 } from 'uuid'
import { AnimationDialog } from "./AnimationDialog"
import { DrawComponent } from "./DrawComponent"
import { LoadingModal } from "../Modal"
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { primaryColors } from "../../styles/colors"
import { desktop_p3, montserratBoldFontFamily } from "../../styles/textStyles"
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';

const Container = styled.div`
  display:grid;
  column-gap:10px;
  grid-template-columns:16rem 1fr;
  position:relative;
`

const DrawContainer = styled.div`
  width:600px;
  height:300px;
  background-color:#fafafa;
  position:relative;
  overflow:auto;
  border-radius:2px;
  margin:0px;
  padding:0px;
  cursor:crosshair;
  overflow:hidden;
`

const LineContainer = styled.div`
  width:600px;
  height:300px;
  background-color:transparent;
  top:0px;
  left:0px;
  pointer-events:none;
  position:absolute;
  overflow:auto;
  margin:0px;
  padding:0px;
  cursor:crosshair;
  overflow:hidden;
`

const Position = styled.div`
  position:absolute;
  top:10px;
  right:90px;
  z-index:100;
  font-size:1.0em;
  user-select:none;
  background-color:#f0f0f0;
  border-radius:10px;
  padding:5px 10px 5px 10px;
`

const ControlContainer = styled.div`
  display:flex;
  flex-direction:column;
  position:relative;
`

const ToolGroupContainer = styled.div`
  display:flex;
  flex-direction:column;
  position:relative;
  border-top-left-radius:15px;
  background-color:#fafafa;
  padding:16px 8px;
  margin-bottom:10px;
  box-shadow: 1px 1px 3px gray;
`

const ToolContainer = styled.div`
  grid-column-start:2;
  grid-column-end:3;
  display:flex;
  flex-direction:row;
  row-gap:15px;
  column-gap:10px;
  margin-bottom:10px;
  align-items:center;
  width:100%;
`

const EditContainer = styled.div`
  position:absolute;
  top:-10px;
  right:20px;
  background-color:white;
  padding:5px;
  border-radius:50%;
  width:1.5em;
  height:1.5em;
`

const DrawGrid = styled.div`
  opacity:0.05;
  width:100%;
  height:100%;
  pointer-events:none;
  z-index:1000;
`

const ToolHeading = styled.div`
  font-family: ${montserratBoldFontFamily};
  margin-top:1px;
  margin-bottom:10px;
`

const FontPreview = styled.textarea`
  min-width: 360px;
  max-width: 360px;
  font-size: 40px;
`

const FontSelectContent = styled.div`
  display:flex;
  flex-direction:column;
  row-gap:10px;
  min-width:400px;
`

const ColorSelectContent = styled.div`
  display:flex;
  flex-direction:row;
  row-gap:10px;
  min-width:400px;
`

const SelectRemove = styled.div`
  display:grid;
  grid-template-columns:1fr 2em;
  column-gap:5px;
  align-items:center;
  min-width:400px;
  max-width:400px;
`

const CanvasContainer = styled.div`
  width:100%;
  height:600px;
  overflow:auto;
  box-shadow: 1px 1px 3px gray;
`

const Canvas = styled.div`
  min-width:100%;
  min-height:600px;
  background-color:#f0f0f0;
  position:relative;
`

const FileList = styled.div`
  display:grid;
  grid-template-columns:1fr 6em 4em 3em;
  column-gap:15px;
  min-width:500px;
  margin-bottom:2em;
  align-items:center;
`

const SmallFileList = styled.div`
  display:grid;
  width:100%;
  grid-template-columns:1em 1fr 64px;
  column-gap:15px;
  margin-bottom:2em;
  margin-right:3em;
  font-size:0.7em;
  align-items:center;
  row-gap:2px;
`

const FileAttr = styled.div`
  font-weight:bold;
`

const FileCategory = styled.div``

const File = styled.div``

const Resolution = styled.div``

const CoordinateControls = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  column-gap:4px;
  row-gap:12px;
  margin-top:0.1em;
  margin-bottom:0.5em;
`

const dividerStyles = {
  margin: '16px 0'
}

const ThumbnailImage = styled.img`
  min-width:40px;
  max-width:40px;
  min-height:40px;
  max-height:40px;
  object-fit: cover;
  border-radius:4px;
  justify-self: center;
`

const PreviewImage = styled.img`
  min-width:256px;
  max-width:256px;
  min-height:256px;
  max-height:256px;
  object-fit: contain;
`

const ElementRow = styled.div`
  display:grid;
  width:100%;
  grid-template-columns: 4fr 1fr;
  align-items:center;
`

export const EditTool = () => {
  return <EditContainer><FontAwesomeIcon icon={faEdit} style={{ transform: 'translate(-50%,-50%)', left: '50%', top: '50%', position: 'absolute' }} /></EditContainer >
}


export type AdTemplateInstanceEditorProps = {
  templateId: string,
  customerId: number,
  instance: AdTemplateInstance,
  adTemplate: AdTemplate
  onDelete: (instance: AdTemplateInstance) => void
  onUpdate: (instance: AdTemplateInstance) => void
  onUpdateTemplate: (template: AdTemplate) => void
}

const uiAttrPath = (instance: AdTemplateInstance, attr: string): string => {
  return `${instance.width}x${instance.height}-${attr}`
}

const getFileUseSymbol = (id: string, name: string, category: string,
  currentInstance: AdTemplateInstance, types: AdTemplateType[]): any => {
  let state = category !== "image" && category !== "html" && category !== "font" ? "none" : "notInUse"

  function nextState(isSelected: boolean, state: string) {
    if (isSelected) return "inUse"
    else if (state !== "inUse") return "inUseOther"
    return state
  }

  for (const type of types) {
    for (const instance of type.instances) {
      const isSelected = currentInstance.width === instance.width && currentInstance.height == instance.height
      for (const comp of instance.components) {
        if (comp.type === "Image") {
          const imgComp = comp as ImageAdComponent
          if (imgComp.imageId === id) {
            state = nextState(isSelected, state)
          }
        }
        else if (comp.type === "Html") {
          const htmlComp = comp as HtmlAdComponent
          if (htmlComp.fileId === id) {
            state = nextState(isSelected, state)
          }
        }
        else if (comp.type === "Text" && category === "font") {
          const textComp = comp as TextAdComponent
          if (textComp.font === name) {
            state = nextState(isSelected, state)
          }
        }
      }
    }
  }
  if (state === "none") return <div />
  if (state === "inUse") return <CheckCircle fontSize="small" />
  if (state === "inUseOther") return <CheckCircleOutline fontSize="small" />
  return <RadioButtonUnchecked fontSize="small" />
}

export const AdTemplateInstanceEditor = (props: AdTemplateInstanceEditorProps) => {
  const { customerId, templateId, adTemplate, onDelete, onUpdate, onUpdateTemplate } = props
  const [instance, setInstance] = useState<AdTemplateInstance>(props.instance)
  const [showGrid, setShowGrid] = useState<boolean>(getUIBooleanAttribute(uiAttrPath(instance, "showGrid"), false))
  const [snapGridEnabled, setSnapGridEnabled] = useState<boolean>(getUIBooleanAttribute(uiAttrPath(instance, "snapGridEnabled"), false))
  const [gridSize, setGridSize] = useState<number>(getUIIntAttribute(uiAttrPath(instance, 'gridSize'), 20))
  const [mousePos, setMousePos] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(getUIFloatAttribute(uiAttrPath(instance, 'zoom'), 1.0))
  const [tool, setTool] = useState<"move" | "text" | "image" | "html" | "rectangle">("move")
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [imageSelectDialogOpen, setImageSelectDialogOpen] = useState(false)
  const [fontSelectDialogOpen, setFontSelectDialogOpen] = useState(false)
  const [animationDialogOpen, setAnimationDialogOpen] = useState(false)
  const [animationsRunning, setAnimationsRunning] = useState(true)
  const [lines, setLines] = useState<{ x1: number, y1: number, x2: number, y2: number }[]>([])

  const firstImage = adTemplate.files.filter(f => f.category === "image")[0]
  const [addImageId, setAdImageId] = useState<string | undefined>(firstImage ? firstImage.id : undefined)
  const firstFont = adTemplate.files.filter(f => f.category === "font")[0]
  const [fontName, setFontName] = useState<string | undefined>(firstFont ? firstFont.name : undefined)
  const [editTextDialogOpen, setEditTextDialogOpen] = useState(false)
  const [editText, setEditText] = useState("")
  const [editTextDynamic, setEditTextDynamic] = useState(false)
  const [editTextTimeBased, setEditTextTimeBased] = useState(false)
  const [editTextExternal, setEditTextExternal] = useState(false)
  const [pickColor, setPickColor] = useState<RGBColor>({ r: 255, g: 255, b: 255, a: 255 })
  const [backgroundColor, setBackgroundColor] = useState<RGBColor>({ r: 255, g: 255, b: 255, a: 0.0 })
  const [clickPos, setClickPos] = useState({ x: 0, y: 0 })
  const [requiresLoading, setRequiresLoading] = useState(true)
  const [htmlEditDialogOpen, setHtmlEditDialogOpen] = useState(false)
  const [textToReplace, setTextToReplace] = useState("")
  const [newText, setNewText] = useState("")
  const [loading, setLoading] = useState(false)

  const [colorPickerDialogOpen, setColorPickerDialogOpen] = useState(false)

  const [undoInstances, setUndoInstances] = useState<AdTemplateInstance[]>([])
  const [redoInstances, setRedoInstances] = useState<AdTemplateInstance[]>([])


  const [rectangleStartPos, setRectangleStartPos] = useState({ x: 0, y: 0 });
  const [rectangleEndPos, setRectangleEndPos] = useState({ x: 0, y: 0 });
  const [renderRectangleDynamically, setRenderRectangleDynamically] = useState(false);

  useEffect(() => {
    setInstance(props.instance)
  }, [props.instance])

  useEffect(() => {
    document.querySelectorAll('[data-animated-component=true]').forEach((el) => { (el as any).style.animationPlayState = animationsRunning ? 'running' : 'paused' })
  }, [animationsRunning])

  useEffect(() => {
    if (requiresLoading) {
      setRequiresLoading(false)
      setInstance({ ...instance, components: instance.components.map(c => c.type === "Html" ? { ...c, html: "" } : c) })
      Promise.all(instance.components.map(c => {
        if (c.type === "Html") {
          const h = c as HtmlAdComponent
          return fetch(h.filename).then((response) => response.text()).then((text) => ({ ...h, html: text }))
        }
        return Promise.resolve(c)
      })).then((components) => setInstance({ ...instance, components }))
    }
  }, [requiresLoading])

  useEffect(() => {
    if (editIndex && instance.components[editIndex].type === "Text") {
      const t = instance.components[editIndex] as TextAdComponent
      setEditText(t.text)
      setEditTextDynamic(t.isDynamic)
      setEditTextTimeBased(t.isTimeBased)
      setEditTextExternal(t.isExternal)
    }
    setLinesForEditComponent()
  }, [editIndex])

  useEffect(() => {
    setUINumberAttribute(uiAttrPath(instance, "gridSize"), gridSize)
  }, [gridSize])

  useEffect(() => {
    setUIBooleanAttribute(uiAttrPath(instance, "showGrid"), showGrid)
  }, [showGrid])

  useEffect(() => {
    setUIBooleanAttribute(uiAttrPath(instance, "snapGridEnabled"), snapGridEnabled)
  }, [snapGridEnabled])

  useEffect(() => {
    setUINumberAttribute(uiAttrPath(instance, "zoom"), zoom)
  }, [zoom])

  const updateComponent = (index: number, updated: AdComponent) => {
    const newInstance = { ...instance, components: instance.components.map((curr, currIndex) => currIndex === index ? updated : curr) }
    setInstance(newInstance)
    onUpdate(newInstance)
  }

  const removeComponent = (index: number) => {
    createUndopoint()
    const newInstance = { ...instance, components: instance.components.filter((_, currIndex) => currIndex !== index) }
    if (editIndex === index) {
      setEditIndex(undefined)
    }
    setInstance(newInstance)
    onUpdate(newInstance)
  }

  const addComponent = (component: TextAdComponent | ImageAdComponent | HtmlAdComponent | RectangleAdComponent) => {
    const newInstance = { ...instance, components: instance.components.concat(component) }
    setInstance(newInstance)
    setEditIndex(instance.components.length)
    onUpdate(newInstance)
    if (component.type === "Html") {
      setRequiresLoading(true)
    }
    setTool("move")
    createUndopoint()
  }

  const moveToBack = () => {
    if (typeof editIndex !== undefined) {
      createUndopoint()
      const length = instance.components.length
      const prevIndex = (editIndex! - 1 + length) % length
      const newInstance = {
        ...instance, components: swapItems(instance.components, prevIndex, editIndex!)
          .map((c, zIndex) => ({ ...c, area: { ...c.area, zIndex } }))
      }
      setInstance(newInstance)
      onUpdate(newInstance)
      setEditIndex(prevIndex)
    }
  }

  const moveToFront = () => {
    if (typeof editIndex !== undefined) {
      createUndopoint()
      const length = instance.components.length
      const newIndex = (editIndex! + 1) % length
      const newInstance = {
        ...instance, components: swapItems(instance.components, editIndex!, newIndex)
          .map((c, zIndex) => ({ ...c, area: { ...c.area, zIndex } }))
      }
      setInstance(newInstance)
      onUpdate(newInstance)
      setEditIndex(newIndex)
    }
  }

  const updateEditComponent = (partial: any) => {
    if (editIndex !== undefined) {
      createUndopoint()
      updateComponent(editIndex, { ...instance.components[editIndex], ...partial })
    }
  }

  const updateEditComponentArea = (partialArea: any) => {
    if (editIndex !== undefined) {
      updateEditComponent({ area: { ...instance.components[editIndex].area, ...partialArea } })
    }
  }

  const toggleBold = () => {
    updateEditComponent({ bold: !(instance.components[editIndex!] as TextAdComponent).bold })
  }

  const toggleItalic = () => {
    updateEditComponent({ italic: !(instance.components[editIndex!] as TextAdComponent).italic })
  }

  const toggleUnderline = () => {
    updateEditComponent({ underline: !(instance.components[editIndex!] as TextAdComponent).underline })
  }

  const replaceHtmlText = () => {
    if (editIndex !== undefined) {
      const htmlComponent = instance.components[editIndex] as HtmlAdComponent
      apiPatchAdTemplateFile(customerId, templateId, htmlComponent.fileId, textToReplace, newText).then((response) => {
        updateEditComponent({ html: response.content })
      })
    }
  }

  const removeFile = (fileId: string) => {
    apiDeleteAdTemplateFile(customerId, templateId, fileId).then((files) => {
      onUpdateTemplate({ ...adTemplate, files })
    })
  }

  const replayAnimations = () => {
    setRequiresLoading(true)
    document.querySelectorAll('[data-animated-component=true]').forEach((el) => {
      const animationName = (el as any).style.animationName;
      (el as any).style.animationName = null;
      el.clientWidth;
      (el as any).style.animationName = animationName
    })
  }

  const objectFitToggle = () => {
    const objectFit = (instance.components[editIndex!] as ImageAdComponent).objectFit
    updateEditComponent({ objectFit: objectFit ? objectFits[(objectFits.indexOf(objectFit) + 1) % objectFits.length] : 'none' })
  }
  const showTextEdit = (editIndex !== undefined && instance.components[editIndex].type === "Text")
  const showImageEdit = (editIndex !== undefined && instance.components[editIndex].type === "Image")
  const showHtmlEdit = (editIndex !== undefined && instance.components[editIndex].type === "Html")
  const showRectangleEdit = (editIndex !== undefined && instance.components[editIndex].type === "Rectangle")

  useEffect(() => {
    for (const file of adTemplate.files) {
      if (file.category === "font") {
        const ff = new FontFace(file.name, `url('${file.filename}'`)
        if (!document.fonts.has(ff)) {
          document.fonts.add(ff)
        }
      }
    }
  }, [adTemplate.files])

  useEffect(() => {
    if (editIndex !== undefined) {
      const t = instance.components[editIndex] as TextAdComponent
      const r = instance.components[editIndex] as RectangleAdComponent
      setEditText(t.text || "");
      setPickColor(t.color || { r: 0, g: 0, b: 0, a: 1.0 });
      setBackgroundColor(t.backgroundColor || { r: 255, g: 255, b: 255, a: 0.0 })
      setBackgroundColor(r.backgroundColor || { r: 255, g: 255, b: 255, a: 0.0 })
      if (t.font) {
        setFontName(t.font)
      }
    }
  }, [editTextDialogOpen])

  const editTextComponent = editIndex !== undefined ? instance.components[editIndex] as TextAdComponent : undefined
  const editComponent = editIndex !== undefined ? instance.components[editIndex] : undefined
  const editRectangleComponent = editIndex !== undefined ? instance.components[editIndex] as RectangleAdComponent : undefined


  const handleDrawComponentClick = (x: number, y: number) => {
    const overlapping = instance.components.map((c, index) => ({ comp: c, index })).filter((ci => isInsideRect(x, y, ci.comp.area)))
      .sort((a, b) => a.comp.area.width * a.comp.area.height - b.comp.area.width * b.comp.area.height)
    if (overlapping.length > 1 && overlapping.find((ic) => ic.index === editIndex) !== undefined) {
      const oIndex = overlapping.findIndex((ic) => ic.index === editIndex)
      setEditIndex(overlapping[(oIndex + 1) % overlapping.length].index)
    } else {
      setEditIndex(overlapping.length > 0 ? overlapping[0].index : undefined)
    }
  }

  const handleUndoBtnClick = () => {
    if (undoInstances.length > 0) {
      const newUndoInstances: AdTemplateInstance[] = [...undoInstances]
      // array.pop() Removes the last instance and returns it
      const prevInstance = newUndoInstances.pop()
      setInstance(prevInstance!)
      setUndoInstances(newUndoInstances)
      setRedoInstances([...redoInstances, copyObject(instance)])
      onUpdate(prevInstance!)
      setEditIndex(undefined)
    }
  }

  const handleRedoBtnClick = () => {
    if (redoInstances.length > 0) {
      const newRedoInstances: AdTemplateInstance[] = [...redoInstances]
      const nextInstance = newRedoInstances.pop()
      setInstance(nextInstance!)
      setRedoInstances(newRedoInstances)
      setUndoInstances([...undoInstances, copyObject(instance)])
      onUpdate(nextInstance!)
      setEditIndex(undefined)
    }
  }

  const createUndopoint = () => {
    setUndoInstances([...undoInstances, copyObject(instance)])
    setRedoInstances([])
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = document.getElementById("drawContainer")?.getBoundingClientRect();
    if (rect && tool === "rectangle") {
      setRenderRectangleDynamically(true);
      setRectangleStartPos({
        x: snapNumber(Math.max(e.clientX - rect.left, 0) / zoom, snapGridEnabled ? gridSize : 1),
        y: snapNumber(Math.max(e.clientY - rect.top, 0) / zoom, snapGridEnabled ? gridSize : 1)
      });
    }
  };

  const handleMouseUp = () => {
    if (tool === "rectangle") {
      // Calculate width and height of the rectangle
      const width = rectangleEndPos.x - rectangleStartPos.x;
      const height = rectangleEndPos.y - rectangleStartPos.y;

      addComponent({
        id: uuidv4(),
        editMode: false,
        type: "Rectangle",
        area: {
          x: rectangleStartPos.x,
          y: rectangleStartPos.y,
          width,
          height,
          zIndex: instance.components.length,
          xyLocked: false,
          widthHeightLocked: false
        },
        backgroundColor: { r: 255, g: 255, b: 255, a: 0.0 },
        borderRadius: {
          all: 0,
          setSeparately: false,
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: 0,
          unit: 'px'
        }
      } as RectangleAdComponent);

      setRenderRectangleDynamically(false);
      setTool("move");
      // Clear rectangle positions when the tool is deactivated
      setRectangleStartPos({ x: 0, y: 0 });
      setRectangleEndPos({ x: 0, y: 0 });
    }
  };

  const setLinesForEditComponent = () => {
    if (editComponent) {
      let leftX = 0
      let leftY = editComponent.area.y + editComponent.area.height / 2
      let rightX = instance.width
      let rightY = editComponent.area.y + editComponent.area.height / 2
      let topX = editComponent.area.x + editComponent.area.width / 2
      let topY = 0
      let bottomX = editComponent.area.x + editComponent.area.width / 2
      let bottomY = instance.height
      for (const component of instance.components) {
        const side = component !== editComponent ? getAreaSide(editComponent.area, component.area) : 'none'
        if (side === 'left' && leftX < component.area.x + component.area.width) {
          leftX = component.area.x + component.area.width
          leftY =
            Math.max(component.area.y, editComponent.area.y) * 0.5 +
            Math.min(component.area.y + component.area.height, editComponent.area.y + editComponent.area.height) * 0.5
        }
        if (side === 'right' && rightX > component.area.x) {
          rightX = component.area.x
          rightY =
            Math.max(component.area.y, editComponent.area.y) * 0.5 +
            Math.min(component.area.y + component.area.height, editComponent.area.y + editComponent.area.height) * 0.5
        }
        if (side === 'top' && topY < component.area.y + component.area.height) {
          topX = Math.max(component.area.x, editComponent.area.x) * 0.5 +
            Math.min(component.area.x + component.area.width, editComponent.area.x + editComponent.area.width) * 0.5
          topY = component.area.y + component.area.height
        }
        if (side === 'bottom' && bottomY > component.area.y) {
          bottomX = Math.max(component.area.x, editComponent.area.x) * 0.5 +
            Math.min(component.area.x + component.area.width, editComponent.area.x + editComponent.area.width) * 0.5
          bottomY = component.area.y
        }
      }
      setLines([
        { x1: leftX, y1: leftY, x2: editComponent.area.x, y2: leftY },
        {
          x1: editComponent.area.x + editComponent.area.width, y1: rightY,
          x2: rightX, y2: rightY
        },
        {
          x1: topX, y1: topY,
          x2: topX, y2: editComponent.area.y
        },
        {
          x1: bottomX, y1: editComponent.area.y + editComponent.area.height,
          x2: bottomX, y2: bottomY
        }
      ])
    }
    else {
      setLines([])
    }
  }

  return (<Container style={{}}>
    <ToolContainer>
      <ToggleButtonGroup size="small" exclusive={true} value={tool} onChange={(_, value) => setTool(value)}>
        <ToggleButton value="move"><OpenWith /></ToggleButton>
        <ToggleButton value="image"><Tooltip title="Image" arrow placement="top"><Image /></Tooltip></ToggleButton>
        <ToggleButton value="text"><Tooltip title="Text" arrow placement="top"><TitleOutlined /></Tooltip></ToggleButton>
        <ToggleButton value="rectangle"><Tooltip title="Rectangle" arrow placement="top"><RectangleOutlinedIcon /></Tooltip></ToggleButton>
        <ToggleButton value="html"><Html /></ToggleButton>
      </ToggleButtonGroup>
      <Tooltip title="Undo" arrow placement="top">
        <IconButton disabled={undoInstances.length === 0} onClick={handleUndoBtnClick}><UndoIcon /></IconButton>
      </Tooltip>
      <Tooltip title="Redo" arrow placement="top">
        <IconButton disabled={redoInstances.length === 0} onClick={handleRedoBtnClick}><RedoIcon /></IconButton>
      </Tooltip>
    </ToolContainer>
    <ControlContainer>
      <ToolGroupContainer>
        <ToolHeading>Elements</ToolHeading>
        <List sx={{
          maxHeight: 200,
          overflow: 'auto'
        }}>
          {instance.components.length < 1 && <Typography sx={{ fontSize: '14px' }}>No elements added yet.</Typography>}
          {instance.components.length > 0 && reverseArray(instance.components).map((comp: AdComponent) =>
            <ListItem sx={{
              backgroundColor: editIndex === instance.components.indexOf(comp) ? primaryColors[100] : 'transparent',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '&:hover': { cursor: 'pointer', backgroundColor: primaryColors[100] }
            }}
              onClick={() => setEditIndex(instance.components.indexOf(comp))}>

              <Box>
                {comp.type === "Text" && <>
                  {(comp as TextAdComponent).isDynamic ? "Variable" : comp.type} - {cutStringToMaxLength((comp as TextAdComponent).text, 16)}
                </>}
                {comp.type === "Rectangle" && <>
                  {comp.type} - {comp.area.x}x{comp.area.y}
                </>}
                {comp.type === "Image" && <>
                  <ElementRow>
                    <div style={{ wordBreak: "break-all" }}>{(comp as ImageAdComponent).name}</div>
                    <Tooltip title={<PreviewImage src={(comp as ImageAdComponent).imageFile} />}>
                      <ThumbnailImage src={(comp as ImageAdComponent).imageFile} />
                    </Tooltip>
                  </ElementRow>
                </>}
              </Box>
            </ListItem>)}
        </List>
        <Divider sx={dividerStyles} />
        <ToolHeading>Entity</ToolHeading>
        <ButtonGroup sx={{ flexWrap: "wrap" }}>
          <IconButton disabled={typeof editIndex === "undefined" || editIndex === 0}><FlipToBack onClick={() => moveToBack()} /></IconButton>
          <IconButton disabled={typeof editIndex === "undefined" || editIndex >= instance.components.length - 1}><FlipToFront onClick={() => moveToFront()} /></IconButton>
          <IconButton disabled={typeof editIndex === "undefined"} onClick={() => removeComponent(editIndex!)}><Delete /></IconButton>
          <IconButton onClick={() => {
            setUploadDialogOpen(true)
          }}><UploadFile /></IconButton>
        </ButtonGroup>
        <Divider sx={dividerStyles} />
        {editComponent && <>
          <ToolHeading>Transform</ToolHeading>
          <CoordinateControls>
            <TextField label="X" type="number" size="small" value={editComponent.area.x}
              onChange={(ev) => { !editComponent.area.xyLocked && updateEditComponentArea({ x: parseInt(ev.target.value, 10) }) }} />
            <TextField label="Y" type="number" size="small" value={editComponent.area.y}
              onChange={(ev) => { !editComponent.area.xyLocked && updateEditComponentArea({ y: parseInt(ev.target.value, 10) }) }} />
            <IconButton onClick={() => updateEditComponentArea({ xyLocked: !(editComponent.area.xyLocked) })} sx={{ ...iconBtnStyles, padding: '4px' }}>
              {editComponent.area.xyLocked ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
            <TextField label="Width" type="number" size="small" value={editComponent.area.width}
              onChange={(ev) => { !editComponent.area.widthHeightLocked && updateEditComponentArea({ width: parseInt(ev.target.value, 10) }) }} />
            <TextField label="Height" type="number" size="small" value={editComponent.area.height}
              onChange={(ev) => { !editComponent.area.widthHeightLocked && updateEditComponentArea({ height: parseInt(ev.target.value, 10) }) }}
            />
            <IconButton onClick={() => updateEditComponentArea({ widthHeightLocked: !(editComponent.area.widthHeightLocked) })} sx={{ ...iconBtnStyles, padding: '4px' }}>
              {editComponent.area.widthHeightLocked ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </CoordinateControls>
        </>}
        <Divider sx={dividerStyles} />
        <ToolHeading>Animations</ToolHeading>
        <ButtonGroup>
          <IconButton>{animationsRunning ? <Stop onClick={() => setAnimationsRunning(false)} /> : <PlayArrow onClick={() => setAnimationsRunning(true)} />}</IconButton>
          <IconButton><Replay onClick={() => replayAnimations()} /></IconButton>
        </ButtonGroup>
        <Divider sx={dividerStyles} />
        <ToolHeading>Files</ToolHeading>
        <SmallFileList>
          {adTemplate.files.length <= 0 && 'No files uploaded yet.'}
          {adTemplate.files.sort((a, b) => a.category.localeCompare(b.category)).map(f => <>

            {getFileUseSymbol(f.id, f.name, f.category, instance, adTemplate.types)}
            <File style={{ wordBreak: "break-all" }}>{f.name}</File>
            {f.category === "image" && <Tooltip title={<PreviewImage src={f.filename} />}>
              <ThumbnailImage src={f.filename} />
            </Tooltip>}
            {f.category === "font" && <Tooltip title={<div style={{ fontFamily: f.name }}>ABCDEFGHIJLKMNOPQRSTUVWXYZÅÄÖabcdefghhijlmnopqrstuvwxyzåäö0123456789</div>}><div style={{ textAlign: "center" }}><FontDownload /></div></Tooltip>}
            {f.category === "page" && <div style={{ justifySelf: 'center' }}>{(f as AdTemplateFileHtml).width}x{(f as AdTemplateFileHtml).height}</div>}
            {(f.category === "archive" || f.category === "script") && <div />}
          </>)}
        </SmallFileList>
        <ButtonGroup>
          <IconButton onClick={() => {
            setUploadDialogOpen(true)
          }}><UploadFile /></IconButton>
        </ButtonGroup>
        <Divider sx={dividerStyles} />
        {showTextEdit && <Box sx={{}}>
          <ToolHeading>Text edit</ToolHeading>
          <ButtonGroup sx={{ flexWrap: "wrap", rowGap: 1, columnGap: 0.5 }}>
            <ToggleButtonGroup size="small" exclusive value={editTextComponent?.alignHorizontal} onChange={(_, value) => updateEditComponent({ alignHorizontal: value })}>
              <ToggleButton value="left"><FormatAlignLeft /></ToggleButton>
              <ToggleButton value="center"><FormatAlignCenter /></ToggleButton>
              <ToggleButton value="right"><FormatAlignRight /></ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup size="small" exclusive value={editTextComponent?.alignVertical} onChange={(_, value) => updateEditComponent({ alignVertical: value })}>
              <ToggleButton value="top"><VerticalAlignTop /></ToggleButton>
              <ToggleButton value="center"><VerticalAlignCenter /></ToggleButton>
              <ToggleButton value="bottom"><VerticalAlignBottom /></ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup size="small">
              <ToggleButton value={true} selected={editTextComponent?.bold} onClick={(_) => toggleBold()}><FormatBold /></ToggleButton>
              <ToggleButton value={true} selected={editTextComponent?.italic} onClick={() => toggleItalic()}><FormatItalic /></ToggleButton>
              <ToggleButton value={true} selected={editTextComponent?.underline} onClick={() => toggleUnderline()}><FormatUnderlined /></ToggleButton>
            </ToggleButtonGroup>
            <IconButton onClick={() => setFontSelectDialogOpen(true)} sx={{}}>
              <Tooltip title="Select font" arrow placement="top"><FontDownload /></Tooltip>
            </IconButton>
            <TextField
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={editTextComponent?.fontSize}
              label="font size(px)"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateEditComponent({ fontSize: Number(event.target.value) })}
              size="small"
              sx={{ maxWidth: '120px', marginTop: '5px' }}
            />
            <TextField
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={editTextComponent?.lineHeight || 100}
              label="line height(%)"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateEditComponent({ lineHeight: Number(event.target.value) })}
              size="small"
              sx={{ maxWidth: '110px', marginTop: '5px' }}
            />
            <IconButton onClick={() => setEditTextDialogOpen(true)}>
              <Tooltip title="Edit" arrow placement="top"><Edit /></Tooltip>
            </IconButton>
            <IconButton onClick={() => setColorPickerDialogOpen(true)}>
              <Tooltip title="Color & background" arrow placement="top"><Palette /></Tooltip>
            </IconButton>
            <IconButton onClick={() => setAnimationDialogOpen(true)}><Gif /></IconButton>
          </ButtonGroup>
        </Box>}
        {showRectangleEdit && <Box sx={{}}>
          <ToolHeading>Rectangle edit</ToolHeading>
          <ButtonGroup sx={{ flexWrap: "wrap", rowGap: 1, columnGap: 0.5 }}>
            <IconButton onClick={() => setColorPickerDialogOpen(true)}>
              <Tooltip title="Color & background" arrow placement="top"><Palette /></Tooltip>
            </IconButton>
            <Box>
              <Typography sx={{ ...desktop_p3, fontFamily: montserratBoldFontFamily }}>Border radius</Typography>
              <FormGroup>
                <FormControlLabel sx={{ '& span': { ...desktop_p3 } }} control={<Checkbox size="small" checked={editRectangleComponent?.borderRadius.setSeparately}
                  onChange={() => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, setSeparately: !editRectangleComponent?.borderRadius.setSeparately } })} />} label="Set separately" />
              </FormGroup>
              <Grid container rowSpacing={2} columnSpacing={1} sx={{ mb: 2, mt: 0.5 }}>
                {!editRectangleComponent?.borderRadius.setSeparately && <Grid item xs={6}>
                  <TextField label="Radius" type="number" InputLabelProps={{ shrink: true }} size="small" value={editRectangleComponent?.borderRadius.all} onChange={(ev) => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, all: ev.target.value } })} />
                </Grid>}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      sx={{ maxWidth: 80 }}
                      value={editRectangleComponent?.borderRadius.unit}
                      label="Unit"
                      onChange={(ev) => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, unit: ev.target.value } })}
                    >
                      <MenuItem value={"px"}>px</MenuItem>
                      <MenuItem value={"%"}>%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {editRectangleComponent?.borderRadius.setSeparately && <Grid container rowSpacing={2} columnSpacing={1} sx={{}}>
                <Grid item xs={6}>
                  <TextField label="Top left" type="number" InputLabelProps={{ shrink: true }} size="small"
                    value={editRectangleComponent?.borderRadius.topLeft}
                    onChange={(ev) => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, topLeft: ev.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Top right" type="number" InputLabelProps={{ shrink: true }} size="small"
                    value={editRectangleComponent?.borderRadius.topRight}
                    onChange={(ev) => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, topRight: ev.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Bottom left" type="number" InputLabelProps={{ shrink: true }} size="small"
                    value={editRectangleComponent?.borderRadius.bottomLeft}
                    onChange={(ev) => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, bottomLeft: ev.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Bottom right" type="number" InputLabelProps={{ shrink: true }} size="small"
                    value={editRectangleComponent?.borderRadius.bottomRight}
                    onChange={(ev) => updateEditComponent({ borderRadius: { ...editRectangleComponent?.borderRadius, bottomRight: ev.target.value } })} />
                </Grid>
              </Grid>}
            </Box>
          </ButtonGroup>
        </Box>}
        {showImageEdit && <ToolHeading>Image</ToolHeading>}
        {showImageEdit && <ButtonGroup sx={{ flexWrap: "wrap" }}>
          <IconButton onClick={() => objectFitToggle()}><AspectRatio /></IconButton>
          <IconButton onClick={() => setAnimationDialogOpen(true)}><Gif /></IconButton>
        </ButtonGroup>}
        {showHtmlEdit && <ToolHeading>Html</ToolHeading>}
        {showHtmlEdit && <ButtonGroup sx={{ flexWrap: "wrap" }}>
          <IconButton onClick={() => setHtmlEditDialogOpen(true)}><Settings /></IconButton>
        </ButtonGroup>}
      </ToolGroupContainer>
      <ToolGroupContainer>
        <ToolHeading>Canvas</ToolHeading>
        <TextField label="Zoom" type="number" size="small" style={{ minWidth: '100px' }}
          value={Math.floor(zoom * 100)}
          inputProps={{ step: 10, min: 10, max: 300, style: { paddingLeft: '5px' } }}
          onChange={(e) => setZoom(Number.parseInt(e.target.value) / 100.0)} />
        <FormControlLabel label='Show grid' sx={{ marginTop: 2 }} control={<Checkbox sx={{ p: 0, paddingLeft: 1.5 }} size="small" checked={showGrid} onChange={(ev) => setShowGrid(ev.target.checked)} />} />
        <FormControlLabel label='Snap grid' sx={{ marginBottom: 2 }} control={<Checkbox sx={{ p: 0, paddingLeft: 1.5 }} size="small" checked={snapGridEnabled} onChange={(ev) => setSnapGridEnabled(ev.target.checked)} />} />
        <TextField disabled={!showGrid && !snapGridEnabled} label="Grid size" type="number" style={{ maxWidth: '80px' }} size="small" value={gridSize}
          inputProps={{ style: { paddingLeft: '5px' } }}
          onChange={(e) => setGridSize(Number.parseInt(e.target.value))} />
      </ToolGroupContainer>
    </ControlContainer>

    <Button size="small" variant="contained" color="error" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => onDelete(instance)}>Delete</Button>
    <CanvasContainer>
      <Canvas style={{
        width: `${Math.max(600, instance.width) * zoom}px`,
        height: `${600 * zoom}px`,
        background: gridSize > 1 ? `repeating-conic-gradient(#f0f0f0 0% 25%, #e0e0e0 0% 50%) 50% / ${gridSize * zoom * 2}px ${gridSize * zoom * 2}px` : 'none',
        backgroundPosition: `${gridSize * zoom}px ${gridSize * zoom}px`,
      }}>
        <DrawContainer id={"drawContainer"} style={{
          width: instance.width, height: instance.height,
          transform: `scale(${zoom},${zoom})`,
          transformOrigin: 'top left'
        }}
          onDoubleClick={() => {
            handleDrawComponentClick(mousePos.x, mousePos.y)
          }}
          onClick={(e) => {
            const rect = document.getElementById("drawContainer")?.getBoundingClientRect()
            if (rect) {
              const x = snapNumber(Math.max(e.clientX - rect.left, 0) / zoom, snapGridEnabled ? gridSize : 1)
              const y = snapNumber(Math.max(e.clientY - rect.top, 0) / zoom, snapGridEnabled ? gridSize : 1)
              if (tool === "text") {
                const area = { x, y, width: 100, height: 50, zIndex: instance.components.length, xyLocked: false, widthHeightLocked: false }
                addComponent({
                  id: uuidv4(),
                  editMode: false,
                  type: "Text",
                  isDynamic: false,
                  isTimeBased: false,
                  isExternal: false,
                  text: "Text",
                  area: snapGridEnabled ? snapToGrid(area, gridSize) : area,
                  font: "Arial",
                  fontSize: 20,
                  backgroundColor: { r: 255, g: 255, b: 255, a: 0.0 },
                  color: { r: 0, g: 0, b: 0, a: 1.0 },
                } as TextAdComponent)
              } else if (tool === "rectangle") {
                handleMouseDown(e);
              }
              else if (tool === "image") {
                setClickPos({ x, y })
                setImageSelectDialogOpen(true)
              }
              else if (tool === "html") {
                const entryFile = adTemplate.files.find(f => {
                  if (f.type === "html") {
                    const fhtml = f as AdTemplateFileHtml
                    return fhtml.width === instance.width && fhtml.height === instance.height
                  }
                  return false
                }) as AdTemplateFileHtml
                if (entryFile) {
                  addComponent({
                    id: uuidv4(),
                    editMode: false,
                    type: "Html",
                    fileId: entryFile.id,
                    filename: entryFile.filename,
                    area: { x: 0, y: 0, width: entryFile.width, height: entryFile.height }
                  } as HtmlAdComponent)
                }
              }
            }
          }}
          onMouseMove={(e) => {
            const rect = document.getElementById("drawContainer")?.getBoundingClientRect()
            if (rect && tool !== 'rectangle') {
              if (editComponent !== undefined) {
                setLinesForEditComponent()
              }
              setMousePos({
                x: snapNumber(Math.max(e.clientX - rect.left, 0) / zoom, snapGridEnabled ? gridSize : 1),
                y: snapNumber(Math.max(e.clientY - rect.top, 0) / zoom, snapGridEnabled ? gridSize : 1)
              })
            }
            if (rect && tool === 'rectangle') {
              setRectangleEndPos({
                x: snapNumber(Math.max(e.clientX - rect.left, 0) / zoom, snapGridEnabled ? gridSize : 1),
                y: snapNumber(Math.max(e.clientY - rect.top, 0) / zoom, snapGridEnabled ? gridSize : 1)
              })
            }
          }}>
          {instance.components.sort((a, b) => a.area.zIndex - b.area.zIndex).map((comp, index) =>
            <DrawComponent snapGridEnabled={snapGridEnabled} c={comp} gridSize={gridSize} zoom={zoom}
              editMode={editIndex === index}
              createUndopoint={() => { createUndopoint() }}
              updateComponent={updateComponent.bind(this, index)} removeComponent={removeComponent.bind(this, index)} />)}
          <DrawGrid id="drawGrid" style={{
            background: gridSize > 1 && showGrid ? `repeating-conic-gradient(#f0f0f0 0% 25%, #000000 0% 50%) 50% / ${gridSize * 2}px ${gridSize * 2}px` : 'none',
            backgroundPosition: showGrid ? `${gridSize}px ${gridSize}px` : undefined,
            pointerEvents: tool === "move" ? "none" : "auto"
          }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          />
          {/* Render the rectangle dynamically */}
          {renderRectangleDynamically && (
            <Box
              style={{
                position: 'absolute',
                left: rectangleStartPos.x,
                top: rectangleStartPos.y,
                width: rectangleEndPos.x - rectangleStartPos.x,
                height: rectangleEndPos.y - rectangleStartPos.y,
                border: '1px dotted black',
                pointerEvents: 'none' // Make sure the rectangle doesn't interfere with mouse events on DrawContainer
              }}
            />
          )}
        </DrawContainer>
        <LineContainer style={{
          width: instance.width, height: instance.height,
          transform: `scale(${zoom},${zoom})`,
          transformOrigin: 'top left',
          fontSize: `${1.0 / 2}em`
        }}>
          {lines.map(l => {
            return <Line x1={l.x1} y1={l.y1} x2={l.x2} y2={l.y2} showLength={true} />
          })}
        </LineContainer>
        <Position>{mousePos.x}, {mousePos.y}</Position>
      </Canvas>
    </CanvasContainer>


    <Dialog
      open={uploadDialogOpen}
      onClose={() => setUploadDialogOpen(false)}
    >
      <DialogTitle>
        Files
      </DialogTitle>
      <DialogContent>
        <ToolHeading>Existing files</ToolHeading>
        <FileList>
          <FileAttr>Filename</FileAttr>
          <FileAttr>Size</FileAttr>
          <FileAttr>Category</FileAttr>
          <div></div>
          {adTemplate.files.length <= 0 && 'No files uploaded yet.'}
          {adTemplate.files.map(f => <>
            <File>{f.name}</File>
            <Resolution>{(f as any).width && (f as any).height ? `${(f as any).width}x${((f as any).height)}` : ''}</Resolution>
            <FileCategory>{f.category}</FileCategory>
            <IconButton onClick={() => removeFile(f.id)}><DeleteRounded style={{ color: 'red' }} /></IconButton>
          </>)}
        </FileList>
      </DialogContent>
      <FormControl>
        <input
          style={{ display: 'none' }}
          id={`raised-button-file-${templateId}`}
          multiple
          type="file"
          onChange={(event) => {
            if (event.target.files) {
              setLoading(true)
              const formData = new FormData()
              const filename = event.target.files[0].name.substring(0, event.target.files[0].name.lastIndexOf("."))
              formData.append('File', event.target.files[0])
              event.target.value = ""
              apiUploadAdTemplateFile(customerId, templateId, filename, formData)
                .then((adTemplate) => {
                  onUpdateTemplate(adTemplate)
                  setLoading(false)
                  setUploadDialogOpen(false)
                }).catch(() => setLoading(false))
            }
          }} />
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setUploadDialogOpen(false) }}>Cancel</Button>
          <label htmlFor={`raised-button-file-${templateId}`}>
            <Button variant="contained" component="span" color="primary">Upload</Button>
          </label>
        </DialogActions>
      </FormControl>
    </Dialog>
    <Dialog
      open={imageSelectDialogOpen}
      onClose={() => setImageSelectDialogOpen(false)}
    >
      <DialogTitle>
        Select Image
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ m: 1, minWidth: '300px' }}>
          <Select size="small" value={addImageId} onChange={(ev) => setAdImageId(ev.target.value)}>
            {adTemplate.files.filter(f => f.category === "image").map((f: AdTemplateFile) => {
              const i = f as AdTemplateFileImage
              return <MenuItem value={i.id}>{i.name}({i.width}x{i.height})</MenuItem>
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <FormControl>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setImageSelectDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" component="span" disabled={!addImageId} color="primary" onClick={() => {
            const image = adTemplate.files.find(f => f.id === addImageId)! as AdTemplateFileImage
            addComponent({
              editMode: false,
              type: "Image",
              id: uuidv4(),
              imageId: image.id,
              imageFile: image.filename,
              name: image.name,
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              area: snapToGrid({
                x: clickPos.x, y: clickPos.y,
                width: Math.min(image.width, instance.width),
                height: Math.min(image.height, instance.height),
                zIndex: instance.components.length,
                xyLocked: false,
                widthHeightLocked: false
              }, gridSize)
            } as ImageAdComponent)
            setImageSelectDialogOpen(false)
          }}>Add Image</Button>
        </DialogActions>
      </FormControl>
    </Dialog>
    <Dialog
      open={fontSelectDialogOpen}
      onClose={() => setFontSelectDialogOpen(false)}
    >
      <DialogTitle>
        Select Font
      </DialogTitle>
      <DialogContent>
        <FontSelectContent>
          <ToolHeading>Available fonts</ToolHeading>
          <SelectRemove>
            <Select size="small" value={fontName} onChange={(ev) => setFontName(ev.target.value)}>
              {adTemplate.files.filter(f => f.category === "font").map(f => <MenuItem value={f.name}>{f.name}</MenuItem>)}
              <MenuItem value='Arial'>Arial</MenuItem>
              <MenuItem value='Verdana'>Verdana</MenuItem>
              <MenuItem value='Tahoma'>Tahoma</MenuItem>
              <MenuItem value='Times New Roman'>Times New Roman</MenuItem>
              <MenuItem value='Georgia'>Georgia</MenuItem>
              <MenuItem value='Courier New'>Courier New</MenuItem>
            </Select>
            <Delete />
          </SelectRemove>
          <ToolHeading>Preview</ToolHeading>
          <FontPreview style={{ fontFamily: fontName }}>
            abcdefghijklm ABCDEF
          </FontPreview>
        </FontSelectContent>
      </DialogContent>
      <FormControl>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setFontSelectDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" component="span" disabled={!fontName} color="primary" onClick={() => {
            updateEditComponent({ font: fontName })
            setFontSelectDialogOpen(false)
          }}>Set font</Button>
        </DialogActions>
      </FormControl>
    </Dialog>
    <Dialog
      open={editTextDialogOpen}
      onClose={() => setEditTextDialogOpen(false)}
    >
      <DialogTitle>
        Edit Text
      </DialogTitle>
      <DialogContent>
        <FormControlLabel label="Variable" control={<Checkbox checked={editTextDynamic} onChange={(ev) => setEditTextDynamic(ev.target.checked)} />} />
        <FormControlLabel label="Time-based" control={<Checkbox checked={editTextTimeBased} onChange={(ev) => setEditTextTimeBased(ev.target.checked)} />} />
        <FormControlLabel label="External" control={<Checkbox checked={editTextExternal} onChange={(ev) => setEditTextExternal(ev.target.checked)} />} />
        <FontSelectContent>
          <ToolHeading>{editTextDynamic ? 'Variable name' : 'Text'}</ToolHeading>
          <TextField multiline={!editTextDynamic} rows={4} value={editText} onChange={(ev) => setEditText(ev.target.value)} />
        </FontSelectContent>
      </DialogContent>
      <FormControl>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setEditTextDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" component="span" disabled={!fontName} color="primary" onClick={() => {
            updateEditComponent({
              text: editText,
              isDynamic: editTextDynamic,
              isTimeBased: editTextTimeBased,
              isExternal: editTextExternal,
            })
            setEditTextDialogOpen(false)
          }}>Set text</Button>
        </DialogActions>
      </FormControl>
    </Dialog>
    <Dialog
      open={htmlEditDialogOpen}
      onClose={() => setHtmlEditDialogOpen(false)}
    >
      <DialogTitle>
        Edit HTML
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ m: 1, minWidth: '300px' }}>
          <ToolHeading>Text to replace</ToolHeading>
          <TextField label="Text to replace" value={textToReplace} size="small" onChange={(ev) => setTextToReplace(ev.target.value)} />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: '300px' }}>
          <ToolHeading>New text or parameter</ToolHeading>
          <TextField label="Replace with" value={newText} size="small" onChange={(ev) => setNewText(ev.target.value)} />
        </FormControl>
      </DialogContent>
      <FormControl>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setHtmlEditDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" component="span" color="primary" onClick={() => {
            replaceHtmlText()
            setHtmlEditDialogOpen(false)
          }}>Set text</Button>
        </DialogActions>
      </FormControl>
    </Dialog>
    <Dialog
      open={colorPickerDialogOpen}
      onClose={() => setColorPickerDialogOpen(false)}
    >
      <DialogTitle>
        Color palette
      </DialogTitle>
      <DialogContent>
        <ColorSelectContent>
          <div>
            <ToolHeading>Select color</ToolHeading>
            <SketchPicker color={pickColor} onChangeComplete={(color, _) => setPickColor(color.rgb)} />
          </div>
          <div>
            <ToolHeading>Select background</ToolHeading>
            <SketchPicker presetColors={[
              { title: "Transparent", color: 'transparent' },
              { title: "White", color: 'white' },
              { title: "Black", color: 'black' }
            ]} color={backgroundColor} onChangeComplete={(color, _) => setBackgroundColor(color.rgb)} />
          </div>
        </ColorSelectContent>
      </DialogContent>
      <FormControl>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setColorPickerDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" component="span" color="primary" onClick={() => {
            updateEditComponent({ color: pickColor, backgroundColor: backgroundColor })
            setColorPickerDialogOpen(false)
          }}>Set color</Button>
        </DialogActions>
      </FormControl>
    </Dialog>

    <Dialog
      open={colorPickerDialogOpen}
      onClose={() => setColorPickerDialogOpen(false)}
    >
      <DialogTitle>
        Color palette
      </DialogTitle>
      <DialogContent>
        <ColorSelectContent>
          <div>
            <ToolHeading>Select color</ToolHeading>
            <SketchPicker color={pickColor} onChangeComplete={(color, _) => setPickColor(color.rgb)} />
          </div>
          <div>
            <ToolHeading>Select background</ToolHeading>
            <SketchPicker presetColors={[
              { title: "Transparent", color: 'transparent' },
              { title: "White", color: 'white' },
              { title: "Black", color: 'black' }
            ]} color={backgroundColor} onChangeComplete={(color, _) => setBackgroundColor(color.rgb)} />
          </div>
        </ColorSelectContent>
      </DialogContent>
      <FormControl>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="contained" color="error" onClick={() => { setColorPickerDialogOpen(false) }}>Cancel</Button>
          <Button variant="contained" component="span" color="primary" onClick={() => {
            updateEditComponent({ color: pickColor, backgroundColor: backgroundColor })
            setColorPickerDialogOpen(false)
          }}>Set color</Button>
        </DialogActions>
      </FormControl>
    </Dialog>
    <AnimationDialog open={animationDialogOpen} animation={editIndex !== undefined ? (instance.components[editIndex] as TextAdComponent).animation : undefined}
      updateAnimation={(animation) => {
        updateEditComponent({ animation })
      }}
      setOpen={(open) => setAnimationDialogOpen(open)} />
    {loading && <LoadingModal />}
  </Container>)
}

const iconBtnStyles = {
  border: `1px solid #D3D3D3`,
  borderRadius: '5px'
}