import { ChangeEvent, useState } from 'react'
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomationSettingsErrors, setAdvancedPostBoosting, setAdvancedPostBoostingErrors } from '../../../context/meta/MetaActions';
import { convertToMaterialDateTime, getEndDate } from '../../../utils/helpers'
import { useLocation } from 'react-router-dom';
import { MetaErrors } from '../../../context/meta/MetaTypes';

/**
 * @description This component is used to set the ad set schedule for both the ad automation and the advanced post booster.
 */
const AdSetSchedule = () => {
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting, advancedPostBoostingErrors }, dispatch] = useStateValue()

  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')

  const [schedulingType, setSchedulingType] = useState<string>('dynamic_period_of_time')
  const [startTime, setStartTime] = useState<Date | string | undefined>(metaAdAutoLocation ? adAutomationSettings.adSet.start_time && convertToMaterialDateTime(new Date(adAutomationSettings.adSet.start_time)) : advancedPostBoosting.adSet.start_time && convertToMaterialDateTime(new Date(advancedPostBoosting.adSet.start_time)))
  const [endTime, setEndTime] = useState<Date | string | undefined>(metaAdAutoLocation ? adAutomationSettings.adSet.end_time && convertToMaterialDateTime(new Date(adAutomationSettings.adSet.end_time)) : advancedPostBoosting.adSet.end_time && convertToMaterialDateTime(new Date(advancedPostBoosting.adSet.end_time)))

  const [days, setDays] = useState<number>(1)
  const [daysError, setDaysError] = useState(false)

  const handleSchedulingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    // Running time must be scheduled for at least 24 hours. Setting the number of days to 1 will practically provide less than 24 hours so set it to 1.01.
    let endDate: Date = getEndDate(days)
    if (days === 1) {
      endDate = getEndDate(1.01)
    }

    setSchedulingType(value)
    if (value === 'dynamic_period_of_time') {
      dispatch(setAdAutomationSettings({
        ...adAutomationSettings, adSet: {
          ...adAutomationSettings.adSet,
          start_time: new Date().toISOString(),
          end_time: endDate.toISOString(),
          dynamicPeriodOfTime: true,
          dynamicDays: days
        }
      }))
    }
    else if (value === 'period_of_time') {
      // Update the appropriate state depending on the current location.
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet,
            start_time: new Date().toISOString(),
            end_time: endDate.toISOString(),
            dynamicPeriodOfTime: false
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet,
            start_time: new Date().toISOString(),
            end_time: endDate.toISOString(),
            dynamicPeriodOfTime: false
          }
        }))
      }
    }
  }


  const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, start_time: new Date(e.target.value).toISOString() } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, start_time: new Date(e.target.value).toISOString() } }))
    }
  }

  const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, adSet: { ...adAutomationSettings.adSet, end_time: new Date(e.target.value).toISOString() } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, adSet: { ...advancedPostBoosting.adSet, end_time: new Date(e.target.value).toISOString() } }))
    }
  }

  const handlePeriodOfTimeChange = (e: any) => {
    const ds = e.target.value;
    setDays(ds)
    if (Number(ds) && Number(ds) >= 1 && Number(ds) <= 365) {
      setDaysError(false)
      const endDate: Date = getEndDate(ds)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.adSetDaysError)))
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.adSetDaysError)))
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet,
            start_time: new Date().toISOString(),
            end_time: endDate.toISOString(),
            dynamicDays: parseInt(ds, 10)
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet,
            start_time: new Date().toISOString(),
            end_time: endDate.toISOString(),
            dynamicDays: parseInt(ds, 10)
          }
        }))
      }
    } else {
      setDaysError(true)
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.adSetDaysError).concat([MetaErrors.adSetDaysError])))
      } else {
        dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.adSetDaysError).concat([MetaErrors.adSetDaysError])))
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Schedule</Typography>
      </Grid>

      {schedulingType === 'date_calenders' && <>
        <Grid item xs={6}>
          <TextField
            id="datetime-local"
            label="Start time"
            type="datetime-local"
            value={startTime}
            onChange={handleStartTimeChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="datetime-local"
            label="End time"
            type="datetime-local"
            value={endTime}
            onChange={handleEndTimeChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </>}


      {(schedulingType === 'period_of_time' || schedulingType === 'dynamic_period_of_time') && <>

        <Grid item xs={4}>
          <TextField
            id="period_of_time"
            label="Number of days to show ad from post"
            name='period_of_time'
            variant="standard"
            fullWidth
            value={days}
            onChange={handlePeriodOfTimeChange}
            InputLabelProps={{
              shrink: true
            }}
            error={daysError}
            helperText={daysError ? "Enter a number between 1 and 365." : ""}
            sx={{ fontSize: '14px' }}
          />
        </Grid>
      </>}
    </>
  )
}

export default AdSetSchedule;