import React, { useEffect } from 'react'
// utm_source=facebook&utm_medium=cpc
import { useState } from 'react'
import { Divider, Grid, TextField, InputLabel, FormControl, Select, Typography, MenuItem, SelectChangeEvent, Switch, FormControlLabel } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdvancedPostBoosting } from '../../../context/meta/MetaActions';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { getBusinessOwnedPixels } from '../../../api/Marketing';
import { useLocation } from 'react-router-dom';
import IOSSwitchComponent from './IOSSwitchComponent';
import { beautifyString, convertObjectToArray } from '../../../utils/helpers';
import { CallToActionButtonType } from '../../../context/meta/MetaTypes';




const AdInfo = () => {

  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')

  const customerState = useCustomerState()
  const [{ adAutomationSettings, advancedPostBoosting }, dispatch] = useStateValue()
  const [ctaBtnType, setCtaBtnType] = useState<CallToActionButtonType>(advancedPostBoosting.ad.callToActionButtonType)
  const [url, setUrl] = useState<string>(advancedPostBoosting.ad.url)
  const [campaignObjective] = useState(metaAdAutoLocation ? adAutomationSettings.campaign.objective : advancedPostBoosting.campaign.objective)


  const [checked, setChecked] = React.useState(advancedPostBoosting.ad.addCTA)

  const [hidden, setHidden] = React.useState(false)

  useEffect(() => {
    if (campaignObjective.toLowerCase().includes("awareness")) {
      setHidden(true)
      setChecked(false)
    }
  }, [campaignObjective])

  const handleUrlParametersChange = (e: any) => {
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, ad: { ...advancedPostBoosting.ad, urlParameters: e.target.value } }))
  }

  const handleSwitchClick = async () => {
    const newValue = !checked
    setChecked(newValue)
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, ad: { ...advancedPostBoosting.ad, addCTA: newValue } }))
  }

  const handleCtaBtnTypeChange = (e: SelectChangeEvent<any>) => {
    const type = e.target.value
    setCtaBtnType(type)
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, ad: { ...advancedPostBoosting.ad, callToActionButtonType: type } }))

  }


  const handleUrlChange = (e: any) => {
    const url = e.target.value
    // const urlErr = 'CTA button url error'
    setUrl(url)
    dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, ad: { ...advancedPostBoosting.ad, url: url } }))
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        {/*<Typography variant="h6">Create a new ad set</Typography>*/}
      </Grid>

      {!hidden && <>

        <Grid item xs={12}>
          <Typography variant="h6">Ad creative</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6"><Switch checked={checked} onChange={handleSwitchClick} />Call to action</Typography>
        </Grid>

        {checked && <>
          <Grid item xs={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="ad-pixel-select-label">CTA button type</InputLabel>
              <Select
                labelId="ad-pixel-select-label"
                id="ad-pixel"
                value={ctaBtnType}
                onChange={handleCtaBtnTypeChange}
                label="Pixel"
              >
                {convertObjectToArray(CallToActionButtonType).map((bType: any) => {
                  return <MenuItem
                    sx={{ fontSize: '14px' }}
                    key={bType}
                    value={bType}>
                    {beautifyString(bType)}
                  </MenuItem>
                })}
              </Select>

            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-basic"
              label="Website url that you want to promote / CTA button url"
              name='Website url that you want to promote / CTA button url '
              variant="standard"
              fullWidth
              value={url}
              onChange={handleUrlChange}
              //error={urlError}
              //helperText={urlError ? "Provide proper url" : ""}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

        </>}

        <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
          <Divider />
        </Grid>

      </>}


      <Grid item xs={12}>
        <Typography variant="h6">Url parameters</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="standard-basic"
          label="Url parameters"
          name='Url parameters'
          variant="standard"
          fullWidth
          value={advancedPostBoosting.ad.urlParameters}
          onChange={handleUrlParametersChange}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>


    </Grid>
  )
}

export default AdInfo;