

import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { LoadingModal } from '../Modal';
import { apiOAuthLinkedInAdsLogin } from '../../api/Auth';
import { redBtn, regularBtnStyles } from '../../styles/buttons';
import { useAdminStateValue } from '../../context/admin/AdminContext';
import SelectLinkedInAssetsDialog from '../SelectLinkedInAssetsDialog';
import { setLinkedInAssets } from '../../context/admin/AdminActions';
import { apiGetLinkedInSettings, apiRemoveLinkedInSettings } from '../../api/Customer';
import { desktop_h3, desktop_p2, montserratBoldFontFamily } from '../../styles/textStyles';


const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type LinkedInAdminProps = {
  customerId: string
}

export const LinkedInAdmin = (props: LinkedInAdminProps) => {
  const { customerId } = props
  const [loading, setLoading] = useState(false)
  const [{ linkedInAssets }, dispatch] = useAdminStateValue()

  const isLinkedInConfigured = linkedInAssets.savedAssetsIntoDB.adAccount?.id

  function doLinkedInAdsLogin() {
    setLoading(true)
    apiOAuthLinkedInAdsLogin(customerId).then((response) => {
      window.location.href = response.url
    }).catch(_ => {
      setLoading(false)
      alert("Error in authentication. Please try again later.")
    })
  }

  function removeLinkedInAdsAuth() {
    setLoading(true)
    apiRemoveLinkedInSettings(customerId).then(() => {
      dispatch({ type: "CLEAR_LINKEDIN_ASSETS" })
      setLoading(false)
    }).catch(() => {
      setLoading(false)
      alert("Error in removing settings. Please try again later.")
    })
  }

  return (<Grid container>
    {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
      <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sx={gridItemStyles}>
          <Alert severity='info'>Recommended role: Account Manager or higher.</Alert>
        </Grid>
        <Grid item xs={3} sx={{ pt: 0 }}>
          <Button sx={{ ...regularBtnStyles, mt: 1 }} fullWidth onClick={doLinkedInAdsLogin.bind(this)}>Authorize Ads</Button>
        </Grid>
        <Grid item xs={9} sx={{ pt: 0 }}>
          <Button sx={{ ...redBtn, mt: 1 }} onClick={removeLinkedInAdsAuth.bind(this)}>Remove authorization</Button>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={isLinkedInConfigured ? {...desktop_h3, fontFamily: montserratBoldFontFamily} : {...desktop_p2}}>{isLinkedInConfigured ? 'Current configuration' : 'Not configured yet.'}</Typography>
        </Grid>

        {isLinkedInConfigured && <>
          <Grid item xs={4} sx={{ mb: 4 }}>
            <TextField fullWidth size='small' label="Ad account" variant="outlined" value={`${linkedInAssets.savedAssetsIntoDB.adAccount.name} - ${linkedInAssets.savedAssetsIntoDB.adAccount.id}`} />
          </Grid>
        </>}
        <Grid item xs={6}>
          <SelectLinkedInAssetsDialog isOpen={linkedInAssets.openAssetsDialog} />
        </Grid>

      </Grid>
    </Grid>}
  </Grid>)
}
