import { CustomerTree } from "../../api/Types"

type CustomerErrorMessage = "NoCustomers" | "LoadingError"

export type CustomersState = {
  selected?: CustomerTree
  customers: CustomerTree[]
  errorMessage?: CustomerErrorMessage
  loading: boolean
}

type CustomersActionRequest = {
  type: 'REQUEST_CUSTOMERS'
}

type CustomersActionLoaded = {
  type: 'CUSTOMERS_LOADED'
  customers: CustomerTree[]
}

type CustomersActionSelected = {
  type: 'CUSTOMERS_SELECTED'
  selected: CustomerTree
}

type CustomersActionUnSelected = {
  type: 'CUSTOMERS_UNSELECTED'
}

type CustomersActionError = {
  type: 'CUSTOMERS_ERROR'
  errorMessage: CustomerErrorMessage
}

export type CustomersAction = CustomersActionRequest | CustomersActionLoaded | CustomersActionSelected | CustomersActionError | CustomersActionUnSelected

export const initialCustomersState: CustomersState = {
  customers: [],
  loading: false,
};

export const CustomerReducer = (state: CustomersState, action: CustomersAction): CustomersState => {
  switch (action.type) {
    case 'REQUEST_CUSTOMERS':
      return {
        ...initialCustomersState,
        loading: true,
      };
    case 'CUSTOMERS_LOADED':
      return {
        ...initialCustomersState,
        customers: action.customers,
        loading: false
      };
    case 'CUSTOMERS_SELECTED':
      return {
        ...state,
        selected: action.selected,
        loading: false
      };
    case 'CUSTOMERS_UNSELECTED':
      return {
        ...state,
        selected: undefined,
        loading: false
      };
    case 'CUSTOMERS_ERROR':
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    default:
      throw new Error('Unhandled action type');
  }
};