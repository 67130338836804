import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material"
import { Creative } from "../api/Types"
import { maxStringLength, numberToString } from "../utils/Generators"
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import { neutralColors, statusColors } from "../styles/colors"
import CreativePreviewAdditionalInfoDialog from "./CreativePreviewAdditionalInfoDialog"
import { planeIcon, searchIcon } from "../utils/icons";
import { desktop_p2 } from "../styles/textStyles";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid'
import ConversionDetailsDialog from "./Ads/ConversionDetailsDialog";

export const conversionItems = [
  { id: "totalConversions", title: "Conv" },
  { id: "mobileTotalConversions", title: "Mobile" },
  { id: "tabletTotalConversions", title: "Tablet" },
  { id: "desktopTotalConversions", title: "Desktop" },
  { id: "postClickConversions", title: "Post-click" },
  { id: "postViewConversions", title: "Post-view" }
]

export type CreativeViewProps = Creative & { conversions: number, conversionName: string }

export const mapCategory = (category: string): string => {
  switch (category) {
    case 'displayVideo':
      return 'Display & Video';
    case 'search':
      return 'Search'
    case 'display':
      return 'Display'
    case 'discovery':
      return 'Discovery'
    case 'app':
      return 'App'
    case 'facebook':
      return 'Meta'
    case 'bingAds':
      return 'Bing Ads'
    case 'linkedInAds':
      return 'LinkedIn Ads'
    case 'youtube':
      return 'Youtube'
    case 'performanceMax':
      return 'Performance Max'
  }
  return category
}

export const CreativePreview = (props: CreativeViewProps) => {
  const { title, impressions, clicks, cost, status, ctr, headlines, category, descriptions,
    conversions, conversionName, externalUrls, totalConversionDetails, desktopConversionDetails, mobileConversionDetails } = props
  const url = props.url ? props.url.replace("&amp;", "&") : ""
  const showImage = !url.endsWith(".html") && !url.endsWith(".mp4") && category !== 'facebook' && url.length > 0 && category !== 'linkedInAds'
  const history = useHistory()

  const width = props.width && Number(props.width) > 0 ? `${props.width}px`: '100%'
  const height = props.height && Number(props.height) > 0 ? `${props.height}px`: '100%'

  const uniqueId = uuidv4()
  
  const[iframeAdScale, setIframeAdScale] = useState(1)

  useEffect(() => {
    const iframeContainer = document.getElementById(`iframeAdContainer-${uniqueId}`);
    const iframe = document.getElementById(`iframeAd-${uniqueId}`);
    
    const handleResize = () => {
      if(iframeContainer && iframe){
        const containerWidth = iframeContainer.offsetWidth
        const containerHeight = iframeContainer.offsetHeight
        const w = Number(props.width)
        const h =  Number(props.height)

        let heightScale = 1
        let widthScale = 1

        if(h > containerHeight){
          heightScale = containerHeight / h
          const scale = Math.min(heightScale, heightScale)
          setIframeAdScale(scale)
        }
        if(w > containerWidth){
          widthScale = containerWidth / w
          const scale = Math.min(widthScale, widthScale)
          setIframeAdScale(scale)
        }
        if(w > containerWidth && h > containerHeight){
          widthScale = containerWidth / w
          heightScale = containerHeight / h
          const scale = Math.min(widthScale, heightScale)
          setIframeAdScale(scale)
        }
      }
    };

    // Call initially
    handleResize();
    window.addEventListener('resize', handleResize);
    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [uniqueId, props.width, props.height, iframeAdScale]);

  
  const handleSearchIconClick = (url: string, width: string, height: string) => {
    const pUrl = '/preview';
    let previewUrl = `${pUrl}?url=${encodeURIComponent(url)}&width=${encodeURIComponent(width)}&height=${encodeURIComponent(height)}`;
    if(category === "facebook" && externalUrls && externalUrls.length > 0){
      previewUrl = `${previewUrl}&postUrl=${encodeURIComponent(externalUrls[0])}`
    }
    window.open(previewUrl, '_blank');
  }
  
  return (<Box sx={boxContainerStyles}>

    {/* Heading */}
    <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
      <Grid item sx={{paddingRight: '36px'}}>
        <Typography>{mapCategory(category)}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {/*<CircleIcon sx={{ fontSize: '12px', marginRight: '7px', color: status === "Active" ? statusColors.green : statusColors.red }} />*/}
          {title && <Typography>{title.replace(/_/g, '-')}</Typography>}
          {!title && headlines[0]?.length > 0 && <Typography>{maxStringLength(headlines[0], 40)}</Typography>}
        </Box>
      </Grid>
    </Grid>
    {/* planeIcon */}
    <Box sx={{position: 'absolute', top:24, right:24, display: 'flex'}}>
      {props.landingPageUrl && props.landingPageUrl.length > 0 &&
        <IconButton onClick={() => window.open(props.landingPageUrl)}>
          {planeIcon()}
        </IconButton>}
    </Box>

    {/* media */}
    <Grid container sx={{ marginTop: '24px', border: '1px transparent solid', position: 'relative', alignItems: 'center', background: neutralColors[100] }}>

      <Box sx={mediaWrapper}>
        {url.length > 0 ?
          <Box sx={mediaContainer}>
            {url.endsWith(".mp4") && <video controls style={mediaElement}>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>}
            {(url.endsWith(".html") || category === 'facebook' || category === 'linkedInAds') &&
            <Box id={`iframeAdContainer-${uniqueId}`} style={{ width: '100%', height: '100%', overflow: 'hidden'}}>
            {<iframe id={`iframeAd-${uniqueId}`}
              onLoad={() => ''} 
              style={{ width: width, height: height, objectFit: 'scale-down', transformOrigin: 'top left', transform: `scale(${iframeAdScale})` }}
              frameBorder={0} src={url} 
              sandbox="allow-same-origin allow-scripts"
            />}
            </Box>
            }
            {showImage && <img src={url} style={mediaElement} />}
          </Box>
          :
          (externalUrls && externalUrls.length > 0 && category === 'youtube') ?
            <Box sx={mediaContainer}>
              <iframe
                style={mediaElement}
                src={`https://www.youtube.com/embed/${externalUrls[0].split('?v=')[1]}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </Box>
            :
            ((category === 'search' || category === 'discovery') && (headlines.length > 0 || descriptions.length > 0)) ? <Box sx={textContainer}>
              <Box>
                <Typography variant='h6' sx={{ mb: 2 }}>Headlines</Typography>
                {headlines && headlines.length > 0 ?
                  headlines.map((h, index) => <Typography key={index} variant='body2'>{h}</Typography>)
                  :
                  <Typography variant='body2'>Not found</Typography>
                }
              </Box>
              <Box>
                <Typography variant='h6' sx={{ mt: 2, mb: 2 }}>Descriptions</Typography>
                {descriptions && descriptions.length > 0 ?
                  descriptions.map((d, index) => <Typography key={index} variant='body2'>{d}</Typography>)
                  :
                  <Typography variant='body2'>Not found</Typography>
                }
              </Box>
            </Box>
              :
              <Box sx={mediaContainer}>
                <img src="question-mark.png" style={mediaElement} />
              </Box>
        }
      </Box>

      <Box sx={mediaOverlayIcons}>
        {(url.endsWith(".html") || category === "facebook" || showImage) && url.length > 0 &&
          <Box sx={{}}>
            <IconButton onClick={() => handleSearchIconClick(url, width, height)} sx={{ background: 'rgba(255,255,255,1)', padding: '6px' }}>
              {searchIcon()}
            </IconButton>
          </Box>}

        {category !== "search" && category !== "discovery" && (headlines.length > 0 || descriptions.length > 0) &&
          <Box sx={{}}>
            <CreativePreviewAdditionalInfoDialog headlines={headlines} descriptions={descriptions} />
          </Box>}
      </Box>

    </Grid>

    {/*<Divider sx={{ borderColor: '#000', marginBottom: '28px' }} />*/}

    {/* metrics */}
    <Grid container direction="row" style={{ justifyContent: 'center', gap: '30px', marginTop: '24px' }}>
      <Box>
        <Typography sx={desktop_p2}>Cost</Typography>
        <Typography sx={desktop_p2}>{cost?.toFixed(cost < 100 ? 2 : 0).replace(".", ",")}€</Typography>
      </Box>
      <Box>
        <Typography sx={desktop_p2}>Impr.</Typography>
        <Typography sx={desktop_p2}>{numberToString(impressions || 0)}</Typography>
      </Box>
      <Box>
        <Typography sx={desktop_p2}>Clicks</Typography>
        <Typography sx={desktop_p2}>{numberToString(clicks || 0)}</Typography>
      </Box>
      <Box>
        <Typography sx={desktop_p2}>CTR</Typography>
        <Typography sx={desktop_p2}>{((ctr || 0) * 100).toFixed(2)}%</Typography>
      </Box>
      <Box sx={{position: 'relative'}}>
        {category !== "facebook" && <Tooltip title={<>{conversionItems.map(i => <div key={title + "-" + i.id}>{i.title} {Math.round((props as any)[i.id])}</div>)}</>} arrow>
          <Box>
            <Typography sx={desktop_p2}>{maxStringLength(conversionName, 8)}</Typography>
            <Typography sx={desktop_p2}>{numberToString(conversions || 0)}</Typography>
          </Box>
        </Tooltip>}
        {category === "facebook" && <>
        <Box>
          <Typography sx={desktop_p2}>Conv.</Typography>
          <Typography sx={desktop_p2}>{numberToString(totalConversionDetails?.total || 0)}</Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: '-4px', right: '-32px'}}>
          <Tooltip title={"Conversion details"} arrow>
            <ConversionDetailsDialog 
              totalConversionDetails={totalConversionDetails} 
              desktopConversionDetails={desktopConversionDetails} 
              mobileConversionDetails={mobileConversionDetails}/>
          </Tooltip>
          </Box></>}
        </Box>
    </Grid>
  </Box>)
}

// Style objects

const boxContainerStyles = {
  border: `1px solid ${neutralColors[100]}`,
  padding: '24px',
  fontSize: '14px',
  lineHeight: '100%',
  borderRadius: '5px',
  width: '100%',
  position: 'relative'
}

const mediaWrapper = {
  border: "1px transparent solid",
  position: "relative",
  width: "100%",
  paddingTop: "80%" /* 5:4 Aspect Ratio (divide 4 by 5 = 0.80) */

}
const mediaContainer = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}
const textContainer = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  overflow: "scroll",
  padding: "16px"
}
const mediaElement = {
  "width": "100%",
  "height": "100%",
  "object-fit": "scale-down",
}

const iframeStyles = {
  "width": "200%",
  "height": "200%", 
  "border": "none",
  "transform-origin": "top left",
  "transform": "scale(0.5)"
}

const mediaOverlayIcons = {
  "z-index": 5,
  position: "absolute",
  bottom: 10,
  right: 10,
  display: 'flex',
  flexDirection: 'row',
  border: '1px transparent solid',
  gap: '8px'
}