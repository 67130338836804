import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const PreviewPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [url, setUrl] = useState("")
  const [width, setWidth] = useState("")
  const [height, setHeight] = useState("")
  const [postUrl, setPostUrl] = useState("")

  const [competitorCreativePreviewData, setCompetitorCreativePreviewData] = useState<any>(null);

  useEffect(() => {
    const url = urlParams.get("url");
    const width = urlParams.get("width");
    const height = urlParams.get("height");
    const postUrl = urlParams.get("postUrl");
    url && setUrl(url)
    width && setWidth(width)
    height && setHeight(height)
    postUrl && setPostUrl(postUrl)
  }, [urlParams])

  useEffect(() => {
    const previewData = localStorage.getItem('previewData');
    if (previewData) {
      setCompetitorCreativePreviewData(JSON.parse(previewData));
    }
  }, []);
  
  return (
    <Box style={{width: '100vw', height: '100vh'}}>
      {!competitorCreativePreviewData && <>
      <Box sx={{ margin: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '24px' }}>
        <a href={decodeURIComponent(url)} target='_blank'>Origin URL</a>
        {postUrl && <a href={decodeURIComponent(postUrl)} target='_blank'>Post URL</a>}
      </Box>
      {<iframe className='previewIframe' src={url} style={{width: width, height: height === "690px" ? "100%": height, border: 'none'}} />}
      </>}
      {competitorCreativePreviewData && <img src={`data:image/png;base64,${competitorCreativePreviewData.url}`} alt="competitor creative image"/>}
    </Box>
  );
};

export default PreviewPage;
