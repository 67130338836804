import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomationSettingsErrors, setAdvancedPostBoosting, setAdvancedPostBoostingErrors } from '../../../context/meta/MetaActions';
import { MarketingObjStatus, CampaignObjectives, MetaErrors, BudgetLevel } from '../../../context/meta/MetaTypes';
import { capitalizeFirstLetter, beautifyString, convertObjectToArray } from '../../../utils/helpers'
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import Budget from './Budget';
import BudgetLevelComponent from './BudgetLevelComponent';


const CampaignInfo = () => {
  // const [metaState, dispatch] = useStateValue()
  // You can destructure only the adSettings property from metaState
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting, advancedPostBoostingErrors }, dispatch] = useStateValue()
  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')

  const [name, setName] = useState(metaAdAutoLocation ? adAutomationSettings.campaign.name : advancedPostBoosting.campaign.name)
  const [nameError, setNameError] = useState(false)
  const [objective, setObjective] = useState(metaAdAutoLocation ? adAutomationSettings.campaign.objective : advancedPostBoosting.campaign.objective)
  const [status, setStatus] = useState(metaAdAutoLocation ? adAutomationSettings.campaign.status : advancedPostBoosting.campaign.status)
  const [specialAdCategories, setSpecialAdCategories] = useState<string[]>([])

  useEffect(() => {
    setStatus(metaAdAutoLocation ? adAutomationSettings.campaign.status : advancedPostBoosting.campaign.status)
  }, [adAutomationSettings])


  const handleNameChange = (e: any) => {
    const n = e.target.value
    setName(n)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, campaign: { ...adAutomationSettings.campaign, name: n } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, campaign: { ...advancedPostBoosting.campaign, name: n } }))
    }

    if (n && n.length >= 3) {
      setNameError(false)
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.campaignNameError)))
      dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.campaignNameError)))
    } else {
      setNameError(true)
      // Add campaignNameError to the error list if it not exists.
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== MetaErrors.campaignNameError).concat([MetaErrors.campaignNameError])))
      } else {
        dispatch(setAdvancedPostBoostingErrors(advancedPostBoostingErrors.filter(e => e !== MetaErrors.campaignNameError).concat([MetaErrors.campaignNameError])))
      }
    }
  }

  const handleSpecialAdCategoriesChange = (e: any) => {
    setSpecialAdCategories(e.target.value)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, campaign: { ...adAutomationSettings.campaign, special_ad_categories: e.target.value } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, campaign: { ...advancedPostBoosting.campaign, special_ad_categories: e.target.value } }))
    }
  }

  const handleObjectiveChange = (e: SelectChangeEvent<CampaignObjectives>) => {
    const ob = e.target.value as CampaignObjectives
    setObjective(ob)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, campaign: { ...adAutomationSettings.campaign, objective: ob } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, campaign: { ...advancedPostBoosting.campaign, objective: ob } }))
    }
  }

  const handleStatusChange = (e: SelectChangeEvent<MarketingObjStatus>) => {
    const s = e.target.value as MarketingObjStatus
    setStatus(s)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({
        ...adAutomationSettings,
        active: s === MarketingObjStatus.ACTIVE ? true : false,
        campaign: { ...adAutomationSettings.campaign, status: s },
        adSet: { ...adAutomationSettings.adSet, status: s }
      }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, campaign: { ...advancedPostBoosting.campaign, status: s } }))
    }
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        {/*<Typography variant="h6">Create a new campaign</Typography>*/}
      </Grid>
      {metaAdAutoLocation && <Grid item xs={12}>
        <Typography variant="h6">Campaign</Typography>
      </Grid>}
      <Grid item xs={12}>
        <Typography variant="h6">Name and status</Typography>
      </Grid>

      <Grid item xs={8}>
        <TextField
          id="standard-basic"
          label="Campaign name"
          name='Campaign name'
          variant="standard"
          fullWidth
          value={name}
          onChange={handleNameChange}
          error={nameError}
          helperText={nameError ? "Enter at least 3 characters." : ""}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="campaign-status-select-label">Campaign status</InputLabel>
          <Select
            labelId="campaign-status-select-label"
            id="campaign-objective"
            value={status}
            onChange={handleStatusChange}
            label="Budget type"
          >
            {convertObjectToArray(MarketingObjStatus).map((s: MarketingObjStatus) => {
              return <MenuItem sx={{ fontSize: '14px' }} key={s} value={s}>{capitalizeFirstLetter(s)}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider sx={{ width: '100%' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Objective</Typography>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="campaign-objective-select-label">Campaign objective</InputLabel>
          <Select
            labelId="campaign-objective-select-label"
            id="campaign-objective"
            value={objective}
            onChange={handleObjectiveChange}
            label="Budget type"
          >
            {convertObjectToArray(CampaignObjectives).map((o: CampaignObjectives) => {
              if (o.toLowerCase().includes("app") || o.toLowerCase().includes("sales") || o.toLowerCase().includes("leads")) {
                return <MenuItem disabled sx={{ fontSize: '14px' }} key={o} value={o}>{beautifyString(o.slice(8))}</MenuItem>
              }
              return <MenuItem sx={{ fontSize: '14px' }} key={o} value={o}>{beautifyString(o.slice(8))}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel id="campaign-special-category-select-label">Special categories</InputLabel>
          <Select multiple value={specialAdCategories} size="small" labelId="campaign-special-category-select-label"
            onChange={handleSpecialAdCategoriesChange}>
            <MenuItem value="HOUSING">Housing</MenuItem>
            <MenuItem value="CREDIT">Credit</MenuItem>
            <MenuItem value="EMPLOYMENT">Employment</MenuItem>
            <MenuItem value="ISSUES_ELECTIONS_POLITICS">Election politics</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider sx={{ width: '100%' }} />
      </Grid>

      <BudgetLevelComponent />
      {
        (metaAdAutoLocation && adAutomationSettings.budgetProps.budgetLevel === BudgetLevel.CAMPAIGN_LEVEL || !metaAdAutoLocation && advancedPostBoosting.budgetProps.budgetLevel === BudgetLevel.CAMPAIGN_LEVEL) &&
        <Budget />
      }

    </Grid >
  )
}

export default CampaignInfo;


