import { CustomerTree } from "../api/Types"


export const range = (n: number) => {
  return Array.from(Array(n).keys())
}

export const toNumberDict = <T>(values: T[], fn: (value: T) => number): { [key: number]: T } => {
  const dict: { [key: number]: any } = {}
  for (const value of values) {
    dict[fn(value)] = value
  }
  return dict
}

export const toStringDict = <T>(values: T[], fn: (value: T) => string): { [key: number]: T } => {
  const dict: { [key: string]: any } = {}
  for (const value of values) {
    dict[fn(value)] = value
  }
  return dict
}

export const getMax = (arr: number[]): number => {
  return arr.length > 0 ? arr.reduce((max, curr) => Math.max(max, curr), arr[0]) : 0
}

export const generateRandomId = (): string => {
  return `id-${Math.random().toString().replaceAll('.', '-')}`
}

export const translateToSvgCoordinate = (svgId: string, event: any): { x: number, y: number } | undefined => {
  const svg = document.getElementById(svgId)?.querySelector('svg')
  const ctm = svg?.getScreenCTM()?.inverse()
  if (svg && ctm) {
    const pt = svg.createSVGPoint();
    pt.x = event.clientX;
    pt.y = event.clientY;
    const transformed = pt.matrixTransform(ctm)
    return { x: transformed.x, y: transformed.y }
  }
}

export const weekdayToName = (index: number, long: boolean): string => {
  const names: string[] = ['Maanantai', 'Tiistai', 'Keskiviikko',
    'Torstai', 'Perjantai', 'Lauantai', 'Sunnuntai']
  const name = names[index - 1]
  return name.substring(0, long ? name.length : 2)
}

export const debounce = (fn: (...args: any[]) => void, ms = 1000) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const debouncePromise = <T>(fn: (...args: any[]) => Promise<T>, ms = 1000) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        fn.apply(this, args).then(resolve).catch(reject)
      }, ms);
    })
  };
};

export const numberToString = (value: number): string => {
  if (value > 1000000) {
    return (value / 1000000).toFixed(2) + "M"
  }
  if (value > 1000) {
    return (value / 1000).toFixed(2) + "k"
  }
  return value.toFixed(0).toString()
}

export const maxStringLength = (value: string, max: number): string => {
  if (value.length > max) {
    return value.substring(0, max - 3) + "..."
  }
  return value
}

export const toPercentage = (value: number): string => {
  return (value * 100).toFixed(0) + "%"
}

export const toPrice = (value?: number): string => {
  if (!value) {
    return "-"
  }
  return value.toFixed(2).replace(".", ",") + " €"
}

export const fixedStringLength = (value: string, fixed: number): string => {
  if (value.length > fixed) {
    return value.substring(0, fixed - 3) + "..."
  }
  else if (value.length < fixed) {
    return value.padStart(fixed, " ")
  }
  return value
}

export const getURLParameter = (key: string): string | undefined => {
  const url = window.location.search.substring(1);
  const variables = url.split('&');
  for (const variable of variables) {
    const parts = variable.split('=');
    if (parts[0] === key) {
      return parts[1];
    }
  }
  return
}

export const getSubstringKeyValue = (str: string, key: string, stopper: string): string | undefined => {
  const keyIndex = str.indexOf(key)
  if (keyIndex !== -1) {
    const startIndex = keyIndex + key.length
    const restOfStr = str.substring(startIndex)
    return restOfStr.substring(0, restOfStr.indexOf(stopper))
  }
  return
}

export const findCustomerParentFromTree = (customers: CustomerTree[], id: number,
  parent: CustomerTree | undefined = undefined): CustomerTree | undefined => {
  for (const customer of customers) {
    if (customer.id === id) {
      return parent
    }
    const searchResult = findCustomerParentFromTree(customer.children, id, customer)
    if (searchResult) {
      return searchResult
    }
  }
}

export const flattenCustomers = (customers: CustomerTree[], level = 0): (CustomerTree & { level: number })[] => {
  let flattened: (CustomerTree & { level: number })[] = []
  for (const customer of customers) {
    flattened.push({ ...customer, level })
    flattened = flattened.concat(flattenCustomers(customer.children, level + 1))
  }
  return flattened
}