import styled from "styled-components";
import { CustomDatasetRow } from "../../api/Types";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { regularBtnStyles, whiteBtnBlackBorder } from "../../styles/buttons";
import { apiAddCustomDatasetRows, apiGetCustomDatasetRows, apiRemoveCustomDatasetRow, apiUpdateCustomDatasetRow } from "../../api/Assistant";
import { Add, ExpandMore, Label } from "@mui/icons-material";
import { convertCsvStringToArray, cutStringToMaxLength } from "../Utils";
import { EditableText } from "../Common/EditableText";
import { FlexibleLoaderDialog } from "./FlexibleLoaderDialog";
import { FaqFlexibleLoaderDialog } from "./FaqFlexibleLoaderDialog";


const Container = styled.div`
  display:grid;
  grid-template-columns: 1fr 1.5fr 4em;
  margin-top:1em;
  margin-right:3em;
  row-gap:0.5em;
  column-gap:1.5em;
`

const Heading = styled.div`
  font-weight:bold;
`

const Column = styled.div`
  max-height:150px;
`


const ColumnText = styled.div`
  margin-left:0.7em;
  max-height:150px;
`

const ColumnCommand = styled.div`
  align-self:center;
`

const FullRow = styled.div`
  grid-column-start:1;
  grid-column-end:4;
`

const Buttons = styled.div`
  display:grid;
  grid-template-columns: 4em 1fr 4em;
  margin-top:0.5em;
  column-gap:1.0em;
  align-items:center;
  justify-items:center;
  max-width:18em;
  margin-left:auto;
  margin-right:0;
`

const TopButtons = styled.div`
  display:grid;
  grid-template-columns: 11em 13em 13em 11em;
  column-gap:1em;
`

const BrowserContainer = styled.div`
  width:100%;
  padding-right:1em;
  grid-column-start:1;
  grid-column-end:4;
`

type CustomDatasetRowSelectable = {
  id: string
  question: string
  answer: string
  selected: boolean
}

export type CustomDatasetRowViewProps = {
  customerId: number
  datasetId: string,
  setLoading: (loading: boolean) => void
}

export const CustomDatasetRowView = (props: CustomDatasetRowViewProps) => {
  const { customerId, datasetId, setLoading } = props
  const [rows, setRows] = useState<CustomDatasetRowSelectable[]>([])
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [flexibleLoaderDialogOpen, setFlexibleLoaderDialogOpen] = useState(false)
  const [faqFlexibleLoaderDialogOpen, setFaqFlexibleLoaderDialogOpen] = useState(false)
  const fileReader = new FileReader();

  function loadCustomerDatasetRows() {
    setLoading(true)
    apiGetCustomDatasetRows(customerId, datasetId, offset).then((response) => {
      setTotal(response.total)
      setRows(response.rows.map(r => ({ ...r, selected: false })))
      setLoading(false)
    })
  }

  function onAddRows(rows: { question: string, answer: string }[]) {
    setLoading(true)
    apiAddCustomDatasetRows(customerId, datasetId, rows).then(() => {
      loadCustomerDatasetRows()
    }).catch(() => { setLoading(false) })
  }

  function onRemoveRow(id: string) {
    setLoading(true)
    apiRemoveCustomDatasetRow(customerId, datasetId, id).then(() => {
      loadCustomerDatasetRows()
    }).catch(() => { setLoading(false) })
  }

  function updateRow(row: CustomDatasetRowSelectable) {
    setRows(rows.map(r => r.id === row.id ? row : r))
    apiUpdateCustomDatasetRow(customerId, datasetId, row)
  }

  useEffect(() => {
    loadCustomerDatasetRows()
  }, [props.datasetId, offset])

  return (
    <>
      <input
        style={{ display: 'none' }}
        id={`upload-file`}
        multiple
        type="file"
        accept={".csv"}
        onChange={(event) => {
          if (event.target.files?.length) {
            const file = event.target.files[0]!
            fileReader.onload = function (event) {
              if (event.target) {
                const uploadFile = document.getElementById('upload-file')
                if (uploadFile) {
                  (uploadFile as HTMLInputElement).value = ""
                }
                const text = event.target.result!.toString();
                onAddRows(convertCsvStringToArray(text))
              }
            };
            fileReader.readAsText(file);
          }
        }} />
      <TopButtons>
        <label htmlFor={`upload-file`}>
          <Button component="span" sx={{ ...regularBtnStyles }}>Import from CSV</Button>
        </label >
        <Button component="span" sx={{ ...regularBtnStyles }} onClick={() => setFlexibleLoaderDialogOpen(true)}>Import from sitemap</Button>
        <Button component="span" sx={{ ...regularBtnStyles }} onClick={() => setFaqFlexibleLoaderDialogOpen(true)}>Import FAQ from web</Button>
        <Button component="span" sx={{ ...regularBtnStyles }} onClick={() => onAddRows([{ question: 'New Question', answer: 'New Answer' }])}>Add example</Button>
      </TopButtons>
      <Container>
        {rows.length > 0 && <><Heading>Question</Heading>
          <Heading>Answer</Heading>
          <Heading></Heading></>}
        <FullRow><hr /></FullRow>
        {rows.length === 0 && <FullRow>Material is empty.</FullRow>}
        {rows.map(r => <>
          <ColumnText>
            <EditableText text={r.question} label="Question" onChange={(question) => { updateRow({ ...r, question }) }} />
          </ColumnText>
          <ColumnText>
            <Tooltip title={<>{r.answer}</>} arrow>
              <EditableText text={r.answer} label="Answer" onChange={(answer) => { updateRow({ ...r, answer }) }} />
            </Tooltip>
          </ColumnText>
          <ColumnCommand>
            <FontAwesomeIcon icon={faTrash} color={'red'} onClick={() => onRemoveRow(r.id)} style={{ cursor: 'pointer' }} />
          </ColumnCommand>
        </>)}
        <FullRow><hr /></FullRow>
        <BrowserContainer>
          <Buttons>
            <Button disabled={offset === 0} sx={{ ...whiteBtnBlackBorder }} onClick={() => setOffset(Math.max(0, offset - 10))}>&#60;</Button>
            <div>
              {offset}-{offset + rows.length} / {total}
            </div>
            <Button disabled={offset + rows.length >= total} sx={{ ...whiteBtnBlackBorder }} onClick={() => setOffset(Math.min(offset + 10, total - 1))}>&#62;</Button>
          </Buttons>
        </BrowserContainer>
        <FlexibleLoaderDialog customerId={customerId} open={flexibleLoaderDialogOpen}
          setLoading={setLoading}
          setOpen={setFlexibleLoaderDialogOpen}
          onImport={(rows) => onAddRows(rows)} />
        <FaqFlexibleLoaderDialog customerId={customerId} open={faqFlexibleLoaderDialogOpen}
          setLoading={setLoading}
          setOpen={setFaqFlexibleLoaderDialogOpen}
          onImport={(rows) => onAddRows(rows)} />
      </Container>
    </>
  );
}
