import styled from "styled-components";
import { LightLineColor } from "../components/Colors";
import { hideTooltip, showTooltip, Tooltip } from "../components/Tooltip";
import { generateRandomId, range, translateToSvgCoordinate } from "../utils/Generators";
import { PolyLine } from "./Types";

const ChartDiv = styled.div`
    width: 100%;
    object-fit: contain;
`

type LineDescriptor = {
  color: string;
  width: number;
  points: PolyLine
  endmarker?: "circle"
}

type AxisDescriptor = {
  min: number
  max: number
}

type HorizontalLine = {
  y: number
  label: string
}

export type LineChartProps = {
  ratio: number,
  labels: {
    titles: string[],
    anchor: "middle" | "left" | "right",
    offset?: number,
    fullRange?: boolean
  },
  vertLines: number,
  horzLines: HorizontalLine[],
  lines: LineDescriptor[],
  xAxis: AxisDescriptor,
  yAxis: AxisDescriptor,
  padding?: number
  paddingLeft?: number,
  width?: number,
  endMarkerToolTip?: React.ReactNode,
  unit: string
};

const LegendText = styled.text`
    stroke: ${LightLineColor};
    font-size: 20px;
    font-family: AvenirLTPro-Roman;
`


const mousePosToY = (svgId: string, x1: number, x2: number, v1: number, v2: number, event: any): number => {
  const pt = translateToSvgCoordinate(svgId, event)
  if (pt) {
    const pos = (pt.x - x1) / (x2 - x1)
    return Math.floor(v1 + Math.min(1.0, Math.max(0.0, pos)) * (v2 - v1))
  }
  return 0.0
}

export const LineChart = (props: LineChartProps) => {
  const width = props.width || 1000
  const { labels, lines, vertLines, ratio, xAxis, yAxis, horzLines, endMarkerToolTip, unit } = props
  const height = ratio * width
  const padding = props.padding || 10
  const paddingLeft = props.paddingLeft || props.padding || 10
  const paddingSides = padding + paddingLeft
  const paddingBottom = 30

  const toAxisCoordinate = (x: number, y: number) => {
    return [
      (x - xAxis.min) / (xAxis.max - xAxis.min) * (width - paddingSides) + paddingLeft,
      (1.0 - (y - yAxis.min) / (yAxis.max - yAxis.min)) * (height - padding - paddingBottom) + padding
    ]
  }

  const textXPosition = (index: number, offset?: number, fullRange?: boolean) =>
    paddingLeft + ((index + (offset || 0.0)) / (labels.titles.length - (fullRange ? 1.0 : 0.0))) * (width - paddingSides)

  const tooltipId = generateRandomId()
  const svgId = generateRandomId()
  return (<ChartDiv id={svgId}>
    {lines.map((line, lineIndex) => {
      const id = `${tooltipId}${lineIndex}`
      return line.points.length > 0 ? <Tooltip key={id} id={id}>
        {endMarkerToolTip || <span key={`${id}span`} style={{ color: line.color }}>{line.points[line.points.length - 1][1]} {unit}</span>}
      </Tooltip> : null
    })}
    <svg viewBox={`0 0 ${width} ${height}`}>
      {labels.titles.map((l, index) => {
        const x = textXPosition(index, labels.offset, labels.fullRange)
        return <LegendText key={`lt${index}`} x={x} y={height - 8} textAnchor={labels.anchor}>{l}</LegendText>
      })}

      {horzLines.map((l, index) => {
        const p1 = toAxisCoordinate(xAxis.min, l.y)
        const p2 = toAxisCoordinate(xAxis.max, l.y)
        return <line key={`lkey${index}`} x1={p1[0]} y1={p1[1]} x2={p2[0]} y2={p2[1]}
          stroke={LightLineColor} strokeWidth={1} strokeDasharray="4" />
      })}

      {horzLines.map((l, index) => {
        const p = toAxisCoordinate(xAxis.min, l.y)
        return <LegendText key={`lth${index}`} x={p[0] - 5.0} y={p[1]} textAnchor="end" alignmentBaseline={"middle"}>{l.label}</LegendText>
      })}

      {range(vertLines).map((index => {
        const x = paddingLeft + (index / vertLines) * (width - paddingSides)
        return <line key={`vline${index}`} x1={x} y1={height - paddingBottom} x2={x} y2={padding}
          stroke={LightLineColor} strokeWidth={1} />
      }))}
      <line key="vlineFirst" x1={width - padding} y1={height - paddingBottom} x2={width - padding} y2={padding}
        stroke={LightLineColor} strokeWidth={1} />
      <line key="vlineLast" x1={paddingLeft} y1={height - paddingBottom} x2={width - padding} y2={height - paddingBottom}
        stroke={LightLineColor} strokeWidth={1} />
      {lines.map((line, lineIndex) => {
        return line.points.map((p, index) => {
          const p1 = toAxisCoordinate(p[0], p[1])
          if (index < line.points.length - 1) {
            const p2 = toAxisCoordinate(line.points[index + 1][0], line.points[index + 1][1])
            return <>
              <line key={`lines${lineIndex}_${index}`} x1={p1[0]} y1={p1[1]} x2={p2[0]} y2={p2[1]} stroke={line.color}
                strokeWidth={line.width}
              />
              <rect key={`rects${lineIndex}_${index}`} x={Math.min(p1[0], p2[0]) - 2.0} y={Math.min(p1[1], p2[1]) - 2.0}
                width={Math.abs(p2[0] - p1[0]) + 2.0} height={Math.abs(p2[1] - p1[1]) + 2.0}
                fillOpacity={0.0}
                onMouseMove={(event: any) => {
                  showTooltip(event, `${tooltipId}${lineIndex}`,
                    `<span style="color:${line.color}"}>${mousePosToY(svgId, p1[0], p2[0], line.points[index][1], line.points[index + 1][1], event)} ${unit}</span>`)
                }}
                onMouseLeave={() => hideTooltip(`${tooltipId}${lineIndex}`)}
              ></rect>
            </>
          }
          return line.endmarker === "circle" ?
            <circle id="endMarker" key={`circle${lineIndex}_endmarker`} cx={p1[0]} cy={p1[1]} r={6}
              stroke={line.color}
              onMouseEnter={(event: any) => { showTooltip(event, `${tooltipId}${lineIndex}`) }}
              onMouseLeave={() => hideTooltip(`${tooltipId}${lineIndex}`)}
            /> : null
        })
      })}
    </svg>
  </ChartDiv >)
}