import React, { useReducer, ReactNode, Dispatch } from 'react';
import {
  useGoogleLogin,
  GoogleLoginHookReturnValue,
} from 'react-use-googlelogin'
import { createContext } from '..';
import { AuthAction, AuthReducer, AuthState, initialAuthState } from './AuthReducer';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from "react-router-dom";

const [useGoogleAuth, GoogleAuthProvider] = createContext<GoogleLoginHookReturnValue>()
const [useAuthState, AuthStateProvider] = createContext<AuthState>()
const [useAuthDispatch, AuthDispatchProvider] = createContext<Dispatch<AuthAction>>()

export type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider = (props: AuthProviderProps) => {
  const [user, dispatch] = useReducer(AuthReducer, initialAuthState);
  const hookData = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || 'undefined',
  });
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider // used only for logging out
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{}}
    >
      <GoogleAuthProvider.Provider value={hookData}>
        <AuthStateProvider.Provider value={user}>
          <AuthDispatchProvider.Provider value={dispatch}>
            {props.children}
          </AuthDispatchProvider.Provider>
        </AuthStateProvider.Provider>
      </GoogleAuthProvider.Provider>
    </Auth0Provider>
  )
};

export { useGoogleAuth, useAuthDispatch, useAuthState }