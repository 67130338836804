import * as React from 'react';
import { useEffect, useState } from 'react'
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { InputBase, Box, Divider, IconButton, AccordionDetails, Accordion, Grid, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getAdAutomations } from '../../../api/Marketing';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomations } from '../../../context/meta/MetaActions';
import { useAlertStateValue } from '../../../context/alert/AlertContext';
import { setAlert } from '../../../context/alert/AlertAction';
import { AlertSeverity } from '../../../context/alert/AlertTypes';
import SingleMetaAccordion from '../sharedMetaComponents/SingleMetaAccordion';
import { MetaObjType } from '../../../context/meta/MetaTypes';
import MetaAccordionHeader from '../sharedMetaComponents/MetaAccordionHeader';


const AdAutomations: React.FC<any> = () => {
  const state = useCustomerState()
  const [{ adAutomations }, dispatch] = useStateValue()
  const [loading, setloading] = useState(false)
  const [filter, setFilter] = useState('');
  const [{ alert }, alertDispatch] = useAlertStateValue()


  // handle user input in the search field
  const handleFilterchange = (event: any) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    setloading(true)
    state?.selected?.id && getAdAutomations(state?.selected?.id).then((result) => {
      dispatch(setAdAutomations(result))
      setloading(false)
    }).catch(error => {
      setloading(false)
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
    });
  }, [state])


  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && !adAutomations && <div>No ad automations found.</div>}
      {!loading && adAutomations !== undefined && adAutomations.length < 1 && <div>No ad automations found.</div>}

      {!loading && adAutomations !== undefined && adAutomations !== null && adAutomations.length > 0 && <div>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
        >
          <form>
            <Box display="flex" alignItems="center">
              <InputBase
                placeholder="Filter by name..."
                value={filter}
                onChange={handleFilterchange}
              />
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Box>
            <Divider style={{ marginBottom: '24px' }} />
          </form>

        </Box>
        <MetaAccordionHeader />
        {adAutomations.sort((a, b) => new Date(b.created_time).valueOf() - new Date(a.created_time).valueOf())
          .filter((item: any) => item?.name?.toLowerCase().includes(filter.toLowerCase()))
          .map((item: any) => {
            return <SingleMetaAccordion key={item.id} metaObj={item} metaObjType={MetaObjType.Ad_Automation} customerId={state.selected?.id} />
          })}
      </div>}
    </>)
}

export default AdAutomations;

