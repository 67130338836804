import { Box, Grid, Link, Typography } from '@mui/material'
import { desktop_h1, desktop_h2, desktop_p1 } from '../styles/textStyles'

const ContactUsView = () => {
  return(
      <Box sx={{ margin: '50px' }}>
  
        <Grid container spacing={4} sx={{ marginBottom: '36px' }}>
          <Grid item xs={12} sx={{}}>
            <Typography variant='h1' sx={desktop_h1}>Contact Us</Typography>        
          </Grid>

          <Grid item xs={4} sx={{}}>
            <Typography variant='h2' sx={desktop_h2} style={{marginBottom: '8px'}}>Mika Hirvikorpi</Typography> 
            <Typography sx={desktop_p1}><Link href="mailto:mika.hirvikorpi@kiva.fi" sx={linkStyles}>mika.hirvikorpi@kiva.fi</Link></Typography> 
            <Typography sx={desktop_p1}><Link href="tel:+358504869448" sx={linkStyles}>+358 50 486 9448</Link></Typography>        
          </Grid>

          <Grid item xs={4} sx={{}}>
            <Typography variant='h2' sx={desktop_h2} style={{marginBottom: '8px'}}>Sami Laakkonen</Typography> 
            <Typography sx={desktop_p1}><Link href='mailto:sami.laakkonen@kiva.fi' sx={linkStyles}>sami.laakkonen@kiva.fi</Link></Typography> 
            <Typography sx={desktop_p1}><Link href="tel:+358504134321" sx={linkStyles}>+358 50 413 4321</Link></Typography>        
          </Grid>

        </Grid>
      </Box>
  )
}

const linkStyles = {
  color: '#000',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}

export default ContactUsView