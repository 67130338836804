import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useCustomerState } from '../context/customer/CustomerContext';
import { TextField, Typography, ToggleButton, ToggleButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, FormControl, DialogActions, Button, Box, Grid, Divider, Avatar, Tooltip } from '@mui/material';
import { AnimatedSpinner } from '../components/Modal';
import { Conversation, PromptContent, apiGetConversations, apiRemoveConversation, apiSendPrompt, apiUpdatePrompts } from '../api/Assistant';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import SmartToySharpIcon from '@mui/icons-material/SmartToySharp';
import { Build, Delete, Height } from '@mui/icons-material';
import { desktop_h1, desktop_h3, desktop_labels_l1, desktop_p1, desktop_p3, montserratSemiBoldFontFamily } from '../styles/textStyles';
import { regularBtnStyles, whiteBlackBorderBtnStyles } from '../styles/buttons';
import Background from '../components/Background';
import { neutralColors, secondaryColors } from '../styles/colors';
import { trashIcon } from '../utils/icons';
import { Edit } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { trimText } from '../utils/helpers';

const Header = styled.div`
  margin-top:1em;
  font-size:2.0em;
`

const Line = styled.div`
  margin:2px;
`

const Answer = styled.div`
  position:relative;
  padding: 52px 16px 16px;
  margin-top:16px;
  margin-bottom:16px;
  border-radius:10px;
  font-size:0.9em;
  border: 1px ${neutralColors[200]} solid;
  margin-right: auto; /* Aligns the div to the left */
  text-align: left;
  max-width: 90%;

`

const User = styled.div`
  margin-top:1.0em;
  padding: 16px;
  color: ${neutralColors[950]};
  margin-bottom:1.0em;
  border: 1px ${neutralColors[50]} solid;
  background-color: ${neutralColors[50]};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: auto; /* Aligns the div to the right */
  text-align: left;
  max-width: 90%;
`

const Waiting = styled.div`
  font-size:4.5em;
  width:768px;
  resize:none;
  font-size:1.1em;
  padding: 24px 100px 24px 24px;
  border-radius:50px;
  box-sizing: border-box;
  background-color:white;
  outline:none;
  box-shadow: 0px 0px 3px #7f7f7f;
  border: 1px solid transparent;
`

const Content = styled.div`
  display:grid;
  grid-template-columns:1fr;
  column-gap:25px;
  position:relative;
`

const Current = styled.div`
  top:0px;
  left:0px;
  bottom:0px;
  display:grid;
  grid-template-rows: 1fr;
  position: relative;
`

const List = styled.div`  border: 1px solid green;`

const ConversationSelect = styled.div`
  margin-top:5px;
  padding: 16px;
  border-radius:5px;
  cursor:pointer;
  background-color: ${secondaryColors[50]};
  &:hover {
    background-color: ${secondaryColors[100]};
  }
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
`

const SelectedConversation = styled.div`
  margin-top:5px;
  padding: 16px;
  border-radius:10px;
  background-color:${secondaryColors[100]};
  color:#000;
  position:relative;
  user-select:none;
  border:1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
`

const Remove = styled.div`
  z-index:10;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModifyContainer = styled.div`
  position:absolute;
  top:0px;
  right:0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px ${neutralColors[100]} solid;
  width: 100%;
  align-items: center;
`

const LlmContainer = styled.div`
  padding-right:1em;
  display:flex;
  justify-content:flex-end;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`

const QuestionInput = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 1.1em;
  position: relative;
  padding: 24px 132px 24px 24px;
  border-radius: 50px;
  border: 1px solid lightgray;
  box-sizing: border-box;
  outline: none;
  &:focus {
    box-shadow: 0px 0px 3px #7f7f7f;
    border: 1px solid transparent;
  }
  max-height: 300px;
  border: 1px ${neutralColors[200]} solid;
`;

const ConversationContainer = styled.div`
  overflow-y:auto;
`

const QuestionContainer = styled.div`
  position:fixed;
  bottom: 30px;
  left:48%;
  width:768px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  `

const initialPrompts = [{ role: "assistant", content: "" }]

export const DigitalMarketingAssistantView = () => {
  const { selected } = useCustomerState()
  const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState("")
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [conversationId, setConversationId] = useState<string | undefined>()
  const [prompts, setPrompts] = useState<PromptContent[]>(initialPrompts)
  const [conversations, setConversations] = useState<Conversation[]>([])
  const [llm, setLLM] = useState<"ChatGPT" | "Palm2" | "Pena">("ChatGPT")
  const [editTextDialogOpen, setEditTextDialogOpen] = useState(false)
  const [editText, setEditText] = useState("")
  const [editIndex, setEditIndex] = useState(0)
  const [copyToClipboardIconTitle, setCopyToClipboardIconTitle] = useState("Copy answer")

  const askQuestion = () => {
    if (selected) {
      setLoading(true)
      setQuestion("")
      apiSendPrompt(selected.id, llm, prompts, conversationId).then((response) => {
        setLoading(false)
        setConversationId(response.conversationId)
        setPrompts(prompts.concat({ role: "assistant", content: response.reply, llm }))
        if (!conversations.find(c => c.id === conversationId)) {
          setConversations(conversations.concat(response.conversation))
        }
        document.getElementById("bottomMarker")?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      }).catch(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (selected) {
      apiGetConversations(selected.id).then(response => setConversations(response))
    }
  }, [selected])

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      prompts.push({ role: "user", content: question });
      setPrompts(prompts);
      askQuestion();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const renderContentWithFormatting = (content: string) => {
    return content.split('\n').map((line, index) => (
      <div key={index}>{line.trim() === "" ? <>&nbsp;</> : line}</div>
    ));
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyToClipboardIconTitle("Copied to clipboard.")
      setTimeout(() => {
        setCopyToClipboardIconTitle("Copy answer")
      }, 4000);
    }).catch(_ => {
      setCopyToClipboardIconTitle("Copy answer")
    });
  };

  return (<Grid container sx={{height:'100%'}}>
    <Grid item sx={{width: 'calc(100% - 319px)', p: '50px 32px 32px 50px'}}>
      <Box sx={{}}>
        <Typography variant="h1" sx={desktop_h1}>MAIA</Typography>
      </Box>
      <Box sx={{ mt: '12px' }}>
        <Typography variant='h3' sx={desktop_h3}>Marketing AI Assistant</Typography>
        <Typography sx={{...desktop_p3, mt:1}}>Hello, how can I assist you as a digital marketeer?</Typography>
      </Box>
      <Content>
        <Current>
          <ConversationContainer>
            {prompts.map((p, index) => {
              return p.role === "user" ? <User> {renderContentWithFormatting(p.content)} </User> : p.content && <Answer key={`prompt${index}`}>
                {renderContentWithFormatting(p.content)}
                {index > 0 && <ModifyContainer>
                  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '5px', pl: '12px'}}>
                    <Avatar
                      style={{ margin: "auto", width: '22px', height: '22px' }}
                      src={""}
                      alt="profilePicture"
                    />
                    <Typography sx={{...desktop_h3, fontFamily: montserratSemiBoldFontFamily}}>MAIA</Typography>
                  </Box>
                  <Box>
                    <Tooltip title="Edit answer" placement="top" arrow>
                      <IconButton onClick={() => {
                        setEditText(p.content)
                        setEditIndex(index)
                        setEditTextDialogOpen(true)
                      }}><Edit sx={{width: '20px', height: '20px'}} /></IconButton>
                    </Tooltip>
                    <Tooltip title={copyToClipboardIconTitle} placement="top" arrow>
                      <IconButton onClick={() => copyToClipboard(p.content)}>
                        <ContentCopyIcon sx={{width: '20px', height: '20px'}} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top" arrow>
                      <IconButton onClick={() => {
                        prompts.splice(index - 1, 2)
                        apiUpdatePrompts(selected!.id, prompts, conversationId).then(() => {
                          setPrompts(JSON.parse(JSON.stringify(prompts)))
                        })
                      }}>
                        <Delete sx={{width: '20px', height: '20px'}} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ModifyContainer>}

              </Answer>
            })}
          </ConversationContainer>
          <div id="bottomMarker"></div>
        </Current>
        <QuestionContainer>
            {!loading && <QuestionInput
                ref={textareaRef}
                rows={1}
                autoFocus
                value={question}
                onChange={handleInputChange}
                onKeyUp={handleKeyUp}
                placeholder="Type here..."
              />}
            {!loading && <LlmContainer>
              <ToggleButtonGroup size="small">
                <Tooltip title="ChatGPT" arrow placement='top'><ToggleButton value={true} selected={llm === "ChatGPT"} onClick={(_) => setLLM("ChatGPT")}><img src="chatgpt.png" height="20" /></ToggleButton></Tooltip>
                <Tooltip title="Palm 2" arrow placement='top'><ToggleButton value={true} selected={llm === "Palm2"} onClick={() => setLLM("Palm2")}><img src="palm2.png" height="20" /></ToggleButton></Tooltip>
                <Tooltip title="Pena" arrow placement='top'><ToggleButton value={true} selected={llm === "Pena"} onClick={() => setLLM("Pena")}><img src="kiva-logo-192.jpeg" height="20" /></ToggleButton></Tooltip>
              </ToggleButtonGroup>
            </LlmContainer>}
            {loading && <Waiting><AnimatedSpinner /></Waiting>}
          </QuestionContainer>
      </Content>

      <Dialog
        open={editTextDialogOpen}
        onClose={() => setEditTextDialogOpen(false)}
      >
        <DialogTitle>
          Edit Answer
        </DialogTitle>
        <DialogContent>
          <TextField multiline rows={8} style={{ minWidth: 500 }} value={editText} onChange={(ev) => setEditText(ev.target.value)} />
        </DialogContent>
        <FormControl>
          <DialogActions sx={{ gap: 1, mb: 1, mr: 1 }}>
            <Button sx={{...whiteBlackBorderBtnStyles}} onClick={() => { setEditTextDialogOpen(false) }}>Cancel</Button>
            <Button sx={{...regularBtnStyles}} component="span" onClick={() => {
              const newPrompts = prompts.map((p, index) => index === editIndex ? { ...p, userModified: true, content: editText } : p)
              apiUpdatePrompts(selected!.id, newPrompts, conversationId).then(() => {
                setPrompts(newPrompts)
              })

              setEditTextDialogOpen(false)
            }}>Set answer</Button>

          </DialogActions>
        </FormControl>
      </Dialog>
    </Grid>
 
    <Divider orientation="vertical" flexItem sx={{ width: '1px', margin: 0, background: neutralColors[300], minHeight: 'calc(100vh - 64px)' }} />

    {/* conversations list */}
    <Grid item sx={{width: 254, m: '0 32px' }}>
        {conversationId && <Box sx={{margin: '100px auto 0', display: 'flex', justifyContent:'flex-start'}}><Button sx={regularBtnStyles} onClick={() => {
          setConversationId(undefined)
          setPrompts([{ role: "assistant", content: "" }])
          setQuestion("")
        }}>
          Start new conversation
        </Button></Box>}
        <Box sx={{ mb: '24px', mt:'48px' }}>
          <Typography sx={desktop_p1}>Conversations</Typography>
        </Box>
        {conversations.map(c => c.id === conversationId && conversationId ?
          <SelectedConversation>
            <Box>
              <Typography sx={desktop_labels_l1}>{moment(c.dateTime).format("D.M.YYYY HH:mm")}</Typography>
              <Typography sx={desktop_labels_l1} style={{marginTop: '12px'}}>{trimText(c.prompts[1]?.content, 50)}</Typography>
            </Box>
            <Remove>
              <IconButton onClick={() => {
                apiRemoveConversation(selected!.id, c.id).then((response) => {
                  setConversations(response)
                  setPrompts(initialPrompts)
                  setConversationId(undefined)
                })
              }}>
                {trashIcon()}
              </IconButton>
            </Remove>
          </SelectedConversation>
          : <ConversationSelect>
            <div onClick={() => {
              setConversationId(c.id)
              setPrompts(c.prompts)
            }}>
              <Typography sx={desktop_labels_l1}>{moment(c.dateTime).format("D.M.YYYY HH:mm")}</Typography>
              <Typography sx={desktop_labels_l1} style={{marginTop: '12px'}}>{trimText(c.prompts[1]?.content, 50)}</Typography>
            </div>
            <Remove><IconButton onClick={() => apiRemoveConversation(selected!.id, c.id).then((response) => setConversations(response))}>{trashIcon()}</IconButton></Remove>
          </ConversationSelect>)}
    </Grid>

  </Grid>)
}