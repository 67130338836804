import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { apiGetLocations } from "../../../api/Marketing";
import { debouncePromise } from "../../../utils/Generators";
import { AdSetTargetCity } from "../../../context/meta/MetaTypes";

export type CitySelectProps = {
  customerId: number
  radius: number
  countryCode: string
  cities: AdSetTargetCity[]
  onSetCities: (cities: AdSetTargetCity[]) => void
}


const apiGetLocationsDebounced = debouncePromise(apiGetLocations, 1000)

export default function CitySelect(props: CitySelectProps) {
  const { customerId, countryCode, onSetCities, radius } = props
  const [open, setOpen] = React.useState(false);
  const [cities, setCities] = React.useState<AdSetTargetCity[]>(props.cities);
  const [options, setOptions] = React.useState<AdSetTargetCity[]>([]);
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")

  React.useEffect(() => {
    if (search.length > 1) {
      setLoading(true)
      apiGetLocationsDebounced(customerId, countryCode, "city", search).then((result: any) => {
        setOptions(result.locations)
        setLoading(false)
      })
    }
  }, [search]);


  return (
    <Autocomplete
      id="city-select"
      size="small"
      value={cities}
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(city, value) => city.name === value.name}
      getOptionLabel={(city) => `${city.name}${city.radius ? `(${city.radius}km)` : ''}`}
      onChange={(_, values) => {
        const valuesEx = values.map(v => ({ ...v, radius: v.radius || radius, distance_unit: "kilometer" }))
        setCities(valuesEx);
        onSetCities(valuesEx)
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          onChange={(e) => setSearch(e.target.value)}
          label="Cities"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}