import React from 'react';
import { useEffect, useState } from 'react'
import styled from 'styled-components';
import { getFacebookPosts } from '../../../api/Posts';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { ChangeEvent } from 'react'
import { Alert, AlertTitle, FormControl, Grid, Typography } from '@mui/material';
import { getEndDate } from '../../../utils/helpers'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import BasicPostBoosting from './BasicPostBoosting';
import AdvancedPostBoosting from './AdvancedPostBoosting';
import { seedPosts } from './seedPosts';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAllPosts, setMatchedPosts } from '../../../context/meta/MetaActions';
import { BoostingType } from '../../../context/meta/MetaTypes';
import { getMetaAuthStatus } from '../../../api/Marketing';
import { Link } from 'react-router-dom';

const Container = styled.div`
    margin-left:50px;
    margin-right:50px;
`

const Header = styled.div`
    margin-left:0.1em;
    margin-top:1em;
    margin-bottom:1.0em;
    font-size:2.0em;
`

const CreativeContainer = styled.div`
    flex-direction: row;
`

export type Post = {
  id: string | number,
  message: string,
  full_picture: string,
  created_time: any,
  message_tags: any[],
  attachments: {
    data: any[],
    paging?: any
  },
  is_eligible_for_promotion: boolean,
  is_published: boolean,
  reactions: {
    data: any[],
    paging: any,
    summary: {
      total_count: number,
      viewer_reaction: string | boolean
    }
  },
  comments: {
    data: any[],
    paging: any,
    summary: {
      order: string,
      total_count: number,
      can_comment: boolean
    }
  },
  permalink_url: string,
  promotable_id: string,
  type: string
}

const PostBoosterPage: React.FC<any> = () => {

  const [{ allPosts, matchedPosts }, dispatch] = useStateValue()
  const [boostingType, setSchedulingType] = useState<string>(BoostingType.ADVANCED)
  const state = useCustomerState()
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    dispatch(setMatchedPosts(allPosts))
  }, [])

  useEffect(() => {
    state?.selected?.id && getMetaAuthStatus(state?.selected?.id).then((metaAuthStatus) => {
      if (!metaAuthStatus.authorized) {
        setShowAlert(true)
      }
    }).catch(error => {
      console.error(error.message)
    });
  }, [state])

  const handleBoostingSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    setSchedulingType(value)
    if (value === 'period_of_time') {
      // Running time must be scheduled for at least 24 hours. Setting the number of days to 1 will practically provide less than 24 hours so set it to 1.01.
      const endDate: Date = getEndDate(1.01)
    }
  }

  return (
    <Container>
      <Header>
        <Grid>
          {showAlert && <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            To use this feature, please authorize Kiva Suite application from <b>Admin &rarr; Meta</b>. <strong><Link to="/admin">Click here to authorize!</Link></strong>
          </Alert>}
          <div>Post booster</div>
          <Typography sx={{ mt: 2, mb: 2, color: "#7B7B7B", fontSize: '14px' }} variant='body1'>Boost the already published posts by creating ads that improve their visibility.</Typography>
        </Grid>
      </Header>

      <CreativeContainer>
        <>
          {
            <Grid item xs={12} sx={{ marginLeft: '0.1em' }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Boosting process</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={boostingType}
                  onChange={handleBoostingSelection}
                >
                  <FormControlLabel disabled value={BoostingType.BASIC} control={<Radio />} label="Basic" />
                  <FormControlLabel value={BoostingType.ADVANCED} control={<Radio />} label="Advanced" />
                </RadioGroup>
              </FormControl>
            </Grid>
          }

          {boostingType === BoostingType.BASIC && <BasicPostBoosting />}
          {boostingType === BoostingType.ADVANCED && <AdvancedPostBoosting />}
        </>

      </CreativeContainer>



    </Container >
  )
}


export default PostBoosterPage;
