
import { Button, Grid, TextField, Typography } from '@mui/material';
import { apiGetBingAdsSettings, apiRemoveBingAdsSettings } from '../../api/Customer';
import { useEffect, useState, ChangeEvent } from 'react';
import { BingAdsSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiOAuthBingAdsLogin } from '../../api/Auth';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { redBtn, regularBtnStyles } from '../../styles/buttons';

const textFieldTextStyles = {
  mb: 0.3
}

const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}

export type BingAdminProps = {
  customerId: number
}

export const BingAdmin = (props: BingAdminProps) => {
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState<BingAdsSettings>({ accountIds: [''], customerId: '' })
  const [{}, alertDispatch] = useAlertStateValue()

  useEffect(() => {
    apiGetBingAdsSettings(props.customerId).then((bingAdsSettings: BingAdsSettings) => {
      setLoading(false)
      if (bingAdsSettings) {
        setSettings(bingAdsSettings)
      } else {
        setSettings({ accountIds: [], customerId: '' })
      }
    }).catch((err) => {
      console.log("Error in BingAdmin:apiGetBingAdsSettings: ", err)
    })
  }, [props.customerId])


  const handleCustomerIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, customerId: event?.target.value })
  }

  const handleAccountIdsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, accountIds: event?.target.value.split(",") })
  }

  const doBingAdsLogin = () => {
    if (settings.accountIds[0].length <= 4) {
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Please, provide proper account ID.", duration: null }))
    } else {
      if (props.customerId) {
        apiOAuthBingAdsLogin(props.customerId, settings).then((response:any) => {
          window.location.href = response.url
        }).catch((_:any) => {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in authentication. Please try again later.", duration: null }))
        })
      }
    }
  }

  const removeBingAdsLogin = () => {
    if (props.customerId) {
      apiRemoveBingAdsSettings(props.customerId).then(() => {
        setSettings({ accountIds: [], customerId: '' })
      }).catch((_:any) => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in removing authentication. Please try again later.", duration: null }))
      })
    }
  }

  return (
    <Grid container>
     {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
      <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>Customer Id</Typography>
          <TextField fullWidth size='small' variant="outlined" value={settings.customerId} onChange={handleCustomerIdChange} />
        </Grid>

        <Grid item xs={6} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>Account Ids</Typography>
          <TextField fullWidth size='small' variant="outlined" value={settings.accountIds.join(",")} onChange={handleAccountIdsChange} />
        </Grid>
      </Grid>
      <Grid container sx={{maxWidth:500}} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={gridItemStyles}>
          <Button sx={{ ...regularBtnStyles, mt: 1 }} fullWidth onClick={doBingAdsLogin}>Authenticate Ads</Button>
        </Grid>
        {settings.accountIds.length > 0 && <Grid item xs={6} sx={gridItemStyles}>
          <Button sx={{ ...redBtn, mt: 1 }} fullWidth onClick={removeBingAdsLogin}>Remove authorization</Button>
        </Grid>}
      </Grid>
    </Grid>}
  </Grid>
  )
}