import { Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { BudgetLevel } from '../../../context/meta/MetaTypes'
import { useLocation } from 'react-router-dom'
import { useStateValue } from '../../../context/meta/MetaContext'
import { setAdAutomationSettings, setAdvancedPostBoosting } from '../../../context/meta/MetaActions'

/**
 * @Component BudgetLevelComponent
 */
const BudgetLevelComponent = () => {
  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting, advancedPostBoostingErrors }, dispatch] = useStateValue()

  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')
  const [budgetLevel, setBudgetLevel] = useState(metaAdAutoLocation ? adAutomationSettings.budgetProps.budgetLevel : advancedPostBoosting.budgetProps.budgetLevel)

  const initialMonthlyBudget = metaAdAutoLocation ?
    adAutomationSettings.budgetProps.monthlyBudget :
    advancedPostBoosting.budgetProps.monthlyBudget
  const [useMonthlyBudget, setUseMonthlyBudget] = useState(typeof initialMonthlyBudget === "number")
  const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget || 1)

  const handleBudgetLevelChange = (e: ChangeEvent<HTMLInputElement>) => {
    const bl = e.target.value as BudgetLevel
    setBudgetLevel(bl)
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, budgetProps: { ...adAutomationSettings.budgetProps, budgetLevel: bl } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, budgetProps: { ...advancedPostBoosting.budgetProps, budgetLevel: bl } }))
    }
  }

  const handleMonthlyBudgetChange = (monthlyBudget: number | undefined) => {
    if (monthlyBudget) { setMonthlyBudget(monthlyBudget) }
    if (metaAdAutoLocation) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, budgetProps: { ...adAutomationSettings.budgetProps, monthlyBudget } }))
    } else {
      dispatch(setAdvancedPostBoosting({ ...advancedPostBoosting, budgetProps: { ...advancedPostBoosting.budgetProps, monthlyBudget } }))
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Budget</Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 2, color: "#7B7B7B", fontSize: '14px' }}>You can set the budget at either the campaign level or the ad set level. In addition you can set monthly limit for budget.</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormLabel id="">Limits</FormLabel>
        <Grid xs={4}>
          <FormControlLabel control={<Checkbox checked={useMonthlyBudget}
            onChange={(ev) => {
              setUseMonthlyBudget(ev.target.checked)
              handleMonthlyBudgetChange(ev.target.checked ? monthlyBudget : undefined)
            }} />} label="Set monthly budget" />
        </Grid>
        {useMonthlyBudget && <Grid xs={4}>
          <TextField
            id="standard-basic"
            variant="standard"
            type='number'
            name='Monthly maximum spend (€)'
            label='Monthly maximum spend (€)'
            fullWidth
            value={monthlyBudget}
            onChange={(ev) => handleMonthlyBudgetChange(parseFloat(ev.target.value))}
            InputLabelProps={{
              shrink: true
            }}
            sx={{ fontSize: '14px' }}
          />
        </Grid>}
      </Grid>
      <Grid item xs={12} sx={{ marginLeft: '0.1em' }}>
        <FormControl>
          <FormLabel id="">Budget level</FormLabel>
          <RadioGroup
            row
            aria-labelledby=""
            name="row-radio-buttons-group"
            value={budgetLevel}
            onChange={handleBudgetLevelChange}
          >
            <FormControlLabel value={BudgetLevel.CAMPAIGN_LEVEL} control={<Radio />} label="Campaign level" />
            <FormControlLabel value={BudgetLevel.AD_SET_LEVEL} control={<Radio />} label="Ad set level" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {budgetLevel === BudgetLevel.AD_SET_LEVEL && <>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: "#7B7B7B", fontSize: '14px' }}>Budget settings will be shown within "Create ad set" step.</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
          <Divider />
        </Grid>
      </>}
    </>
  )
}

export default BudgetLevelComponent
