//import { format } from "date-fns"
import { apiRootUrl, CreativeResponse } from "./Types"
import { getApi, postApi } from "./Utils"
import moment from 'moment'

export type GetCreativesParams = {
  searchKeyword: string | undefined
  startDate: Date
  endDate: Date
  sort: string
  direction: string
  statuses: string[] | undefined
  campaigns: string[]
  adGroups: string[]
  dimensions: string[]
  floodlightActivity: string | undefined
  showZeros: boolean
  categories: string[]
  start: number
  count: number
}

export type TotalCampaignSpend = {
  partnerId: string
  advertiserId: string
  campaignName: string
  campaignId: string
  category: string
  clicks: number
  spend: number
  impressions: number
  totalConversions: number
}

export type TotalPartnerSpend = {
  partnerId: string
  clicks: number
  spend: number
  impressions: number
  totalConversions: number
}

export type TotalAdvertiserSpend = {
  partnerId: string
  advertiserId: string
  clicks: number
  spend: number
  impressions: number
  totalConversions: number
}

export type TotalCreativeSpendsResponse = {
  campaignSpends: TotalCampaignSpend[];
  advertiserSpends: TotalAdvertiserSpend[];
  partnerSpends: TotalPartnerSpend[];
  spend: number
  clicks: number
  impressions: number
  totalConversions: number
}

export type TotalCreativeSpendsTreeResponse = {
  customerId: string
  name: string
  spendReport: TotalCreativeSpendsResponse
  children: TotalCreativeSpendsTreeResponse[]
}

export type ApiGetChartMetricsParams = {
  startDate: string
  endDate: string
  searchKeyword: string | undefined
  statuses: string[] | undefined
  campaigns: string[]
  categories: string[]
  dimensions: string[]
  sort: string
  start: number
  direction: string,
  count: number
}

export const apiGetCreatives = async (customerId: number, params: GetCreativesParams): Promise<CreativeResponse> => {
  return postApi(`creative/customer/${customerId}`, {
    ...params,
    startDate: moment(params.startDate.toISOString()).format("YYYY-MM-DD"),
    endDate: moment(params.endDate.toISOString()).format("YYYY-MM-DD"),
  }, { start: params.start, total: 0, dimensions: {}, creatives: [], campaigns: [], adGroups: [], floodlightActivities: [], categoriesImpressions: {} })
}

export const apiGetSpends = async (customerId: number, startDate: string, endDate: string): Promise<TotalCreativeSpendsResponse> => {
  return await postApi<TotalCreativeSpendsResponse>(`creative/customer/${customerId}/spends`, {
    startDate,
    endDate
  }, {
    spend: 0, clicks: 0, totalConversions: 0, impressions: 0,
    partnerSpends: [], advertiserSpends: [], campaignSpends: []
  })
}
export const apiGetChartMetrics = async (customerId: number, params: ApiGetChartMetricsParams): Promise<any> => {
  return await postApi<any>(`creative/customer/${customerId}/chartMetrics`, params)
}

export const apiGetSpendsTree = async (customerId: number, startDate: string, endDate: string): Promise<TotalCreativeSpendsTreeResponse> => {
  return await postApi<TotalCreativeSpendsTreeResponse>(`creative/customer/${customerId}/spendsTree`, {
    startDate,
    endDate
  }, {
    customerId: customerId.toString(),
    name: "",
    spendReport: {
      spend: 0, clicks: 0, totalConversions: 0, impressions: 0,
      partnerSpends: [], advertiserSpends: [], campaignSpends: []
    },
    children: []
  })
}

export const apiExportSpends = async (customerId: number, startDate: string, endDate: string): Promise<{ url?: string, redirectUrl?: string }> => {
  return await postApi<{ url?: string, redirectUrl?: string }>(`creative/customer/${customerId}/spends/export`, { startDate, endDate })
}

export const apiFlushCache = async (): Promise<void> => {
  await getApi(`creative/flushcache`)
}