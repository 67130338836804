import React, { ChangeEvent, useEffect, useState } from 'react'
import LineChart from './LineChart'
import BarChart from './BarChart'
import { useCustomerState } from '../../context/customer/CustomerContext'
import { capitalizeFirstLetter, formatDateAsYearMonthDay } from '../../utils/helpers'
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { ChartDataObj, CreativeChartMetrics } from './Types'
import { primaryColors } from '../../styles/colors'

export type ChartsProps = {
  metrics: CreativeChartMetrics[]
  loading: boolean
}

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'Septemper',
  'October',
  'November',
  'December'
];

const Charts = (props: ChartsProps) => {
  const [currentYearMetrics, setCurrentYearMetrics] = useState<CreativeChartMetrics[]>([])
  const [lastYearMetrics, setLastYearMetrics] = useState<CreativeChartMetrics[]>([])

  const [currentYearChartData, setCurrentYearChartData] = useState<ChartDataObj[]>([])
  const [lastYearChartData, setLastYearChartData] = useState<ChartDataObj[]>([])
  const [currentMetric, setCurrentMetric] = useState<keyof CreativeChartMetrics>("impressions")

  const [chartType, setChartType] = useState('bar')

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: currentMetric === "totalConversions" ? "Total conversions" : capitalizeFirstLetter(currentMetric),
      }
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Last year',
        data: lastYearChartData,
        borderColor: primaryColors[200],
        backgroundColor: primaryColors[200],
      },
      {
        label: 'Current year',
        data: currentYearChartData,
        borderColor: primaryColors[500],
        backgroundColor: primaryColors[500],
      }
    ],
  };

  useEffect(() => {
    handleApiMetrics(props.metrics)
  }, [props.metrics])

  const currentDate = new Date();

  const handleApiMetrics = (metrics: CreativeChartMetrics[]) => {
    let currentYearMetrics: CreativeChartMetrics[] = [];
    let lastYearMetrics: CreativeChartMetrics[] = [];

    metrics.map((item) => {
      if (item.month.includes(currentDate.getFullYear().toString())) {
        currentYearMetrics.push(item)
      } else {
        lastYearMetrics.push(item)
      }
    })

    setCurrentYearMetrics(currentYearMetrics)
    setLastYearMetrics(lastYearMetrics)

    const currentYearChartData: ChartDataObj[] = Array.from({ length: labels.length }, (_, index) => {
      const month = `${currentDate.getFullYear()}-${(index + 1).toString().padStart(2, '0')}`;
      const monthMetrics: CreativeChartMetrics | undefined = currentYearMetrics.find(item => item.month === month);
      return {
        x: labels[index],
        y: monthMetrics ? monthMetrics[currentMetric] : new Date(month) <= new Date(currentDate.getFullYear(), currentDate.getMonth() - 1) ? 0 : null
      };
    });

    const lastYearChartData: ChartDataObj[] = Array.from({ length: labels.length }, (_, index) => {
      const month = `${currentDate.getFullYear() - 1}-${(index + 1).toString().padStart(2, '0')}`;
      const monthMetrics = lastYearMetrics.find(item => item.month === month);
      return {
        x: labels[index],
        y: monthMetrics ? monthMetrics[currentMetric] : 0
      };
    });

    setCurrentYearChartData(currentYearChartData)
    setLastYearChartData(lastYearChartData)
   
  }


  const handleMetricChange = (e: ChangeEvent<HTMLInputElement> ) => {
    const value = e.target.value as keyof CreativeChartMetrics;
    setCurrentMetric(value)
    
    const currentYearChartData: ChartDataObj[] = Array.from({ length: labels.length }, (_, index) => {
      const month = `${currentDate.getFullYear()}-${(index + 1).toString().padStart(2, '0')}`;
      const monthMetrics = currentYearMetrics.find(item => item.month === month);
      return {
        x: labels[index],
        y: monthMetrics ? monthMetrics[value] : new Date(month) <= new Date(currentDate.getFullYear(), currentDate.getMonth() - 1) ? 0 : null
      };
    });

    const lastYearChartData: ChartDataObj[] = Array.from({ length: labels.length }, (_, index) => {
      const month = `${currentDate.getFullYear() - 1}-${(index + 1).toString().padStart(2, '0')}`;
      const monthMetrics = lastYearMetrics.find(item => item.month === month);
      return {
        x: labels[index],
        y: monthMetrics ? monthMetrics[value] : 0
      };
    });

    setCurrentYearChartData(currentYearChartData)
    setLastYearChartData(lastYearChartData)
  }

  const handleChartTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setChartType(value)
  }

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant='body2' sx={{mb: 2}}>Applied filters: keyword, channels, campaigns statuses and dimensions. </Typography>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Chart type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={chartType}
            onChange={handleChartTypeChange}
          >
            <FormControlLabel value="line" control={<Radio />} label="Line" />
            <FormControlLabel value="bar" control={<Radio />} label="Bar" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{mt: 4}}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Current Metric</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={currentMetric}
            onChange={handleMetricChange}
          >
            <FormControlLabel value="impressions" control={<Radio />} label="Impressions" />
            <FormControlLabel value="clicks" control={<Radio />} label="Clicks" />
            <FormControlLabel value="totalConversions" control={<Radio />} label="Total conversions" />
            <FormControlLabel value="spend" control={<Radio />} label="Spend (€)" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {!props.loading && <Grid item xs={12} sx={{mt: 2, mb: 2}}>
        {chartType === "line" && <LineChart options={options} data={data} />}
        {chartType === "bar" && <BarChart options={options} data={data} />}
      </Grid>}
      {props.loading && <Grid container xs={12} sx={{mt: 2, mb: 2, minHeight: 600, alignItems: 'center', justifyContent:'center'}}>
        <CircularProgress style={{ width: '36px', height: '36px', color: '#000' }}/>
      </Grid>}

    </div>
  )
}

export default Charts




