import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { neutralColors, primaryColors } from '../styles/colors';
import { infoIcon } from '../utils/icons';

export type CreativePreviewAdditionalInfoDialogProps = {
  headlines: string[],
  descriptions: string[]
}

const CreativePreviewAdditionalInfoDialog = (props: CreativePreviewAdditionalInfoDialogProps) => {

  const { headlines, descriptions } = props
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} sx={{ background: 'rgba(255,255,255,1)', padding: '6px'  }}>
        {infoIcon()}
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "480px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant='h5' sx={{ mt: 2, mb: 2 }}>Additional info</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant='h6' sx={{ mb: 2 }}>Headlines</Typography>
            {headlines && headlines.length > 0 ?
              headlines.map((h, index) => <Typography key={index} variant='body2'>{h}</Typography>)
              :
              <Typography variant='body2'>Not found</Typography>
            }
          </Box>
          <Box>
            <Typography variant='h6' sx={{ mt: 2, mb: 2 }}>Descriptions</Typography>
            {descriptions && descriptions.length > 0 ?
              descriptions.map((d, index) => <Typography key={index} variant='body2'>{d}</Typography>)
              :
              <Typography variant='body2'>Not found</Typography>
            }
          </Box>
        </DialogContent>
        <DialogActions sx={{ pb: 1.5, pr: 1.5 }}>
          <Button autoFocus onClick={handleClose} sx={{
            padding: '10px 16px',
            borderRadius: '20px',
            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
            fontSize: '14px',
            lineHeight: '100%',
            textTransform: 'none',
            color: '#000',
            background: neutralColors[100],
            border: 'none',
            '&:hover': {
              border: 'none',
              background: neutralColors[100]
            }
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CreativePreviewAdditionalInfoDialog