import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useAlertStateValue } from '../context/alert/AlertContext';
import { removeAlert } from '../context/alert/AlertAction';

const CustomAlert = () => {

  const [{ alert }, alertDispatch] = useAlertStateValue()
  const handleClose = () => {
    alertDispatch(removeAlert())
  };

  const styles = {
    maxWidth: '600px',
    '&.MuiSnackbar-anchorOriginBottomLeft': { left: '50% !important', transform: 'translateX(-50%)' },
    //'& .css-3yjvh5-MuiPaper-root-MuiAlert-root': { backgroundColor: alert.severity === "success" ? '#378E3B !important': '#da4e4b !important'},
    '& .css-1pxa9xg-MuiAlert-message': { color: '#fff !important' },
  }

  const getBackroundColor = () => {
    let color = ''
    switch (alert.severity) {
      case "success":
        color = '#5EAD64 !important'
        break;
      case "error":
        color = '#da4e4b !important'
        break;
      case "info":
        color = '#81d4fa !important'
        break;
      case "warning":
        color = '#ffab00 !important'
        break;
      default:
        break;
    }
    return color
  }

  return (
    <Snackbar open={alert.open} autoHideDuration={alert.duration} onClose={handleClose} sx={styles}>
      <Alert onClose={handleClose} severity={alert.severity} sx={{
        width: '100%',
        backgroundColor: getBackroundColor(),
        '& .MuiAlert-icon': {
          color: "#fff !important"
        },
        '& .MuiAlert-message': {
          color: "#fff !important"
        },
        '& .MuiSvgIcon-root': {
          color: "#fff !important"
        }
      }}>
        {alert.message}
      </Alert>
    </Snackbar>
  )
}


export default CustomAlert;