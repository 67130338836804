import styled from 'styled-components';

const Div = styled.div`
    display:none;
`

export function showTooltip(evt: any, id: string, innerHTML?: string) {
  const tooltip = document.getElementById(id);
  const container = document.getElementById(id + "_container")
  if (tooltip && container) {
    const pageX = evt.pageX
    const pageY = evt.pageY
    tooltip.innerHTML = innerHTML || container.innerHTML

    tooltip.style.display = "block";
    tooltip.style.left = pageX + 10 + 'px';
    tooltip.style.top = pageY + 10 + 'px';
  }
}

export function hideTooltip(id: string) {
  const tooltip = document.getElementById(id);
  if (tooltip) {
    tooltip.style.display = "none";
  }
}

export type TooltipProps = {
  id: string
  children: React.ReactNode
}

export const Tooltip = (props: TooltipProps) => {
  const element = document.createElement("div")
  element.id = props.id
  element.className = "tooltip"
  element.style.display = "none"
  document.body.appendChild(element)
  return <Div id={props.id + "_container"}>{props.children}</Div>
}