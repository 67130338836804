import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from '@mui/material';
import { setAdAutomationSettings } from '../../../context/meta/MetaActions';
import TargetingPlacements from './TargetingPlacements';
import { AdSet, AdSetTargetCity, SavedAudience } from '../../../context/meta/MetaTypes';
import { getSavedAudiences } from '../../../api/Marketing';
import { countryCodes } from '../../Utils';
import CitySelect from './CitySelect';
import { Refresh } from '@mui/icons-material';
import styled from 'styled-components';


const SelectRefresh = styled.div`
  display:grid;
  grid-template-columns:1fr 2em;
  align-items:bottom;
`

export type AdSetTargetingDialogProps = {
  customerId: number,
  dialogOpen: boolean,
  adSet: AdSet
  onCloseDialog: (update: boolean, adSet?: AdSet) => void,
}


/**
 * @description This component is used to set the ad set targeting for both the ad automation and the advanced post booster.
 */
const AdSetTargetingDialog = (props: AdSetTargetingDialogProps) => {
  const { customerId, dialogOpen, onCloseDialog } = props

  const [adSet, setAdSet] = useState<AdSet>(props.adSet)
  const [minAge, setMinAge] = useState<number>(adSet.targeting.age_min)
  const [maxAge, setMaxAge] = useState<number>(adSet.targeting.age_max)
  const [genders, setGenders] = useState<number[]>(adSet.targeting.genders)
  const [cities, setCities] = useState<AdSetTargetCity[]>(adSet.targeting.geo_locations.cities || [])
  const [countries, setCountries] = useState<string[]>(adSet.targeting.geo_locations.countries || [])
  const [minAgeError, setMinAgeError] = useState(false)
  const [maxAgeError, setMaxAgeError] = useState(false)
  const [cityRadius, setCityRadius] = useState(adSet.defaultCityRadius || 40)

  const [savedAudiences, setSavedAudienced] = useState<any[]>([])
  const [loadingAudiences, setLoadingAudiences] = useState(true)

  const [audience, setAudience] = useState<string>(adSet.audience)
  const [currentSavedAudienceId, setCurrentSavedAudienceId] = useState<string>(adSet.currentSavedAudienceId)
  const [currentSavedAudience, setCurrentSavedAudience] = useState<SavedAudience | undefined>(adSet.currentSavedAudience)

  function reloadAudiences(useCache: boolean) {
    setLoadingAudiences(true)
    getSavedAudiences(customerId, useCache).then((result) => {
      setSavedAudienced(result.savedAudiences)
      setLoadingAudiences(false)
    }).catch(() => {
      setLoadingAudiences(false)
    });
  }


  useEffect(() => {
    reloadAudiences(true)
  }, [customerId])

  const handleUseSavedAudienceOrSetAudienceManually = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelection: string = event.target.value
    setAudience(newSelection)

    if (newSelection.toString() === 'manually') {
      setCurrentSavedAudienceId('')
      setCurrentSavedAudience(undefined)
      setAdSet({
        ...adSet,
        targeting: {
          ...adSet.targeting,
          age_min: Number(minAge),
          age_max: Number(maxAge),
          genders: (genders.length > 1 || genders.includes(0)) ? [1, 2] : genders,
          geo_locations: { countries },
          flexible_spec: [],
          locales: []
        },
        currentSavedAudienceId: '',
        currentSavedAudience: undefined,
        audience: newSelection
      })
    } else {
      setAdSet({
        ...adSet,
        currentSavedAudienceId: '',
        currentSavedAudience: undefined,
        audience: newSelection
      })
    }
  }

  const handleSavedAudienceSelection = (e: SelectChangeEvent<any>) => {
    const id: string = e.target.value
    const selected: SavedAudience = savedAudiences.find((sa: SavedAudience) => sa.id === id)
    setCurrentSavedAudienceId(id)
    setCurrentSavedAudience(selected)
    const savedAudienceTargeting = selected.targeting;

    const { age_min, age_max, genders, geo_locations, ...adSetTargetingOmittedManualAudience } = adSet.targeting
    setAdSet({
      ...adSet,
      targeting: {
        ...savedAudienceTargeting, ...adSetTargetingOmittedManualAudience,
      },
      currentSavedAudienceId: id,
      currentSavedAudience: selected
    })
  }

  const handleMinAgeChange = (e: any) => {
    const minA = e.target.value;
    setMinAge(minA)
    if (Number(minA) && Number(minA) >= 13 && Number(minA) <= 80) {
      setMinAgeError(false)
    } else {
      setMinAgeError(true)
    }
    setAdSet({
      ...adSet,
      targeting: {
        ...adSet.targeting,
        age_min: Number(minA)
      }
    })
  }

  const handleMaxAgeChange = (e: any) => {
    const maxA = e.target.value;
    setMaxAge(maxA)
    if (Number(maxA) && Number(maxA) >= 13 && Number(maxA) <= 80) {
      setMaxAgeError(false)
    } else {
      setMaxAgeError(true)
    }
    setAdSet({
      ...adSet,
      targeting: {
        ...adSet.targeting,
        age_max: Number(maxA)
      }
    })
  }

  const handleGendersChange = (e: SelectChangeEvent<number>) => {
    const g = e.target.value as number
    setGenders([g])
    if (Number(g) === 0) {
      setAdSet({ ...adSet, targeting: { ...adSet.targeting, genders: [1, 2] } })
    } else {
      setAdSet({ ...adSet, targeting: { ...adSet.targeting, genders: [g] } })
    }
  }

  const handleCountriesChange = (e: SelectChangeEvent<string>) => {
    const newCountries = [e.target.value]
    setCountries(newCountries)
    const targeting = adSet.targeting
    setAdSet({
      ...adSet, targeting: {
        ...targeting,
        geo_locations: { ...targeting.geo_locations, countries: newCountries }
      }
    })
  }

  const handleCitiesChange = (newCities: AdSetTargetCity[]) => {
    const targeting = adSet.targeting
    setCities(newCities)
    setAdSet({
      ...adSet, targeting: {
        ...targeting,
        geo_locations: { ...targeting.geo_locations, cities: newCities }
      }
    })
  }

  const handleCityRadiusChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRadius = parseInt(ev.target.value, 10)
    setCityRadius(newRadius)
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => onCloseDialog(false)}
    >
      <DialogTitle>
        Change adset targeting
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12}>
            <Typography variant='h6'>Audience</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Use saved audience or set audience manually</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={audience}
                onChange={handleUseSavedAudienceOrSetAudienceManually}
              >
                <FormControlLabel value="useSavedAudience" control={<Radio />} label="Use saved audience" />
                <FormControlLabel value="manually" control={<Radio />} label="Set audience manually" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {audience === 'useSavedAudience' && <>
            <Grid item xs={6}>
              <SelectRefresh>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                  <InputLabel id="custom-audience-select-label">Audience</InputLabel>
                  <Select
                    disabled={loadingAudiences}
                    labelId="custom-audience-select-label"
                    id="custom-audience"
                    value={currentSavedAudienceId}
                    onChange={handleSavedAudienceSelection}
                    label="Audience"
                  >
                    {savedAudiences?.length > 0 && savedAudiences.map((sa: SavedAudience) => {
                      return <MenuItem
                        sx={{ fontSize: '14px' }}
                        key={sa.id}
                        value={sa.id}>
                        {`${sa.name} - (id: ${sa.id})`}
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
                {loadingAudiences ? <CircularProgress style={{ width: '1.0em', height: '1.0em', marginTop: '1.5em', marginLeft: '0.5em' }} /> : <IconButton style={{ marginTop: '0.5em' }} onClick={() => reloadAudiences(false)}><Refresh /></IconButton>}
              </SelectRefresh>
            </Grid>
            {currentSavedAudience && <>
              <Grid item xs={12}>

                <Typography style={styles.body2} variant='body2'>
                  Run status
                </Typography>
                <Typography style={styles.caption} variant='caption'>
                  {currentSavedAudience.run_status}
                </Typography>

                <Typography style={styles.body2} variant='body2'>
                  Estimated Audience Size
                </Typography>
                <Typography style={styles.caption} variant='caption'>
                  {currentSavedAudience.approximate_count_lower_bound !== -1 ? currentSavedAudience.approximate_count_lower_bound : ' '}
                  {' '} - {' '}
                  {currentSavedAudience.approximate_count_upper_bound !== -1 ? currentSavedAudience.approximate_count_upper_bound : ' '}
                </Typography>

                {currentSavedAudience.sentence_lines?.map((sl: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <Grid item xs={12}>
                        <Typography style={styles.body2} variant='body2'>
                          {sl.content}
                        </Typography>
                        <Typography style={styles.caption} variant='caption'>
                          {sl.children.map((item: string) => item)}
                        </Typography>
                      </Grid>
                    </Fragment>)
                })}
              </Grid>
            </>}

          </>}

          {audience === 'manually' && <>
            <Grid item xs={12}>
              <Typography variant='h6'>Age and gender</Typography>
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="min-age"
                label="Min age"
                name='Min age'
                variant="standard"
                fullWidth
                value={adSet.targeting.age_min}
                onChange={handleMinAgeChange}
                InputLabelProps={{
                  shrink: true
                }}
                error={minAgeError}
                helperText={minAgeError ? "Enter an age between 13 and 80." : ""}
                sx={{ fontSize: '14px' }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="max-age"
                label="Max age"
                name='Max age'
                variant="standard"
                fullWidth
                value={maxAge}
                onChange={handleMaxAgeChange}
                InputLabelProps={{
                  shrink: true
                }}
                error={maxAgeError}
                helperText={maxAgeError ? "Enter an age between 13 and 80." : ""}
                sx={{ fontSize: '14px' }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={(genders.length > 1 || genders.includes(0)) ? 0 : genders[0]}
                  onChange={handleGendersChange}
                  label="Budget type"
                >
                  <MenuItem sx={{ fontSize: '14px' }} value={0}>Both</MenuItem>
                  <MenuItem sx={{ fontSize: '14px' }} value={1}>Male</MenuItem>
                  <MenuItem sx={{ fontSize: '14px' }} value={2}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>Locations</Typography>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="countries-select-label">Countries</InputLabel>
                <Select
                  labelId="countries-select-label"
                  id="countries-select"
                  value={countries[0]}
                  onChange={handleCountriesChange}
                  label="Countries"
                >
                  {countryCodes.map(cc => <MenuItem sx={{ fontSize: '14px' }} value={cc.code}>{cc.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                {customerId && <CitySelect customerId={customerId} radius={cityRadius} countryCode={countries[0]} cities={cities}
                  onSetCities={handleCitiesChange} />}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                {customerId &&
                  <TextField variant="standard" label="City radius(km)" id="radius-input"
                    type="number" value={cityRadius} onChange={handleCityRadiusChange} />}
              </FormControl>
            </Grid>
          </>}
          <TargetingPlacements />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onCloseDialog(false)}>Cancel</Button>
        <Button variant="contained" color="error" onClick={() => {
          onCloseDialog(true, adSet)
        }}>Change</Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  body1: {
    margin: '10px auto',
    color: '#000'
  },
  body2: {
    margin: '10px auto'
  },
  caption: {
    color: '#7d7d7d'
  },
}

export default AdSetTargetingDialog;