import { ChangeEvent } from 'react';
import {
  Button,
  FormControl, Grid, TextField
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AdsSearchBtn from './../AdsSearchBtn';

export type KeywordSearchProps = {
  keyword: string | undefined,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleAdsSearchBtnClick: () => void
}

const KeywordSearch = ({ keyword, onChange, handleAdsSearchBtnClick }: KeywordSearchProps) => {
  return (
    <Grid container sx={{ alignItems: 'center' }} xs={12} columnSpacing={'16px'}>
      <Grid item xs={8}>
        <FormControl fullWidth sx={{}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField value={keyword} label="Keyword" onChange={onChange} size="small"  sx={{border:'none'}} />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <AdsSearchBtn handleAdsSearchBtnClick={handleAdsSearchBtnClick}/>
      </Grid>
    </Grid>
  )
}

export default KeywordSearch