import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react';
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { closeIcon } from '../../../utils/icons';
import { neutralColors } from '../../../styles/colors';
import { desktop_h2, desktop_p2 } from '../../../styles/textStyles';
import { StyledMenu } from './../StyledMenu';
import FilterField from './../FilterField';
import AdsSearchBtn from './../AdsSearchBtn';
import { checkboxClasses } from "@mui/material/Checkbox";
import { useStateValue } from '../../../context/ads/AdsContext';
import { setAdUnits } from '../../../context/ads/AdsActions';


export type DimensionsProps = {
  handleAdsSearchBtnClick: () => void
}

const Dimensions = ({ handleAdsSearchBtnClick }: DimensionsProps) => {

  const [{ adUnits }, dispatch] = useStateValue()

  const { dimensions, allDimensions } = adUnits

  const selectSingleDimension = (value: string) => {
    dispatch(setAdUnits({ ...adUnits, dimensions: [value] }))

  }
  const selectAllDimensions = () => {
    dispatch(setAdUnits({ ...adUnits, dimensions: [] }))
  }

  const updateStateDimensions = (dimensions: string[]) => {
    dispatch(setAdUnits({
      ...adUnits,
      dimensions: dimensions,
      start: 0
    }))
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleAdsSearchBtnClick();
  };

  const [selectAll, setSelectAll] = useState<boolean>(dimensions.length === 0 ? true : false)

  const [filter, setFilter] = useState('')

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  const handleSelectAllCampaigns = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      selectAllDimensions()
    } else {
      dispatch(setAdUnits({ ...adUnits, dimensions: dimensions }))
    }
    setSelectAll(checked)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const i = event.target.value;
    let updatedDimensions = null;

    if (checked && (dimensions).indexOf(i) === -1) {
      updatedDimensions = [...dimensions, i]
    } else {
      updatedDimensions = dimensions.filter(d => d !== i)
    }
    dispatch(setAdUnits({ ...adUnits, dimensions: updatedDimensions }))

    if (updatedDimensions?.length === allDimensions.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={''}
        sx={{
          textTransform: 'none',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
          background: "#fff",
          minWidth:'124px',
          color: "#000",
          borderRadius: '5px',
          border: '0.5px solid #000',
          ":hover": { background: '#fff' }
        }}
      >
        Dimensions {selectAll ? "(all)" : `(${dimensions.length})`}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <Grid container sx={{ padding: '32px', width: '100%', background: neutralColors[50] }}>

          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
            <Box >
              <Typography variant='h2' sx={desktop_h2}>Dimensions</Typography>

            </Box>
            <Box sx={{}}>

              <IconButton onClick={handleClose}>
                {closeIcon()}
              </IconButton>

            </Box>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <FilterField filter={filter} handleFilterChange={handleFilterChange} />
            </Box>
            <Box>
              <FormControlLabel
                label={"All"}
                sx={desktop_p2}
                control={<Checkbox checked={selectAll} onChange={handleSelectAllCampaigns}
                  sx={{}}
                />}
              />
            </Box>
            {allDimensions.filter(i => i.title.toString().toLowerCase().includes(filter?.toLowerCase())).map((i: any) => {
              const checked = (dimensions?.length === 0 && selectAll) ? true : (dimensions).indexOf(i.id) > -1;

              return (<Grid key={`${i.id}`} container sx={{ justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}  >
                <FormControlLabel
                  label={i.title}
                  sx={desktop_p2}
                  control={<Checkbox checked={checked} value={i.id} onChange={handleCheckboxChange}
                    sx={{}}
                  />}
                />

                <Button onClick={(event) => {
                  event.stopPropagation();
                  if (checked && (dimensions.length === 1)) {
                    selectAllDimensions()
                    setSelectAll(true)
                  }
                  else {
                    selectSingleDimension(i.id)
                    setSelectAll(false)
                  }
                }}
                  sx={desktop_p2}
                >

                  {checked && (dimensions.length === 1) ? "ALL" : "ONLY"}
                </Button>
              </Grid>)

            })}
          </Box>

          <Grid container sx={{ justifyContent: 'right', marginTop: '64px' }} >
            <AdsSearchBtn handleAdsSearchBtnClick={handleClose} />
          </Grid>

        </Grid>



      </StyledMenu>
    </div>
  );
}

export default Dimensions