import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { KivaSuiteLogo } from '../components/Logo';
import { useAuthDispatch, useAuthState, useGoogleAuth } from '../context/auth/AuthContext';
import { CustomersDropdown } from './../components/CustomersDropdown';
import { useCustomerDispatch, useCustomerState } from '../context/customer/CustomerContext';
import { Link, useHistory } from "react-router-dom";
import { auth0Logout } from '../context/auth/AuthActions';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Grid } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { drawerWidth } from '.';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useCompetitorStateValue } from '../context/competitors/CompetitorContext';
import { apiGetMetaSettings } from '../api/Customer';
import { setCompetitorAdUnits, setCompetitorFacebookPages } from '../context/competitors/CompetitorActions';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface TopAppBarProps {
  open: any,
  setOpen: any
}

/**
 * TopAppBar component
 */
const TopAppBar = ({ open, setOpen }: TopAppBarProps) => {
  const auth = useAuthState()
  const dispatch = useCustomerDispatch()
  const { customers, selected } = useCustomerState()
  const history = useHistory();
  const googleAuth = useGoogleAuth()
  const authDispatch = useAuthDispatch()
  const { logout } = useAuth0()

  const [{ competitorAdUnits }, competitorDispatch] = useCompetitorStateValue()
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if(selected && selected.id){
      apiGetMetaSettings(selected.id).then(res => {
        if(res) {
          const fPages = res.competitorFacebookPages || []
          competitorDispatch(setCompetitorFacebookPages(fPages))
          competitorDispatch(setCompetitorAdUnits({...competitorAdUnits, facebookPageIds: fPages?.map(p => (p.id))}))
        }
      }).catch(err => {
        console.log("apiGetMetaSettings error: ", err);
      });
    }
  }, [selected])

  const [popoverAnchorEl, setPopoverAnchorElp] = React.useState<HTMLButtonElement | null>(null);

  const handleAccountCircleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElp(event.currentTarget);
  };

  const handlePopovertClose = () => {
    setPopoverAnchorElp(null);
  };

  const openPopover = Boolean(popoverAnchorEl);
  const popoverid = openPopover ? 'simple-popover' : undefined;

  return (
    <AppBar position="fixed" open={open} sx={{ backgroundColor: '#fff', borderBottom: '1px solid rgba(0,0,0,0.12)', color: '#333', boxShadow: 'none' }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Box component="div" sx={{ flexGrow: 1 }}>

          <Grid container sx={{ alignItems: 'center', justifyItems: 'center' }}>

            {!open && <Grid container sx={{ ml:1, width: '140px', alignItems: 'center', justifyItems: 'center' }}>
              <Link to={auth.isSignedIn ? '/' : '/login'} style={{ display: 'flex', alignItems: 'center'}}><KivaSuiteLogo width='124px' /></Link>
            </Grid>}
            <Grid item sx={{}}>
              {auth.isSignedIn && <CustomersDropdown dispatch={dispatch}
                authDispatch={authDispatch}
                customers={customers}
                isAdmin={true}
                googleAuth={googleAuth}
                selected={selected} />}
            </Grid>
          </Grid>
        </Box>

        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountCircleClick}
            color="inherit"
          >
            <Avatar
              style={{ margin: "auto" }}
              src={auth.user.picture ? auth.user.picture : ""}
              alt="profilePicture"
            />
          </IconButton>

          <Popover
            id={popoverid}
            open={openPopover}
            anchorEl={popoverAnchorEl}
            onClose={handlePopovertClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ padding: '5px', width: 260 }}>
              <Grid style={{ marginTop: 16 }}>
                <Avatar
                  style={{ margin: "auto", width: '60px', height: '60px' }}

                  src={auth.user.picture ? auth.user.picture : ""}
                  alt="profilePicture"
                />
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginTop: 16 }}
                >
                  {auth.user.name}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ marginBottom: 16 }}
                >
                  {auth.user.email}
                </Typography>
              </Grid>
              <Divider style={{ marginBottom: 5 }} />
              <MenuItem onClick={() => {history.push('/profile'); handlePopovertClose();}}>
                <AccountCircleIcon style={{ marginRight: 10 }} />{" "}
                Your profile
              </MenuItem>
              <MenuItem onClick={() => {history.push('/contact-us'); handlePopovertClose();}}>
                <HelpCenterIcon style={{ marginRight: 10 }} />{" "}
                Contact us
              </MenuItem>
              <MenuItem onClick={() => auth0Logout(logout, authDispatch)}>
                <LogoutIcon style={{ marginRight: 10, marginLeft: '2px' }} />{" "}
                Logout
              </MenuItem>
            </Box>
          </Popover>

        </div>

      </Toolbar>
    </AppBar>
  )
}

export default TopAppBar