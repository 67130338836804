import { Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { getFacebookPosts } from '../../../api/Posts'
import { setAlert } from '../../../context/alert/AlertAction'
import { useAlertStateValue } from '../../../context/alert/AlertContext'
import { AlertSeverity } from '../../../context/alert/AlertTypes'
import { useCustomerState } from '../../../context/customer/CustomerContext'
import { setAllPosts, setMatchedPosts, setPostsEndDate, setPostsStartDate } from '../../../context/meta/MetaActions'
import { useStateValue } from '../../../context/meta/MetaContext'
import { setLoading } from '../../../context/shared/SharedActions'
import { useSharedStateValue } from '../../../context/shared/SharedContext'

const PostsStartEndDate = () => {

  const [{ postsStartDate, postsEndDate }, dispatch] = useStateValue()
  const [{ loading }, sharedDispatch] = useSharedStateValue()
  // const [apply, setApply] = useState<boolean>(false)
  const state = useCustomerState()
  const [{ alert }, alertDispatch] = useAlertStateValue()

  useEffect(() => {
    sharedDispatch(setLoading(true))
    state?.selected?.id && getFacebookPosts(state?.selected?.id, postsStartDate, postsEndDate).then((posts) => {
      dispatch(setAllPosts(posts))
      dispatch(setMatchedPosts(posts))
      // setApply(false)
      sharedDispatch(setLoading(false))
    }).catch(error => {
      sharedDispatch(setLoading(false))
      // setApply(false)
      const errMsg = error?.response?.data?.message ? error?.response?.data?.message : `Get Facebook posts error. ${error.message}`
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: errMsg, duration: null }))
    });
  }, [state])

  const handleApply = () => {
    if (loading) {
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: 'Please wait. The previous process is not completed yet.', duration: null }))
    } else {
      sharedDispatch(setLoading(true))
      state?.selected?.id && getFacebookPosts(state?.selected?.id, postsStartDate, postsEndDate).then((posts) => {
        dispatch(setAllPosts(posts))
        dispatch(setMatchedPosts(posts))
        // setApply(false)
        sharedDispatch(setLoading(false))
      }).catch(error => {
        sharedDispatch(setLoading(false))
        // setApply(false)
        const errMsg = error?.response?.data?.message ? error?.response?.data?.message : `Get Facebook posts error. ${error.message}`
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: errMsg, duration: null }))
        console.error(error)
      });
    }

  }

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPostsStartDate(e.target.value))
  }

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPostsEndDate(e.target.value))
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
        {/*<Typography variant='h5'>Date</Typography>*/}
      </Grid>

      <Grid item xs={4} sx={{}}>
        <TextField
          id="startDate"
          label="Start date"
          type="date"
          value={postsStartDate}
          //defaultValue="2017-05-24"
          onChange={handleStartDateChange}
          size="small"
          sx={{ width: '100%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{}}>
        <TextField
          id="endDate"
          label="End date"
          type="date"
          //defaultValue="2017-05-24"
          value={postsEndDate}
          onChange={handleEndDateChange}
          size="small"
          sx={{ width: '100%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ mt: 0.1 }}>
        <Button variant="contained" onClick={handleApply} sx={{ textTransform: 'none', width: '100%' }}>
          {loading ? <CircularProgress style={{ width: '24px', height: '24px', color: "#fff" }} /> : 'Apply'}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
        <Divider />
      </Grid>

    </Grid>
  )
}

export default PostsStartEndDate