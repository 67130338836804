import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Grid, Typography } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import CustomCheckbox from '../../CustomCheckbox';
import { useAuthState } from '../../../context/auth/AuthContext';
import { setAdAutomationSettings, setAdvancedPostBoosting } from '../../../context/meta/MetaActions';
import { useLocation } from 'react-router-dom';



const PublisherPlatforms = () => {
  const location = useLocation()
  const metaAdAutoLocation = location.pathname.includes('meta-ad-automations')

  const [{ adAutomationSettings, adAutomationSettingsErrors, advancedPostBoosting }, dispatch] = useStateValue()
  const { publisher_platforms, facebook_positions, instagram_positions, messenger_positions, audience_network_positions } = adAutomationSettings.adSet.targeting
  const [selected, setSelected] = useState<any[]>(metaAdAutoLocation ? publisher_platforms || [] : advancedPostBoosting.adSet.targeting.publisher_platforms || []);
  const [selectedFacebookPositions, setSelectedFacebookPositions] = useState<any[]>(metaAdAutoLocation ? facebook_positions || [] : advancedPostBoosting.adSet.targeting.facebook_positions || [])
  const [selectedInstagramPositions, setSelectedInstagramPositions] = useState<any[]>(metaAdAutoLocation ? instagram_positions || [] : advancedPostBoosting.adSet.targeting.instagram_positions || [])
  const [selectedMessengerPositions, setSelectedMessengerPositions] = useState<any[]>(metaAdAutoLocation ? messenger_positions || [] : advancedPostBoosting.adSet.targeting.messenger_positions || [])
  const [selectedAudienceNetworkPositions, setSelectedAudienceNetworkPositions] = useState<any[]>(metaAdAutoLocation ? audience_network_positions || [] : advancedPostBoosting.adSet.targeting.audience_network_positions || [])

  const options = [
    {
      key: 'facebook',
      label: 'Facebook'
    },
    {
      key: 'instagram',
      label: 'Instagram'
    },
    {
      key: 'messenger',
      label: 'Messenger'
    },
    {
      key: 'audience_network',
      label: 'Audience network'
    }
  ];

  const facebookPositions = [
    {
      key: 'feed',
      label: 'News feeds'
    },
    {
      key: 'right_hand_column',
      label: 'Right hand columns'
    },
    {
      key: 'instant_article',
      label: 'Instant articles'
    },
    {
      key: 'marketplace',
      label: 'Marketplace'
    },
    {
      key: 'video_feeds',
      label: 'Video feeds'
    },
    {
      key: 'story',
      label: 'Story'
    },
    {
      key: 'search',
      label: 'Search'
    },
    {
      key: 'instream_video',
      label: 'In-stream videos'
    },
    {
      key: 'facebook_reels',
      label: 'Reels'
    },


  ]

  const instagramPositions = [
    {
      key: 'stream',
      label: 'Stream'
    },
    {
      key: 'story',
      label: 'Story'
    },
    {
      key: 'shop',
      label: 'Shop'
    },
    {
      key: 'explore',
      label: 'Explore'
    },
    {
      key: 'reels',
      label: 'Reels'
    }
  ];

  const audienceNetworkPositions = [
    {
      key: 'classic',
      label: 'Classic'
    },
    {
      key: 'rewarded_video',
      label: 'Rewarded video'
    }
  ];

  const messengerPositions = [
    {
      key: 'messenger_home',
      label: 'Messenger home'
    },
    {
      key: 'sponsored_messages',
      label: 'Sponsered messages'
    },
    {
      key: 'story',
      label: 'Story'
    }
  ];
  // Handle platforms change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selected.includes(value)) {
      setSelected(selected.filter(s => s !== value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, publisher_platforms: selected.filter(s => s !== value)
            }
          }
        }))
      } else {
        // advanced post boosting
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, publisher_platforms: selected.filter(s => s !== value)
            }
          }
        }))
      }
      // When a publisher platform unckecked, uncheck also it's own positions
      switch (value) {
        case 'facebook':
          setSelectedFacebookPositions([])
          if (metaAdAutoLocation) {
            dispatch(setAdAutomationSettings({
              ...adAutomationSettings, adSet: {
                ...adAutomationSettings.adSet, targeting: {
                  ...adAutomationSettings.adSet.targeting, facebook_positions: []
                }
              }
            }))
          } else {
            // advanced post boosting
            dispatch(setAdvancedPostBoosting({
              ...advancedPostBoosting, adSet: {
                ...advancedPostBoosting.adSet, targeting: {
                  ...advancedPostBoosting.adSet.targeting, facebook_positions: []
                }
              }
            }))
          }
          break;
        case 'instagram':
          setSelectedInstagramPositions([])
          if (metaAdAutoLocation) {
            dispatch(setAdAutomationSettings({
              ...adAutomationSettings, adSet: {
                ...adAutomationSettings.adSet, targeting: {
                  ...adAutomationSettings.adSet.targeting, instagram_positions: []
                }
              }
            }))
          } else {
            dispatch(setAdvancedPostBoosting({
              ...advancedPostBoosting, adSet: {
                ...advancedPostBoosting.adSet, targeting: {
                  ...advancedPostBoosting.adSet.targeting, instagram_positions: []
                }
              }
            }))
          }
          break;
        case 'messenger':
          setSelectedMessengerPositions([])
          if (metaAdAutoLocation) {
            dispatch(setAdAutomationSettings({
              ...adAutomationSettings, adSet: {
                ...adAutomationSettings.adSet, targeting: {
                  ...adAutomationSettings.adSet.targeting, messenger_positions: []
                }
              }
            }))
          } else {
            // advanced post boosting
            dispatch(setAdvancedPostBoosting({
              ...advancedPostBoosting, adSet: {
                ...advancedPostBoosting.adSet, targeting: {
                  ...advancedPostBoosting.adSet.targeting, messenger_positions: []
                }
              }
            }))
          }
          break;
        case 'audience_network':
          setSelectedAudienceNetworkPositions([])
          if (metaAdAutoLocation) {
            dispatch(setAdAutomationSettings({
              ...adAutomationSettings, adSet: {
                ...adAutomationSettings.adSet, targeting: {
                  ...adAutomationSettings.adSet.targeting, audience_network_positions: []
                }
              }
            }))
          } else {
            // advanced post boosting
            dispatch(setAdvancedPostBoosting({
              ...advancedPostBoosting, adSet: {
                ...advancedPostBoosting.adSet, targeting: {
                  ...advancedPostBoosting.adSet.targeting, audience_network_positions: []
                }
              }
            }))
          }
          break;
        default:
          break;
      }
    } else {
      setSelected(selected.concat(value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, publisher_platforms: selected.concat(value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, publisher_platforms: selected.concat(value)
            }
          }
        }))
      }
    }
  };

  // Handle Facebook positions change
  const handleFacebookPoistionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedFacebookPositions.includes(value)) {
      setSelectedFacebookPositions(selectedFacebookPositions.filter(s => s !== value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, facebook_positions: selectedFacebookPositions.filter(s => s !== value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, facebook_positions: selectedFacebookPositions.filter(s => s !== value)
            }
          }
        }))
      }
    } else {
      setSelectedFacebookPositions(selectedFacebookPositions.concat(value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, facebook_positions: selectedFacebookPositions.concat(value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, facebook_positions: selectedFacebookPositions.concat(value)
            }
          }
        }))
      }
    }
  }

  // Handle Instagram positions change
  const handleInstagramPoistionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedInstagramPositions.includes(value)) {
      setSelectedInstagramPositions(selectedInstagramPositions.filter(s => s !== value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, instagram_positions: selectedInstagramPositions.filter(s => s !== value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, instagram_positions: selectedInstagramPositions.filter(s => s !== value)
            }
          }
        }))
      }
    } else {
      setSelectedInstagramPositions(selectedInstagramPositions.concat(value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, instagram_positions: selectedInstagramPositions.concat(value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, instagram_positions: selectedInstagramPositions.concat(value)
            }
          }
        }))
      }
    }
  }

  // Handle Messenger positions change
  const handleMessengerPositionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedMessengerPositions.includes(value)) {
      setSelectedMessengerPositions(selectedMessengerPositions.filter(s => s !== value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, messenger_positions: selectedMessengerPositions.filter(s => s !== value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, messenger_positions: selectedMessengerPositions.filter(s => s !== value)
            }
          }
        }))
      }
    } else {
      setSelectedMessengerPositions(selectedMessengerPositions.concat(value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, messenger_positions: selectedMessengerPositions.concat(value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, messenger_positions: selectedMessengerPositions.concat(value)
            }
          }
        }))
      }
    }
  }

  // Handle audience network positions change
  const handleAudienceNetworkPositionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedAudienceNetworkPositions.includes(value)) {
      setSelectedAudienceNetworkPositions(selectedAudienceNetworkPositions.filter(s => s !== value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, audience_network_positions: selectedAudienceNetworkPositions.filter(s => s !== value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, audience_network_positions: selectedAudienceNetworkPositions.filter(s => s !== value)
            }
          }
        }))
      }
    } else {
      setSelectedAudienceNetworkPositions(selectedAudienceNetworkPositions.concat(value));
      if (metaAdAutoLocation) {
        dispatch(setAdAutomationSettings({
          ...adAutomationSettings, adSet: {
            ...adAutomationSettings.adSet, targeting: {
              ...adAutomationSettings.adSet.targeting, audience_network_positions: selectedAudienceNetworkPositions.concat(value)
            }
          }
        }))
      } else {
        dispatch(setAdvancedPostBoosting({
          ...advancedPostBoosting, adSet: {
            ...advancedPostBoosting.adSet, targeting: {
              ...advancedPostBoosting.adSet.targeting, audience_network_positions: selectedAudienceNetworkPositions.concat(value)
            }
          }
        }))
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' style={{ fontSize: '18px' }}>Platforms</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Select platforms</FormLabel>
          <FormGroup aria-label="position" row >
            {options.map(option =>
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={<Checkbox onChange={handleChange} />}
                label={option.label}
                labelPlacement="end"
                checked={selected.indexOf(option.key) > -1}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>

      {selected.includes('facebook') && <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel sx={{ fontSize: '14px' }} component="legend">Facebook positions</FormLabel>
          <FormGroup aria-label="position" row>
            {facebookPositions.map(option =>
              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                key={option.key}
                value={option.key}
                control={<Checkbox onChange={handleFacebookPoistionsChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />}
                label={option.label}
                labelPlacement="end"
                checked={selectedFacebookPositions.indexOf(option.key) > -1}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>}

      {selected.includes('instagram') && <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel sx={{ fontSize: '14px' }} component="legend">Instagram positions</FormLabel>
          <FormGroup aria-label="position" row>
            {instagramPositions.map(option =>
              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                key={option.key}
                value={option.key}
                control={<Checkbox onChange={handleInstagramPoistionsChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />}
                label={option.label}
                labelPlacement="end"
                checked={selectedInstagramPositions.indexOf(option.key) > -1}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>}

      {selected.includes('messenger') && <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel sx={{ fontSize: '14px' }} component="legend">Messenger positions</FormLabel>
          <FormGroup aria-label="position" row>
            {messengerPositions.map(option =>
              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                key={option.key}
                value={option.key}
                control={<Checkbox onChange={handleMessengerPositionsChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />}
                label={option.label}
                labelPlacement="end"
                checked={selectedMessengerPositions.indexOf(option.key) > -1}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>}

      {selected.includes('audience_network') && <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel sx={{ fontSize: '14px' }} component="legend">Audience network positions</FormLabel>
          <FormGroup aria-label="position" row>
            {audienceNetworkPositions.map(option =>
              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                key={option.key}
                value={option.key}
                control={<Checkbox onChange={handleAudienceNetworkPositionsChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />}
                label={option.label}
                labelPlacement="end"
                checked={selectedAudienceNetworkPositions.indexOf(option.key) > -1}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>}
    </>
  )
}

export default PublisherPlatforms