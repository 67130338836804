

import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { apiGetAdUnitsSettings, apiPutAdUnitConfiguration, apiPutDv360CaptchaText, apiPutDv360OneTimeText, apiStartDv360Login } from '../api/Customer';
import { useEffect, useState } from 'react';
import { AdUnitSettings } from '../api/Types';
import styled from 'styled-components';
import { apiOAuthLogin } from '../api/Auth';
import { LoadingModal } from './Modal';
import { debounce } from '../utils/Generators';
import { regularBtnStyles } from '../styles/buttons';

const putAdUnitConfigurationDebounced = debounce(apiPutAdUnitConfiguration, 1000)

const textFieldTextStyles = {
  mb: 0.3
}

const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}

export type DisplayVideoAdminProps = {
  customerId: number
}

type Phase = "initial" | "getCaptchaImage" | "inputCaptcha" | "inputOneTime" | "done" | "error"

const CaptchaImage = styled.div`
    background-size:contain;
    width:100%;
    min-height:500px;
`

export const DisplayVideoAdmin = (props: DisplayVideoAdminProps) => {
  const { customerId } = props
  const [loading, setLoading] = useState(true)
  const [captchaText, setCaptchaText] = useState("")
  const [oneTimeText, setOnetimeText] = useState("")
  const [captchaImageUrl, setCaptchaImageUrl] = useState<string | undefined>(undefined)
  const [dvLoginOpen, setDvLoginOpen] = useState(false)
  const [phase, setPhase] = useState<Phase>("initial")
  const [requiresUpdate, setRequiresUpdate] = useState(false)
  const [adunitSettings, setAdUnitSettings] = useState<AdUnitSettings>({
    dv360: {
      advertiserIds: []
    }
  })

  const doDisplayVideoLogin = () => {
    apiOAuthLogin("displayvideo", customerId).then((response) => {
      window.location.href = response.url
    }).catch(_ => {
      alert("Error in authentication. Please try again later.")
    })
  }

  useEffect(() => {
    apiGetAdUnitsSettings(customerId).then((adunitSettings) => {
      setLoading(false)
      if (adunitSettings && adunitSettings.dv360?.advertiserIds) {
        setAdUnitSettings(adunitSettings)
      } else {
        setAdUnitSettings({
          dv360: { advertiserIds: [], allowEdit: true },
        })
      }
    })
  }, [customerId])

  useEffect(() => {
    if (requiresUpdate) {
      setRequiresUpdate(false)
      putAdUnitConfigurationDebounced(customerId, adunitSettings)
    }
  }, [requiresUpdate])

  return (<Grid container>
    <Grid item xs={12} sx={gridItemStyles}>
      {loading ? <LoadingModal /> :
        <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Partner Id</Typography>
            <TextField fullWidth size='small' variant="outlined"
              value={adunitSettings.dv360.partnerId || ''}
              onChange={(ev) => {
                setAdUnitSettings({
                  dv360: {
                    advertiserIds: adunitSettings.dv360.advertiserIds,
                    partnerId: ev.target.value
                  }
                })
                setRequiresUpdate(true)
              }} />
          </Grid>

          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Advertiser Ids</Typography>
            <TextField fullWidth size='small' variant="outlined"
              value={adunitSettings.dv360.advertiserIds.join(",")}
              onChange={(ev) => {
                setAdUnitSettings({
                  dv360: {
                    advertiserIds: ev.target.value.split(","),
                    partnerId: adunitSettings.dv360.partnerId
                  }
                })
                setRequiresUpdate(true)
              }} />
          </Grid>
        </Grid>}
    </Grid>

    <Grid container xs={12} sx={gridItemStyles}>
      <Grid item xs={2}>
        <Button variant='contained' color="primary"
          sx={{ ...regularBtnStyles }}
          onClick={() => doDisplayVideoLogin()}>Authorize Metrics</Button>
      </Grid>
      <Grid item xs={2}>
        <Button variant='contained' color="primary"
          disabled={!adunitSettings.dv360.partnerId}
          sx={{ ...regularBtnStyles }} onClick={() => {
            setDvLoginOpen(true)
            setPhase("getCaptchaImage")
            setLoading(true)
            apiStartDv360Login(customerId).then((image) => {
              setLoading(false)
              if (image) {
                setCaptchaImageUrl(URL.createObjectURL(image))
                setPhase("inputCaptcha")
              } else {
                setPhase("error")
              }
            }).catch(() => {
              setLoading(false)
              setPhase("error")
            })
          }}>Authorize Visuals</Button>
      </Grid>
    </Grid>


    <Dialog open={dvLoginOpen} fullWidth>
      <DialogTitle>Display Video Login</DialogTitle>
      <DialogContent>
        <Grid container xs={12} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>Captcha Image</Typography>
          {captchaImageUrl && <CaptchaImage style={{ backgroundImage: `url('${captchaImageUrl}')` }} />}
        </Grid>
        <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          {captchaImageUrl && phase === "inputCaptcha" && <>
            <Grid item xs={4} sx={gridItemStyles}>
              <TextField size='small' variant="outlined" fullWidth label='Captcha text'
                value={captchaText}
                onChange={(ev) => setCaptchaText(ev.target.value)} />
            </Grid>
            <Grid item xs={2} sx={gridItemStyles}>
              <Button color="primary" variant="contained" onClick={() => {
                apiPutDv360CaptchaText(customerId, captchaText).then(() => {
                  setPhase("inputOneTime")
                })
              }}>Send</Button>
            </Grid>
          </>}

          {phase === "inputOneTime" && <>
            <Grid item xs={4} sx={gridItemStyles}>
              <TextField size='small' variant="outlined" fullWidth label='One-time password'
                value={oneTimeText}
                onChange={(ev) => setOnetimeText(ev.target.value)} />
            </Grid>
            <Grid item xs={2} sx={gridItemStyles}>
              <Button color="primary" variant="contained" onClick={() => {
                apiPutDv360OneTimeText(customerId, oneTimeText).then(() => {
                  setPhase("done")
                })
              }}>Send</Button>
            </Grid>
          </>}
          <Grid item xs={2} sx={gridItemStyles}>
            <Button color={phase === "done" ? "primary" : "secondary"} variant="contained" onClick={() => {
              setDvLoginOpen(false)
              setCaptchaText("")
              setOnetimeText("")
              setPhase("initial")
            }}>{phase === "done" ? "OK" : "Cancel"}</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </Grid>)
}
