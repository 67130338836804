import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material"
import { apiCreateCustomDataset } from "../../api/Assistant"
import { useState } from "react"
import { CustomDataset } from "../../api/Types"


export type CreateTeachingMaterialsDialogProps = {
  customerId: number
  open: boolean
  setOpen: (open: boolean) => void
  setLoading: (loading: boolean) => void
  onCreate: (customDatasets: CustomDataset[]) => void
}

export const CreateTeachingMaterialsDialog = (props: CreateTeachingMaterialsDialogProps) => {
  const { customerId, open, setOpen, setLoading, onCreate } = props
  const [newName, setNewName] = useState("")
  return (<Dialog
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>
      Create Teaching Materials
    </DialogTitle>
    <DialogContent>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <TextField label="Name" value={newName} size="small" onChange={(ev) => { setNewName(ev.target.value) }} />
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="error" onClick={() => { setOpen(false) }}>Cancel</Button>
      <Button variant="contained" color="primary" onClick={() => {
        setLoading(true)
        apiCreateCustomDataset(customerId, newName).then((customDatasets) => {
          setLoading(false)
          setOpen(false)
          onCreate(customDatasets)
        }).catch(() => {
          setLoading(false)
          setOpen(false)
        })
      }}>Create</Button>
    </DialogActions>
  </Dialog>)
}