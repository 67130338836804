import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiGetCustomerServices } from '../api/Customer';
import { CustomerService, KivaAnalyticsConfiguration } from '../api/Types';
import { useCustomerState } from '../context/customer/CustomerContext';
import CopyCodeBlock from './CodeBlock';
import { desktop_h1, desktop_h2, desktop_p1 } from '../styles/textStyles';
import { neutralColors } from '../styles/colors';

const KivaAnalytics = () => {
  const state = useCustomerState()
  const selected = state.selected
  const [config, setConfig] = useState<string | undefined>('XXXXXXXXX')

  useEffect(() => {
    if (selected) {
      apiGetCustomerServices(selected.id).then((customerServices: CustomerService[]) => {
        const kivaAnalytics = customerServices?.find((cs: CustomerService) => cs.type = 'KivaAnalytics');
        if (kivaAnalytics) {
          const kivaAnalyticsId: string | undefined = (kivaAnalytics.configuration as KivaAnalyticsConfiguration)?.kivaAnalyticsId;
          const googleAnalytics4IdStr: string | undefined = (kivaAnalytics.configuration as KivaAnalyticsConfiguration)?.googleAnalytics4Id;
          let googleAnalytics4IdArray = googleAnalytics4IdStr?.split(',');
          googleAnalytics4IdArray = googleAnalytics4IdArray?.map((str: string) => str.replace(/\s/g, ""));
          const firstGoogleAnalytics4Id = googleAnalytics4IdArray && googleAnalytics4IdArray[0];
          firstGoogleAnalytics4Id ? setConfig(firstGoogleAnalytics4Id) : setConfig(kivaAnalyticsId)
        }
      })
    }
  }, [selected])

  const analyticsScript = `<script src="https://analytics.kiva.fi/kiva.tools.min.js"></script>`;

  const analyticsCodeBlock = `<script>
  window.kivaLayer = window.kivaLayer || [];
  window.kivaLayer.push('config', '${config}');
  window.kivaLayer.push('pageview');
</script>`;

  return (
    <Box sx={{ margin: '50px' }}>

      <Grid container sx={{ alignItems: 'left'}}>
        <Box sx={{ width: '50%' }}>
          <Typography variant='h1' sx={{ ...desktop_h1 }}>Kiva Analytics</Typography>
        </Box>
        <Box sx={{ alignItems: 'center', maxWidth: '900px', background: neutralColors[50], borderRadius: '5px', padding: '16px 24px', margin: '40px 0' }}>
          <Typography sx={desktop_p1}>Kiva Analytics is a GDPR-compliant analytics application that collects and stores information regardless of the user’s cookie settings.</Typography>
        </Box>
      </Grid>

      <Grid container maxWidth={'900px'}>
        <Grid item xs={12}>
          <Typography variant='h2' sx={{...desktop_h2, marginBottom: '16px'}}>Installation</Typography>
          <Typography sx={desktop_p1}>The following piece of code should be included inside the “head” element of each page where data collection is needed. In case you are using for example React or Next.js, the script loading can be pretty much anywhere, it starts to operate when it is loaded. Before loading the events will go to the queue. </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: '40px', mb: '40px' }}>
          <CopyCodeBlock code={analyticsScript} language={'js'} showLineNumbers={true} />
        </Grid>

        <Grid item xs={12} sx={{ }}>
          <Typography sx={desktop_p1}>Actual collection of events is done with code shown below. This particular example sends a page view to Kiva Analytics. The “kivaLayer” initialization is needed in case you are not sure that the script has loaded before this piece of code is run. The config should be sent with the first event as well. </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: '40px', mb: '40px' }}>
          <CopyCodeBlock code={analyticsCodeBlock} language={'js'} showLineNumbers={true} />
        </Grid>

        <Grid item xs={12} sx={{ mb: '40px' }}>
          <Typography sx={desktop_p1}>
            For more information, please visit: <a target='_blank' rel="noreferrer" href='https://www.kiva.fi/en/kiva-analytics-tracking-solution/'>Kiva Analytics</a>
          </Typography>
        </Grid>

      </Grid>

    </Box>
  )

}
export default KivaAnalytics;