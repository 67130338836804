import { Box, CircularProgress } from '@mui/material'
import { primaryColors } from '../styles/colors'

interface CircularProgressLoadingProps {
  boxStyles?: Object
  circularProgressStyles?: Object
}

const CircularProgressLoading = ({boxStyles, circularProgressStyles}: CircularProgressLoadingProps) => {
  return (
    <Box sx={boxStyles? boxStyles : {display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '50px' }}>
        <CircularProgress style={circularProgressStyles ? circularProgressStyles : { width: '36px', height: '36px', color: primaryColors[400] }} />
    </Box>
  )
}

export default CircularProgressLoading