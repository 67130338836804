import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Post } from '.';
import { convertToFinnishDate, URL_REGEX } from '../../../utils/helpers';
import AddPostsDialog from './AddPostsDialog';
import { useAlertStateValue } from '../../../context/alert/AlertContext';
import { setAlert } from '../../../context/alert/AlertAction';
import { AlertSeverity } from '../../../context/alert/AlertTypes';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

interface Data {
  id: string | number;
  message: string;
  url: string;
  type: string,
  reactions: number;
  comments: number;
  created_time: string;
}

function createData(
  id: string | number,
  message: string,
  url: string,
  type: string,
  reactions: number,
  comments: number,
  created_time: string,
): Data {
  return {
    id,
    message,
    url,
    type,
    reactions,
    comments,
    created_time
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]).reverse();
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  align: any
}

const headCells: readonly HeadCell[] = [
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'Message',
    align: 'left'
  },
  {
    id: 'url',
    numeric: false,
    disablePadding: false,
    label: 'Preview',
    align: 'left'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    align: 'left'
  },
  {
    id: 'reactions',
    numeric: true,
    disablePadding: false,
    label: 'Reactions',
    align: 'center'
  },
  {
    id: 'comments',
    numeric: true,
    disablePadding: false,
    label: 'Comments',
    align: 'center'
  },
  {
    id: 'created_time',
    numeric: false,
    disablePadding: false,
    label: 'Created time',
    align: 'left'
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  checked: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, checked } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{
              color: "#424242",
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={checked /*rowCount > 0 && numSelected === rowCount*/}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ paddingLeft: headCell.id === 'message' ? '0' : '16px' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selectedPosts: Post[]
}



const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, selectedPosts } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Posts
        </Typography>
      )}
      {numSelected > 0 ? (
        <AddPostsDialog selectedPosts={selectedPosts} />
      ) : null}
    </Toolbar>
  );
};

const PostsTable = ({ posts }: any) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('created_time');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedPosts, setSelectedPosts] = React.useState<Post[]>([]);
  const [selectAllChecked, setSelectAllChecked] = React.useState(false)
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const rows: Data[] = posts.map((p: Post) => {
    return createData(p.id, p.message, p.permalink_url, p.type, p.reactions?.summary?.total_count, p.comments?.summary?.total_count, p.created_time);
  })

  const [{ alert }, alertDispatch] = useAlertStateValue()

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Boolean(!selectAllChecked);
    setSelectAllChecked(newValue)
    if (newValue) {
      const newSelected = rows.map((r: any) => r.id);
      // const postsWithUrl = rows.filter((r: any) => URL_REGEX.test(r.message) ? r : null);
      // const newSelected = postsWithUrl.map((r: any) => r.id);
      setSelected(newSelected);
      setSelectedPosts(posts)
      // alertDispatch(setAlert({ open: true, severity: AlertSeverity.Warning, message: "Posts without a link are ignored. Ads can not be created for the posts that do not include a url.", duration: null }))
      return;
    } else {
      setSelected([]);
      setSelectedPosts([])
      return;
    }


  };

  const handleClick = (_event: React.MouseEvent<unknown>, id: string, _row: any) => {

    //const includesUrl = URL_REGEX.test(row.message)
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setSelectedPosts(posts.filter((p: Post) => newSelected.includes(p.id.toString())))
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} selectedPosts={selectedPosts} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              checked={selectAllChecked}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id.toString());
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (

                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id.toString(), row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" width={'7.5%'}>
                        <Checkbox
                          style={{
                            color: "#424242",
                            marginRight: '16px'
                          }}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        style={{ padding: '5px 0' }}
                        width={'35%'}
                      >
                        {`${row.message.split(" ", 50).join(" ")}...`}
                      </TableCell>
                      <TableCell align="left" width={'7.5%'}>
                        <Tooltip title="Preview on Facebook" onClick={() => window.open(`${row.url}`)}>
                          <IconButton>
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" width={'10%'}>{row.type}</TableCell>
                      <TableCell align="center" width={'10%'}>{row.reactions}</TableCell>
                      <TableCell align="center" width={'10%'}>{row.comments}</TableCell>
                      <TableCell align="left" width={'20%'}>{convertToFinnishDate(row.created_time.toString())}</TableCell>
                    </TableRow>

                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
export default PostsTable;