import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFacebookPosts } from '../../../../api/Posts';
import { setAlert } from '../../../../context/alert/AlertAction';
import { useAlertStateValue } from '../../../../context/alert/AlertContext';
import { AlertSeverity } from '../../../../context/alert/AlertTypes';
import { useCustomerState } from '../../../../context/customer/CustomerContext';
import { setAllPosts } from '../../../../context/meta/MetaActions';
import { useStateValue } from '../../../../context/meta/MetaContext';
import { seedPosts } from '../seedPosts';
import PostBoosterStepper from './PostBoosterStepper';

const Container = styled.div`
    margin-left:50px;
    margin-right:50px;
`

const Header = styled.div`
    margin-left:0.1em;
    margin-top:1.5em;
    margin-bottom:1.0em;
    font-size:2.0em;
`

const CreateBoostingRule = () => {
  const state = useCustomerState()

  const [{ allPosts, matchedPosts }, dispatch] = useStateValue()
  const [filter, setFilter] = useState('');
  const [loading, setloading] = useState(false)
  const [{ alert }, alertDispatch] = useAlertStateValue()

  return (
    <Container>
      <Header>Create post boosting rule</Header>
      <Grid container sx={{ maxWidth: 800 }}>
        <PostBoosterStepper />
      </Grid>
    </Container>
  );
}
export default CreateBoostingRule;
