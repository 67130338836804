export const SET_Navigation_Drawer_State = 'SET_Navigation_Drawer_State'
export const SET_LOADING = 'SET_LOADING'

type SetNavigationDrawerAction = {
  type: typeof SET_Navigation_Drawer_State,
  data: boolean
}

type SetLoadingAction = {
  type: typeof SET_LOADING,
  data: boolean
}

export type SharedAction = SetNavigationDrawerAction | SetLoadingAction

export type SharedState = {
  drawerIsOpen: boolean,
  loading: boolean
}