import * as React from 'react';
import { useEffect, useState } from 'react'
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { InputBase, Box, Divider, IconButton, AccordionDetails, Accordion, Grid, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomations, setPostBoostingRules } from '../../../context/meta/MetaActions';
import { getAllPostBoostingRules } from '../../../api/Posts';
import MetaAccordionHeader from '../sharedMetaComponents/MetaAccordionHeader';
import SingleMetaAccordion from '../sharedMetaComponents/SingleMetaAccordion';
import { MetaObjType } from '../../../context/meta/MetaTypes';


const PostBoostingRules: React.FC<any> = () => {
  const state = useCustomerState()
  const [{ postBoostingRules }, dispatch] = useStateValue()
  const [loading, setloading] = useState(false)
  const [filter, setFilter] = useState('');

  // handle user input in the search field
  const handleFilterchange = (event: any) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    setloading(true)
    state?.selected?.id && getAllPostBoostingRules(state?.selected?.id).then((rules) => {
      dispatch(setPostBoostingRules(rules))
      setloading(false)
    }).catch(error => {
      setloading(false)
    });
  }, [state])

  return (
    <Box sx={{ mt: 3 }}>
      {loading && <Box>Loading...</Box>}
      {!loading && !postBoostingRules && <Box>No rules found.</Box>}
      {!loading && postBoostingRules.length < 1 && <Box>No rules found.</Box>}

      {!loading && postBoostingRules !== undefined && postBoostingRules !== null && postBoostingRules.length > 0 && <>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
        >
          <form>
            <Box display="flex" alignItems="center">
              <InputBase
                placeholder="Filter by name..."
                value={filter}
                onChange={handleFilterchange}
              />
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Box>
            <Divider style={{ marginBottom: '24px' }} />
          </form>

        </Box>
        <MetaAccordionHeader />
        {postBoostingRules.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
          .filter((item: any) => item?.name?.toLowerCase().includes(filter.toLowerCase()))
          .map((item: any) => {
            return <SingleMetaAccordion key={item.id} metaObj={item} metaObjType={MetaObjType.POST_BOOSTING_RULE} customerId={state.selected?.id} />
          })}
      </>

      }
    </Box>)
}

export default PostBoostingRules;

