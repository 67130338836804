

import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { ReadpeakAdsSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiSaveReadpeakCredentials } from '../../api/Auth';
import { apiGetReadpeakSettings } from '../../api/Customer';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import * as uuid from 'uuid'
import { regularBtnStyles } from '../../styles/buttons';

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type ReadpeakAdminProps = {
  customerId: string
}

export const ReadpeakAdmin = (props: ReadpeakAdminProps) => {
  const { customerId } = props
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState<ReadpeakAdsSettings>({ apiKey: '', clientNames: [] })
  const [{ alert }, alertDispatch] = useAlertStateValue()

  useEffect(() => {
    apiGetReadpeakSettings(customerId).then((response) => {
      if (response) {
        // Use dummy apiKey since there is no need to reveal the original one to the user.
        setSettings({ apiKey: uuid.v4(), clientNames: response.clientNames })
      } else {
        setSettings({ apiKey: '', clientNames: [] })
      }
    })
  }, [customerId])


  const handleClientNamesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, clientNames: event?.target.value.split(",") })
  }

  const handleApiKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, apiKey: event?.target.value })
  }

  const handleSaveCredentials = () => {
    setSaveBtnProgress(true)
    apiSaveReadpeakCredentials(customerId, settings).then((res) => {
      if(res.status === 200){
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Saved successfully!", duration: 7000 }))
        setSaveBtnProgress(false)
      }
    }).catch(error => {
      console.log("save readpeak credentials error", error)
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Error while saving Readpeak credentials. ${error.message}`, duration: null }))
      setSaveBtnProgress(false)
    })

  }

  return (
  <Grid container>
    {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
      <Grid component={"form"} container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>API key</Typography>
          <TextField fullWidth size='small' type="password" variant="outlined" value={settings.apiKey} onChange={handleApiKeyChange} />
        </Grid>
        <Grid item xs={6} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>Readpeak client names</Typography>
          <TextField fullWidth size='small' variant="outlined" value={settings.clientNames.join(",")} onChange={handleClientNamesChange} />
        </Grid>

        <Grid item xs={12} sx={{ pt: 0 }}>
          <Button sx={{ ...regularBtnStyles, mt: 1 }} onClick={handleSaveCredentials}>
            {saveBtnProgress ?  <CircularProgress  style={{width: '20px', height: '20px', color: "#fff"}} /> : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </Grid>}
  </Grid>
  )
}
