import { AdGroupCreative, CampaignCreative, CreativeResponse } from "../../api/Types"
import { CreativeChartMetrics } from "../../components/Charts/Types"


export type AdUnits = {
  creatives: CreativeResponse
  allCampaigns: CampaignCreative[]
  allFloodlightActivities: { id?: string, title: string }[]
  allDimensions: { id?: string, title: string }[]
  customerId?: number
  loading: boolean
  startDate: Date
  endDate: Date
  sort: string
  direction: string
  statuses: string[]
  campaigns: string[]
  allAdGroups: AdGroupCreative[]
  adGroups: string[]
  dimensions: string[]
  floodlightActivity: string | undefined
  searchKeyword?: string
  showZeros: boolean
  categories: string[]
  start: number
  conversions: string;
  showSelectCustomer: boolean,
  gridViewItemsCount: number,
}

export type AdUnitsCharts = {
  chartMetrics: CreativeChartMetrics[]
  chartLoading: boolean
}


export const SET_AD_UNITS = 'SET_AD_UNITS'
export const SET_AD_UNITS_CHARTS = 'SET_AD_UNITS_CHARTS'

type SetAdUnitsAction = {
  type: typeof SET_AD_UNITS;
  data: AdUnits
};

type SetAdUnitsChartsAction = {
  type: typeof SET_AD_UNITS_CHARTS;
  data: AdUnitsCharts
};

export type AdsAction = SetAdUnitsAction | SetAdUnitsChartsAction

export type CategoryItem = {
  value: string,
  label: string
}

export type AdsState = {
  adUnits: AdUnits
  adUnitsCharts: AdUnitsCharts
};
