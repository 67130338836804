import { getFacebookPosts } from "../../api/Posts";
import { AdAutomationSettings, MetaAction, GET_AD_AUTOMATION_SETTINGS, SET_AD_AUTOMATION_SETTINGS, SET_AD_AUTOMATION_SETTINGS_ERRORS, SET_AD_AUTOMATIONS, GET_ALL_POSTS, SET_ALL_POSTS, SET_MATCHED_POSTS, PostBoostingCondition, PostBoostingFilters, AdvancedPostBoosting, SET_ADVANCED_POST_BOOSTING, SET_POST_BOOSTING_RULES, SET_ADVANCED_POST_BOOSTING_ERRORS, SET_CAMPAIGN, Campaign, AdSet, SET_AD_SET, SET_CAMPAIGN_ERRORS, SET_AD_SET_ERRORS, SET_POSTS_START_DATE, SET_POSTS_END_DATE, SET_META_ASSETS, MetaAssets } from "./MetaTypes";

export const getAdAutomationSettings = (): MetaAction => {
  return { type: GET_AD_AUTOMATION_SETTINGS }
}

export const setAdAutomationSettings = (adAutomationSettings: AdAutomationSettings): MetaAction => {
  return { type: SET_AD_AUTOMATION_SETTINGS, data: adAutomationSettings }
}

export const setAdAutomations = (adAutomations: any[]): MetaAction => {
  return { type: SET_AD_AUTOMATIONS, data: adAutomations }
}

export const setAdAutomationSettingsErrors = (adAutomationSettingsErrors: string[]): MetaAction => {
  return { type: SET_AD_AUTOMATION_SETTINGS_ERRORS, data: adAutomationSettingsErrors }
}

// Post booster actions

export const getAllPosts = (customerId: number, startDate: string, endDate: string) => async () => {
  const allPosts = await getFacebookPosts(customerId, startDate, endDate)
  return { type: SET_ALL_POSTS, data: allPosts }
}

export const setAllPosts = (allPosts: any[]): MetaAction => {
  return { type: SET_ALL_POSTS, data: allPosts }
}

export const setMatchedPosts = (matchedPosts: any[]): MetaAction => {
  return { type: SET_MATCHED_POSTS, data: matchedPosts }
}

export const setAdvancedPostBoosting = (advancedPostBoosting: AdvancedPostBoosting): MetaAction => {
  return { type: SET_ADVANCED_POST_BOOSTING, data: advancedPostBoosting }
}

export const setAdvancedPostBoostingErrors = (advancedPostBoostingErrors: string[]): MetaAction => {
  return { type: SET_ADVANCED_POST_BOOSTING_ERRORS, data: advancedPostBoostingErrors }
}

export const setPostBoostingRules = (postBoostingRules: any[]): MetaAction => {
  return { type: SET_POST_BOOSTING_RULES, data: postBoostingRules }
}

export const setCampaign = (campaign: Campaign): MetaAction => {
  return { type: SET_CAMPAIGN, data: campaign }
}

export const setAdSet = (adSet: AdSet): MetaAction => {
  return { type: SET_AD_SET, data: adSet }
}

export const setCampaignErrors = (campaignErrors: string[]): MetaAction => {
  return { type: SET_CAMPAIGN_ERRORS, data: campaignErrors }
}

export const setAdSetErrors = (adSetErrors: string[]): MetaAction => {
  return { type: SET_AD_SET_ERRORS, data: adSetErrors }
}

export const setPostsStartDate = (startDate: string): MetaAction => {
  return { type: SET_POSTS_START_DATE, data: startDate }
}

export const setPostsEndDate = (endDate: string): MetaAction => {
  return { type: SET_POSTS_END_DATE, data: endDate }
}

export const setMetaAssets = (metaAssets: MetaAssets): MetaAction => {
  return { type: SET_META_ASSETS, data: metaAssets }
}