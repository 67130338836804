import { Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Link, TextField, Typography } from "@mui/material"
import { useCustomerState } from "../context/customer/CustomerContext"
import { desktop_h1 } from "../styles/textStyles"
import { useEffect, useState } from "react";
import { apiCreateCustomerFolder, apiDeleteCustomerFile, apiGetCustomerFiles, apiUpdateCustomerFile, apiUploadCustomerFile, getCustomerFileBase64, getCustomerFileUrl } from "../api/Customer";
import { FileTree } from "../api/Types";
import { regularBtnStyles } from "../styles/buttons";
import styled from "styled-components";
import { Folder } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFile, faSave } from "@fortawesome/free-solid-svg-icons";
import { debouncePromise } from "../utils/Generators";
import { cutStringToMaxLength } from "../components/Utils";
import { LoadingModal } from "../components/Modal";

const PathContainer = styled.div`
`

const Separator = styled.span`
  margin-left:0.2em;
  margin-right:0.2em;
`

const ImageContainer = styled.div`
  max-height:8em;
  margin-bottom:0.2em;
`

const PreviewImage = styled.img`
  object-fit: contain;
  max-height: 8em;
  border-radius: 5px;
`

const fileToIcon = (file: FileTree) => {
  if (file.type === "Folder") return <Folder />
  return <FontAwesomeIcon icon={faFile} />
}


const findWithIdFromTree = (id: string, tree: FileTree): FileTree[] => {
  if (tree.id === id) {
    return [tree]
  }
  for (const child of tree.children) {
    const childTree = findWithIdFromTree(id, child)
    if (childTree.length > 0) {
      return [tree].concat(childTree)
    }
  }
  return []
}

const findSafeFromTree = (id: string | undefined, tree: FileTree): FileTree[] => {
  if (!id) {
    return [tree]
  }
  return findWithIdFromTree(id, tree)
}

const toFileLabel = (file: FileTree): string => {
  if (file.type === "Folder") {
    return file.label
  }
  if (file.width && file.height) {
    return `${cutStringToMaxLength(file.label, 12)} (${file.width}x${file.height})`
  }
  return cutStringToMaxLength(file.label + "." + file.subCategory, 12)
}

const FileCard = (props: {
  customerId: number,
  file: FileTree,
  large?: boolean,
  selected: boolean,
  onMoveTo: (id: string) => void,
  onUpdated: (file: FileTree) => void,
  onSelect: (file: FileTree) => void,
}) => {
  const { customerId, file, large, selected, onMoveTo, onUpdated, onSelect } = props
  const [edit, setEdit] = useState(false)
  const [label, setLabel] = useState(file.label)
  const [description, setDescription] = useState(file.description)

  function onEdited(file: FileTree) {
    setLabel(file.label)
    setDescription(file.description)
    onUpdated(file)
  }

  return (<Grid xs={edit ? 4 : 2}><Card style={selected ? { backgroundColor: '#f0fff0ff', boxShadow: "2px 2px 2px" } : {}}>
    {edit ? <>
      <CardHeader avatar={<Avatar>
        {fileToIcon(file)}
      </Avatar>} title={<TextField fullWidth size="small" value={label}
        onKeyUp={(ev) => { if (ev.key === "Enter") setEdit(false) }}
        onChange={(ev) => onEdited({ ...file, label: ev.target.value })} />} />

      <CardContent sx={{ minHeight: '12em' }}>
        {file.type === "File" && file.category === "image" && <img style={{ margin: "auto", objectFit: "contain", maxWidth: '100%', maxHeight: '100%', marginBottom: '1em' }} src={getCustomerFileUrl(customerId, file.id)} />}
        <TextField fullWidth label="Description" rows={4} multiline size="small" value={description}
          onChange={(ev) => onEdited({ ...file, description: ev.target.value })} />
      </CardContent>
    </>
      :
      <CardActionArea onDoubleClick={() => onMoveTo(file.id)} onClick={() => onSelect(file)}>
        <CardHeader avatar={<Avatar>
          {fileToIcon(file)}
        </Avatar>} title={toFileLabel(file)} />

        <CardContent sx={large ? { minHeight: '12em' } : { minHeight: '12em', maxHeight: '12em' }}>
          {file.type === "File" && file.category === "image" && (
            large ? <img style={{ margin: "auto", objectFit: "contain", maxWidth: '100%', maxHeight: '100%', marginBottom: '1em' }} src={getCustomerFileUrl(customerId, file.id)} /> :
              <ImageContainer>
                <PreviewImage src={getCustomerFileUrl(customerId, file.id)} />
              </ImageContainer>
          )}
          {description?.length > 0 ? description : "No description"}
        </CardContent>
      </CardActionArea>}
    <CardActions>
      <IconButton size="small">
        <FontAwesomeIcon icon={edit ? faSave : faEdit} onClick={() => setEdit(!edit)} />
      </IconButton>
    </CardActions>
  </Card></Grid>)
}

const CreateFolderDialog = (props: {
  open: boolean,
  setOpen: (open: boolean) => void,
  onCreate: (name: string) => void
}) => {
  const { open, setOpen, onCreate } = props
  const [newFolderName, setNewFolderName] = useState("")
  return (<Dialog fullWidth
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>
      Create Folder
    </DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Name"
        fullWidth
        variant="standard"
        value={newFolderName}
        onChange={(event) => { setNewFolderName(event.target.value) }}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>Cancel</Button>
      <Button variant="contained" color="primary" onClick={() => {
        onCreate(newFolderName)
      }}>Create</Button>
    </DialogActions>
  </Dialog>)
}

const apiUpdateCustomerFileDebounced = debouncePromise(apiUpdateCustomerFile, 1000)

export const FilesView = () => {
  const [treeRoot, setTreeRoot] = useState<FileTree | undefined>()
  const [currentTree, setCurrentTree] = useState<FileTree | undefined>()
  const [currentPath, setCurrentPath] = useState<FileTree[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const [createFolderOpen, setCreateFolderOpen] = useState(false)
  const customer = useCustomerState()
  const customerId = customer.selected?.id
  const [loading, setLoading] = useState<string | undefined>()

  async function removeFile() {
    if (customerId) {
      let files
      let index = 0;
      for (const id of selected) {
        try {
          setLoading(`Removing file (${index+1}/${selected.length})`)
          files = await apiDeleteCustomerFile(customerId, id)
          index++
        } catch {
        }
      }
      if (files) {
        setTreeRoot(files)
        const newPath = findSafeFromTree(currentTree?.id, files)
        if (newPath.length === 0) {
          setCurrentTree(currentPath[currentPath.length - 2])
          setCurrentPath(currentPath.slice(0, -1))
        } else {
          setCurrentPath(newPath)
          setCurrentTree(newPath[newPath.length - 1])
        }
      }
      setSelected([])
      setLoading(undefined)
    }
  }

  function moveTo(id: string) {
    if (treeRoot) {
      setSelected([])
      const path = findSafeFromTree(id, treeRoot)
      setCurrentPath(path)
      setCurrentTree(path[path.length - 1])
    }
  }

  function createFolder(name: string) {
    if (currentTree && customerId) {
      setLoading("Creating folder")
      setCreateFolderOpen(false)
      apiCreateCustomerFolder(name, customerId, currentTree.id).then((files) => {
        setTreeRoot(files)
        const path = findSafeFromTree(currentTree?.id, files)
        setCurrentPath(path)
        setCurrentTree(path[path.length - 1])
        setLoading(undefined)
      }).catch(() => setLoading(undefined))
    }
  }

  function editFileTree(file: FileTree) {
    if (currentTree && customerId) {
      apiUpdateCustomerFileDebounced(customerId, file).then((files: any) => {
        setTreeRoot(files)
        const path = findSafeFromTree(currentTree?.id, files)
        setCurrentPath(path)
        setCurrentTree(path[path.length - 1])
      })
    }
  }

  function select(id: string) {
    if (selected.findIndex(s => s === id) !== -1) {
      setSelected([...selected.filter(s => s !== id)])
    } else {
      setSelected([...selected, id])
    }
  }

  async function downloadFiles() {
    if (customerId && treeRoot) {
      for (const index in selected) {
        const fileId = selected[index]
        setLoading(`Downloading files (${(index+1)}/${selected.length})`)
        const path = findSafeFromTree(fileId, treeRoot)
        const file = path[path.length - 1]
        if (file.type === "File") {
          const response = await getCustomerFileBase64(customerId, fileId)
          const link = document.createElement("a")
          link.href = response
          link.setAttribute(
            'download',
            `${file.label}.${file.subCategory}`,
          );
          link.click()
          link.parentNode?.removeChild(link)
        }
      }
      setLoading(undefined)
      setSelected([])
    }
  }

  async function uploadFiles(fileList: FileList) {
    try {
      if (customerId) {
        let files = treeRoot
        for (let index = 0; index < fileList.length; index++) {
          setLoading(`Uploading files (${index+1}/${fileList.length})`)
          const formData = new FormData()
          const filename = fileList[index].name.substring(0, fileList[index].name.lastIndexOf("."))
          formData.append('File', fileList[index])
          files = await apiUploadCustomerFile(customerId, currentTree!.id, filename, formData)
        }
        if (files) {
          setTreeRoot(files)
          const path = findSafeFromTree(currentTree?.id, files)
          setCurrentPath(path)
          setCurrentTree(path[path.length - 1])
        }
      }
      setLoading(undefined)
    }
    catch {
      setLoading(undefined)
    }
  }


  useEffect(() => {
    if (customerId) {
      apiGetCustomerFiles(customerId).then((response) => {
        setSelected([])
        setTreeRoot(response)
        setCurrentTree(response)
        setCurrentPath([response])
      })
    }
  }, [customerId])

  return (<Box sx={{ margin: '50px' }}>
    <Typography variant='h1' sx={{ ...desktop_h1, mb: '32px' }}>Files</Typography>
    <Grid container columnGap={1}>
      <Grid item xs={12}>
        <PathContainer>
          {currentPath.map((f, index) => <>
            <Link style={{ cursor: "pointer" }} onClick={() => moveTo(f.id)}>{f.label}</Link>
            {index < currentPath.length - 1 ? <Separator>/</Separator> : ""}
          </>)}
        </PathContainer>
      </Grid>
      <Grid container xs={12} columnGap={1} marginTop={2}>
        {customerId && currentTree?.type === "Folder" ? <>
          <Button variant="contained" onClick={() => setCreateFolderOpen(true)} sx={regularBtnStyles}>Create folder</Button>
          <FormControl>
            <input
              style={{ display: 'none' }}
              id={`raised-button-files`}
              multiple
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  uploadFiles(event.target.files).then(() => {
                    event.target.value = ""
                  })
                }
              }} />
            <label htmlFor={`raised-button-files`}>
              <Button variant="contained" sx={regularBtnStyles} component="span" color="primary">Upload file</Button>
            </label>
          </FormControl>
          <Button size="small" disabled={selected.length <= 0} sx={regularBtnStyles} onClick={() => downloadFiles()}>Download</Button>
          <Button size="small" disabled={selected.length <= 0} variant="contained" color="error" onClick={() => removeFile()}>Delete</Button>
        </> : <></>}
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
    <Grid container marginBottom={2} columnGap={1} rowGap={1}>
      {(!customerId || !currentTree || !currentTree.children.length) && currentTree?.type === "Folder" && <Grid item xs={12}>No files in folder.</Grid>}
      {customerId && currentTree?.children.map(f => <FileCard selected={selected.findIndex((value) => value === f.id) !== -1}
        customerId={customerId} file={f}
        onMoveTo={(id: string) => moveTo(id)}
        onUpdated={(file: FileTree) => editFileTree(file)}
        onSelect={(file: FileTree) => select(file.id)} />)}
    </Grid>
    {customerId && currentTree?.type === "File" && <FileCard selected={selected.findIndex((value) => value === currentTree?.id) !== -1} large={true} customerId={customerId} file={currentTree} onMoveTo={(id: string) => moveTo(id)}
      onUpdated={(file: FileTree) => editFileTree(file)}
      onSelect={(file: FileTree) => select(file.id)} />}
    {loading && <LoadingModal message={loading} />}
    <CreateFolderDialog open={createFolderOpen} setOpen={setCreateFolderOpen}
        onCreate={createFolder}/>
  </Box>)
}