import { IdNameObj } from "../../api/Types"

export const SET_LINKED_IN_ASSETS = 'SET_LINKED_IN_ASSETS'
export const CLEAR_LINKEDIN_ASSETS = 'CLEAR_LINKEDIN_ASSETS'
export const SET_GOOGLE_ADS_CONFIG = 'SET_GOOGLE_ADS_CONFIG'

type SetLinkedInAssetsAction = {
  type: typeof SET_LINKED_IN_ASSETS,
  data: LinkedInAssets
}

type SetGoogleAdsConfigAction = {
  type: typeof SET_GOOGLE_ADS_CONFIG,
  data: GoogleAdsConfig
}

type ClearLinkedInAssetsAction = {
  type: typeof CLEAR_LINKEDIN_ASSETS,
}

export type LinkedInAdAccount = {
  id: number | string,
  name: string,
  type?: string
}

export type LinkedInAssets = {
  adAccounts: LinkedInAdAccount[]
  currentAssets: {
    adAccount: LinkedInAdAccount
  },
  savedAssetsIntoDB: {
    adAccount: LinkedInAdAccount
  },
  openAssetsDialog: boolean
}

export type AdminAction = SetLinkedInAssetsAction | SetGoogleAdsConfigAction | ClearLinkedInAssetsAction

export type AdminState = {
  linkedInAssets: LinkedInAssets
  googleAdsConfig: GoogleAdsConfig
}

export type SavedAssets = {
  linkedInAssets: {
    adAccount: LinkedInAdAccount
  }
}

export type GoogleAdsCustomerClient = {
  clientCustomer: string,
  descriptiveName: string,
  id: string,
  level: string,
  manager: boolean,
  resourceName: string,
  status: string
}

export type GoogleAdsLoginAccount = {
  id: string,
  name: string,
  customerClients: GoogleAdsCustomerClient[]
}

export type GoogleAdsChildCustomer = {
  id: string,
  name: string,
  googleAdsSettings: { loginAccount: IdNameObj, adAccounts: IdNameObj[] }
}

export type GoogleAdsSettings = {
  loginAccount: IdNameObj,
  adAccounts: IdNameObj[],
  childCustomers: GoogleAdsChildCustomer[]
}

export type GoogleAdsConfig = {
  loginAccounts: GoogleAdsLoginAccount[]
  adAccounts: IdNameObj[]
  currentSettings: {
    loginAccount: GoogleAdsLoginAccount
    adAccounts: IdNameObj[]
    childCustomers: GoogleAdsChildCustomer[]
  },
  savedSettingsIntoDB: {
    loginAccount: IdNameObj
    adAccounts: IdNameObj[]
    childCustomers: GoogleAdsChildCustomer[]
  },
  openConfigDialog: boolean
}

