import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { capitalizeFirstLetter, beautifyString, convertToFinnishDate, gendersToString, isItOldDate } from '../../../../utils/helpers'
import { Colors } from '../../../../constants/colors';

interface AdSetDetailsProps {
  adSet: any
}
const AdSetDetails = ({ adSet }: AdSetDetailsProps) => {
  return (
    <>
      <Grid container rowSpacing={4} sx={{ marginTop: 0.5 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Ad set details</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2'>
            Status
          </Typography>
          <Typography style={styles.caption} variant='caption' sx={{ color: adSet.status === "PAUSED" ? Colors.yellow : Colors.green }}>
            {capitalizeFirstLetter(adSet.status)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Billing event
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {beautifyString(adSet.billing_event)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Optimization goal
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {beautifyString(adSet.optimization_goal)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={4} sx={{ marginTop: 0.5 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            {adSet.daily_budget > 0 ? 'Daily budget' : 'Lifetime budget'}
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {adSet.daily_budget > 0 ? `${adSet.daily_budget / 100} €` : `${adSet.lifetime_budget / 100} €`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Bid amount
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {adSet.bid_amount ? `${adSet.bid_amount / 100} €` : '-'}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Start time
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {convertToFinnishDate(adSet.start_time)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            End time
          </Typography>
          {adSet?.end_time && <Typography style={styles.caption} sx={{ color: isItOldDate(adSet.end_time) ? 'red !important' : 'green !important' }} variant='caption'>
            {convertToFinnishDate(adSet.end_time)}
          </Typography>}
          {!adSet?.end_time && <Typography style={styles.caption} sx={{ color: 'green !important' }} variant='caption'>
            Ongoing
          </Typography>}
        </Grid>
      </Grid>

      <Grid container rowSpacing={4} sx={{ marginTop: 0.5 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Ad set targeting details</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Age
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {adSet.targeting.age_min} - {adSet.targeting.age_max}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Genders
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {adSet.targeting.genders ? gendersToString(adSet.targeting.genders) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={styles.body2} variant='body2' sx={{}}>
            Countries
          </Typography>
          <Typography style={styles.caption} variant='caption'>
            {adSet.targeting.geo_locations?.countries?.map((c: string) => c).join(', ')}
          </Typography>
        </Grid>
      </Grid>
    </>

  )
}

const styles = {
  body1: {
    margin: '10px auto',
    color: '#000'
  },
  body2: {
    margin: '10px auto'
  },
  caption: {
    color: '#7d7d7d'
  },
}

export default AdSetDetails;