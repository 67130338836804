import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { blackBtn } from '../../../styles/buttons';
import { useStateValue } from '../../../context/meta/MetaContext';
import { useCustomerState } from '../../../context/customer/CustomerContext';
import { useEffect, useState } from 'react';
import { getAllPostBoostingRules } from '../../../api/Posts';
import { setPostBoostingRules } from '../../../context/meta/MetaActions';
import PostBoostingRules from './PostBoostingRules';


const AdvancedPostBoosting = () => {
  const [{ postBoostingRules }, dispatch] = useStateValue()
  const state = useCustomerState()
  const [loading, setloading] = useState(false)



  const history = useHistory()


  const handleCreate = () => {
    history.push('/post-booster/create')
  }


  return (
    <>
      <Grid container sx={{ mt: 1, marginLeft: '0.1em' }}>
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ mt: 1, mb: 2 }}>Rules</Typography>
          <Button onClick={handleCreate} variant='contained' sx={{textTransform: 'none'}}>Create</Button>
        </Grid>
      </Grid>
      <PostBoostingRules />
    </>
  )
}

export default AdvancedPostBoosting