import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type BarChartProps = {
  options: any,
  data: any
}

const BarChart = (props: BarChartProps) => {
  
  return (
    <Bar options={props.options} data={props.data} />
  )
}

export default BarChart
