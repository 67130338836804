import { CustomerTree } from "../api/Types";

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Replace Underscores with Spaces and capitalize first letter
export const beautifyString = (string: string) => {
  const result = string.replaceAll("_", " ");
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const stringToArrayOfStrings = (string: string) => {
  let result = string.split(",");
  // remove whitespaces from the begining and the end of a string
  result = result.map((s: string) => s.trim())
  return result;
};

export const arrayOfStringsToString = (stringArray: string[]) => {
  const result = stringArray.join(", ");
  return result;
};

export const convertToMaterialDateTime = (d: Date) => {
  return `${d.toISOString().slice(0, 10)}T${d.toLocaleTimeString().slice(0, 5)}`;
};

export const gendersToString = (genders: number[]) => {
  const result = genders.map((g: number) => {
    if(g === 0){
      return "Male, Female"
    }else{
      return g === 1 ? "Male" : "Female"
    }
  });
  return result.join(", ");
};

export const convertToFinnishDate = (date: string) => {
  /*
    let result: string[] = date.split('T');
    return `${result[0].replaceAll('-', '.')}, ${result[1].replaceAll(':', '.').slice(0,5)}`
    */
  const result: Date = new Date(date);
  const d = `${result.getDate()}.${result.getMonth() + 1}.${result.getFullYear()}`;
  const minutes = (result.getMinutes() < 10 ? "0" : "") + result.getMinutes();
  const time = `${result.getHours()}.${minutes}`;
  return `${d}, ${time}`;
};

export const isItOldDate = (date: string): boolean => {
  const received = new Date(date);
  const today = new Date();
  if (received.getTime() < today.getTime()) {
    return true;
  }
  return false;
};

export const getEndDate = (days: number): Date => {
  // 86400000 is the number of millisenconds in a day
  const ms = new Date().getTime() + 86400000 * days;
  const endDate = new Date(ms);
  return endDate;
};

export const generateSimpleUniqueId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
}

// regex to ckeck if a string contains a url.
export const URL_REGEX = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?");


export const convertObjectToArray = (obj: any): any[] => {
  return Object.values(obj).map((value => value));
};

export const findActiveServices = (id: number, customers: CustomerTree[]): string[] | undefined => {
  for (const customer of customers) {
    if (customer.id === id) {
      return customer.services
    }
    const services = findActiveServices(id, customer.children)
    if (services) {
      return services
    }
  }
  return
}

export const formatDateAsYearMonthDay = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const trimText = (text: string, maxLength: number) => {
  if (text && text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
}

export const beautifyAndCapitalizeFirstLetter = (str: string) => {
  const transformedStr = str.replace(/[_\.]/g, ' ')
  return transformedStr.charAt(0).toUpperCase() + transformedStr.slice(1)
};