

import { Alert, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { SlackSettings, YoutubeContentSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiOAuthLogin, apiOAuthSlackLogin, apiOAuthYoutubeContentLogin } from '../../api/Auth';
import { apiGetSlackSettings, apiGetYoutubeContentSettings } from '../../api/Customer';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import * as uuid from 'uuid'
import { regularBtnStyles } from '../../styles/buttons';

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type SlackAdminProps = {
  customerId: string
}

export const SlackAdmin = (props: SlackAdminProps) => {
  const { customerId } = props
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState<SlackSettings>({ channels: [] })
  const [_, alertDispatch] = useAlertStateValue()

  useEffect(() => {
    apiGetSlackSettings(customerId).then((settings) => setSettings(settings))
  }, [customerId])

  function doSlackLogin() {
    setLoading(true)
    apiOAuthSlackLogin(customerId, settings).then((response: any) => {
      window.location.href = response.url
    }).catch(() => {
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in authorization. Please try again later.", duration: null }))
    })
  }

  return (
    <Grid container>
      {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
        <Grid component={"form"} container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Channels in use for Suite</Typography>
            <TextField fullWidth size='small' variant="outlined" value={settings.channels.join(",")}
              onChange={(ev) => setSettings({ channels: ev.target.value.split(",") })} />
          </Grid>

          <Grid item xs={12} sx={{ pt: 0 }}>
            <Button sx={{ ...regularBtnStyles, mt: 1 }} onClick={doSlackLogin}>
              Authorize
            </Button>
          </Grid>
        </Grid>
      </Grid>}
    </Grid>
  )
}
