import axios from "axios";
import { apiRootUrl, AuthenticatedUser, BingAdsSettings, GoogleAdsSettings, LoginResponse, OAuthLoginResponse, OAuthSystem, LinkedInAdsSettings, ReadpeakAdsSettings, YoutubeContentSettings, SlackSettings, DatasetSettings } from "./Types";

axios.defaults.withCredentials = true;

export const apiDoLogin = async (token: string): Promise<AuthenticatedUser | undefined> => {
  const loginResponse = await fetch(apiRootUrl + "/user/login", {
    method: "POST",
    credentials:'include',
    body: JSON.stringify({ token }),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (loginResponse.status > 201) {
    throw Error(loginResponse.status.toString())
  }
  const json = (await loginResponse.json()) as LoginResponse
  return { name: json.name, email: json.email, token, picture: json.picture, identityProvider: json.identityProvider }
}

export const apiDoLogout = async () => {
  const logoutResponse = await fetch(apiRootUrl + "/user/logout", {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (logoutResponse.status > 201) {
    return
  }
}

export const apiOAuthLogin = async (system: OAuthSystem, customerId?: number): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}?system=${system}`;
  const oauthResponse = await fetch(url, {
    method: "GET",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
  return await oauthResponse.json() as OAuthLoginResponse
}

export const apiOAuthGoogleAnalyticsLogin = async (customerId: number, accountIds: string[]): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}?system=googleAnalytics&accountIds=${accountIds.join(",")}`;
  const oauthResponse = await fetch(url, {
    method: "GET",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
  return await oauthResponse.json() as OAuthLoginResponse
}

export const apiOAuthSearchConsoleLogin = async (customerId: number, websiteUrl: string): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}?system=searchConsole&websiteUrl=${encodeURIComponent(websiteUrl)}`
  const response = await fetch(url, {
    method: "GET",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
  return await response.json() as OAuthLoginResponse
}

export const apiOAuthGoogleAdsLogin = async (customerId: number): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}/googleAds`
  const response = await fetch(url, {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({})
  })
  if (response.status !== 200) {
    throw Error("Google ads login failed")
  }
  return await response.json() as OAuthLoginResponse
}

export const apiOAuthBingAdsLogin = async (customerId: number, bingAdsSettings: BingAdsSettings): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}/bingAds`
  const response = await fetch(url, {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bingAdsSettings)
  })
  if (response.status !== 200) {
    throw Error("Bing ads login failed")
  }
  return await response.json() as OAuthLoginResponse
}

export const apiOAuthLinkedInAdsLogin = async (customerId: string): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}/linkedInAds`
  const response = await fetch(url, {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({})
  })
  if (response.status !== 200) {
    throw Error("LinkedIn ads login failed")
  }
  return await response.json() as OAuthLoginResponse
}

export const apiOAuthYoutubeContentLogin = async (customerId: string, youtubeContentSettings: YoutubeContentSettings): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}/youtubeContent`
  const response = await fetch(url, {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(youtubeContentSettings)
  })
  if (response.status !== 200) {
    throw Error("Youtube content login failed")
  }
  return await response.json() as OAuthLoginResponse
}

export const apiOAuthSlackLogin = async (customerId: string, settings: SlackSettings): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}/slack`
  const response = await fetch(url, {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(settings)
  })
  if (response.status !== 200) {
    throw Error("Slack login failed")
  }
  return await response.json() as OAuthLoginResponse
}

export const apiOAuthDatasetLogin = async (customerId: string, settings: DatasetSettings): Promise<OAuthLoginResponse> => {
  const url = `${apiRootUrl}/user/oauth/login/${customerId}/dataset`
  const response = await fetch(url, {
    method: "POST",
    credentials:'include',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(settings)
  })
  if (response.status !== 200) {
    throw Error("Dataset login failed")
  }
  return await response.json() as OAuthLoginResponse
}

export const apiSaveReadpeakCredentials = async (customerId: string, readpeakAdsSettings: ReadpeakAdsSettings): Promise<any> => {
  try {
    const url = `${apiRootUrl}/user/saveCredentials/${customerId}/readpeakAds`
    return await axios.post(url, readpeakAdsSettings)
  } catch (error) {
    console.log("apiSaveReadpeakCredentials error", error)
  }
}

export const apiDoAuth0Login = async (): Promise<any> => {
  try {
    return await axios.get(`${apiRootUrl}/user/auth0/login/`)
  } catch (error) {
    console.log(error);
  }
};

export const apiDoAuth0Logout = async (): Promise<any> => {
  try {
    return await axios.get(`${apiRootUrl}/user/auth0/logout/`);
  } catch (error) {
    console.log(error);
  }
};

/**
 * Changes passwords for users with Auth0 credentials.
 * @param customerId
 * @param bodyData
 */
export const apiDoAuth0ChangePassword = async (bodyData: {}): Promise<any> => {
  try {
    return await axios.post(`${apiRootUrl}/user/auth0/changePassword/`, bodyData);
  } catch (error) {
    console.log(error);
  }
};

/**
 * @desc used to get the assets (saved into the session) that a user own or has access to
 * @param customerId
 */
export const apiGetUserAssets = async (customerId: string) => {
  try {
    const res = await axios.get(`${apiRootUrl}/user/${customerId}/user-assets`);
    return res.data
  } catch (error: any) {
    console.log("apiGetUserAssets", error);
    return error.response.data
  }
}
