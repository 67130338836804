

import { Button, Grid, TextField, Typography } from '@mui/material';
import { apiGetGoogleAnalyticsSettings } from '../api/Customer';
import { useEffect, useState } from 'react';
import { GoogleAnalyticsSettings } from '../api/Types';
import { apiOAuthGoogleAnalyticsLogin } from '../api/Auth';
import { LoadingModal } from './Modal';
import { regularBtnStyles } from '../styles/buttons';


const textFieldTextStyles = {
  mb: 0.3
}

const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}

export type GoogleAnalyticsAdminProps = {
  customerId: number
}

export const GoogleAnalyticsAdmin = (props: GoogleAnalyticsAdminProps) => {
  const { customerId } = props
  const [loading, setLoading] = useState(true)
  const [gaSettings, setAccountIds] = useState<GoogleAnalyticsSettings>({ accountIds: [] })

  const doGoogleAnalyticsLogin = () => {
    apiOAuthGoogleAnalyticsLogin(customerId, gaSettings.accountIds).then((response) => {
      window.location.href = response.url
    }).catch(_ => {
      alert("Error in authentication. Please try again later.")
    })
  }

  useEffect(() => {
    apiGetGoogleAnalyticsSettings(customerId).then((response) => {
      setLoading(false)
      if (response) {
        setAccountIds(response)
      } else {
        setAccountIds({ accountIds: [] })
      }
    })
  }, [customerId])

  return (<Grid container>
    <Grid item xs={12} sx={gridItemStyles}>
      {loading ? <LoadingModal /> :
        <Grid container sx={{ maxWidth: 800 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Account Ids</Typography>
            <TextField fullWidth size='small' variant="outlined"
              value={gaSettings.accountIds.join(",")}
              onChange={(ev) => setAccountIds({ accountIds: ev.target.value.split(",") })} />
          </Grid>

        </Grid>}
    </Grid>

    <Grid container xs={12} sx={gridItemStyles}>
      <Grid item xs={2}>
        <Button variant='contained' color="primary"
          sx={{ ...regularBtnStyles }}
          onClick={() => doGoogleAnalyticsLogin()}>Authorize</Button>
      </Grid>
    </Grid>
  </Grid>)
}
